/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactRequest
 */
export interface ContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    message?: string;
}

export function ContactRequestFromJSON(json: any): ContactRequest {
    return ContactRequestFromJSONTyped(json, false);
}

export function ContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ContactRequestToJSON(value?: ContactRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'subject': value.subject,
        'message': value.message,
    };
}


