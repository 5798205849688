/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    OrderMenu,
    OrderMenuFromJSON,
    OrderMenuFromJSONTyped,
    OrderMenuToJSON,
    Payment,
    PaymentFromJSON,
    PaymentFromJSONTyped,
    PaymentToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    Voucher,
    VoucherFromJSON,
    VoucherFromJSONTyped,
    VoucherToJSON,
} from './';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    uuid?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    discount?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    invoiceCorrectionNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    deletedAt?: Date;
    /**
     * 
     * @type {User}
     * @memberof Order
     */
    user?: User;
    /**
     * 
     * @type {Array<OrderMenu>}
     * @memberof Order
     */
    menus?: Array<OrderMenu>;
    /**
     * 
     * @type {Payment}
     * @memberof Order
     */
    payment?: Payment;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    invoice?: Address;
    /**
     * 
     * @type {Array<Voucher>}
     * @memberof Order
     */
    vouchers?: Array<Voucher>;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'invoiceNumber': !exists(json, 'invoiceNumber') ? undefined : json['invoiceNumber'],
        'invoiceCorrectionNumber': !exists(json, 'invoiceCorrectionNumber') ? undefined : json['invoiceCorrectionNumber'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'menus': !exists(json, 'menus') ? undefined : ((json['menus'] as Array<any>).map(OrderMenuFromJSON)),
        'payment': !exists(json, 'payment') ? undefined : PaymentFromJSON(json['payment']),
        'invoice': !exists(json, 'invoice') ? undefined : AddressFromJSON(json['invoice']),
        'vouchers': !exists(json, 'vouchers') ? undefined : ((json['vouchers'] as Array<any>).map(VoucherFromJSON)),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uuid': value.uuid,
        'userId': value.userId,
        'status': value.status,
        'price': value.price,
        'discount': value.discount,
        'invoiceNumber': value.invoiceNumber,
        'invoiceCorrectionNumber': value.invoiceCorrectionNumber,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'user': UserToJSON(value.user),
        'menus': value.menus === undefined ? undefined : ((value.menus as Array<any>).map(OrderMenuToJSON)),
        'payment': PaymentToJSON(value.payment),
        'invoice': AddressToJSON(value.invoice),
        'vouchers': value.vouchers === undefined ? undefined : ((value.vouchers as Array<any>).map(VoucherToJSON)),
    };
}


