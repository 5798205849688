import React from 'react';
import styles from './thirdImage.module.scss';

type ThirdImageProps = {
  invert?: boolean;
  children?: React.ReactNode;
  title: string;
  image?: string;
};


const ThirdImage = ({ title, invert, children, image }: ThirdImageProps) => (
  <section className={[styles.thirdImage, invert && styles.invert].join(' ')}>

    <article className={styles.text}>
      <h2 className="h2">{title}</h2>
      {children}
    </article>
    <div className={styles.pic}>
      <picture>
        <img src={image} alt='' />
      </picture>
    </div>
  </section>
);
export default ThirdImage;
