import React from 'react';
import styles from './dayDelivery.module.scss';
import { displayPrice } from 'src/logic/utils/price';

type DailyDeliveryProps = {
  diff: number;
  deliveryPrice: number;
  hasLocation: boolean;
  freeDelivery: boolean;
}

const DayDelivery = ({ diff, deliveryPrice, hasLocation, freeDelivery }: DailyDeliveryProps) => (
  <section className={styles.dayDeliveryBox}>
    <h3 className={styles.deliveryHeader}>Dostawa</h3>
    <div className={styles.deliveryDetails}>
      { hasLocation &&
      <p>
        Dostarczymy za darmo! :)
      </p>
      }
      { !hasLocation && freeDelivery &&
      <p>
        Twoją dostawę dostarczymy za darmo! :)
      </p>
      }
      { !hasLocation && !freeDelivery &&
      <p>
        Brakuje <b>{displayPrice(diff)}</b> do darmowej <br />dostawy w tym dniu
      </p>
      }
      <b className={styles.deliveryValue}>{displayPrice((!!hasLocation || !!freeDelivery) ? 0 : deliveryPrice)}</b>
    </div>
  </section>
);

export default DayDelivery;
