import React, { useState, useCallback } from 'react';
import styles from './forms.module.scss';
import cn from 'classnames';
import ScrollBtn from '../buttons/scrollBtn/scrollBtn';

type FormProps = {
  children?: React.ReactNode;
  className?: string;
  full?: boolean;
  align?: string;
};

type BtnLinkProps = {
  link: string;
  type: 'black' | 'white' | 'orange' | 'red' | 'crimson';
  children?: React.ReactNode;
  className?: string;
};

type BtnProps = {
  type: 'black' | 'white' | 'orange' | 'red' | 'crimson';
  children?: React.ReactNode;
  action?(): void;
  disabled?: boolean;
};

export const FormSection = ({ children, className }: FormProps) => (
  <section className={cn(styles.section, className)}>{children}</section>
);

export const FormRow = ({ children, full, align }: FormProps) => (
  <div className={[styles.row, full && styles.full, align && styles[align]].join(' ')}>{children}</div>
);

export const BtnRow = ({ children }: FormProps) => <div className={styles.btnRow}>{children}</div>;

export const BtnLink = ({ link, type, children, className }: BtnLinkProps) => {
  const cssClass = {
    black: styles.btnBlack,
    white: styles.btnWhite,
    orange: styles.btnOrange,
    red: styles.btnRed,
    crimson: styles.btnCrimson
  };
  return (
    <a href={link} className={cn(styles.btn, type ? cssClass[type] : styles.btnBlack, className)}>
      {children}
    </a>
  );
};

export const BtnForm = ({ action, type, children, disabled }: BtnProps) => {
  const cssClass = {
    black: styles.btnBlack,
    white: styles.btnWhite,
    orange: styles.btnOrange,
    red: styles.btnRed,
    crimson: styles.btnCrimson
  };
  return (
    <button
      type='submit'
      onClick={action}
      disabled={disabled}
      className={[styles.btn, type ? cssClass[type] : styles.btnBlack].join(' ')}
    >
      {children}
    </button>
  );
};

export const VoucherInput = ({ addCode }: { addCode?: (code: string) => unknown }) => {
  const [code, setCode] = useState('');
  const sendCode = useCallback(() => {
    addCode?.(code);
    setCode('');
  }, [code, addCode]);
  const handleChange = useCallback(
    (e) => {
      setCode(e.target.value);
    },
    [setCode]
  );
  const handleKeyDown = (e: React.KeyboardEvent) => e.key === 'Enter' && sendCode();
  return (
    <div className={styles.voucherWrapper}>
      <input
        type='text'
        value={code}
        onChange={handleChange}
        className={styles.voucherInput}
        placeholder='Wprowadź kod'
        onKeyDown={handleKeyDown}
      />
      {code && <ScrollBtn action={sendCode} />}
    </div>
  );
};
