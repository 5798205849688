import { useMemo } from 'react';
import { FormikTouched, FormikErrors } from 'formik';
import { get, isPlainObject } from 'lodash';

export const useErrorsList = <T extends object>(keys: (keyof T)[], touched: FormikTouched<T>, errors: FormikErrors<T>, status?: string) => {
  const errorList: string[] = useMemo(() => {
      const clientErrors = keys.filter(f => touched[f])
        .map(f => errors[f])
        .filter((e: unknown): e is string => !!e && typeof e === 'string')
        .map(e => `${e}`);
      return status ? [status, ...clientErrors] : clientErrors
    },
    [keys, errors, touched, status]
  );
  return errorList;
}

export const hasErrors = <T extends {}>(errors: FormikErrors<T>, touched: FormikTouched<T>): boolean => {
  return Object.entries(errors)
    .filter(([, e]) => e)
    .map(([key]) => isPlainObject(get(errors, key)) ? hasErrors(get(errors, key), get(touched, key)) : get(touched, key))
    .some(x => x);
}
