import cn from 'classnames';
import React, { Component } from 'react';

import ReactSelect from 'react-select';
import { Props as LibSelectProps } from 'react-select';
import { Props as RightActionProps, RightActions } from '../rightActions';
import styles from './select.module.scss';

export type OptionType = { label: string; value: string };

export type SelectProps<TOptionType extends OptionType> = LibSelectProps<TOptionType> & RightActionProps & {
  fill?: boolean;
};

const noOptionsMessage = () => 'Brak dopasowania';

export class Select<TOptionType extends OptionType> extends Component<SelectProps<TOptionType>> {
  public static defaultProps = {
    disabled: false,
    fill: false,
  };
  public render() {
    const { fill, disableActions, rightActions, disabled, ...rest } = this.props;
    const containerClassses = cn(
      styles.container,
      {
        [styles.fill]: fill,
      },
    );
    const selectClasses = cn(
      styles.select,
      {
        [styles.fill]: fill,
        [styles.multiline]: rest.multiline
      },
    );

    return (
      <div className={containerClassses}>
        <ReactSelect className={selectClasses} classNamePrefix='s' noOptionsMessage={noOptionsMessage} {...rest} isDisabled={disabled} />
        <RightActions rightActions={rightActions} disableActions={disableActions} disabled={disabled} multiline={rest.multiline} />
      </div>
    );
  }
}
