import React, { useEffect, useState, useRef } from 'react';
import styles from './basketBtn.module.scss';
import { displayPrice } from 'src/logic/utils';

type BasketBtnProps = {
  quantity: number;
  price: number;
  openBasket(): void;
};

const BasketBtn = ({ quantity, price, openBasket }: BasketBtnProps) => {
 
  const [showPrice, setShowPrice] = useState(false);
  const timeoutToClear = useRef<NodeJS.Timeout>();
  
  useEffect( ()=> {
    timeoutToClear.current && clearTimeout(timeoutToClear.current);
    setShowPrice(true);
    timeoutToClear.current = setTimeout(()=>setShowPrice(false), 1500);
  },[price]);

  return (
  <a className={styles.basketBtn} title="koszyk" onClick={openBasket}>
    {quantity}
    {!!price && <div className={[styles.priceTooltip, showPrice ? 'show' : 'hide'].join(' ') }>{displayPrice(price)}</div>}
  </a>
);
}

export default BasketBtn;
