/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    RoleDataFrame,
    RoleDataFrameFromJSON,
    RoleDataFrameToJSON,
} from '../models';

export interface ApiRolesGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiRolesIdDeleteRequest {
    id: number;
}

export interface ApiRolesIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiRolesPostRequest {
    relations?: string;
    deleted?: boolean;
    role?: Role;
}

/**
 * no description
 */
export class RoleApi extends runtime.BaseAPI {

    /**
     * Get list of Role
     */
    async apiRolesGetRaw(requestParameters: ApiRolesGetRequest): Promise<runtime.ApiResponse<RoleDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Role
     */
    async apiRolesGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<RoleDataFrame> {
        const response = await this.apiRolesGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Role
     */
    async apiRolesIdDeleteRaw(requestParameters: ApiRolesIdDeleteRequest): Promise<runtime.ApiResponse<Role>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRolesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }

    /**
     * Remove entity of Role
     */
    async apiRolesIdDelete(id: number): Promise<Role> {
        const response = await this.apiRolesIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Role
     */
    async apiRolesIdGetRaw(requestParameters: ApiRolesIdGetRequest): Promise<runtime.ApiResponse<Role>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRolesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }

    /**
     * Get entity of Role
     */
    async apiRolesIdGet(id: number, relations?: string, deleted?: boolean): Promise<Role> {
        const response = await this.apiRolesIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Role (if id defined)
     */
    async apiRolesPostRaw(requestParameters: ApiRolesPostRequest): Promise<runtime.ApiResponse<Role>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleToJSON(requestParameters.role),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Role (if id defined)
     */
    async apiRolesPost(relations?: string, deleted?: boolean, role?: Role): Promise<Role> {
        const response = await this.apiRolesPostRaw({ relations: relations, deleted: deleted, role: role });
        return await response.value();
    }

}
