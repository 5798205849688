import { toast } from 'react-toastify';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { ActionType, getType, createAsyncAction } from 'typesafe-actions';
import { push } from 'connected-react-router';
import { Filter } from './apiConfig';

export type UpsertParams<TEntity> = { entity: TEntity; redirectTo?: (id: number) => string } & { [k in keyof TEntity]?: Blob };

export type GetManyParams<TEntity> = {
  filter?: Filter<TEntity>;
  offset?: number;
  limit?: number;
}

type DataFrame<TEntity> = {
  count?: number;
  limit?: number;
  offset?: number;
  data: TEntity[];
}

type Params<TEntity> = {
  getDetails: (id: number) => Promise<TEntity>;
  getAll: (filter?: Filter<TEntity>, offset?: number, limit?: number) => Promise<DataFrame<TEntity>>;
  upsert: (t: Omit<UpsertParams<TEntity>, 'redirectTo'>) => Promise<TEntity>;
  delete: (i: number) => Promise<unknown>;
}

export const createAdminLogic = <TEntry extends string>(entry: TEntry) => <TEntity extends { id?: number }>(params: Params<TEntity>) => {
  const idStr = (e: string) => e;
  const idNum = (e: number) => e;
  // this is small trick that allows compiler to infer info that entity is of type TEntity
  const packEntity = (e: TEntity) => [e];
  const packEntityAndBlobs = (dt: UpsertParams<TEntity>) => [dt];
  const packSafeEntityAndBlobs = (p: { id: number; dt: (et: TEntity) => UpsertParams<TEntity> }) => p;

  const actions = {
    getDetails: createAsyncAction([`get ${entry}`, idNum], [`get ${entry} success`, packEntity], [`get ${entry} failure`, idStr])<number, [TEntity], string>(),
    getAll: createAsyncAction(`get all ${entry}s`, `get all ${entry}s success`, `get all ${entry}s failure`, `get all ${entry}s cancel`)<GetManyParams<TEntity> | void, TEntity[], string>(),
    upsert: createAsyncAction([`upsert ${entry}`, packEntityAndBlobs], [`upsert ${entry} success`, packEntity], [`upsert ${entry} failure`, idStr])<[UpsertParams<TEntity>], [TEntity], string>(),
    safeUpsert: createAsyncAction([`safe upsert ${entry}`, packSafeEntityAndBlobs], [`safe upsert ${entry} success`, packEntity], [`safe upsert ${entry} failure`, idStr])<{ id: number; dt: (entity: TEntity) => UpsertParams<TEntity> }, [TEntity], string>(),
    delete: createAsyncAction(`delete ${entry}`, `delete ${entry} success`, `delete ${entry} failure`, `delete ${entry} cancel`)<number, number, string>(),
  }

  type State = {
    pending: boolean;
    list: TEntity[];
    details: TEntity | null;
  }

  const initialState: State = {
    pending: true,
    list: [],
    details: null
  }

  type Actions = ActionType<typeof actions>;

  const reducer = (state: State = initialState, action: Actions): State => {
    switch (action.type) {
      case getType(actions.getDetails.request): return { ...state, pending: true };
      case getType(actions.getDetails.success): return {
        ...state,
        pending: false,
        details: (action as ReturnType<typeof actions.getDetails.success>).payload[0]
      };
      case getType(actions.getDetails.failure): return { ...state, pending: false, details: null };

      case getType(actions.getAll.request): return { ...state, pending: true };
      case getType(actions.getAll.success): {
        const list = (action as ReturnType<typeof actions.getAll.success>).payload;
        return { ...state, pending: false, list: !list.length && !state.list.length ? state.list : list };
      }
      case getType(actions.getAll.failure): return { ...state, pending: false, list: initialState.list };

      case getType(actions.upsert.request): return { ...state, pending: true };
      case getType(actions.upsert.success): {
        const entity = (action as ReturnType<typeof actions.upsert.success>).payload[0]
        return {
          ...state,
          pending: false,
          details: entity,
          list: state.list.some(e => e.id === entity.id) ? state.list.map(e => e.id === entity.id ? entity : e) : [...state.list, entity]
        };
      }
      case getType(actions.upsert.failure): return { ...state, pending: false };

      case getType(actions.safeUpsert.request): return { ...state, pending: true };
      case getType(actions.safeUpsert.success): {
        const entity = (action as ReturnType<typeof actions.safeUpsert.success>).payload[0]
        return {
          ...state,
          pending: false,
          details: entity,
          list: state.list.some(e => e.id === entity.id) ? state.list.map(e => e.id === entity.id ? entity : e) : [...state.list, entity]
        };
      }
      case getType(actions.safeUpsert.failure): return { ...state, pending: false };

      case getType(actions.delete.request): return { ...state, pending: true };
      case getType(actions.delete.success): {
        const payload = (action as ReturnType<typeof actions.delete.success>).payload
        return {
          ...state,
          pending: false,
          details: state.details?.id === payload ? null : state.details,
          list: state.list.filter(e => e.id !== payload)
        }
      }
      case getType(actions.delete.failure): return { ...state, pending: false, details: null };
      default: return state;
    }
  }

  function* onGetDetails({ payload }: ReturnType<typeof actions.getDetails.request>) {
    try {
      const entity: TEntity = yield call(params.getDetails, payload);
      
      yield put(actions.getDetails.success(entity))
    } catch (e) {
      yield call(toast.error, 'Wystąpił błąd, spróbuj ponownie za chwilę');
      yield put(actions.getDetails.failure(`${e}`));
    }
  }


  function* onGetAll({ payload: {filter, offset, limit} = {filter: undefined, offset: undefined, limit: undefined} }: ReturnType<typeof actions.getAll.request>) {
    try {
      const entities: DataFrame<TEntity> = yield call(params.getAll, filter, offset, limit);
      yield put(actions.getAll.success(entities.data))
    } catch (e) {
      yield call(toast.error, 'Nie można pobrać danych, spróbuj ponownie za chwilę');
      yield put(actions.getAll.failure(`${e}`));
    }
  }

  function* onUpsert({ payload: [{ redirectTo, ...data }] }: ReturnType<typeof actions.upsert.request>) {
    try {
      const upserted: TEntity = yield call(params.upsert, data);
      yield put(actions.upsert.success(upserted));
      if (redirectTo && upserted.id) {
        yield put(push(redirectTo(upserted.id)))
      }
    } catch (e) {
      yield call(toast.error, 'Nie można zapisać, spróbuj ponownie za chwilę');
      yield put(actions.upsert.failure(`${e}`));
    }
  }

  function* onSafeUpsert({ payload: { id, dt } }: ReturnType<typeof actions.safeUpsert.request>) {
    try {
      const entity: TEntity = yield call(params.getDetails, id);
      const { redirectTo, ...updatedEntity } = dt(entity);
      const upserted: TEntity = yield call(params.upsert, updatedEntity);
      yield put(actions.safeUpsert.success(upserted));
      if (redirectTo && upserted.id) {
        yield put(push(redirectTo(upserted.id)))
      }
    } catch (e) {
      yield call(toast.error, 'Nie można zapisać, spróbuj ponownie za chwilę');
      yield put(actions.safeUpsert.failure(`${e}`));
    }
  }

  function* onDelete({ payload }: ReturnType<typeof actions.delete.request>) {
    try {
      yield call(params.delete, payload);
      yield put(actions.delete.success(payload))
    } catch (e) {
      yield call(toast.error, 'Nie można usunąć, spróbuj ponownie za chwilę');
      yield put(actions.delete.failure(`${e}`));
    }
  }
  function* saga(): IterableIterator<any> {
    yield takeLatest(getType(actions.getAll.request), onGetAll);
    yield takeLatest(getType(actions.getDetails.request), onGetDetails);
    yield takeEvery(getType(actions.upsert.request), onUpsert);
    yield takeEvery(getType(actions.safeUpsert.request), onSafeUpsert);
    yield takeLatest(getType(actions.delete.request), onDelete);
  }

  return { actions, reducer, saga }
}
