import { call, apply, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType, createAsyncAction, createAction } from 'typesafe-actions';
import { MenuApi, Menu, MenuItem, Product, MenuDataFrame } from 'src/api'
import { getApi, getRelations } from './utils';
import { uniqBy, minBy } from 'lodash';
import { startOfDay, subHours, addHours } from 'date-fns';
import { getFilter } from './utils/apiConfig';


export type MenuItemWithProduct = MenuItem & { product: Product };

export type FilterOption = { id: number; name: string; active: boolean };

export type DayProducts = {
  [category: string]: Array<MenuItemWithProduct>;
}

export const menuActions = {
  menu: createAsyncAction('get all menu options', 'get all menu options success', 'get all menu options failure')<void, Menu[], Error>(),
  dayMenu: createAsyncAction('get day menu', 'get day menu success', 'get day menu failure')<number, { dayProducts: DayProducts; categories: string[] }, Error>(),
  filterOptions: createAction('set filter options')<FilterOption[]>(),
};

export type MenuActions = ActionType<typeof menuActions>;

// we use only these uncommented. Rest is ready to be used, we just need to uncomment
const filterOptions = [
  // {id: 1, name: 'fish', active: false},
  // {id: 2, name: 'fit',  active: false},
  { id: 3, name: 'vegan', active: false },
  { id: 4, name: 'vege', active: false },
  { id: 5, name: 'gluten-free', active: false },
  { id: 6, name: 'lacto-free', active: false },
  // {id: 7, name: 'spicy',  active: false}
];

type State = {
  menu: Menu[];
  categories: string[];
  dayMenu: DayProducts | null;
  filterOptions: FilterOption[];
  pending: boolean;
}

const initialState: State = {
  menu: [],
  categories: [],
  dayMenu: null,
  filterOptions: filterOptions,
  pending: true,
};

export const menuReducer = (state: State = initialState, action: MenuActions): State => {
  switch (action.type) {
    case getType(menuActions.menu.request): return { ...state, pending: true };
    case getType(menuActions.menu.success): return { ...state, pending: false, menu: action.payload };
    case getType(menuActions.menu.failure): return { ...state, pending: false, menu: initialState.menu };

    case getType(menuActions.dayMenu.request): return { ...state, pending: true };
    case getType(menuActions.dayMenu.success): return { ...state, pending: false, dayMenu: action.payload.dayProducts, categories: action.payload.categories };
    case getType(menuActions.dayMenu.failure): return { ...state, pending: false, dayMenu: null, categories: initialState.categories };

    case getType(menuActions.filterOptions): return { ...state, pending: false, filterOptions: action.payload };
    default: return state;
  }
};

export const getMinMenu = (menu: Menu[], timeToOrder: number) => {
  return minBy(
    menu.filter(menu => {
      const date = menu.date ?? new Date();
      // for monday we are not allowed to buy in weekend, so we need to add 1 day
      const tto = timeToOrder;
      return subHours(startOfDay(date), tto).getTime() >= Date.now()
    }),
    m => m.date?.getTime() ?? 0
  );
}

function* onMenuGetAll() {
  const api: MenuApi = yield call(getApi, MenuApi);
  try {
    const response: MenuDataFrame = yield apply(api, api.apiMenusGet, [undefined, false, getFilter<Menu>({ 'date': { gt: new Date() } })]);
    yield put(menuActions.menu.success(response.data.filter(m => (m.date?.getTime() ?? 0) > Date.now()).map(m => ({ ...m, date: addHours(m.date ?? new Date(), 12) }))));
  } catch (e) {
    yield put(menuActions.menu.failure(e as any));
  }
}

function* onDayMenu({ payload }: ReturnType<typeof menuActions.dayMenu.request>) {
  const api: MenuApi = yield call(getApi, MenuApi);
  try {
    const response: Menu = yield apply(api, api.apiMenusIdGet, [payload, getRelations<Menu>({ 'items': { 'product': { tags: true, category: true } } })]);

    const items = response.items?.filter((i): i is MenuItemWithProduct => !!i.product) ?? []

    const dayProducts: DayProducts = items.reduce((agg, mi) => {
      const category = mi.product.category?.name ?? '';
      return {
        ...agg,
        [category]: [...(agg[category] ?? []), mi]
      }
    }, {} as DayProducts);
    const allCategoris = items
      .map(i => [i.product.category?.id, i.product.category?.name, i.product.category?.order])
      .filter((cat): cat is [number, string, number] => cat[0] != null && !!cat[1]);

    const uniqCategories = uniqBy(allCategoris, ([id]) => id)
      .sort(([, , order], [, , order2]) => order > order2 ? 1 : -1)
      .map(([, name]) => name);

    const isAnyPromotedProduct = items.some(i => i.isPromoted);
    const categories = isAnyPromotedProduct ? ['Polecane', ...uniqCategories] : uniqCategories
    const promotedDayProducts = isAnyPromotedProduct ? { ...dayProducts, Polecane: items.filter(i => i.isPromoted) } : dayProducts

    yield put(menuActions.dayMenu.success({ dayProducts: promotedDayProducts, categories: categories }));
  } catch (e) {
    yield put(menuActions.dayMenu.failure(e as any));
  }
}


export function* menuSaga(): IterableIterator<any> {
  yield takeLatest(getType(menuActions.menu.request), onMenuGetAll);
  yield takeLatest(getType(menuActions.dayMenu.request), onDayMenu);
}
