import React, { useMemo, useEffect } from 'react';
import styles from './landingPage.module.scss';
import ContentHeader from '../content/contentHeader/contentHeader';
import ContentFooter from '../content/contentFooter/contentFooter';
import HeroSection from './heroSection/heroSection';
import MenuList from '../menuIList/menuList';
import Banner from './banner/banner';
import Feature from './feature/feature';
import { routes } from 'src/routes';
import { GlobalState } from 'src/logic/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { getMinMenu } from '../../logic/menu';
import { isToday, isTomorrow, format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { menuActions } from 'src/logic/menu';
import { getDateString } from 'src/logic/utils/dates';
import { Categories } from './categories/categories';
// import XmasBanner from './xmasBanner/xmasBanner';
// import MobileBanner from './mobileBanner/mobileBanner';
import { userActions } from 'src/logic/user';
import { toPrice } from 'src/logic/utils';


const LandingPage = () => {
  const menu = [
    { label: 'O nas', path: routes.aboutUs(), requireLogin: false },
    { label: 'Dlaczego my?', path: routes.why(), requireLogin: false },
    { label: 'Bezpieczeństwo', path: routes.security(), requireLogin: false },
    { label: 'Zasięg', path: routes.deliveryRange(), requireLogin: false },
    { label: 'Dostawa do biur', path: routes.deliveryToOffices(), requireLogin: false },
    { label: 'Kontakt', path: routes.contact(), requireLogin: false },
  ];

  const menus = useSelector((gs: GlobalState) => gs.menu.menu);
  const timeToOrder = useSelector((gs: GlobalState) => gs.settings.TIME_TO_ORDER);

  const minMenu = useMemo(() => getMinMenu(menus, timeToOrder), [menus, timeToOrder]);
  const minDate = minMenu?.date ?? new Date();
  const promotedDay = isToday(minDate)
    ? 'dziś'
    : isTomorrow(minDate)
      ? 'jutro'
      : format(minDate, 'EEEE', { locale: pl })

  const activeDate = getDateString(minDate);

  // Holiday time
  // const year = new Date().getFullYear();
  // const today = new Date().getTime();
  // const nextHolidayDate = new Date(year,11,12);
  // const holidayTime = today < new Date(year,11,21,15,0,0).getTime() && today > new Date(year,10,24).getTime();

  const dispatch = useDispatch();
  useEffect(() => {
    minMenu?.id && dispatch(menuActions.dayMenu.request(minMenu.id));
  }, [dispatch, minMenu]
  );
  useEffect(() => { dispatch(userActions.loadDeviceType()) }, [dispatch]);
  const deviceType = useSelector((gs: GlobalState) => gs.user.deviceType);
  const dayMenu = useSelector((gs: GlobalState) => gs.menu.dayMenu);
  const settings = useSelector((gs: GlobalState) => gs.settings);
  const promotedItems = useMemo(() => {
    if (!dayMenu) {
      return [];
    }
    if (dayMenu['Polecane']?.length) {
      return dayMenu['Polecane'].slice(0, 3);
    }
    return Object.values(dayMenu).map(c => c[0]).filter(c => c).slice(0, 3);
  }, [dayMenu]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ContentHeader menu={menu} />
      <HeroSection />
      <section className={styles.featureSection}>
        <Feature type="cal" text="Zamawiaj dziś do godz. 12:00 na jutro i na kolejne dni!" />
        <Feature type="delivery" text={`Nie przejmuj się minimalną kwotą zamówień – to nie u nas. Zyskuj darmową dostawę dla zamówień powyżej ${toPrice(settings.FREE_DELIVERY)}zł per dzień.`} />
        <Feature type="bag" text="Dostarczymy Ci jedzenie prosto pod drzwi na terenie miejscowości w całej Polsce." />
      </section>
      {/* {
        holidayTime &&
        <section className={styles.bannerSection}>
          <XmasBanner />
        </section>
      }
      {
        !holidayTime && (!deviceType || (deviceType !== 'ios')) &&
        <section className={styles.bannerSection}>
          <MobileBanner />
        </section>
      } */}
      <section className={styles.recommended}>
        <MenuList menuItems={promotedItems} title={`Polecane na ${promotedDay}`} loading={false} itemLimit={3} activeDate={activeDate} />
      </section>

      <section className={styles.bannerSection}>
        <Banner />
      </section>
      <section className={styles.recommended}>
        <Categories activeDate={activeDate} />
      </section>

      <ContentFooter />
    </>
  );
};
export default LandingPage;
