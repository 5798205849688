import React from 'react';
import styles from './countEditor.module.scss';

type CountEditorProps = {
  value: number;
  updateCount(val: number): void;
  small?: boolean;
  limit: number;
}

const CountEditor = ({ value, updateCount, small, limit }: CountEditorProps) => (
  <div className={small ? styles.countEditorSmall : styles.countEditor} title="zmień ilość">
    <button className={styles.counterBtn} onClick={() => updateCount(-1)}>-</button>
    <span className={styles.counterValue} >{value}</span>
    <button className={styles.counterBtn} onClick={() => updateCount(1)} disabled={limit <= value}>+</button>
  </div>
);
export default CountEditor;
