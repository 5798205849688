import { call, apply, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType, createAsyncAction } from 'typesafe-actions';
import { SettingApi } from 'src/api'
import { getApi } from './utils';
import { SettingDataFrame } from '../api/models/SettingDataFrame';

export type ProductPackage = {
  name: string;
  value: number;
};

export type State = {
  DELIVERY_HOME: number;
  DELIVERY_OFFICE: number;
  FREE_DELIVERY: number;
  ITEM_LIMIT: number;
  TIME_TO_ORDER: number;
  TIME_TO_CANCEL: number;
  ALLOWED_POSTAL_CODES: string;
  SHOW_FACEBOOK_LOGIN: boolean;
  CUTLERY_COST: number;
  SHOW_BANNER: boolean;
  BANNER_DESKTOP: string;
  BANNER_MOBILE: string;
  BANNER_ADDRESS: string;
  XMAS_BANNER_ADDRESS: string;
  MENU_FILE: string;
  PRODUCT_PACKAGE: ProductPackage[];
  PRODUCT_PACKAGE_VAT: string;
}
export const settingsActions = {
  load: createAsyncAction('load settings', 'load settings success', 'load settings failure')<void, State, Error>(),
};

export type SettingActions = ActionType<typeof settingsActions>;

const initialState: State = {
  DELIVERY_HOME: 999,
  DELIVERY_OFFICE: 0,
  FREE_DELIVERY: 5000,
  ITEM_LIMIT: 10,
  TIME_TO_ORDER: 0,
  TIME_TO_CANCEL: 9,
  ALLOWED_POSTAL_CODES: '.*',
  SHOW_FACEBOOK_LOGIN: false,
  CUTLERY_COST: 100,
  SHOW_BANNER: false,
  BANNER_ADDRESS: '',
  BANNER_DESKTOP: '',
  BANNER_MOBILE: '',
  XMAS_BANNER_ADDRESS: '',
  MENU_FILE: '',
  PRODUCT_PACKAGE: [],
  PRODUCT_PACKAGE_VAT: '23',
};

export const settingsReducer = (state: State = initialState, action: SettingActions): State => {
  switch (action.type) {
    case getType(settingsActions.load.success): return { ...state, ...action.payload };
    default: return state;
  }
};

function* onLoad() {
  const api: SettingApi = yield call(getApi, SettingApi);
  try {
    const response: SettingDataFrame = yield apply(api, api.apiSettingsGet, []);
    const state = {
      DELIVERY_HOME: parseInt(response.data.find(d => d.name === 'DELIVERY_HOME')?.value ?? '', 10),
      DELIVERY_OFFICE: parseInt(response.data.find(d => d.name === 'DELIVERY_OFFICE')?.value ?? '', 10),
      FREE_DELIVERY: parseInt(response.data.find(d => d.name === 'FREE_DELIVERY')?.value ?? '', 10),
      ITEM_LIMIT: parseInt(response.data.find(d => d.name === 'ITEM_LIMIT')?.value ?? '', 10),
      TIME_TO_ORDER: parseInt(response.data.find(d => d.name === 'TIME_TO_ORDER')?.value ?? '', 10),
      TIME_TO_CANCEL: parseInt(response.data.find(d => d.name === 'TIME_TO_CANCEL')?.value ?? '', 10),
      ALLOWED_POSTAL_CODES: response.data.find(d => d.name === 'ALLOWED_POSTAL_CODES')?.value ?? '.*',
      SHOW_FACEBOOK_LOGIN: false,
      CUTLERY_COST: parseInt(response.data.find(d => d.name === 'CUTLERY_COST')?.value ?? '', 10) || 100,
      SHOW_BANNER: !!response.data.find(d => d.name === 'SHOW_BANNER')?.value && response.data.find(d => d.name === 'SHOW_BANNER')?.value !== 'false',
      BANNER_DESKTOP: response.data.find(d => d.name === 'BANNER_DESKTOP')?.value ?? '',
      BANNER_MOBILE: response.data.find(d => d.name === 'BANNER_MOBILE')?.value ?? '',
      BANNER_ADDRESS: response.data.find(d => d.name === 'BANNER_ADDRESS')?.value ?? '',
      XMAS_BANNER_ADDRESS: response.data.find(d => d.name === 'XMAS_BANNER_ADDRESS')?.value ?? '',
      MENU_FILE: response.data.find(d => d.name === 'MENU_FILE')?.value ?? '',
      PRODUCT_PACKAGE: JSON.parse(response.data.find(d => d.name === 'PRODUCT_PACKAGE')?.value ?? '[]'),
      PRODUCT_PACKAGE_VAT: response.data.find(d => d.name === 'PRODUCT_PACKAGE_VAT')?.value ?? '',
    }
    yield put(settingsActions.load.success(state));
  } catch (e) {
    yield put(settingsActions.load.failure(e));
  }
}

export function* settingsSaga(): IterableIterator<any> {
  yield takeLatest(getType(settingsActions.load.request), onLoad);
}
