import React, { useState, useEffect } from 'react';
import styles from './account.module.scss';
import { FormSection, BtnForm } from '../forms/Forms';
import BalanceItem from './balanceItem/balanceItem';
import Modal from 'src/containers/modal/modal';
import Fieldset from 'src/containers/fieldset/fieldset';
import { useDispatch, useSelector } from 'react-redux';
import { walletActions } from '../../logic/wallet';
import { GlobalState } from 'src/logic/reducers';
import { displayPrice } from 'src/logic/utils';
import FormattedTextField, { IBANFormat } from '../forms/textField/formattedTextField';
import ErrorList from '../forms/errorList/errorList';
import SplitConent from 'src/containers/splitContent/splitConent';

const MyBalance = () => {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(walletActions.load.request()) }, [dispatch]);
  const operations = useSelector((gs: GlobalState) => gs.wallet.list);
  const userDetails = useSelector((gs: GlobalState) => gs.user.details);
  const [iban, changeIban] = useState('');
  const [touched, setTouched] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const hasError = iban.length !== 32
  const handlePayout = () => {
    if (hasError) {
      setTouched(true);
      return;
    }
    dispatch(walletActions.requestPayout({ iban, amount: userDetails?.wallet as any }))
    setOpenModal(false);
  };
  return (
    <div>
      <section className={styles.header}>
        <SplitConent>
        <h2>
          Twoje saldo konta: <b>{displayPrice(userDetails?.wallet)}</b>
        </h2>
        <BtnForm type="white" action={() => setOpenModal(true)} disabled={!userDetails?.wallet}>
            Wypłać środki
          </BtnForm>
          </SplitConent>
      </section>
      <FormSection>
      <h3>{operations.length > 0 ? 'Historia salda' : 'Historia salda jest pusta'}</h3>
        {operations.map((o, i) => (
          <BalanceItem item={o} key={i} />
        ))}

      </FormSection>
      {openModal && (
        <Modal close={() => setOpenModal(false)}>
          <Fieldset title="Wypłać środki">
            <div className={styles.modalInfo}>
              <p>
                Wypłacanie środków obecnie odbywa się w naszej aplikacji
                manualnie, przy pomocy przelewów bankowych. Wypłata trwa do 3
                dni roboczych.
              </p>
              <p>
                Aby wypłacić <b>wszystkie</b> swoje środki z konta, podaj dane
                do przelewu bankowego i kliknij przycisk &ldquo;Wypłać środki&rdquo;.
              </p>
              <p>
                Uwaga: na Twoje konto zwrócone zostaną wszystkie zgromadzone
                środki dostępne w momencie wykonania przelewu.
              </p>
              <FormattedTextField
                value={iban}
                format={IBANFormat}
                onChange={(e) => { changeIban(e); }}
                error={touched && hasError}
                onBlur={() => setTouched(true)}
                type="text"
                label="Numer konta bankowego"
              />
            </div>
            <ErrorList errors={touched && hasError ? ['Błędny numer konta'] : []} />
            <div className={styles.payoutAction}>
              <BtnForm type="orange" action={handlePayout}>Wypłać środki</BtnForm>
            </div>
          </Fieldset>
        </Modal>
      )}
    </div>
  );
};
export default MyBalance;
