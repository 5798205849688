import React, { useState } from 'react';
import styles from './menuItem.module.scss';
import { MenuItem as MenuItemType } from 'src/api';
import CountEditor from '../countEditor/countEditor';
import { basketActions } from 'src/logic/basket';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { displayPrice, getPackagePrice } from 'src/logic/utils/price';
import { replace } from 'connected-react-router';
import { routes } from 'src/routes';
import { createSlug } from 'src/logic/utils/slug';
import { imgUrl } from '../../logic/utils/imgUrl';
import { DateString } from 'src/logic/utils';
import Tag from '../tag/tag';
import LimitTooltip from '../countEditor/limitTooltip';
import { MenuItemTag } from './menuItemTag';
import { gtmAddToCart, gtmRemoveFromCart, gtmSelectItem, setItemToGtmItem } from 'src/logic/gtm';

type MenuItemProps = {
  item: MenuItemType;
  loading: boolean;
  itemLimit: number;
  setItem: boolean;
  activeDate: DateString | null;
};
const MenuItem = ({ item, loading, itemLimit, setItem, activeDate }: MenuItemProps) => {
  const dispatch = useDispatch();
  const [showLimitTooltip, setShowLimitTooltip] = useState(false);
  const packages = useSelector((gs: GlobalState) => gs.settings.PRODUCT_PACKAGE);
  const { productId = 0, quantityStock = 0, product } = item;
  const count = useSelector((gs: GlobalState) =>
    activeDate
      ? gs.basket.basket[activeDate]?.items.find((b) => b.productId === productId)
          ?.quantity
      : 0
  );
  const upadetCount = (quantity: number) => {
    dispatch(
      basketActions.modifyBasket({
        productId: productId,
        productName: item?.product?.name ?? '',
        productCategory: item?.product?.category?.name ?? '',
        productCategoryId: item?.product?.category?.id ?? 0,
        productDescription: item?.product?.description ?? '',
        date: new Date(activeDate ?? 0),
        quantity,
        price: item?.price ?? 0,
        discountedPrice: item?.price ?? 0,
        menuId: item?.menuId ?? 0,
        menuItemId: item?.id ?? 0,
        itemLimit: Math.min(itemLimit, item?.quantityStock ?? 0),
        productStock: item?.quantityStock ?? 0,
        productInfo: {
          calories: item?.product?.calories ?? 0,
          protein: item?.product?.protein ?? 0,
          carbs: item?.product?.carbs ?? 0,
          fat: item?.product?.fat ?? 0,
          weight: item?.product?.weight ?? 0
        },
        package: { name: item?.product?._package || '', value: item?.product?._package ? getPackagePrice(packages, item?.product?._package) : 0 },
      })
    );
    const gtmItem = setItemToGtmItem(item)
    if(quantity === 1) gtmAddToCart(gtmItem);
    if(quantity === -1) gtmRemoveFromCart(gtmItem);
    if(quantity + (count ?? 0) === Math.min(itemLimit, quantityStock)) {
      setShowLimitTooltip(true);
      setTimeout(()=>setShowLimitTooltip(false), 3000);
    }
    else if(showLimitTooltip === true){
      setShowLimitTooltip(false);
    }
  };

  const getMenuItemTag = (item: MenuItemType) => {
    const seasonal = item.product?.tags?.find(t=>t.name === 'seasonal');
    if(seasonal){
      return <MenuItemTag label='Sezon na...' color='green' />
    }
    const newt = item.product?.tags?.find(t=>t.name === 'new');
    if(newt){
      return <MenuItemTag label='Nowość!' color='red' />
    }
    const goodp = item.product?.tags?.find(t=>t.name === 'good-price');
    if(goodp){
      return <MenuItemTag label='Dobra cena' color='orange' />
    }
    const bests = item.product?.tags?.find(t=>t.name === 'bestseller');
    if(bests){
      return <MenuItemTag label='Bestseller' color='yellow' />
    }
    return <> </>

  }

  const showDetails = () => {
    dispatch(
      replace(
        routes.menuDetails(
          activeDate ? new Date(activeDate) : '',
          createSlug(productId ?? 0, product?.name ?? '')
        )
      )
    )
    const gtmItem = {id: item.id, name: item.product?.name, category: item.product?.category?.name, price: item.price };
    gtmSelectItem(gtmItem, activeDate);
  }
  return (
    <article
      className={
        quantityStock < 1 || loading
          ? styles.unavailableMenuItem
          : count
          ? styles.selectedMenuItem
          : styles.menuItem
      }
    >

      {showLimitTooltip && <LimitTooltip>Nie mamy już więcej </LimitTooltip>}

      <header className={styles.header}>
        <img onClick={showDetails}
          src={imgUrl(
            product?.pictureMenu ?? '/dinner.png',
            222 * (setItem ? 1.5 : 1),
            222
          )}
          className={styles.shade}
          alt="danie"
        />
        { getMenuItemTag(item) }
        {!quantityStock ? (
          <b>Wyprzedane</b>
        ) : count ? (
          <CountEditor
            value={count}
            updateCount={upadetCount}
            limit={Math.min(itemLimit, quantityStock)}
          />
        ) : (
          <a className={styles.addBtn} onClick={() => upadetCount(1)}>
            Dodaj
          </a>
        )}
      </header>
      <div className={styles.itemContent}>
        <h1 onClick={showDetails}>{product?.name || 'Danie'}</h1>
        <h2>{displayPrice(item?.price)}</h2>
        <p>{product?.description}</p>
      </div>
      <footer className={styles.footer}>
        {product?.tags?.map((t) => (<Tag key={t.name ?? ''} tag={t} />
        ))}
      </footer>
    </article>
  );
};
export default MenuItem;
