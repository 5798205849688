/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    SendNotificationResponse,
    SendNotificationResponseFromJSON,
    SendNotificationResponseToJSON,
    SendNotificationsRequest,
    SendNotificationsRequestFromJSON,
    SendNotificationsRequestToJSON,
} from '../models';

export interface ApiNotificationsSendPostRequest {
    sendNotificationsRequest?: SendNotificationsRequest;
}

/**
 * no description
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Send notifications to clients
     */
    async apiNotificationsSendPostRaw(requestParameters: ApiNotificationsSendPostRequest): Promise<runtime.ApiResponse<SendNotificationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/notifications/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendNotificationsRequestToJSON(requestParameters.sendNotificationsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SendNotificationResponseFromJSON(jsonValue));
    }

    /**
     * Send notifications to clients
     */
    async apiNotificationsSendPost(sendNotificationsRequest?: SendNotificationsRequest): Promise<SendNotificationResponse> {
        const response = await this.apiNotificationsSendPostRaw({ sendNotificationsRequest: sendNotificationsRequest });
        return await response.value();
    }

}
