/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Voucher,
    VoucherFromJSON,
    VoucherToJSON,
    VoucherDataFrame,
    VoucherDataFrameFromJSON,
    VoucherDataFrameToJSON,
} from '../models';

export interface ApiVouchersCodeCodeGetRequest {
    code: string;
    relations?: string;
    deleted?: boolean;
}

export interface ApiVouchersGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiVouchersIdDeleteRequest {
    id: number;
}

export interface ApiVouchersIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiVouchersPostRequest {
    relations?: string;
    deleted?: boolean;
    voucher?: Voucher;
}

export interface ApiVouchersUserCodePutRequest {
    code: string;
}

/**
 * no description
 */
export class VoucherApi extends runtime.BaseAPI {

    /**
     * Get entity of Voucher
     */
    async apiVouchersCodeCodeGetRaw(requestParameters: ApiVouchersCodeCodeGetRequest): Promise<runtime.ApiResponse<Voucher>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling apiVouchersCodeCodeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/vouchers/code/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherFromJSON(jsonValue));
    }

    /**
     * Get entity of Voucher
     */
    async apiVouchersCodeCodeGet(code: string, relations?: string, deleted?: boolean): Promise<Voucher> {
        const response = await this.apiVouchersCodeCodeGetRaw({ code: code, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Get list of Voucher
     */
    async apiVouchersGetRaw(requestParameters: ApiVouchersGetRequest): Promise<runtime.ApiResponse<VoucherDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vouchers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Voucher
     */
    async apiVouchersGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<VoucherDataFrame> {
        const response = await this.apiVouchersGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Voucher
     */
    async apiVouchersIdDeleteRaw(requestParameters: ApiVouchersIdDeleteRequest): Promise<runtime.ApiResponse<Voucher>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVouchersIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vouchers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherFromJSON(jsonValue));
    }

    /**
     * Remove entity of Voucher
     */
    async apiVouchersIdDelete(id: number): Promise<Voucher> {
        const response = await this.apiVouchersIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Voucher
     */
    async apiVouchersIdGetRaw(requestParameters: ApiVouchersIdGetRequest): Promise<runtime.ApiResponse<Voucher>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVouchersIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vouchers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherFromJSON(jsonValue));
    }

    /**
     * Get entity of Voucher
     */
    async apiVouchersIdGet(id: number, relations?: string, deleted?: boolean): Promise<Voucher> {
        const response = await this.apiVouchersIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Voucher (if id defined)
     */
    async apiVouchersPostRaw(requestParameters: ApiVouchersPostRequest): Promise<runtime.ApiResponse<Voucher>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vouchers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VoucherToJSON(requestParameters.voucher),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Voucher (if id defined)
     */
    async apiVouchersPost(relations?: string, deleted?: boolean, voucher?: Voucher): Promise<Voucher> {
        const response = await this.apiVouchersPostRaw({ relations: relations, deleted: deleted, voucher: voucher });
        return await response.value();
    }

    /**
     * Add entity of Voucher to User
     */
    async apiVouchersUserCodePutRaw(requestParameters: ApiVouchersUserCodePutRequest): Promise<runtime.ApiResponse<Voucher>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling apiVouchersUserCodePut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vouchers/user/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherFromJSON(jsonValue));
    }

    /**
     * Add entity of Voucher to User
     */
    async apiVouchersUserCodePut(code: string): Promise<Voucher> {
        const response = await this.apiVouchersUserCodePutRaw({ code: code });
        return await response.value();
    }

}
