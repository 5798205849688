/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    transaction?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    identifier?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    statement?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    method?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    wallet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    paid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    orderId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Order}
     * @memberof Payment
     */
    order?: Order;
}

export function PaymentFromJSON(json: any): Payment {
    return PaymentFromJSONTyped(json, false);
}

export function PaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'transaction': !exists(json, 'transaction') ? undefined : json['transaction'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'statement': !exists(json, 'statement') ? undefined : json['statement'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'wallet': !exists(json, 'wallet') ? undefined : json['wallet'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'order': !exists(json, 'order') ? undefined : OrderFromJSON(json['order']),
    };
}

export function PaymentToJSON(value?: Payment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uuid': value.uuid,
        'transaction': value.transaction,
        'identifier': value.identifier,
        'statement': value.statement,
        'method': value.method,
        'amount': value.amount,
        'wallet': value.wallet,
        'paid': value.paid,
        'orderId': value.orderId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'order': OrderToJSON(value.order),
    };
}


