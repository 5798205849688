import React from 'react';
import styles from './countEditor.module.scss';

type LimitTooltipProps = {
  top?: string;
  right?: string;
  children?: React.ReactNode;
}

const LimitTooltip = ({ top, right, children }: LimitTooltipProps) => (
  <div className={styles.limitTooltip} style={ {top: top ?? '40px', right: right ?? '-8px'}} >
      {children}
  </div>
);
export default LimitTooltip;
