/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddressDataFrame
 */
export interface AddressDataFrame {
    /**
     * 
     * @type {number}
     * @memberof AddressDataFrame
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof AddressDataFrame
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof AddressDataFrame
     */
    limit: number;
    /**
     * 
     * @type {Array<Address>}
     * @memberof AddressDataFrame
     */
    data: Array<Address>;
}

export function AddressDataFrameFromJSON(json: any): AddressDataFrame {
    return AddressDataFrameFromJSONTyped(json, false);
}

export function AddressDataFrameFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDataFrame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'offset': json['offset'],
        'limit': json['limit'],
        'data': ((json['data'] as Array<any>).map(AddressFromJSON)),
    };
}

export function AddressDataFrameToJSON(value?: AddressDataFrame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'offset': value.offset,
        'limit': value.limit,
        'data': ((value.data as Array<any>).map(AddressToJSON)),
    };
}


