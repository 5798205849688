/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContactRequest,
    ContactRequestFromJSON,
    ContactRequestToJSON,
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    RegisterDeviceRequest,
    RegisterDeviceRequestFromJSON,
    RegisterDeviceRequestToJSON,
} from '../models';

export interface ApiContactPostRequest {
    contactRequest?: ContactRequest;
}

export interface ApiRegisterDevicePostRequest {
    registerDeviceRequest?: RegisterDeviceRequest;
}

/**
 * no description
 */
export class CommonApi extends runtime.BaseAPI {

    /**
     * Send contact form
     */
    async apiContactPostRaw(requestParameters: ApiContactPostRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactRequestToJSON(requestParameters.contactRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Send contact form
     */
    async apiContactPost(contactRequest?: ContactRequest): Promise<object> {
        const response = await this.apiContactPostRaw({ contactRequest: contactRequest });
        return await response.value();
    }

    /**
     * Register device
     */
    async apiRegisterDevicePostRaw(requestParameters: ApiRegisterDevicePostRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/registerDevice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterDeviceRequestToJSON(requestParameters.registerDeviceRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Register device
     */
    async apiRegisterDevicePost(registerDeviceRequest?: RegisterDeviceRequest): Promise<object> {
        const response = await this.apiRegisterDevicePostRaw({ registerDeviceRequest: registerDeviceRequest });
        return await response.value();
    }

}
