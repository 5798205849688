import { compact } from 'lodash';
import { multiSelectFilterValue, selectFilterValue, stringFilterValue, TableState, dateRangeFilterValue } from '../controlledGenericTable';
import { FilterableColumnDefinition } from './inMemoryGenericTable';

const getPredicates = <T>(definitions: Array<FilterableColumnDefinition<T>>): Array<(row: T, tableState: TableState) => boolean> => {
  const predicates = definitions
    .map((d) => d.filterable.type === 'none'
      ? null
      : (
        (r: T, tableState: TableState) => {
          switch (d.filterable.type) {
            case 'text': return d.filterable.predicate(r, stringFilterValue(tableState.filters[d.key]));
            case 'select': return d.filterable.predicate(r, selectFilterValue(tableState.filters[d.key]));
            case 'multi': return d.filterable.predicate(r, multiSelectFilterValue(tableState.filters[d.key]));
            case 'date': return d.filterable.predicate(r, dateRangeFilterValue(tableState.filters[d.key]));
          }
          return true;
        }
      ),
    );
  return compact(predicates);
};

export const getData = <T>(data: T[], tableState: TableState, definitions: Array<FilterableColumnDefinition<T>>): T[] => {
  const predicates = getPredicates(definitions);
  const sorter = tableState.sort && definitions.find((d) => d.key === tableState.sort);

  const filtered = Object.keys(tableState.filters) ? data.filter((row) => predicates.every((p) => p(row, tableState))) : data;
  const sorted = sorter && sorter.sortable ? filtered.sort((a, b) => sorter.compare(a, b, tableState.sortDirection)) : filtered;
  return sorted;
};

export const sliceData = <T>(data: T[], page: number, itemsPerPage: number ): T[] => {
  const sliced = data.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  return sliced;
};
