import { take, fork } from 'redux-saga/effects';

type ActionPattern = string[]

export const combineLatest = <P extends ActionPattern>(actionTypes: P, saga: (x: any) => IterableIterator<any>) => fork(function*() {
  const actions: { [id: string]: P } = {}
  while (true) {
    const action: any = yield take(actionTypes)
    actions[action.type] = action

    if (allActionsReady(actions, actionTypes))
      yield fork(saga, [actionTypes.map(t => actions[t])])
  }
})

function allActionsReady<P extends ActionPattern>(actions: { [id: string]: P }, actionTypes: P) {
  return Object.keys(actions).length === actionTypes.length
}
