import React, { useState, useEffect } from 'react';
import ContentPage from './contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import { BtnForm, FormRow } from '../forms/Forms';
import CodeCheckResult from './codeCheckResult/codeCheckResult';
import FormattedTextField, {
  PostalFormat,
} from '../forms/textField/formattedTextField';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { ReactComponent as Map } from './map.svg';
import { orderActions } from 'src/logic/order';

const Range = () => {
  const [failed, setFailed] = useState<boolean>();
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const postalCodes = useSelector((gs: GlobalState) => gs.order.postalCodes);
  
  const checkingPostalCode = (postalCode: string) => {
    const savedPostalCode = postalCodes.find((code) => code.postalCode === postalCode)
    if (!savedPostalCode) {
      dispatch(orderActions.checkPostalCode.request(postalCode));
    } else {
      setFailed(!savedPostalCode.isAvailable)
    }
  }

  useEffect(() => {
    setFailed(undefined);
  }, [setFailed, code]);

  useEffect(() => {
    if (code.length === 6) {
      const isPostalCodeAvailable = postalCodes.find((postalCode) => postalCode.postalCode === code)?.isAvailable
      setFailed(!isPostalCodeAvailable)
    }
  }, [postalCodes])

  return (
    <ContentPage>
      <PageWrapper>
        <div className="split">
          <div className='rangeBox'>
            <h1 className="h1">Zasięg</h1>
            <h2 className="h2">
              Działamy na terenie <em>całej Polski</em>.
            </h2>
            <h3 className="deliveryHeader">
              Nie jesteś pewien, czy do Ciebie też dojeżdżamy?
              <br/>
              Podaj nam swój kod pocztowy i sprawdź:
            </h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                checkingPostalCode(code);
              }}
            >
              <FormRow align="top">
                <FormattedTextField
                  value={code}
                  format={PostalFormat}
                  onChange={setCode}
                  label="kod pocztowy"
                ></FormattedTextField>
                <BtnForm type="black" disabled={code.length < 6}>
                  Sprawdź
                </BtnForm>
              </FormRow>
            </form>
            {failed !== undefined && (
              <CodeCheckResult failed={failed} code={code} />
            )}
          </div>
          <div className="map">
            <Map />
          </div>
        </div>
      </PageWrapper>
    </ContentPage>
  );
};
export default Range;
