/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Menu,
    MenuFromJSON,
    MenuToJSON,
    MenuDataFrame,
    MenuDataFrameFromJSON,
    MenuDataFrameToJSON,
} from '../models';

export interface ApiMenusGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiMenusIdDeleteRequest {
    id: number;
}

export interface ApiMenusIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiMenusPostRequest {
    relations?: string;
    deleted?: boolean;
    menu?: Menu;
}

/**
 * no description
 */
export class MenuApi extends runtime.BaseAPI {

    /**
     * Get menu file
     */
    async apiMenuGetRaw(): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/menu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get menu file
     */
    async apiMenuGet(): Promise<object> {
        const response = await this.apiMenuGetRaw();
        return await response.value();
    }

    /**
     * Get list of Menu
     */
    async apiMenusGetRaw(requestParameters: ApiMenusGetRequest): Promise<runtime.ApiResponse<MenuDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/menus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Menu
     */
    async apiMenusGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<MenuDataFrame> {
        const response = await this.apiMenusGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Menu
     */
    async apiMenusIdDeleteRaw(requestParameters: ApiMenusIdDeleteRequest): Promise<runtime.ApiResponse<Menu>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMenusIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/menus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuFromJSON(jsonValue));
    }

    /**
     * Remove entity of Menu
     */
    async apiMenusIdDelete(id: number): Promise<Menu> {
        const response = await this.apiMenusIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Menu
     */
    async apiMenusIdGetRaw(requestParameters: ApiMenusIdGetRequest): Promise<runtime.ApiResponse<Menu>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMenusIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/menus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuFromJSON(jsonValue));
    }

    /**
     * Get entity of Menu
     */
    async apiMenusIdGet(id: number, relations?: string, deleted?: boolean): Promise<Menu> {
        const response = await this.apiMenusIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Menu (if id defined)
     */
    async apiMenusPostRaw(requestParameters: ApiMenusPostRequest): Promise<runtime.ApiResponse<Menu>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/menus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MenuToJSON(requestParameters.menu),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Menu (if id defined)
     */
    async apiMenusPost(relations?: string, deleted?: boolean, menu?: Menu): Promise<Menu> {
        const response = await this.apiMenusPostRaw({ relations: relations, deleted: deleted, menu: menu });
        return await response.value();
    }

}
