import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './table.module.scss';

export function TableHeaderCell({children, centered, minimal}: {children?: ReactNode; centered?: boolean; minimal?: boolean}) {
  const classes = cn(
    styles.headerCell,
    {
      [styles.centered]: centered,
      [styles.minimal]: minimal,
    },
  );
  return <th className={classes}>{children}</th>;
}

export function TableCell({children, centered, minimal}: {children?: ReactNode; centered?: boolean; minimal?: boolean}) {
  const classes = cn(
    styles.cell,
    {
      [styles.centered]: centered,
      [styles.minimal]: minimal,
    },
  );
  return <td className={classes}>{children}</td>;
}

export function TableRow({children, className}: {children: ReactNode; className?: string}) {
  return <tr className={cn(styles.row, className)}>{children}</tr>;
}

export function TableHead({children}: {children: ReactNode}) {
  return <thead className={styles.thead}>{children}</thead>;
}

export function TableBody({children}: {children: ReactNode}) {
  return <tbody className={styles.tbody}>{children}</tbody>;
}

export function Table({children}: {children: ReactNode}) {
  return <table className={styles.table}>{children}</table>;
}
