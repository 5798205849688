import React, { useEffect, useRef } from 'react';
import styles from './errorList.module.scss';

type ErrorListProps = {
  errors: string[];
  name?: string;
};

const ErrorList = ({ errors, name }: ErrorListProps) => {
  const ref=useRef<HTMLElement>(null);
  useEffect(() => {
    const element = ref.current;
    if(name){
      element?.setAttribute('name', name);
    }
    return () => element?.removeAttribute('name');
  }, [ref, name, errors]);
  return (
    (errors.length > 0) ? (
      <section className={styles.errorWrapper} ref={ref}>
        <ul>
          {errors.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </section>
  ): null);
};

export default ErrorList;
