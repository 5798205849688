import React, { FC } from 'react';
import styles from './columns.module.scss';

type ColumnsProp = {
    children?: React.ReactNode;
    invert?: boolean;
    
}

export const ColumnsWrapper = ({children, invert}: ColumnsProp) => (
    <div className={[styles.columnsWrapper, invert && styles.invert].join(' ')}>
        {children}
    </div>
)

export const SideColumnn: FC = props => (
    <div className={styles.sideColumn}>
        {props.children}
    </div>
)

export const MainColumnn: FC = props => (
    <div className={styles.mainColumn}>
        {props.children}
    </div>
)
