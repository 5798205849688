import React, { useState } from 'react';
import styles from './contentHeader.module.scss';
import { useLocation, Link } from 'react-router-dom';
import { routes } from 'src/routes';
import { UserInfo } from 'src/components/userInfo';
import Logo from 'src/components/logo/logo';
import BasketBtn from 'src/components/buttons/basketBtn/basketBtn';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useMinMenu } from '../../hooks/useActiveDate';
import { getBasketStats } from 'src/logic/basket';
import { GlobalState } from 'src/logic/reducers';

type ContentHeaderProps = {
  menu: {
    label: string | React.ReactNode;
    path: string;
    requireLogin: boolean;
  }[];
  logout?: boolean;
};

const ContentHeader = ({ menu, logout }: ContentHeaderProps) => {
  const [open, setOpen] = useState(false);
  const minMenu = useMinMenu();
  const loc = useLocation();
  const shopLabel = loc.pathname === routes.buy() ? 'Edytuj' : 'Zamów';
  const dispatch = useDispatch();
  const basket = useSelector((gs: GlobalState) => gs.basket.basket);
  const vouchers = useSelector((gs: GlobalState) => gs.basket.vouchers);
  const freeDelivery = useSelector(
    (gs: GlobalState) => gs.settings.FREE_DELIVERY
  );
  const deliveryHome = useSelector(
    (gs: GlobalState) => gs.settings.DELIVERY_HOME
  );
  const cutleryCost = useSelector((gs: GlobalState) => gs.settings.CUTLERY_COST);
  const location = useSelector((gs: GlobalState) => gs.addresses.location);
  const user = useSelector((gs: GlobalState) => gs.user.details);

  const { itemsCount, total } = getBasketStats(
    basket,
    vouchers,
    freeDelivery,
    deliveryHome,
    !!location,
    cutleryCost,
  );
  return (
    <header className={styles.contentHeader}>
      <div className={styles.wrapper}>
        <Logo />
        <div className={styles.navWrapper}>
          <nav className={open ? styles.open : ''}>
            {menu
              .filter(({ requireLogin }) => !requireLogin || !!user)
              .map((menuItem, i) => (
                <a key={i} href={menuItem.path} className={styles.link}>
                  <span
                    className={
                      menuItem.path === loc.pathname ? 'highlight' : ''
                    }
                  >
                    {menuItem.label}
                  </span>
                </a>
              ))}
            {(!logout || !!user)  && <UserInfo logout={logout} />}
            <Link to={routes.menu('')} className={styles.btnOrderMobile}>
              {shopLabel}
              <img src="/icons/arrow.svg" alt="" />
            </Link>
            <div className={styles.hamburger} onClick={() => setOpen(!open)}>
              <span>&times;</span>
            </div>
          </nav>
          {itemsCount !== 0 && (
            <BasketBtn
              quantity={itemsCount}
              price={total}
              openBasket={() => {
                dispatch(push(routes.menuBasket(minMenu?.date)));
              }}
            />
          )}
          {itemsCount === 0 && (
            <Link to={routes.menu('')} className={styles.btnOrder}>
              {shopLabel}
            </Link>
          )}
          <div className={styles.hamburger} onClick={() => setOpen(!open)}>
            <img src="/icons/hamburger.svg" alt="menu" title="menu" />
          </div>
        </div>
      </div>
    </header>
  );
};
export default ContentHeader;
