import React, { useState, useEffect, useCallback } from 'react';
import styles from './textField.module.scss';
import { useField } from 'formik';

export type TextFieldProps = {
  value?: string;
  onChangeValue?: (value: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: 'text' | 'password' | 'email' | 'phone';
  label: string;
  error?: string | boolean;
  disabled?: boolean;
  multiline?: boolean;
  children?: React.ReactNode;
}

const TextField = ({ label, value, disabled, type, multiline, onChange, error, onBlur, onChangeValue, children, ...props }: TextFieldProps) => {
  const [active, setActive] = useState(!value);
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    value ? setActive(true) : setActive(false);
    onChange?.(event);
    onChangeValue?.(value);
  }, [onChange, setActive, onChangeValue]);

  useEffect(() => { setActive(!!value) }, [value]);
  return (
    <div
      className={[
        styles.fieldWrapper,
        active && styles.fieldActive,
        disabled && styles.fieldDisabled,
        !!error && styles.fieldInvalid
      ].join(
        ' '
      )}
      title={label}
    >
      {
        multiline
          ? (
            <textarea
              rows={5}
              className={styles.fieldInput}
              {...props}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              onBlur={onBlur}
            ></textarea>
          )
          : (
            <input
              type={type ?? 'text'}
              className={styles.fieldInput}
              {...props}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              onBlur={onBlur}
            />
          )
      }
      <label className={styles.fieldLabel}>{label}</label>
      {children}
    </div>
  );
};

type TextFormFieldProps<K extends string> = Omit<TextFieldProps, 'onChange' | 'onBlur' | 'value' | 'error'> & { name: K; parent?: string };
export const TextFormField = <K extends string>({ name, parent, ...props }: TextFormFieldProps<K>) => {
  const [field, meta] = useField(parent ? `${parent}.${name}` : name);
  return <TextField {...props} {...field} error={meta.touched ? meta.error : undefined} />
}

export default TextField;
