import cn from 'classnames';
import React, { ReactNode, forwardRef } from 'react';
import styles from './grid.module.scss';

type RowProps = {
  children: ReactNode;
  className?: string;
  nowrap?: boolean;
  nomargin?: boolean;
  spacing?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const Row = forwardRef(
  function Row({ className, children, nowrap, nomargin, spacing, onClick }: RowProps, ref: React.Ref<HTMLDivElement>) {
    const classes = cn(
      styles.row,
      {
        [styles.nowrap]: nowrap,
        [styles.nomargin]: nomargin,
        [styles.spacing]: spacing
      },
      className
    )
    return (
      <div className={classes} onClick={onClick} ref={ref}>
        {children}
      </div>
    );
  }
)
