import React from 'react';
import styles from './loginForm.module.scss';
import { useFormikContext } from 'formik';
import { FormValues } from './loginForm';

type ResetDoneProps = {
  login(): void;
}

export function ResetDone({ login }: ResetDoneProps) {
  const { values } = useFormikContext<FormValues>();
  return (
    <div className={styles.registered}>
      <p>
        <a href={`mailto:${values.email}`}>{values.email}</a>
        Wysłaliśmy instrukcje dotyczące resetowania hasła na Twój adres e-mail:
        <a href={`mailto:${values.email}`}>{values.email}</a>.
        Jeśli w ciągu dziesięciu minut nie otrzymasz wiadomości, sprawdź, czy przesłany adres jest prawidłowy.
      </p>
      <p className="uppercase black50">
        Wróć do <a onClick={login} > logowania</a>
      </p>
    </div>
  );
}
