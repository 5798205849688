import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import styles from './facebookLogin.module.scss';
import { userActions } from 'src/logic/user';

export const requestThroughRedirect = (url: string, callback: Function) => {
  const width = 600, height = 600;
  const left = (window.innerWidth / 2) - (width / 2);
  const top = (window.innerHeight / 2) - (height / 2);
  const popup = window.open(url, '',
    `toolbar=no, location=no, directories=no, status=no, menubar=no,
      scrollbars=no, resizable=no, copyhistory=no, width=${width},
      height=${height}, top=${top}, left=${left}`
  );
  const check = setInterval(() => {
    if (!popup || popup.closed) {
      clearInterval(check);
    } else {
      try {
        if (popup.document.location.pathname === url && popup.document.body && popup.document.body.innerText) {
          clearInterval(check);
          const content = popup.document.body.innerText;
          const response = content
            .replace(/[\n\r]+/g, '')
            .replace(/"\s*:\s*"/g, '":"')
            .replace(/.*({.*}).*/, '$1');
          popup.close();
          callback(response);
        }
      } catch (e) {
        // Cross-origin error until redirect
      }
    }
  }, 100);
};

const authFacebook = (dispatch: Dispatch<any>) => requestThroughRedirect('/auth/facebook', (token: string) => dispatch(userActions.check(token)));

export function FacebookLogin({ method = 'redirect' }: { method?: 'redirect' | 'modal' }) {
  const dispatch = useDispatch();
  switch (method) {
    case 'redirect': return <a href="/auth/facebook?redirect=" className={styles.loginButton}><img src="/icons/fb.svg" alt="fb" /> Użyj konta Facebook</a>
    case 'modal': return <button onClick={() => authFacebook(dispatch)} className={styles.loginButton}><img src="/icons/fb.svg" alt="fb" /> Użyj konta Facebook</button>
  }
}
