import React from 'react';
import styles from './codeCheckResult.module.scss';
import { BtnLink } from 'src/components/forms/Forms';
import { routes } from 'src/routes';

type CodeCheckResultProps = {
  code: string;
  failed?: boolean;
};

const CodeCheckResult = ({ code, failed }: CodeCheckResultProps) => {
  const leed = failed ? `Kod pocztowy ${code} niestety nie jest obecnie obsługiwany.` :`Świetnie! Kod pocztowy ${code} jest w naszym obszarze dostaw!`;
  const follow = failed ? 'Skontaktuj się z nami a może jednak przyjedziemy!':'Rozpocznij zamawianie już teraz! ';
  return (
  <section
    className={[styles.codeCheckResult, failed && styles.failed].join(' ')}
  >

      <div className={styles.text}>
        <big>{leed}</big>
        <span>{follow}</span>
      </div>
      <div className={styles.button}>
        {failed ? (
          <BtnLink type="white" link={routes.contact()}>
            Napisz do nas
          </BtnLink>
        ) : (
          <BtnLink type="white" link={routes.menu('')}>
            Zamów
          </BtnLink>
        )}
      </div>

  </section>
);
}
export default CodeCheckResult;
