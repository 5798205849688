/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiUsersWithOrdersInfosEntity,
    ApiUsersWithOrdersInfosEntityFromJSON,
    ApiUsersWithOrdersInfosEntityToJSON,
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    UsersWithOrdersInfo,
    UsersWithOrdersInfoFromJSON,
    UsersWithOrdersInfoToJSON,
    UsersWithOrdersInfoDataFrame,
    UsersWithOrdersInfoDataFrameFromJSON,
    UsersWithOrdersInfoDataFrameToJSON,
} from '../models';

export interface ApiUsersWithOrdersInfosGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiUsersWithOrdersInfosIdDeleteRequest {
    id: number;
}

export interface ApiUsersWithOrdersInfosIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiUsersWithOrdersInfosPostRequest {
    relations?: string;
    deleted?: boolean;
    entity?: ApiUsersWithOrdersInfosEntity;
    avatar?: Blob;
}

/**
 * no description
 */
export class UsersWithOrdersInfoApi extends runtime.BaseAPI {

    /**
     * Get list of UsersWithOrdersInfo
     */
    async apiUsersWithOrdersInfosGetRaw(requestParameters: ApiUsersWithOrdersInfosGetRequest): Promise<runtime.ApiResponse<UsersWithOrdersInfoDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/usersWithOrdersInfos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersWithOrdersInfoDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of UsersWithOrdersInfo
     */
    async apiUsersWithOrdersInfosGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<UsersWithOrdersInfoDataFrame> {
        const response = await this.apiUsersWithOrdersInfosGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of UsersWithOrdersInfo
     */
    async apiUsersWithOrdersInfosIdDeleteRaw(requestParameters: ApiUsersWithOrdersInfosIdDeleteRequest): Promise<runtime.ApiResponse<UsersWithOrdersInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersWithOrdersInfosIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/usersWithOrdersInfos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersWithOrdersInfoFromJSON(jsonValue));
    }

    /**
     * Remove entity of UsersWithOrdersInfo
     */
    async apiUsersWithOrdersInfosIdDelete(id: number): Promise<UsersWithOrdersInfo> {
        const response = await this.apiUsersWithOrdersInfosIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of UsersWithOrdersInfo
     */
    async apiUsersWithOrdersInfosIdGetRaw(requestParameters: ApiUsersWithOrdersInfosIdGetRequest): Promise<runtime.ApiResponse<UsersWithOrdersInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersWithOrdersInfosIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/usersWithOrdersInfos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersWithOrdersInfoFromJSON(jsonValue));
    }

    /**
     * Get entity of UsersWithOrdersInfo
     */
    async apiUsersWithOrdersInfosIdGet(id: number, relations?: string, deleted?: boolean): Promise<UsersWithOrdersInfo> {
        const response = await this.apiUsersWithOrdersInfosIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of UsersWithOrdersInfo (if id defined)
     */
    async apiUsersWithOrdersInfosPostRaw(requestParameters: ApiUsersWithOrdersInfosPostRequest): Promise<runtime.ApiResponse<UsersWithOrdersInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.entity !== undefined) {
            formParams.append('entity', requestParameters.entity as any);
        }

        if (requestParameters.avatar !== undefined) {
            formParams.append('avatar', requestParameters.avatar as any);
        }

        const response = await this.request({
            path: `/api/usersWithOrdersInfos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersWithOrdersInfoFromJSON(jsonValue));
    }

    /**
     * Create or update entity of UsersWithOrdersInfo (if id defined)
     */
    async apiUsersWithOrdersInfosPost(relations?: string, deleted?: boolean, entity?: ApiUsersWithOrdersInfosEntity, avatar?: Blob): Promise<UsersWithOrdersInfo> {
        const response = await this.apiUsersWithOrdersInfosPostRaw({ relations: relations, deleted: deleted, entity: entity, avatar: avatar });
        return await response.value();
    }

}
