import React, { createRef } from 'react';
import styles from './navMenu.module.scss';
import { useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { Link } from 'react-scroll';
import cn from 'classnames';
import { slugifyName } from '../../logic/utils/slug';

const NavMenu = () => {
  const categories = useSelector((gs: GlobalState) => gs.menu.categories);
  const menuRef = createRef<HTMLDivElement>();
  const activeClass = 'highlight';

  const offset = -50;

  const Scroll = () => {
    if (menuRef.current) {
      const activeItem = menuRef.current.getElementsByClassName(
        activeClass
      )[0] as HTMLElement;
      if (activeItem) {
        menuRef.current.scrollLeft = activeItem.offsetLeft - 8;
      }
    }
  };

  return (
    <div className={styles.navMenu} ref={menuRef}>
      {categories.map((c, i) => (
        <Link
          key={i}
          spy={true}
          smooth={true}
          hashSpy={true}
          offset={offset}
          className={ c === 'Święta' ? 'xmas' : c === 'Pomagamy' ? 'help' : c === 'Wielkanoc' ? 'easter' :  ''}
          activeClass={activeClass}
          onSetActive={Scroll}
          to={slugifyName(c)}
        >
          {c}
        </Link>
      ))}
    </div>
  );
};

export default NavMenu;
