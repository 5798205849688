import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from 'src/logic/user';
import { replace } from 'connected-react-router';
import { routes } from 'src/routes';

const Verify = () => {
  const m = useRouteMatch<{ code: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (m.params.code) {
      dispatch(userActions.verifyAccount.request({ code: m.params.code, andThen: () => { dispatch(replace(routes.index())) } }));
    } else {
      dispatch(replace(routes.index()));
    }
  }, [m.params.code, dispatch])
  return <div />
}

export default Verify;
