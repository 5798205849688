import React, { useEffect } from 'react';
import MenuProducts from 'src/components/menu/menu';
import { useDispatch, useSelector } from 'react-redux';
import { menuActions } from 'src/logic/menu';
import { GlobalState } from 'src/logic/reducers';
import { useActiveDate } from 'src/components/hooks/useActiveDate';
import { getDateString } from 'src/logic/utils/dates';
import PageHeader from './pageHeader/pageHeader';
import MenuWrapper from './menuWrapper/menuWrapper';
import ContentFooter from 'src/components/content/contentFooter/contentFooter';

const Menu = () => {
  const dispatch = useDispatch();
  const activeDate = useActiveDate();
  const menus = useSelector((gs: GlobalState) => gs.menu.menu);
  const loadingMenu = useSelector((gs: GlobalState) => gs.menu.pending);
  useEffect(() => {
    const id = menus?.find(menu => menu.date && getDateString(menu.date) === activeDate)?.id;
    if (id) {
      dispatch(menuActions.dayMenu.request(id));
    }
  }, [menus, activeDate, dispatch]);

  useEffect(()=> {
    window.scrollTo(0,0);
  }, []);
  
  const dayMenu = useSelector((gs: GlobalState) => gs.menu.dayMenu);
  const categories = useSelector((gs: GlobalState) => gs.menu.categories);
  const filterOptions = useSelector((gs: GlobalState) => gs.menu.filterOptions);
  return (
    <>
      <PageHeader />
      <MenuWrapper>
        <MenuProducts dayMenu={dayMenu} categories={categories} loading={loadingMenu} filterOptions={filterOptions} />
      </MenuWrapper>
      <ContentFooter/>
    </>
  )
}

export default Menu;
