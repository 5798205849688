import React from 'react';
import styles from './loginForm.module.scss';
import { useFormikContext } from 'formik';
import { FormValues } from './loginForm';
import { TextFormField } from '../forms/textField/textField';
import ErrorList from '../forms/errorList/errorList';
import { BtnForm } from 'src/components/forms/Forms';

type SetPasswordProps = {
  login(): void;
  errorsList: string[];
}

export function SetPassword({ login, errorsList }: SetPasswordProps) {
  const { handleSubmit, isSubmitting } = useFormikContext<FormValues>();
  return (
    <div className={styles.setPassword}>
      <TextFormField<keyof FormValues>
        name='password'
        label="hasło"
        type="password"
      />
      <TextFormField<keyof FormValues>
        name='confirmPassword'
        label="powtórz hasło"
        type="password"
      />
      <ErrorList errors={errorsList} />
      <div className={styles.buttonWrapper}>
        <BtnForm type="orange" action={handleSubmit} disabled={isSubmitting}>
          Zapisz hasło
        </BtnForm>
      </div>
      <p className="uppercase black50">
        Wróć do <a onClick={login} > logowania</a>
      </p>
    </div>
  );
}
