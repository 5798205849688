/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnsubscribeRequest
 */
export interface UnsubscribeRequest {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeRequest
     */
    code?: string;
}

export function UnsubscribeRequestFromJSON(json: any): UnsubscribeRequest {
    return UnsubscribeRequestFromJSONTyped(json, false);
}

export function UnsubscribeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnsubscribeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function UnsubscribeRequestToJSON(value?: UnsubscribeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'code': value.code,
    };
}


