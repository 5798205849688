import React, { useEffect, useState } from 'react';
import styles from './details.module.scss';
import SidepanelContent from 'src/containers/sidepanel/sidepanelContent';
import SidepanelFooter from 'src/containers/sidepanel/sidepanelfooter';
import SideCTABtn from '../buttons/sideCTABtn/sideCTABtn';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { displayPrice, getPackagePrice } from 'src/logic/utils';
import { basketActions } from 'src/logic/basket';
import { imgUrl } from 'src/logic/utils/imgUrl';
import Tag from '../tag/tag';
import useWindowSize from '../hooks/useWindowSize';
import CountEditor from '../countEditor/countEditor';
import { useActiveDate } from '../hooks/useActiveDate';
import LimitTooltip from '../countEditor/limitTooltip';
import { gtmAddToCart, gtmRemoveFromCart, gtmViewItem, setItemToGtmItem } from 'src/logic/gtm';

const Details = () => {
  const { pixelRatio } = useWindowSize();
  const activeDate = useActiveDate();
  const { ITEM_LIMIT } = useSelector((gs: GlobalState) => gs.settings);
  const m = useRouteMatch<{ date: string; id: string }>();
  const item = useSelector((gs: GlobalState) =>
    Object.values(gs.menu.dayMenu ?? {})
      .flatMap((f) => f)
      .find((f) => f.productId === parseInt(m.params.id, 10))
  );
  const dispatch = useDispatch();
  const [showLimitTooltip, setShowLimitTooltip] = useState(false);
  const packages = useSelector((gs: GlobalState) => gs.settings.PRODUCT_PACKAGE);
  const count = useSelector((gs: GlobalState) =>
    activeDate ? gs.basket.basket[activeDate]?.items.find((b) => b.productId === item?.productId ?? 0)?.quantity : 0
  );
  const gtmItem = item && setItemToGtmItem(item);

  const upadetCount = (quantity: number) => {
    dispatch(
      basketActions.modifyBasket({
        productId: item?.productId ?? 0,
        productName: item?.product?.name ?? '',
        productCategory: item?.product?.category?.name ?? '',
        productCategoryId: item?.product?.category?.id ?? 0,
        productDescription: item?.product?.description ?? '',
        date: new Date(activeDate ?? 0),
        quantity,
        price: item?.price ?? 0,
        discountedPrice: item?.price ?? 0,
        menuId: item?.menuId ?? 0,
        menuItemId: item?.id ?? 0,
        itemLimit: Math.min(ITEM_LIMIT, item?.quantityStock ?? 0),
        productStock: item?.quantityStock ?? 0,
        productInfo: {
          calories: item?.product?.calories ?? 0,
          protein: item?.product?.protein ?? 0,
          carbs: item?.product?.carbs ?? 0,
          fat: item?.product?.fat ?? 0,
          weight: item?.product?.weight ?? 0
        },
        package: { name: item?.product?._package || '', value: item?.product?._package ? getPackagePrice(packages, item?.product?._package) : 0 },
      })
    );
    if(gtmItem && quantity === 1) gtmAddToCart(gtmItem);
    if(gtmItem && quantity === -1) gtmRemoveFromCart(gtmItem);
    if (quantity + (count ?? 0) === Math.min(ITEM_LIMIT, item?.quantityStock ?? 0)) {
      setShowLimitTooltip(true);
      setTimeout(() => setShowLimitTooltip(false), 3000);
    } else if (showLimitTooltip === true) {
      setShowLimitTooltip(false);
    }
  };
  const category = item?.product?.category;
  const lowResStyle = {
    background: `url(${imgUrl(
      item?.product?.pictureMenu ?? '/dinner.png',
      100 * (category === 'Zestawy' ? 1.5 : 1),
      100
    )})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'auto 96%',
  };

  useEffect(() => gtmItem && gtmViewItem(gtmItem, activeDate), [item])
  return (
    <>
      <SidepanelContent>
        <header className={styles.detailsHeader}>
          {item?.product?.pictureName ? (
            <img
              src={imgUrl(item.product.pictureName, 251 * pixelRatio, 64 * pixelRatio)}
              className={styles.prodHat}
              alt=''
            />
          ) : (
            <h2 className={styles.prodName}>{item?.product?.name}</h2>
          )}
          <div className={styles.prodPic}>
            <img
              style={item ? lowResStyle : undefined}
              src={
                item
                  ? imgUrl(item?.product?.pictureMenu ?? '/dinner.png', 600 * pixelRatio, 600 * pixelRatio)
                  : undefined
              }
              alt=''
            />
          </div>
        </header>
        <article className={styles.details}>
          <p dangerouslySetInnerHTML={{__html: item?.product?.description ?? ''}}></p>
          <h3 title='gramatura produktu'>
            {' '}
            {(item?.product?.category?.name || '').toLowerCase() === 'napoje'
              ? `Objętość: ${item?.product?.weight}ml`
              : `Waga: ${item?.product?.weight}g`}
          </h3>
          <h3>WARTOŚCI ODŻYWCZE W 100G</h3>
          <div className={styles.nutritionalValues}>
            <div className={styles.nutritionalBlock}>
              <div>
                <big>{item?.product?.calories ?? 0}</big>
                <span>kcal</span>
              </div>
              <div>{((item?.product?.calories ?? 0) / 20).toFixed(0)}%</div>
            </div>

            <div className={styles.nutritionalBlock}>
              <div>
                <big>{item?.product?.protein ?? 0} g</big>
                <span>białko</span>
              </div>
              <div>{((item?.product?.protein ?? 0) / 0.5).toFixed(0)}%</div>
            </div>

            <div className={styles.nutritionalBlock}>
              <div>
                <big>{item?.product?.carbs ?? 0} g</big>
                <span>węgl.</span>
              </div>
              <div>{((item?.product?.carbs ?? 0) / 2.7).toFixed(0)}%</div>
            </div>

            <div className={styles.nutritionalBlock}>
              <div>
                <big>{item?.product?.fat ?? 0} g</big>
                <span>tłuszcze</span>
              </div>
              <div>{((item?.product?.fat ?? 0) / 0.7).toFixed(0)}%</div>
            </div>
            <p className={styles.nutritionInfo}>
              % wskazanego dziennego spożycia dla osoby dorosłej wynoszącego 2000 kcal
            </p>
          </div>
          <h3>Składniki</h3>
          <p dangerouslySetInnerHTML={{__html: item?.product?.ingredients ?? ''}}></p>
          {item?.product?.dip && (
            <>
              <h3>DIP</h3>
              <p>{item?.product?.dip}</p>
            </>
          )}
          <div className={styles.tagList}>
            {item?.product?.tags?.map((t) => (
              <Tag tag={t} key={t.id} showLabel />
            ))}
          </div>
        </article>
      </SidepanelContent>
      <SidepanelFooter muted={(item?.quantityStock ?? 0) < 1}>
        Cena: {displayPrice(item?.price)}
        {showLimitTooltip && <LimitTooltip top="8px" right="8px">Nie mamy już więcej </LimitTooltip>}
        {item?.quantityStock ? (
          count ? (
            <CountEditor
              value={count}
              updateCount={upadetCount}
              limit={Math.min(ITEM_LIMIT, item?.quantityStock ?? 0)}
            />
          ) : (
            <SideCTABtn disabled={(item?.quantityStock ?? 0) < 1} action={() => upadetCount(1)}>
              <span>Dodaj do koszyka</span> <img src='/icons/basket.svg' className={styles.cart} alt='' />
            </SideCTABtn>
          )
        ) : (
          <span className={styles.outOfStock}>Niestety nie mamy już więcej na ten dzień</span>
        )}

      </SidepanelFooter>
    </>
  );
};

export default Details;
