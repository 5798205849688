import React from 'react';
import { BtnForm } from 'src/components/forms/Forms';
import { useDispatch } from 'react-redux';
import { orderActions } from '../../../logic/order';
import Modal from 'src/containers/modal/modal';
import Fieldset from 'src/containers/fieldset/fieldset';
import styles from '../account.module.scss';

export const EditDeliveryModal = ({ open, orderId, orderMenuId, close }: { open: boolean; orderId: number | undefined; orderMenuId: number | undefined; close: () => void }) => {
  const dispatch = useDispatch();
  if (!open) {
    return null;
  }
  return (
    <Modal close={close}>
      <Fieldset title="Edytuj zamówienie">
        <div className={styles.modalInfo}>
          <p>
            Czy na pewno chcesz edytować tę dostawę?
          </p>
          <p>
            Ta dostawa zostanie anulowana, a środki z niej zasilą Twoje konto, dzięki czemu będzie je można wykorzystać tworząc nowe zamówienie.
          </p>
        </div>
        <div className={styles.payoutAction}>
          <BtnForm type="orange" action={() => { dispatch(orderActions.editMenu([`${orderId}`, `${orderMenuId}`])) }}>Chcę edytować</BtnForm>
        </div>
      </Fieldset>
    </Modal>
  )
}
