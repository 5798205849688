import React from 'react';
import MenuList from '../menuIList/menuList';
import { DayProducts, FilterOption } from 'src/logic/menu';
import Sidepanel from 'src/containers/sidepanel/sidepanel';
import Basket from '../basket/basket';
import { Element } from 'react-scroll'
import Details from '../details/details';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { routes } from 'src/routes';
import { useActiveDate } from 'src/components/hooks/useActiveDate';
import { replace } from 'connected-react-router';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { orderBy } from 'lodash';
import { slugifyName } from 'src/logic/utils';

type MenuProps = {
  dayMenu: DayProducts | null;
  categories: string[];
  filterOptions: FilterOption[];
  loading: boolean;
};


const Menu = ({
  dayMenu,
  categories,
  filterOptions,
  loading,
}: MenuProps) => {
  const basket = useSelector((gs: GlobalState) => gs.basket.basket);
  const { ITEM_LIMIT } = useSelector((gs: GlobalState) => gs.settings);
  const dispatch = useDispatch();
  const activeDate = useActiveDate();
  const onHideSidepanel = () => dispatch(replace(routes.menu(activeDate ? new Date(activeDate) : '')));
  const m = useRouteMatch();
  const open = !m.isExact;
  const filters = filterOptions.filter(x => x.active === true);

  return (
    <div>
      {categories.map((category) => {
        const menuItems = orderBy(dayMenu?.[category], i => [i.product.order ?? 9999, i.product.name ?? ''])
        const filteredMenuItems = (filters && filters.length > 0) ? menuItems.filter(mi => mi.product?.tags?.some(t => filters.some(f => f.id === t.id))) : menuItems;
        const refName = slugifyName(category);
        if (!filteredMenuItems) {
          return null;
        }
        return (
          <Element name={refName} key={category}>
            <MenuList
              menuItems={filteredMenuItems}
              title={category}
              loading={loading}
              setList={category === 'Zestawy'}
              special={category === 'Święta' ? 'xmas' : category === 'Pomagamy' ? 'help' : category === 'Wielkanoc' ? 'easter' : undefined}
              itemLimit={ITEM_LIMIT}
              activeDate={activeDate}
            />
          </Element>
        );
      })}
      <Sidepanel open={open} close={onHideSidepanel}>
        <Switch>
          <Route path={routes.menuBasket()}><Basket order={basket} /></Route>
          <Route path={routes.menuDetails()}><Details /></Route>
        </Switch>
      </Sidepanel>
    </div>
  );
};
export default Menu;
