import React, { useMemo } from 'react';
import styles from './pageHeader.module.scss';
import BasketBtn from 'src/components/buttons/basketBtn/basketBtn';
import { UserInfo } from 'src/components/userInfo';
import { StickyContainer, Sticky } from 'react-sticky';
import NavMenu from 'src/components/navMenu/navMenu';
import Calendar from 'src/components/calendar/calendar';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { sum } from 'lodash';
import { replace } from 'connected-react-router';
import { routes } from 'src/routes';
import { useActiveDate } from 'src/components/hooks/useActiveDate';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import Filter from 'src/components/filter/filter';
import { menuActions, FilterOption } from 'src/logic/menu';
import Logo from 'src/components/logo/logo';
import { Link } from 'react-scroll';
import { getBasketStats } from 'src/logic/basket';

const PageHeader = () => {
  const menus = useSelector((gs: GlobalState) => gs.menu.menu);
  const basket = useSelector((gs: GlobalState) => gs.basket.basket);
  const vouchers = useSelector((gs: GlobalState) => gs.basket.vouchers);
  const timeToOrder = useSelector((gs: GlobalState) => gs.settings.TIME_TO_ORDER);
  const freeDelivery = useSelector((gs: GlobalState) => gs.settings.FREE_DELIVERY);
  const deliveryHome = useSelector((gs: GlobalState) => gs.settings.DELIVERY_HOME);
  const cutleryCost = useSelector((gs: GlobalState) => gs.settings.CUTLERY_COST);
  const location = useSelector((gs: GlobalState) => gs.addresses.location);
  const filterOptions = useSelector((gs: GlobalState) => gs.menu.filterOptions);
  const dayOrders = useMemo(() => {
    return Object.entries(basket).map(([day, dayOrder]) => ({
      day: new Date(day),
      count: sum(dayOrder.items.map(i => i.quantity))
    }))
  }, [basket]);

  const { itemsCount, total } = getBasketStats(
    basket,
    vouchers,
    freeDelivery,
    deliveryHome,
    !!location,
    cutleryCost,
  );

  const dispatch = useDispatch();
  const activeDate = useActiveDate();
  const showBasket = () => dispatch(replace(routes.menuBasket(activeDate ? new Date(activeDate) : '')));
  const applyFilters = (filterOptions: FilterOption[]) => {
    dispatch(menuActions.filterOptions(filterOptions));
    dispatch(replace(routes.menu(activeDate ? new Date(activeDate) : '')));
  }
  const stickyDistance = 100;
  return (
    <StickyContainer>
      <Sticky>
        {({
          distanceFromTop
        }) => (
            <div
              className={[
                styles.pageHeader,
                distanceFromTop < stickyDistance ? styles.pageHeaderSticky : ' ',
              ].join(' ')}
            >
              <div className={styles.headerWrapper}>
                <section>
                <div className={styles.logoAndDate}>
                  <Logo sticky={distanceFromTop < stickyDistance}/>
                  <Link to="root" smooth={true} offset={-250} className={styles.dateBtn}>
                    {activeDate && format(new Date(activeDate), 'cccccc, d LLL', { locale: pl })}
                  </Link>
                </div>
                <div className={styles.headerButtons}>
                  <UserInfo gentle />
                  <BasketBtn quantity={itemsCount} price={total} openBasket={showBasket} />
                </div>
                </section>
                <section>
                <div className={styles.dayPicker}>
                  <h4 >Wybierz dzień</h4>
                  <Calendar orders={dayOrders} menus={menus} timeToOrder={timeToOrder} />
                </div>
                </section>
                <section>
                <div className={styles.navigation}>
                  <NavMenu />
                </div>
                <div className={styles.filters}>
                  <Filter options={filterOptions} saveAction={applyFilters} clearAction={applyFilters} />
                </div>
                </section>
              </div>
            </div>
          )}
      </Sticky>
    </StickyContainer>
  );
};
export default PageHeader;
