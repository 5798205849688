import React from 'react';
// import ContentPage from '../contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import ContentPage from '../content/contentPage/contentPage';
import styles from './unsubscribe.module.scss';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { routes } from 'src/routes';
import { userActions } from 'src/logic/user';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import cn from 'classnames';

const Unsubscribe = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { code = '', email = '' }: { code?: string; email?: string } = qs.parse(location.search.slice(1));
  return (
    <ContentPage>
      <PageWrapper>
        <h1>Nie chcesz już dostawać od nas powiadomień?</h1>
        <h2>
          Anulowanie subskrypcji sprawi, że mogą ominąć Cię promocje i informacje o nowościach.
          Subskrypcję możesz włączyć ponownie z poziomu ustawień swojego konta.
        </h2>
        <div className={styles.buttonGroup}>
          <button className={styles.button} onClick={() => dispatch(replace(routes.index()))}>Subskrybuj dalej</button>
          <button className={cn(styles.button, styles.colorReverse)} onClick={() => dispatch(userActions.unsubscribe({ code, email, andThen: () => dispatch(replace(routes.index())) }))}>Anuluj subskrypcję</button>
        </div>
      </PageWrapper>
    </ContentPage>
  )
};

export default Unsubscribe;
