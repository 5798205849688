/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendNotificationResponse
 */
export interface SendNotificationResponse {
    /**
     * 
     * @type {string}
     * @memberof SendNotificationResponse
     */
    status?: string;
}

export function SendNotificationResponseFromJSON(json: any): SendNotificationResponse {
    return SendNotificationResponseFromJSONTyped(json, false);
}

export function SendNotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendNotificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SendNotificationResponseToJSON(value?: SendNotificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}


