import { createAdminLogic, UpsertParams, getApi, getRelations } from './utils';
import {
  OrderApi,
  Order,
  OrderMenuApi,
  OrderMenu,
  ProductApi,
  Product,
  Category,
  CategoryApi,
  Tag,
  TagApi,
  User,
  UserApi,
  Menu,
  MenuApi,
  Setting,
  SettingApi,
  Voucher,
  VoucherApi,
  ApiFilesEntity,
  FileApi,
  OrdersPerDaySummary,
  OrdersPerDaySummaryApi,
  Role,
  RoleApi,
  Mailing,
  MailingApi,
  UsersWithOrdersInfoApi,
  UsersWithOrdersInfo,
  Task,
  TaskApi,
  NotificationsApi,
  ProductToJSON,
} from 'src/api';
import { combineReducers } from 'redux';
import { getFilter,Filter } from './utils/apiConfig';

const ordersRelations = getRelations<Order>({ 'user': true, payment: true, menus: { address: true, menu: true, location: true, items: { menuItem: { product: true } } }, vouchers: true, invoice: true })
const ordersUpsertRelations = getRelations<Order>({ 'user': true, payment: true, menus: { address: true, menu: true, location: true, items: { menuItem: true } } })
const orders = createAdminLogic('order')<Order>(
  {
    getDetails: (id: number) => getApi(OrderApi).apiOrdersIdGet(id, ordersRelations, true),
    getAll: (filter?: Filter<Order>, offset?: number, limit?: number) => getApi(OrderApi).apiOrdersGet(ordersRelations, true, getFilter(filter), offset, limit),
    upsert: async ({ entity }: UpsertParams<Order>) => {
      const result = await getApi(OrderApi).apiOrdersPost(ordersUpsertRelations, false, entity);
      return getApi(OrderApi).apiOrdersIdGet(result.id ?? 0, ordersRelations) ?? result;
    },
    delete: (id: number) => getApi(OrderApi).apiOrdersIdCancelPut(id.toString())
  }
)

const orderMenusRelations = getRelations<OrderMenu>({ address: true, order: { payment: true, user: true, invoice: true, vouchers: true }, menu: true, location: true, items: { menuItem: { product: { category: true } } } })
const orderMenus = createAdminLogic('orderMenu')<OrderMenu>(
  {
    getDetails: (id: number) => getApi(OrderMenuApi).apiOrderMenusIdGet(id, orderMenusRelations),
    getAll: (filter?: Filter<OrderMenu>, offset?: number, limit?: number) => getApi(OrderMenuApi).apiOrderMenusGet(orderMenusRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<OrderMenu>) => getApi(OrderMenuApi).apiOrderMenusPost(orderMenusRelations, false, entity),
    delete: (id: number) => getApi(OrderMenuApi).apiOrderMenusIdDelete(id)
  }
)

const ordersPerDaySummaryRelations = getRelations<OrdersPerDaySummary>({  })
const ordersPerDaySummary = createAdminLogic('ordersPerDaySummary')<OrdersPerDaySummary>(
  {
    getDetails: (id: number) => getApi(OrdersPerDaySummaryApi).apiOrdersPerDaySummariesIdGet(id, ordersPerDaySummaryRelations),
    getAll: (filter?: Filter<OrdersPerDaySummary>, offset?: number, limit?: number) => getApi(OrdersPerDaySummaryApi).apiOrdersPerDaySummariesGet(ordersPerDaySummaryRelations, false, getFilter(filter), offset, limit),
    upsert: async (_: UpsertParams<OrdersPerDaySummary>) => { throw Error('not implemented') },
    delete: async (_: number) => { throw Error('not implemented') },
  }
)

const productsRelations = getRelations<Product>({ tags: true, category: true })
const products = createAdminLogic('products')<Product>(
  {
    getDetails: (id: number) => getApi(ProductApi).apiProductsIdGet(id, productsRelations),
    getAll: (filter?: Filter<Product>, offset?: number, limit?: number) => getApi(ProductApi).apiProductsGet(productsRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity, ...blobs }: UpsertParams<Product>) => getApi(ProductApi).apiProductsPostRaw({ relations: productsRelations, entity: JSON.stringify(ProductToJSON(entity)) as any, ...blobs }).then(d => d.value()), // this stringify & cast to any is a workaround as multipart is not handled correctly
    delete: (id: number) => getApi(ProductApi).apiProductsIdDelete(id)
  }
)

const categoriesRelations = getRelations<Category>({})
const categories = createAdminLogic('categories')<Category>(
  {
    getDetails: (id: number) => getApi(CategoryApi).apiCategoriesIdGet(id, categoriesRelations),
    getAll: (filter?: Filter<Category>, offset?: number, limit?: number) => getApi(CategoryApi).apiCategoriesGet(categoriesRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<Category>) => getApi(CategoryApi).apiCategoriesPost(categoriesRelations, false, entity),
    delete: (id: number) => getApi(CategoryApi).apiCategoriesIdDelete(id)
  }
)

const tagsRelations = getRelations<Tag>({})
const tags = createAdminLogic('tags')<Tag>(
  {
    getDetails: (id: number) => getApi(TagApi).apiTagsIdGet(id, tagsRelations),
    getAll: (filter?: Filter<Tag>, offset?: number, limit?: number) => getApi(TagApi).apiTagsGet(tagsRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<Tag>) => getApi(TagApi).apiTagsPost(tagsRelations, false, entity),
    delete: (id: number) => getApi(TagApi).apiTagsIdDelete(id)
  }
)

const usersRelations = getRelations<User>({  })
const usersRelationsWithOrders = getRelations<User>({ roles: true, walletHistories: true, addresses: true, orders: true })
const usersUpdateRelations = getRelations<User>({ roles: true, walletHistories: true, addresses: true  })
const users = createAdminLogic('users')<User | UsersWithOrdersInfo>(
  {
    getDetails: (id: number) => getApi(UserApi).apiUsersIdGet(id, usersRelationsWithOrders),
    getAll: (filter?: Filter<UsersWithOrdersInfo>, offset?: number, limit?: number) => getApi(UsersWithOrdersInfoApi).apiUsersWithOrdersInfosGet(usersRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<User>) => getApi(UserApi).apiUsersPost(usersUpdateRelations, false, JSON.stringify(entity) as any),
    delete: (id: number) => getApi(UserApi).apiUsersIdDelete(id)
  }
)

const menusRelations = getRelations<Menu>({ items: { product: { category: true } } })
const menus = createAdminLogic('menus')<Menu>(
  {
    getDetails: (id: number) => getApi(MenuApi).apiMenusIdGet(id, menusRelations),
    getAll: (filter?: Filter<Menu>, offset?: number, limit?: number) => getApi(MenuApi).apiMenusGet(menusRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<Menu>) => getApi(MenuApi).apiMenusPost(menusRelations, false, entity),
    delete: (id: number) => getApi(MenuApi).apiMenusIdDelete(id)
  }
)

const settingsRelations = getRelations<Setting>({})
const settings = createAdminLogic('settings')<Setting>(
  {
    getDetails: (id: number) => getApi(SettingApi).apiSettingsIdGet(id, settingsRelations),
    getAll: (filter?: Filter<Setting>, offset?: number, limit?: number) => getApi(SettingApi).apiSettingsGet(settingsRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<Setting>) => getApi(SettingApi).apiSettingsPost(settingsRelations, false, entity),
    delete: (id: number) => getApi(SettingApi).apiSettingsIdDelete(id)
  }
)

const rolesRelations = getRelations<Role>({})
const roles = createAdminLogic('roles')<Role>(
  {
    getDetails: (id: number) => getApi(RoleApi).apiRolesIdGet(id, rolesRelations),
    getAll: (filter?: Filter<Role>, offset?: number, limit?: number) => getApi(RoleApi).apiRolesGet(rolesRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<Role>) => getApi(RoleApi).apiRolesPost(rolesRelations, false, entity),
    delete: (id: number) => getApi(RoleApi).apiRolesIdDelete(id)
  }
)

const vouchersRelations = getRelations<Voucher>({ location: true, categories: true, products: true })
const vouchers = createAdminLogic('vouchers')<Voucher>(
  {
    getDetails: (id: number) => getApi(VoucherApi).apiVouchersIdGet(id, vouchersRelations),
    getAll: (filter?: Filter<Voucher>, offset?: number, limit?: number) => getApi(VoucherApi).apiVouchersGet(vouchersRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<Voucher>) => getApi(VoucherApi).apiVouchersPost(vouchersRelations, false, entity),
    delete: (id: number) => getApi(VoucherApi).apiVouchersIdDelete(id)
  }
)

const tasksRelations = getRelations<Task>({ user: true })
const tasks = createAdminLogic('tasks')<Task>(
  {
    getDetails: (id: number) => getApi(TaskApi).apiTasksIdGet(id, tasksRelations),
    getAll: (filter?: Filter<Task>, offset?: number, limit?: number) => getApi(TaskApi).apiTasksGet(tasksRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity }: UpsertParams<Task>) => getApi(TaskApi).apiTasksPost(tasksRelations, false, entity),
    delete: (id: number) => getApi(TaskApi).apiTasksIdDelete(id)
  }
)

// arrays are wrongly generated as strings here, so this is workaround for lazy peoples...
export type FixedMailing = Omit<Mailing, 'recipients' | 'delivered'> & {delivered?: number[]; recipients?: number[]};

const mailingRelations = getRelations<FixedMailing>({ })
const mailing = createAdminLogic('mailing')<FixedMailing>(
  {
    getDetails: (id: number) => getApi(MailingApi).apiMailingsIdGet(id, mailingRelations) as any,
    getAll: (filter?: Filter<FixedMailing>, offset?: number, limit?: number) => getApi(MailingApi).apiMailingsGet(mailingRelations, false, getFilter(filter), offset, limit) as any,
    upsert: ({ entity }: UpsertParams<FixedMailing>) => getApi(MailingApi).apiMailingsPost(mailingRelations, false, entity as unknown as Mailing) as any,
    delete: (id: number) => getApi(MailingApi).apiMailingsIdDelete(id)
  }
)

const fileRelations = '';
const file = createAdminLogic('file')<ApiFilesEntity>(
  {
    getDetails: (id: number) => getApi(FileApi).apiFilesIdGet(id, fileRelations).then(JSON.parse), // NFI why the parse is needed here...
    getAll: (filter?: Filter<ApiFilesEntity>, offset?: number, limit?: number) => getApi(FileApi).apiFilesGet(fileRelations, false, getFilter(filter), offset, limit),
    upsert: ({ entity, ...blobs }: UpsertParams<ApiFilesEntity>) => getApi(FileApi).apiFilesPostRaw({ entity: JSON.stringify(entity) as any, ...blobs }).then(d => d.value()), // this stringify & cast to any is a workaround as multipart is not handled correctly
    delete: (id: number) => getApi(FileApi).apiFilesIdDelete(id)
  }
)


export const invoices = {
  create: (id: number) => getApi(OrderApi).apiOrdersIdInvoicePut(String(id)),
  get: (id: number) => getApi(OrderApi).apiOrdersIdInvoiceGet(String(id)),
	getCorrection: (id: number) => getApi(OrderApi).apiOrdersIdInvoiceCorrectionGet(String(id)),
}

export const notifications = {
  send: (title: string, body: string, recipients: number[], sendToNotAssigned: boolean) => getApi(NotificationsApi).apiNotificationsSendPost({
    title, body, sendToNotAssigned, recipients
  })
}

export const adminActions = {
  orders: orders.actions,
  products: products.actions,
  categories: categories.actions,
  tags: tags.actions,
  users: users.actions,
  menus: menus.actions,
  settings: settings.actions,
  orderMenus: orderMenus.actions,
  ordersPerDaySummary: ordersPerDaySummary.actions,
  vouchers: vouchers.actions,
  tasks: tasks.actions,
  roles: roles.actions,
  mailing: mailing.actions,
  files: file.actions,
}

export const reducer = combineReducers({
  orders: orders.reducer,
  products: products.reducer,
  categories: categories.reducer,
  tags: tags.reducer,
  users: users.reducer,
  menus: menus.reducer,
  settings: settings.reducer,
  orderMenus: orderMenus.reducer,
  ordersPerDaySummary: ordersPerDaySummary.reducer,
  vouchers: vouchers.reducer,
  tasks: tasks.reducer,
  roles: roles.reducer,
  mailing: mailing.reducer,
  files: file.reducer,
})

export function* saga() {
  yield* orders.saga();
  yield* products.saga();
  yield* categories.saga();
  yield* tags.saga();
  yield* users.saga();
  yield* menus.saga();
  yield* settings.saga();
  yield* orderMenus.saga();
  yield* ordersPerDaySummary.saga();
  yield* vouchers.saga();
  yield* tasks.saga();
  yield* roles.saga();
  yield* mailing.saga();
  yield* file.saga();
}
