import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './rightActions.module.scss';

export type Props = {
  disabled?: boolean;
  disableActions?: boolean;
  rightActions?: ReactNode;
  multiline?: boolean;
  className?: string;
};

export function RightActions({ disableActions, rightActions, multiline, disabled = false, className }: Props) {
  const rightActionsClasses = cn(
    styles.rightActions,
    {
      [styles.disabled]: disabled,
      [styles.disableActions]: disableActions || (disableActions == null && disabled),
      [styles.multiline]: multiline
    },
    className
  );
  return (
    <div className={rightActionsClasses}>{rightActions}</div>
  );

}
