export type DateString = string & { __dt_tag: void };

export const getDate = (daysOffset: number) => {
  return new Date(new Date(Date.now() + 1000 * 60 * 60 * 24 * daysOffset).toDateString())
}

export const getDateString = (d: Date): DateString => new Date(d.getTime() - new Date().getTimezoneOffset() * 1000 * 60).toISOString().slice(0, 10) as DateString;

/** this is workaround for https://github.com/OpenAPITools/openapi-generator/issues/5932 */
export const updateDateByTimezone = (d: Date) => new Date(d.getTime() - new Date().getTimezoneOffset() * 1000 * 60);
