import React from 'react';
import styles from './tag.module.scss';

type TagProps = {
  tag: { id?: number; name?: string };
  showLabel?: boolean;
  invert?: boolean;
};

const tagLabels = [
  'n/a',
  'Z rybą',
  'Fit',
  'Vegan',
  'Vege',
  'Bez glutenu',
  'Bez laktozy',
  'Pikantne',
];

const Tag = ({ tag, showLabel, invert }: TagProps) => {
  if(!tag.id || !tagLabels[tag.id]){
    return null;
  }
  return (
    <div className={[styles.tagWrapper, invert && styles.invert].join(' ')}>
      <picture className={styles.tagIcon} key={tag.name} title={tagLabels[tag.id]}>
        <img src={'/icons/' + tag.name + '.svg'} alt={tagLabels[tag.id]} />
      </picture>
      {showLabel && <span className={styles.tagLabel}>{tagLabels[tag.id]}</span>}
    </div>
  );
};

export default Tag;
