import React, { useState, ReactNode } from 'react';

import { createPortal } from 'react-dom';

export const IFrame = ({ children, title, ...props }: { children: ReactNode; width?: string | number; height?: string | number; title: string }) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} ref={setContentRef} title={title}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
