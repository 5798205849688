import React from 'react';
import ContentPage from './contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import ThirdImage from './thirdImage/thirdImage';
import { Link } from 'react-router-dom';
import { routes } from 'src/routes';

const WhyPage = () => (
  <ContentPage>
    <PageWrapper>
      <h1 className="h1">Dlaczego my?</h1>
      <ThirdImage title="Elastyczność" image="/images/dlaczego.svg">
        <p>Masz ochotę na 1 kanapkę? Chcesz zamówić dla całej rodziny z
        kilkudniowym wyprzedzniem i nie martwić się co zjesz za kilka dni? Z
        Pobudką to wszystko jest możliwe! Brak limitu dostawy, a także możliwość
        złożenia zamówień na 2 tygodnie do przodu.</p>
      </ThirdImage>
      <ThirdImage title="Duży wybór" image="/images/wybor.svg" invert>
        <p>Codziennie około 100 pozycji do wyboru, z czego kilkanaście unikatowych.
        Dzięki takiej rotacji nigdy Ci się nie zanudzimy!</p>
      </ThirdImage>
      <ThirdImage title="Świeżość" image="/images/swiezosc.svg">
      <p>Dziś zrobione, dziś zjedzone - to hasło przyświeca nam od samego
        początku. . Nasze produkty są przygotowywane z najświeższych składników w 
        ciągu 24h do momentu dostawy, a 1-dniowy termin przydatności do spożycia,
         gwarantuje brak jakichkolwiek konserwantów, czy ulepszaczy. </p>
      </ThirdImage>
      <ThirdImage title="Cena" image="/images/cena.svg" invert>
      <p>Średnia cena naszych produktów nie przekracza 10zł, dzięki temu jesteśmy
        dużo bardziej konkurencyjni cenowo niż jakakolwiek restauracja, czy
        typowe jedzenie na dowóz.</p>
      </ThirdImage>
      <ThirdImage title="Łatwość zamówienia" image="/images/latwosc.svg">
      <p>Tylko 1 minuta i kilka kliknięć dzielą Cię od tego, abyś mógł się
        cieszyć naszym jedzeniem. Nigdzie indziej zamówienie jedzenia nie jest
        takie proste.</p>
      </ThirdImage>
      <ThirdImage title="Bezpieczeństwo " image="/images/bezpieczenstwo.svg" invert>
      <p>Spełniamy wszelkie normy wymagane przez sanepid, mamy wdrożony system HACCP, 
        a od czasu pojawienie się COVID-19 wprowadziliśmy szereg dodatkowych procedur 
        i obostrzeń, aby działać możliwie bezpiecznie. <Link  to={routes.security()}>Dowiedz się więcej</Link> o naszych standardach. </p>
      </ThirdImage>
      <ThirdImage title="Bezkontaktowość" image="/images/bezkontaktowosc.svg">
      <p>Dzięki zamówieniu on-line oraz bezgotówkowej płatności - niezależnie,
        czy zamawiasz do domu, czy biura Twoje jedzenie pozostawimy w
        wyznaczonym miejscu bez konieczności kontaktu z dostawcą.</p>
      </ThirdImage>
    </PageWrapper>
  </ContentPage>
);

export default WhyPage;
