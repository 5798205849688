import React  from 'react';
import styles from './cookies.module.scss';
import { BtnForm } from '../forms/Forms';
import { routes } from 'src/routes';

type ModalProps = {
  close(): void;
  long?: boolean;
};

const Cookies = ({ close, long }: ModalProps) => {
  return (
    <div className={[styles.coockiesModal, long && styles.long].join(' ')}>
      <div className={styles.modalContent}>
        <p>
          W ramach naszej witryny stosujemy pliki cookies w celu świadczenia
          Państwu usług na najwyższym poziomie, w tym w sposób dostosowany do
          indywidualnych potrzeb. Korzystanie z&nbsp;witryny bez zmiany ustawień
          dotyczących cookies oznacza, że będą one zamieszczane w Państwa
          urządzeniu końcowym. Możecie Państwo dokonać w każdym czasie zmiany
          ustawień dotyczących cookies. Więcej szczegółów w naszej &nbsp; 
          <a href={routes.privacy()}>Polityce Prywatności</a>. 
        </p>
        <BtnForm type={long ? 'orange' : 'white'} action={close}>
          OK
        </BtnForm>
      </div>
    </div>
  );
};
export default Cookies;
