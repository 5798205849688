import React from 'react';
import styles from './balanceItem.module.scss';
import { WalletHistory } from '../../../api/models/WalletHistory';
import { displayPrice } from 'src/logic/utils';
import { Link } from 'react-router-dom';
import { routes } from 'src/routes';

type BalanceItemProps = {
  item: WalletHistory;
};

const BalanceItem = ({ item }: BalanceItemProps) => {
  const { amount, description, createdAt } = item;
  const to = item.orderId
    ? routes.order(item.orderId, 'display')
    : item.orderMenuId
      ? routes.delivery(item.orderMenu?.orderId, item.orderMenuId)
      : '';
  const defaultDescription = (amount ?? 0) < 0 ? 'Wykorzystano środki' : 'Dodano środki';
  return (
    <article className={styles.balanceItem}>
      <div>{description === 'payout' ? 'Wypłata' : (description ?? defaultDescription)}</div>
      <section>
        <div>
          <span className={(amount ?? 0) > 0 ? 'black' : 'red'}>
            {displayPrice(amount)}
          </span>
        </div>
        <div className={styles.itemDate}>{createdAt?.toLocaleDateString()}</div>
      </section>
      <div>
        {
          to &&
          <Link to={to} className={styles.link}>
            Zobacz zamówienie
        </Link>
        }
      </div>
    </article>
  );
};
export default BalanceItem;
