import React, { ReactNode } from 'react';
import TooltipLite, { TooltipProps } from 'react-tooltip-lite';
import styles from './tooltip.module.scss';
import cn from 'classnames';

export function Tooltip({tipContentClassName, ...props}: TooltipProps & { children: ReactNode }) {
  return <TooltipLite
    arrowSize={8}
    {...props}
    className={styles.tooltip}
    tipContentClassName={styles.tooltipContent}
    content={<div className={cn(styles.content, tipContentClassName)}>{props.content}</div>}
  />;
}
