import React from 'react';
import styles from './loginForm.module.scss';
import { BtnForm } from '../forms/Forms';
import { TextFormField } from '../forms/textField/textField';
import { FormValues } from './loginForm';
import { useFormikContext } from 'formik';
import ErrorList from '../forms/errorList/errorList';

export function Reset({ errorsList }: { errorsList: string[] }) {
  const { handleSubmit } = useFormikContext();

  return (
    <div className={styles.reset}>
      <br />
      <TextFormField<keyof FormValues> name='email' label="Adres email" />
      <br />
      <ErrorList errors={errorsList} />
      <BtnForm type="orange" action={handleSubmit} >Resetuj</BtnForm>
    </div>
  );
}
