import React, { useMemo } from 'react';
import styles from './heroSection.module.scss';
import Calendar from '../../calendar/calendar';
import { useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { ReactComponent as LeftHand } from './img/left-image.svg';
import { ReactComponent as RightHand } from './img/right-image.svg';
import { sum } from 'lodash';
import AdminBanner from '../adminBanner/adminBanner';

const HeroSection = () => {
  const menus = useSelector((gs: GlobalState) => gs.menu.menu);
  const basket = useSelector((gs: GlobalState) => gs.basket.basket);
  const timeToOrder = useSelector((gs: GlobalState) => gs.settings.TIME_TO_ORDER);
  const dayOrders = useMemo(() => {
    return Object.entries(basket).map(([day, dayOrder]) => ({
      day: new Date(day),
      count: sum(dayOrder.items.map(i => i.quantity))
    }))
  }, [basket]);
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroOuter}>
      <div className={styles.heroWrapper}>
          <Calendar orders={dayOrders} menus={menus} timeToOrder={timeToOrder}/>
          <AdminBanner />
          <div className={styles.heroContent}>
            <div className={styles.heroText}>
              <h1>
                <em>Wybierz datę dostawy</em>
                <br />i zamów smaczną Pobudkę!
              </h1>
            </div>
            <LeftHand className={styles.leftImg} />

            <RightHand className={styles.rightImg} />
            <p>Oferta obowiązuje na terenie wybranych miejscowości w całej Polsce</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeroSection;
