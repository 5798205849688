import React, { ComponentType } from 'react';
import { Switch, Route, NavLink, Redirect, matchPath, useLocation } from 'react-router-dom';
import styles from './adminContainer.module.scss';
import cn from 'classnames';

export type EntityInfo = [
  string,
  ComponentType<{ editUrl: (id: number | 'new' | '') => string }>,
  ComponentType<{ editUrl: (id: number | 'new' | '') => string }>,
  string[]
];

export const listUrl = (baseUrl: string, entity = ':entity') => `${baseUrl}/${entity}`;
export const detailsUrl = (baseUrl: string, entity = ':entity', id: number | string = ':id') => `${listUrl(baseUrl, entity)}/${id}`;

type ContextType = {
  entity: string | undefined;
  baseUrl: string;
}
export const AdminContext = React.createContext<ContextType>({ entity: undefined, baseUrl: '' });

export function AdminContainer({ entities, baseUrl }: { entities: EntityInfo[]; baseUrl: string }) {
  const location = useLocation();
  const m = matchPath<{ entity: string }>(location.pathname, listUrl(baseUrl));

  return (
    <AdminContext.Provider value={{ entity: m?.params?.entity, baseUrl }}>
      <div className={styles.links}>
        {
          entities.map(([e]) => <NavLink key={e} to={listUrl(baseUrl, e)} activeClassName={styles.active}>{e}</NavLink>)
        }
      </div>
      <div className={styles.container}>
        <div className={cn(styles.listContainer, { [styles.sidenavOpen]: !m?.isExact })}>
          <Switch>
            {
              entities.map(([entity, List]) =>
                <Route key={entity} path={listUrl(baseUrl, entity)} >
                  <List editUrl={(id: number | 'new' | '') => detailsUrl(baseUrl, entity, id)} />
                </Route>
              )
            }
            {entities.length && <Redirect to={listUrl(baseUrl, entities[0][0])} />}
          </Switch>
        </div>
        <Switch>
          {
            entities.map(([entity, , Details]) =>
              <Route key={entity} path={detailsUrl(baseUrl, entity)} >
                <div className={styles.detailsContainer}>
                  <Details editUrl={(id: number | 'new' | '') => detailsUrl(baseUrl, entity, id)} />
                </div>
              </Route>
            )
          }
        </Switch>
      </div>
    </AdminContext.Provider>
  )
}
