import React, { FC, useCallback, useEffect, useRef } from 'react';

import styles from './checkbox.module.scss';

type Props = {
  checked?: boolean | 'indeterminate';
  label?: string;
  onChange?: (check: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox: FC<Props> = ({ checked, label, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.currentTarget.checked, e);
  }, [onChange]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = checked === 'indeterminate';
    }
  }, [checked]);

  return (
    <label className={styles.checkbox} >
      {label && <div>{label}</div>}
      <input type='checkbox' checked={!!checked} onChange={handleChange} ref={inputRef} />
      <div />
    </label>
  );
}
