import React from 'react';
import styles from './voucherInfo.module.scss';
import { displayPrice } from 'src/logic/utils';

type VoucherInfoProps = {
  vouchers: { code: string; amount?: number | string; address?: string }[];
  remove: (code: string) => void;
  header: string;
  isPercentage?: boolean;
};

const DisplayCode = (code: string): string => `*** ${code && code.length > 5 ? code.substr(code.length - 5, 5) : ''}`;

const VoucherInfo = ({ vouchers, header, remove, isPercentage = false }: VoucherInfoProps) =>
<>
  {vouchers.map((v, i) => (
    <div key={i} className={styles.voucherDetails}>
      {v.address ? (
        <p title={header}>
          Dostawa do
          <br />
          <strong>{v.address}</strong>
        </p>
      ) : (
        <p title={header}>Kod zniżkowy ({DisplayCode(v.code)})</p>
      )}
      {isPercentage ?
        (<b className={styles.voucherValue}>{typeof v.amount === 'number' ? displayPrice(v.amount, '%', 0) : v.amount}</b>) :
        (<b className={styles.voucherValue}>{typeof v.amount === 'number' ? displayPrice(v.amount) : v.amount}</b>)
      }
      <a onClick={() => remove(v.code)}>usuń</a>
    </div>
  ))}
  </>

export default VoucherInfo;
