import React from 'react';
import styles from './loginForm.module.scss';
import { useFormikContext } from 'formik';
import { FormValues } from './loginForm';

export function Regsitered() {
  return (
    <div className={styles.registered}>
      <p>
        Właśnie zostałeś zalogowany, smacznych zakupów.
      </p>
      <div className={styles.sentActivation}>
      </div>
    </div>
  );
}
