import React from 'react';
import styles from './fieldset.module.scss';

type FieldsetProps = {
    filled?: boolean;
    title: string;
    children: React.ReactNode;
}

const Fieldset = ({filled, children, title}: FieldsetProps)=> (
    <div className={[styles.fieldset, filled ? styles.fieldsetFilled:''].join(' ')}>
        <h3 className={styles.fieldsetHeader}>{title}</h3>
        {children}
    </div>
)
export default Fieldset;
