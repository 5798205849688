import React, { useEffect, Suspense, useState } from 'react';
import styles from './App.module.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { routes } from 'src/routes';
import { useDispatch, useSelector } from 'react-redux';
import { basketActions } from 'src/logic/basket';
import { addressesActions } from 'src/logic/addresses';
import Menu from './containers/Menu';
import Buy from './components/buy/buy';
import Account from './components/account/account';
import MyOrderDetails from './components/account/myOrderDetails/myOrderDetails';
import AboutPage from './components/content/aboutPage';
import WhyPage from './components/content/whyPage';
import Safety from './components/content/safety';
import Range from './components/content/range';
import Contact from './components/content/contact/contact';
import Rules from './components/content/rules';
import MyDeliveryDetails from './components/account/myOrderDetails/myDeliveryDetails';
import LandingPage from './components/landing/landingPage';
import Verify from './components/verify/verify';
import { settingsActions } from 'src/logic/settings';
import Privacy from './components/content/privacy';
import Cookies from './components/cookies/cookies';
import Unsubscribe from './components/unsubscribe/unsubscribe';
import { userActions } from './logic/user';
import { GlobalState } from './logic/reducers';
import Offline from './containers/Offline';
import { Device } from './components/device/device';
import DeliveryToOffices from './components/content/deliveryToOffices';

const Admin = React.lazy(() => import('./containers/Admin'));


function App({ history }: { history: History<any> }) {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(basketActions.load()) }, [dispatch]);
  useEffect(() => { dispatch(settingsActions.load.request()) }, [dispatch]);
  useEffect(() => { dispatch(addressesActions.load.request()) }, [dispatch]);
  useEffect(() => { dispatch(userActions.loadUserSettings()) }, [dispatch]);
  useEffect(() => { dispatch(userActions.loadDeviceType()) }, [dispatch]);
  const userSettings = useSelector((gs: GlobalState) => gs.user.userSettings);
  const deviceType = useSelector((gs: GlobalState) => gs.user.deviceType);
  const showcoo = !userSettings.cookiesConfirmed && deviceType !== 'ios';
  const [showCookies, setShowCookies] = useState(showcoo);
  const cookiesConfirmed = () => {
    setShowCookies(false);
    dispatch(userActions.confirmCookies());
  }
  return (
    <div className={styles.App}>
      <Suspense fallback={<div></div>}>
        <ConnectedRouter history={history}>
          <Device />
          <Switch>
            <Route path={routes.offline()} component={Offline} />
            <Route exact path={routes.index()} component={LandingPage} />
            <Route exact path={routes.reset()} component={LandingPage} />
            <Route exact path={routes.verify()} component={Verify} />
            <Route path={routes.menu()} component={Menu} />
            <Route path={routes.buy()} component={Buy} />
            <Route path={routes.account()} component={Account} />
            <Route path={routes.admin()} component={Admin} />
            <Route path={routes.delivery()} component={MyDeliveryDetails} />
            <Route path={routes.order()} component={MyOrderDetails} />
            <Route path={routes.aboutUs()} component={AboutPage} />
            <Route path={routes.why()} component={WhyPage} />
            <Route path={routes.security()} component={Safety} />
            <Route path={routes.deliveryRange()} component={Range} />
            <Route path={routes.deliveryToOffices()} component={DeliveryToOffices} />
            <Route path={routes.contact()} component={Contact} />
            <Route path={routes.terms()} component={Rules} />
            <Route path={routes.privacy()} component={Privacy} />
            <Route path={routes.unsubscribe()} component={Unsubscribe} />
            <Redirect to={routes.index()} />
          </Switch>
        </ConnectedRouter>
        <ToastContainer autoClose={5000} position="bottom-left" />
      </Suspense>
      {showCookies && showcoo && <Cookies close={cookiesConfirmed} long />}
    </div>
  );
}

export default App;
