/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Tag,
    TagFromJSON,
    TagToJSON,
    TagDataFrame,
    TagDataFrameFromJSON,
    TagDataFrameToJSON,
} from '../models';

export interface ApiTagsGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiTagsIdDeleteRequest {
    id: number;
}

export interface ApiTagsIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiTagsPostRequest {
    relations?: string;
    deleted?: boolean;
    tag?: Tag;
}

/**
 * no description
 */
export class TagApi extends runtime.BaseAPI {

    /**
     * Get list of Tag
     */
    async apiTagsGetRaw(requestParameters: ApiTagsGetRequest): Promise<runtime.ApiResponse<TagDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Tag
     */
    async apiTagsGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<TagDataFrame> {
        const response = await this.apiTagsGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Tag
     */
    async apiTagsIdDeleteRaw(requestParameters: ApiTagsIdDeleteRequest): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTagsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * Remove entity of Tag
     */
    async apiTagsIdDelete(id: number): Promise<Tag> {
        const response = await this.apiTagsIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Tag
     */
    async apiTagsIdGetRaw(requestParameters: ApiTagsIdGetRequest): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTagsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * Get entity of Tag
     */
    async apiTagsIdGet(id: number, relations?: string, deleted?: boolean): Promise<Tag> {
        const response = await this.apiTagsIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Tag (if id defined)
     */
    async apiTagsPostRaw(requestParameters: ApiTagsPostRequest): Promise<runtime.ApiResponse<Tag>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagToJSON(requestParameters.tag),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Tag (if id defined)
     */
    async apiTagsPost(relations?: string, deleted?: boolean, tag?: Tag): Promise<Tag> {
        const response = await this.apiTagsPostRaw({ relations: relations, deleted: deleted, tag: tag });
        return await response.value();
    }

}
