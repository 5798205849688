import React from 'react';
import { BtnForm } from 'src/components/forms/Forms';
import { useDispatch } from 'react-redux';
import { orderActions } from '../../../logic/order';
import Modal from 'src/containers/modal/modal';
import Fieldset from 'src/containers/fieldset/fieldset';
import styles from '../account.module.scss';

export const CancelDeliveryModal = ({ open, orderId, orderMenuId, close }: { open: boolean; orderId: number | undefined; orderMenuId: number | undefined; close: () => void }) => {
  const dispatch = useDispatch();
  if (!open) {
    return null;
  }
  return (
    <Modal close={close}>
      <Fieldset title="Edytuj zamówienie">
        <div className={styles.modalInfo}>
          <p>
            Czy na pewno chcesz anulować tę dostawę?
          </p>
          <p>
            Środki z anulowania tej dostawy zasilą Twoje konto, dzięki czemu będzie je można wykorzystać w przyszłości do innych zamówień lub wypłacić.
          </p>
        </div>
        <div className={styles.payoutAction}>
          <BtnForm type="orange" action={() => { dispatch(orderActions.cancelMenu([`${orderId}`, `${orderMenuId}`])); close(); }}>Chcę anulować</BtnForm>
        </div>
      </Fieldset>
    </Modal>
  )
}
