import cn from 'classnames';
import React, {Component} from 'react';
import styles from './textArea.module.scss';

export type TextAreaProps = {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  fill?: boolean;
  resize: boolean;
  rows: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
};

export class TextArea extends Component<TextAreaProps> {
  public static defaultProps = {
    disabled: false,
    resize: true,
    rows: 1,
  };

  public render() {
    const {className, fill, resize, ...rest} = this.props;
    const classNames = cn(
      styles.textArea,
      {
        [styles.fill]: fill,
        [styles.noResize]: !resize,
      },
      className,
    );
    return  <textarea className={classNames} {...rest} />;
  }
}
