import cn from 'classnames';
import React from 'react';
import styles from './grid.module.scss';

type Props = {
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
};

export function Spacer({ width }: Props) {
  const classes = cn(
    styles.spacer,
    {
      [styles[`width${width}`]]: width != null,
     },
  );
  return <div className={classes}></div>;
}
