import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextFormField } from '../forms/textField/textField';
import { FormSection, FormRow, BtnRow, BtnForm } from '../forms/Forms';
import { AddressForm, emptyInvoiceAddress, validateAddress } from '../addressForm/addressForm';
import { addressesActions } from 'src/logic/addresses';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { Address, User } from 'src/api';
import { userActions, UserWithPassword } from '../../logic/user';
import { isEqual } from 'lodash';
import { Formik, useFormikContext, FormikErrors } from 'formik';
import ErrorList from '../forms/errorList/errorList';
import { CheckBoxFormField } from '../forms/checkBox/checkBox';
import { routes } from '../../routes';
import Modal from '../../containers/modal/modal';

type Password = { password?: string };

const passwordValidator = (values: Password): FormikErrors<Password> => {
  return {
    password: values.password && values.password.length >= 4 ? undefined : 'Hasło musi mieć co najmniej 4 znaki'
  }
}
const userDataValidator = (values: UserWithPassword): FormikErrors<UserWithPassword> => {
  return {
    email: values.email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? undefined : 'Nieprawidłowy email',
    name: (values.name?.length ?? 0) > 0 ? undefined : 'Imię jest wymagane',
    lastName: (values.lastName?.length ?? 0) > 0 ? undefined : 'Nazwisko jest wymagane',
    phone: values.phone?.match(/^(\+48)?\d{7,9}$/) ? undefined : 'Numer telefonu jest wymagany',
    password: !values.password || values.password.length >= 6 ? undefined : 'Hasło musi mieć co najmniej 6 znaków',
    confirmPassword: values.password === values.confirmPassword ? undefined : 'Hasła muszą być takie same'
  }
}

const UserDataForm = ({ userDetails }: { userDetails: User | null }) => {
  const { values, setValues, errors, touched } = useFormikContext<UserWithPassword>();
  useEffect(() => {
    setValues({ ...userDetails, password: '', confirmPassword: '' })
  }, [userDetails, setValues]);
  const dispatch = useDispatch();

  const handleRevert = useCallback(() => { setValues({ ...userDetails, password: '', confirmPassword: '' }) }, [setValues, userDetails])
  const handleSubmit = useCallback(() => {
    dispatch(userActions.update.request(values));
  }, [dispatch, values]);

  const changed = (!isEqual({ ...userDetails, password: '', confirmPassword: '' }, values));

  const errorsList = useMemo(() => {
    return Object.entries(touched)
      .filter(([, v]) => v)
      .map(([key]) => errors[key as keyof UserWithPassword])
      .filter((e): e is string => !!e)
  }, [errors, touched])

  return (
    <>
      <FormRow>
        <TextFormField<keyof UserWithPassword> name='name' label="Imię" />
        <TextFormField<keyof UserWithPassword> name='lastName' label="Nazwisko" />
      </FormRow>
      <FormRow>
        <TextFormField<keyof UserWithPassword> name='email' label="Adres email" disabled />
        <TextFormField<keyof UserWithPassword> name='phone' label="Numer telefonu" />
      </FormRow>
      <CheckBoxFormField<keyof UserWithPassword> name='marketingAgreement' >
          <span>
          Wyrażam zgodę na przesyłanie mi informacji marketingowych dotyczących Serwisu. (O tym jak przetwarzamy dane osobowe przeczytasz w <a className="link" href={routes.privacy()}>Polityce prywatności</a> Serwisu)
          </span>
      </CheckBoxFormField>
      <h3>Zmień hasło</h3>

      <FormRow>
        <TextFormField<keyof UserWithPassword> name='password' label="Nowe hasło" type='password' />
        <TextFormField<keyof UserWithPassword> name='confirmPassword' label="Powtórz hasło" type='password' />
      </FormRow>
      <ErrorList errors={errorsList} />
      <BtnRow>
        <BtnForm type="black" disabled={!changed} action={handleSubmit}>Zapisz zmiany</BtnForm>
        <BtnForm type="red" disabled={!changed} action={handleRevert}>Anuluj zmiany</BtnForm>
      </BtnRow>
    </>
  )
}

const DeleteAccountModal = ({open, close}: {open: boolean; close: () => void}) => {
  const dispatch = useDispatch();
  const { values, errors } = useFormikContext<Password>();
  const handleSubmit = useCallback(() => {
    dispatch(userActions.delete(values.password ?? ''));
  }, [dispatch, values]);

  if(!open){
    return null;
  }

  return (
    <Modal close={close}>
        <div>
          <h4>
            Ta zmiana nieodwracalnie usunie Twoje konto w serwisie wraz z powiązanymi z nim danymi teleadresowymi. Odzyskanie danych nie będzie możliwe. Czy potwierdzasz chęć usunięcia konta?
          </h4>
          <FormRow>
            <TextFormField<keyof Password> name='password' label="Hasło" type={'password'} />
          </FormRow>
          <BtnRow>
            <BtnForm type="red" disabled={!values.password || !!errors.password} action={handleSubmit}>Tak, usuń to konto</BtnForm>
            <BtnForm type="black" action={close}>Nie, rezygnuję z usuwania</BtnForm>
          </BtnRow>
        </div>
    </Modal>
  )
}


const MyData = () => {
  const dispatch = useDispatch();
  const postalCodes = useSelector((gs: GlobalState) => gs.order.postalCodes);
  useEffect(() => {
    dispatch(addressesActions.load.request());
  }, [dispatch]);

  const selectedUserDetails = useSelector((gs: GlobalState) => gs.user.details);
  const onSubmit = () => { return;};

  const addresses = useSelector((gs: GlobalState) => gs.addresses.addresses);
  const invoiceAddress = addresses.find(a => a.type === 'invoice');
  const saveAddress = useCallback((address: Address) => {
    dispatch(addressesActions.upsert.request(address));
  }, [dispatch]);

  const [showDeleteModal, changeShowDeleteModal] = useState(false);

  return (
    <div>
      <FormSection>
        <h3>Dane użytkownika</h3>
        <Formik<UserWithPassword> initialValues={{}} onSubmit={onSubmit} validate={userDataValidator}>
          <UserDataForm userDetails={selectedUserDetails} />
        </Formik>
      </FormSection>
      <FormSection>
        <h3>Dane do faktury</h3>
        <Formik<Address> initialValues={invoiceAddress ?? emptyInvoiceAddress} onSubmit={saveAddress} validate={validateAddress()}>
          <AddressForm address={invoiceAddress ?? emptyInvoiceAddress} saveAddress addressType='invoice' />
        </Formik>
      </FormSection>
      <FormSection>
        <h3>Usuń to konto</h3>
        <span>Zmiana ta będzie nieodwracalna</span>
        <BtnRow>
          <BtnForm type="red" action={() => changeShowDeleteModal(true)}>Usuń konto</BtnForm>
        </BtnRow>
      </FormSection>
      <Formik<Password> initialValues={{}} onSubmit={onSubmit} validate={passwordValidator}>
        <DeleteAccountModal open={showDeleteModal} close={() => changeShowDeleteModal(false)} />
      </Formik>
    </div>
  )
};
export default MyData;
