/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface Voucher
 */
export interface Voucher {
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Voucher
     */
    multiple?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Voucher
     */
    used?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    percentage?: number;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    wallet?: number;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    discount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Voucher
     */
    freeDelivery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Voucher
     */
    mobileAppOnly?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    dateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    locationId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    deletedAt?: Date;
    /**
     * 
     * @type {Location}
     * @memberof Voucher
     */
    location?: Location;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Voucher
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Voucher
     */
    products?: Array<Product>;
}

export function VoucherFromJSON(json: any): Voucher {
    return VoucherFromJSONTyped(json, false);
}

export function VoucherFromJSONTyped(json: any, ignoreDiscriminator: boolean): Voucher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'multiple': !exists(json, 'multiple') ? undefined : json['multiple'],
        'used': !exists(json, 'used') ? undefined : json['used'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'wallet': !exists(json, 'wallet') ? undefined : json['wallet'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'freeDelivery': !exists(json, 'freeDelivery') ? undefined : json['freeDelivery'],
        'mobileAppOnly': !exists(json, 'mobileAppOnly') ? undefined : json['mobileAppOnly'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : (new Date(json['dateFrom'])),
        'dateTo': !exists(json, 'dateTo') ? undefined : (new Date(json['dateTo'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(CategoryFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductFromJSON)),
    };
}

export function VoucherToJSON(value?: Voucher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'multiple': value.multiple,
        'used': value.used,
        'percentage': value.percentage,
        'wallet': value.wallet,
        'discount': value.discount,
        'freeDelivery': value.freeDelivery,
        'mobileAppOnly': value.mobileAppOnly,
        'dateFrom': value.dateFrom === undefined ? undefined : (value.dateFrom.toISOString().substr(0,10)),
        'dateTo': value.dateTo === undefined ? undefined : (value.dateTo.toISOString().substr(0,10)),
        'note': value.note,
        'locationId': value.locationId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'location': LocationToJSON(value.location),
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(CategoryToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductToJSON)),
    };
}


