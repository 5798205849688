import cn from 'classnames';
import { range } from 'lodash';
import React from 'react';

import styles from './pager.module.scss';

function PageButton({ onClick, page, isSelected }: { onClick: (page: number) => void; page: number; isSelected: boolean }) {
  return <button className={cn(styles.pageBtn, { [styles.active]: isSelected })} onClick={() => onClick(page)}>{page}</button>;
}

function Dots() {
  return <span className={styles.pageDots}>...</span>;
}

export function Pager({ page, pages, onChange }: { page: number; pages: number; onChange: (page: number) => void }) {
  if (pages < 2) {
    return null;
  }

  if (pages <= 7) {
    return <div>{range(1, pages + 1).map((i) => <PageButton key={i} onClick={onChange} page={i} isSelected={i === page} />)}</div>;
  }

  const lowIdx = Math.max(2, Math.min(page - 1, pages - 2));
  const topIdx = Math.min(Math.max(page + 2, 4), pages);

  return (
    <div>
      <PageButton key={'first'} onClick={onChange} page={1} isSelected={page === 1} />
      {lowIdx > 2 && <Dots />}
      {range(lowIdx, topIdx).map((i) => <PageButton key={i} onClick={onChange} page={i} isSelected={i === page} />)}
      {topIdx < pages && <Dots />}
      <PageButton key={'last'} onClick={onChange} page={pages} isSelected={page === pages} />
    </div>
  );
}
