import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { push } from 'connected-react-router';
import { userActions } from 'src/logic/user';

export const FCM_KEY = 'fcm';

export const Device: FC<unknown> = () => {
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = qs.parse(search.slice(1));
    const { fcm, deviceType, ...rest } = queryParams;
    if(!fcm) {
      return;
    }
    const storedFCM = JSON.parse(localStorage.getItem(FCM_KEY) ?? '{}');
    const newQS = qs.stringify(rest);
    dispatch(push(newQS ? `${pathname}?${newQS}` : pathname));
    if(storedFCM.fcm === fcm){
      return;
    }
    localStorage.setItem(FCM_KEY, JSON.stringify({ fcm, deviceType }));
    dispatch(userActions.registerDevice({ token: fcm, deviceType }));
  }, [search, pathname, dispatch]);

  return null;
}
