/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateResponse
 */
export interface ValidateResponse {
    /**
     * 
     * @type {number}
     * @memberof ValidateResponse
     */
    iat?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidateResponse
     */
    exp?: number;
}

export function ValidateResponseFromJSON(json: any): ValidateResponse {
    return ValidateResponseFromJSONTyped(json, false);
}

export function ValidateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iat': !exists(json, 'iat') ? undefined : json['iat'],
        'exp': !exists(json, 'exp') ? undefined : json['exp'],
    };
}

export function ValidateResponseToJSON(value?: ValidateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iat': value.iat,
        'exp': value.exp,
    };
}


