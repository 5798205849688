import React, { useState, useEffect } from 'react';
import { userActions } from 'src/logic/user';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { Login } from '../loginForm';
import styles from './userInfo.module.scss';
import { routes } from '../../routes';
import Modal from '../../containers/modal/modal';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router-dom';

export function UserInfo({ gentle, logout }: { gentle?: boolean; logout?: boolean }) {
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const dispatch = useDispatch();
  const user = useSelector((gs: GlobalState) => gs.user.details);
  const closeModal = () => {
    setShowLoginForm(false);
  };

  const handleLogout = () => dispatch(userActions.logout());

  const handleClick = () => {
    if (user) {
      dispatch(push(routes.account()));
      return;
    }
    setShowLoginForm(true);
  }

  const m = useRouteMatch<{code: string}>();
  useEffect(() => {
    if(m.params.code){
      setShowLoginForm(true);
    }
  }, [m.params.code])

  useEffect(()=> {
    if(user)
      setShowLoginForm(false);
  }, [user]
  )

  return (
    <>{logout? <a className={styles.userLink} onClick={handleLogout}>Wyloguj</a> :
      <a
        title={user ? user.name || user.email : 'login'}
        className={[styles.userLink, gentle && styles.gentle].join(' ')}
        onClick={handleClick}
      >
        {
          user && user.avatar
            ? <img src={user.avatar} width={32} alt="avatar 2" />
            : <img src="/icons/user.svg" width={22} alt='avatar' />
        }
        <span>{user ? 'Moje konto' : 'Zaloguj'}</span>
      </a>}
      {
        showLoginForm && (
          <Modal close={closeModal}>
            <Login />
          </Modal>
        )
      }
    </>
  );
}
