/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    Menu,
    MenuFromJSON,
    MenuFromJSONTyped,
    MenuToJSON,
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
    OrderMenuItem,
    OrderMenuItemFromJSON,
    OrderMenuItemFromJSONTyped,
    OrderMenuItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderMenu
 */
export interface OrderMenu {
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    delivery?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderMenu
     */
    invoice?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderMenu
     */
    invoiceCorrectionNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    orderId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    menuId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    cutlery?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenu
     */
    cutleryCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderMenu
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderMenu
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderMenu
     */
    deletedAt?: Date;
    /**
     * 
     * @type {Address}
     * @memberof OrderMenu
     */
    address?: Address;
    /**
     * 
     * @type {Location}
     * @memberof OrderMenu
     */
    location?: Location;
    /**
     * 
     * @type {Order}
     * @memberof OrderMenu
     */
    order?: Order;
    /**
     * 
     * @type {Menu}
     * @memberof OrderMenu
     */
    menu?: Menu;
    /**
     * 
     * @type {Array<OrderMenuItem>}
     * @memberof OrderMenu
     */
    items?: Array<OrderMenuItem>;
}

export function OrderMenuFromJSON(json: any): OrderMenu {
    return OrderMenuFromJSONTyped(json, false);
}

export function OrderMenuFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMenu {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'delivery': !exists(json, 'delivery') ? undefined : json['delivery'],
        'invoice': !exists(json, 'invoice') ? undefined : json['invoice'],
        'invoiceCorrectionNumber': !exists(json, 'invoiceCorrectionNumber') ? undefined : json['invoiceCorrectionNumber'],
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'menuId': !exists(json, 'menuId') ? undefined : json['menuId'],
        'cutlery': !exists(json, 'cutlery') ? undefined : json['cutlery'],
        'cutleryCost': !exists(json, 'cutleryCost') ? undefined : json['cutleryCost'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'order': !exists(json, 'order') ? undefined : OrderFromJSON(json['order']),
        'menu': !exists(json, 'menu') ? undefined : MenuFromJSON(json['menu']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(OrderMenuItemFromJSON)),
    };
}

export function OrderMenuToJSON(value?: OrderMenu | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'locationId': value.locationId,
        'price': value.price,
        'delivery': value.delivery,
        'invoice': value.invoice,
        'invoiceCorrectionNumber': value.invoiceCorrectionNumber,
        'orderId': value.orderId,
        'menuId': value.menuId,
        'cutlery': value.cutlery,
        'cutleryCost': value.cutleryCost,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'address': AddressToJSON(value.address),
        'location': LocationToJSON(value.location),
        'order': OrderToJSON(value.order),
        'menu': MenuToJSON(value.menu),
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(OrderMenuItemToJSON)),
    };
}


