/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpRequest
     */
    marketingAgreement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    fcm?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    deviceType?: string;
}

export function SignUpRequestFromJSON(json: any): SignUpRequest {
    return SignUpRequestFromJSONTyped(json, false);
}

export function SignUpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'marketingAgreement': !exists(json, 'marketingAgreement') ? undefined : json['marketingAgreement'],
        'fcm': !exists(json, 'fcm') ? undefined : json['fcm'],
        'deviceType': !exists(json, 'deviceType') ? undefined : json['deviceType'],
    };
}

export function SignUpRequestToJSON(value?: SignUpRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'marketingAgreement': value.marketingAgreement,
        'fcm': value.fcm,
        'deviceType': value.deviceType,
    };
}


