/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Mailing
 */
export interface Mailing {
    /**
     * 
     * @type {number}
     * @memberof Mailing
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Mailing
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof Mailing
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Mailing
     */
    recipients?: string;
    /**
     * 
     * @type {string}
     * @memberof Mailing
     */
    shipment?: string;
    /**
     * 
     * @type {string}
     * @memberof Mailing
     */
    delivered?: string;
    /**
     * 
     * @type {Date}
     * @memberof Mailing
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Mailing
     */
    updatedAt?: Date;
}

export function MailingFromJSON(json: any): Mailing {
    return MailingFromJSONTyped(json, false);
}

export function MailingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mailing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'recipients': !exists(json, 'recipients') ? undefined : json['recipients'],
        'shipment': !exists(json, 'shipment') ? undefined : json['shipment'],
        'delivered': !exists(json, 'delivered') ? undefined : json['delivered'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function MailingToJSON(value?: Mailing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'message': value.message,
        'recipients': value.recipients,
        'shipment': value.shipment,
        'delivered': value.delivered,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}


