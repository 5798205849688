import React, { useEffect, useCallback } from 'react';
import { FormSection } from '../forms/Forms';
import { Address } from 'src/api';
import { useDispatch, useSelector } from 'react-redux';
import { addressesActions } from 'src/logic/addresses';
import { GlobalState } from 'src/logic/reducers';
import { AddressForm, emptyHomeAddress, validateAddress } from '../addressForm/addressForm';
import { Formik } from 'formik';

const MyAddresses = () => {
  const dispatch = useDispatch();
  const postalCodes = useSelector((gs: GlobalState) => gs.order.postalCodes);
  useEffect(() => {
    dispatch(addressesActions.load.request());
  }, [dispatch]);

  const addresses = useSelector((gs: GlobalState) => gs.addresses.addresses);
  const settings = useSelector((gs: GlobalState) => gs.settings);
  const homeAddress = addresses.find(a => a.type === 'home');
  const saveAddress = useCallback((address: Address) => {
    dispatch(addressesActions.upsert.request(address));
  }, [dispatch]);

  return (
    <div>
      <FormSection>
        <h3>Adres domu</h3>
        <Formik<Address> initialValues={homeAddress ?? emptyHomeAddress} onSubmit={saveAddress} validate={validateAddress(dispatch, postalCodes)}>
          <AddressForm address={homeAddress ?? emptyHomeAddress} saveAddress addressType='home' />
        </Formik>
      </FormSection>
    </div>
  )
};
export default MyAddresses;
