import React from 'react';
import styles from './myOrderDetails.module.scss';
import { displayPrice } from 'src/logic/utils';
import { Order } from 'src/api';

export const PriceSummarySection = ({ order }: { order: Order | null }) => {
  
  return (
    <section className={styles.orderVouchers}>
      {((order?.vouchers?.length??0) > 0) && <div className={styles.orderGroup}>
        {
          order?.vouchers?.map(v => (
            <div key={1} className={styles.orderItem}>
              <span>Kod promocyjny (***{v.code?.slice(-6)})</span>
              <span>{displayPrice(v.discount ? -1 * (v.discount ?? 0) : v.percentage ? v.percentage : 0, v.discount ? 'zł' : '%', v.discount ? 2 : 0)}</span>
            </div>
          ))
        }
      </div>}
      <div className={styles.orderItem}>
        <big>Wartość zamówienia</big>
        <big className={styles.price}>{displayPrice(order?.price)}</big>
      </div>
    </section>
  )
}
