import React from 'react';
import styles from './sidepanel.module.scss';

const SidepanelFooter = ({
  muted,
  children,
}: {
  muted?: boolean;
  children?: React.ReactNode;
}) => (
  <div className={[styles.sidepanelFooter, muted && styles.muted].join(' ')}>
    {children}
  </div>
);

export default SidepanelFooter;
