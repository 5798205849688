import React from 'react';
import styles from './myOrderItem.module.scss';
import { OrderMenu } from 'src/api';
import { displayPrice } from 'src/logic/utils';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { Link } from 'react-router-dom';
import { routes } from 'src/routes';
import { getDeliveryHours } from 'src/logic/addresses';

type OrderItemProps = {
  item: OrderMenu;
};

const MyOrderItem = ({ item }: OrderItemProps) => {
  const { price, items, menu, order, id } = item;
  const canceled = order?.deletedAt || order?.status === 'canceled' || item.deletedAt;
  const date = menu?.date ?? new Date();
  const count = items?.map(i => i.quantity ?? 0)?.reduce((a, b) => a + b, 0) ?? 0;
  return (
    <Link to={routes.delivery(order?.id, id)} >
    <article className={styles.orderItem}>
      <div className={styles.orderMain}>
        <h3>{format(date, 'EEEEEE, P', { locale: pl })}</h3>
        <span>{getDeliveryHours()}</span>
      </div>
      <div className={[styles.orderSide, canceled && styles.canceled].join(' ')}>
        <div>{
          canceled
            ? (<span>Anulowane</span>)
            : !order?.payment?.paid
              ? (<>{count} szt.<br /><span>Nieopłacone</span></>)
              : `${count} szt.`
        }</div>
        <div><b>{displayPrice(price)}</b></div>

      </div>
      <div className={styles.chevron}></div>
    </article>
    </Link>
  );
};
export default MyOrderItem;
