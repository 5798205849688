import React from 'react';
import styles from './orderSummary.module.scss';
import { displayPrice } from 'src/logic/utils/price';
import { Voucher } from 'src/api';
import { VoucherInput } from 'src/components/forms/Forms';
import VoucherInfo from '../voucherInfo/voucherInfo';

type OrderSummaryProps = {
  foodTotal: number;
  deliveryNum: number;
  deliveryFee: number;
  deliveryOfficeFee: number;
  vouchers: Voucher[];
  hasLocation: boolean;
  total: number;
  cutleryCost: number;
  cutleryNum: number;
  cutleryTotal: number;
  addCode:  (code: string) => void;
  removeCode:  (code: string) => void;
  packagesTotal: number;
};

const OrderSummary = ({
  foodTotal,
  deliveryNum,
  deliveryFee,
  deliveryOfficeFee,
  vouchers,
  hasLocation,
  cutleryTotal,
  cutleryNum,
  cutleryCost,
  total,
  addCode,
  removeCode,
  packagesTotal,
}: OrderSummaryProps) => {
  const hasLocationVoucher = vouchers.find((v) => v.location) || hasLocation;
  const deliveryTotal = hasLocationVoucher ? deliveryOfficeFee : deliveryNum * deliveryFee;
  const vouchersTotal = vouchers.reduce((a, b) => a + (b.discount ?? 0) + (b.wallet ?? 0), 0);
  const vouchersNum = vouchers.filter((v) => !v.location).length;
  const discountVouchers = vouchers.filter((v) => v.discount);
  const walletVouchers = vouchers.filter((v) => v.wallet);
  const percentageVouchers = vouchers.filter((v) => v.percentage);
  const locationVouchers = vouchers.filter((v) => v.location);

  return (
    <section className={styles.orderSummay}>
      <header>
        <h3>Podsumowanie</h3>
      </header>
      <div className={styles.spbWrapper}>
        <label>Posiadasz kod promocyjny?</label>
        <VoucherInput addCode={(code) => addCode(code)} />
      </div>

      <div className={styles.summaryDetails}>
        <div>
          <span>Wartość posiłków</span> <strong>{displayPrice(foodTotal)}</strong>
        </div>
        {hasLocationVoucher && (
          <>
            <div>
              <span>Dostawa </span> <strong>{displayPrice(deliveryOfficeFee * deliveryNum)}</strong>
            </div>
            <VoucherInfo
              vouchers={[
                {
                  code: locationVouchers[0]?.code ?? '',
                  amount: 'Za darmo',
                  address: locationVouchers[0]?.location?.name ?? '',
                },
              ]}
              header='Kod dostaw'
              remove={removeCode}
            />
          </>
        )}
        {deliveryNum > 0 && !hasLocationVoucher && (
          <div>
            <span>
              Dostawa{' '}
              <span>
                ({deliveryNum} x {displayPrice(deliveryFee)})
              </span>
            </span>{' '}
            <strong>{displayPrice(deliveryTotal)}</strong>
          </div>
        )}
        {cutleryTotal > 0 && (
          <div>
            <span>
              Sztućce{' '}
              <span>
                ({cutleryNum} x {displayPrice(cutleryCost)})
              </span>
            </span>{' '}
            <strong>{displayPrice(cutleryTotal)}</strong>
          </div>
        )}
          {packagesTotal > 0 && (
          <div>
            <span>
              Opakowania{' '}
            </span>{' '}
            <strong>{displayPrice(packagesTotal)}</strong>
          </div>
        )}
        {vouchersTotal !== 0 && (
          <div>
            <span>
              Kody promocyjne <span>(x{vouchersNum})</span>
            </span>{' '}
            <strong className={styles.voucherTotal}>{displayPrice(vouchersTotal)}</strong>
          </div>
        )}
        {!!discountVouchers.length && (
          <VoucherInfo
            vouchers={discountVouchers.map((v) => ({ code: v.code ?? '', amount: v.discount }))}
            header='Kod promocyjny'
            remove={removeCode}
          />
        )}
        {!!walletVouchers.length && (
          <VoucherInfo
            vouchers={walletVouchers.map((v) => ({ code: v.code ?? '', amount: v.wallet }))}
            header='Kod doładowujący'
            remove={removeCode}
          />
        )}
        {!!percentageVouchers.length && (
          <VoucherInfo
            vouchers={percentageVouchers.map((v) => ({ code: v.code ?? '', amount: v.percentage }))}
            header='Kod procentowy'
            remove={removeCode}
            isPercentage={true}
          />
        )}

        <div>
          <strong>Zamówienie łącznie</strong> <em>{displayPrice(total)}</em>
        </div>
      </div>
    </section>
  );
};
export default OrderSummary;
