import React from 'react';
import styles from './scrollBtn.module.scss';


type ButtonProps = {
    action(): void;
    disabled?: boolean;
    invert?: boolean;    
}

const ScrollBtn = ({ action, disabled, invert }: ButtonProps) => (
    <button disabled={disabled} className={[styles.scrollBtn, invert && styles.invert].join(' ')} onClick={action}>
    </button>
);

export default ScrollBtn;