import { takeLatest, delay, apply, select, put, call } from 'redux-saga/effects';
import { ActionType, getType, createAction } from 'typesafe-actions';
import { GlobalState } from 'src/logic/reducers';
import { pickBy, orderBy, sumBy, fromPairs, isArray, groupBy, mapValues, isEmpty } from 'lodash';
import { getDateString } from 'src/logic/utils';
import { Voucher, VoucherApi } from 'src/api';
import { getApi, getRelations } from './utils/apiConfig';
import { toast } from 'react-toastify';
import { addDays, addHours, format, startOfDay } from 'date-fns';
import { ProductPackage, settingsActions } from 'src/logic/settings';
import { combineLatest } from './utils/sagaHelpers';
import { pl } from 'date-fns/locale';
import { FCM_KEY } from 'src/components/device/device';

export type BasketProductInfo = {
  calories: number;
  protein: number;
  carbs: number;
  fat: number;
  weight: number;
}
export type BasketItem = {
  productId: number;
  quantity: number;
  price: number;
  discountedPrice: number;
  productName: string;
  productCategoryId: number;
  productCategory: string;
  productDescription: string;
  menuId: number;
  menuItemId: number;
  productInfo: BasketProductInfo;
  itemLimit: number;
  productStock: number;
  package: ProductPackage;
}

export type BasketPerDay = {
  items: BasketItem[];
  cutlery: number;
};
export type Basket = {
  [date in string]: BasketPerDay
}
export type PercentageCategory = 'all' | number;
export type PercentageDiscount = { category: PercentageCategory; product: PercentageCategory; discount: number }

export const getBasketStats = (basket: Basket, vouchers: Voucher[], freeDelivery: number, deliveryHome: number, useLocation: boolean, cutleryCost: number) => {
  const orderByDay = orderBy(Object.entries(basket), ([date]) => new Date(date))
  const itemsCount = orderByDay.map(([, v]) => v.items.reduce((a, b) => a + b.quantity, 0)).reduce((a, b) => a + b, 0);
  const hasDeliveryVoucher = vouchers.some(v => v.location) || useLocation;
  const hasFreeDeliveryVoucher = vouchers.some(v => v.freeDelivery === true) || useLocation;
  const percentageDiscountSum =
    vouchers.filter(v => v.percentage)
      .map(v => {
        if (isEmpty(v.categories) && isEmpty(v.products)) {
          const pd: PercentageDiscount[] = [{ category: 'all', product: 'all', discount: v.percentage ?? 0 }];
          return pd;
        } else {
          const cpd: PercentageDiscount[] = v.categories?.map(c => { return { category: c.id!, discount: v.percentage ?? 0, product: -1 } }) ?? [];
          const ppd: PercentageDiscount[] = v.products?.map(p => { return { product: p.id!, discount: v.percentage ?? 0, category: -1 } }) ?? [];
          return [...cpd, ...ppd];
        }
      })
      .flatMap(x => x);

  const discountByCategory = mapValues(groupBy(percentageDiscountSum, p => p.category), f => f.reduce((a, d) => a * (1 - (d.discount / 10000)), 1))
  const discountByProduct = mapValues(groupBy(percentageDiscountSum, p => p.product), f => f.reduce((a, d) => a * (1 - (d.discount / 10000)), 1))
  orderByDay.forEach(([, bpd]) => bpd.items.forEach(bi => {
    const allDiscount = discountByCategory['all'] ?? 1;
    const categoryDiscount = discountByCategory[bi.productCategoryId] ?? 1;
    const productDiscount = discountByProduct[bi.productId] ?? 1;
    bi.discountedPrice = bi.price * allDiscount * (categoryDiscount === 1 ? productDiscount : categoryDiscount);
  }));
  const foodTotal = orderByDay.map(([, v]) => v.items.reduce((a, b) => a + (b.discountedPrice * b.quantity), 0)).reduce((a, b) => a + b, 0);
  const packagesTotal = orderByDay.map(([, v]) => v.items.reduce((a, b) => a + ((b?.package.value ?? 0) * b.quantity), 0)).reduce((a, b) => a + b, 0);
  const cutleryNum = orderByDay.map(([, v]) => v.cutlery).reduce((a, b) => a + b, 0);
  const cutleryTotal = orderByDay.map(([, v]) => v.cutlery * cutleryCost).reduce((a, b) => a + b, 0);
  const deliveryNum = hasFreeDeliveryVoucher ? 0 : hasDeliveryVoucher
    ? 0
    : orderByDay
      .map(([, v]) => v.items.reduce((a, b) => a + b.discountedPrice * b.quantity, 0) >= freeDelivery ? 0 : 1)
      .reduce((a: number, b: number) => a + b, 0);
  const vouchersCash = sumBy(vouchers, v => (v.discount ?? 0) + (v.wallet ?? 0));
  const total = Math.max(0, foodTotal + packagesTotal + deliveryHome * deliveryNum - vouchersCash + cutleryTotal);
  return { orderByDay, itemsCount, foodTotal, deliveryNum, total, cutleryTotal, cutleryNum, packagesTotal }
}

type ModifyBasketParams = {
  productId: number;
  productName: string;
  productDescription: string;
  productCategory: string;
  productCategoryId: number;
  date: Date;
  quantity: number;
  price: number;
  discountedPrice: number;
  menuId: number;
  menuItemId: number;
  itemLimit: number;
  productStock: number;
  productInfo: BasketProductInfo;
  package: ProductPackage;
};

export const basketActions = {
  load: createAction('load basket saved basket data')<void>(),
  filterBasket: createAction('filter basket')<void>(),
  changeCutlery: createAction('change cutlery')<{ date: Date; cutlery: number }>(),
  addVoucher: createAction('add voucher to basket')<string>(),
  removeVoucher: createAction('remove voucher from basket')<string>(),
  addVoucherSuccess: createAction('add voucher to basket success')<Voucher>(),
  clear: createAction('clear basket')<void>(),
  loadFinished: createAction('load basket data finished')<[Basket, Voucher[]]>(),
  modifyBasket: createAction('add product to basket')<ModifyBasketParams>(),
  cleanupBasketPeriodically: createAction('cleanu up basket after one hour')<void>()
};

export type BasketActions = ActionType<typeof basketActions>;

type State = {
  lastModifiedDate?: Date;
  basket: Basket;
  vouchers: Voucher[];
}

const initialState: State = {
  basket: {},
  vouchers: []
};

const modifyDayBasket = (dayBasket: BasketPerDay | undefined, params: ModifyBasketParams): BasketPerDay['items'] => {
  const { productId, productName, productDescription, quantity, itemLimit, productInfo, productStock, ...rest } = params;
  const basket = dayBasket?.items ?? [];
  const item = basket.find(b => b.productId === productId);
  if (!item) {
    return [...basket, {
      quantity: Math.min(Math.max(0, quantity), itemLimit),
      productId,
      productName: productName,
      productDescription: productDescription,
      productInfo: { ...productInfo },
      productStock: productStock,
      itemLimit: itemLimit,
      ...rest,
      package: rest.package,
    }]
  }
  return basket.map(b => b.productId !== productId
    ? b
    : {
      ...b,
      quantity: Math.min(Math.max(0, b.quantity + quantity), itemLimit)
    }
  ).filter(b => b.quantity > 0)
}

export const basketReducer = (state: State = initialState, action: BasketActions): State => {
  switch (action.type) {
    case getType(basketActions.load): return { ...state };
    case getType(basketActions.loadFinished): return { ...state, basket: action.payload[0], vouchers: action.payload[1] };
    case getType(basketActions.modifyBasket): return {
      ...state,
      lastModifiedDate: new Date(),
      basket: pickBy(
        {
          ...state.basket,
          [getDateString(action.payload.date)]: {
            items: modifyDayBasket(state.basket[getDateString(action.payload.date)], action.payload),
            cutlery: state.basket[getDateString(action.payload.date)]?.cutlery ?? 0
          }
        },
        (value) => value.items.length > 0
      )
    };
    case getType(basketActions.changeCutlery): return {
      ...state,
      basket: pickBy(
        {
          ...state.basket,
          [getDateString(action.payload.date)]: {
            items: state.basket[getDateString(action.payload.date)]?.items ?? [],
            cutlery: action.payload.cutlery
          }
        },
        (value) => value.items.length > 0
      )
    }
    case getType(basketActions.clear): return { ...state, lastModifiedDate: undefined, basket: {}, vouchers: [] };
    case getType(basketActions.addVoucherSuccess): return { ...state, vouchers: [...state.vouchers, action.payload] };
    case getType(basketActions.removeVoucher): return { ...state, vouchers: state.vouchers.filter(v => v.code !== action.payload) };
    default: return state;
  }
};

function* onLoad() {
  yield put(basketActions.cleanupBasketPeriodically());
  const rawBasket: string = yield apply(localStorage, localStorage.getItem, ['basket']);
  const rawVouchers: string = yield apply(localStorage, localStorage.getItem, ['vouchers']);
  try {
    if (rawBasket) {
      const parsedBasket: Basket = JSON.parse(rawBasket);
      // needed because structure of basket has changed, so n
      const basket = Object.values(parsedBasket).some(b => isArray(b)) ? {} : parsedBasket;

      yield put(basketActions.loadFinished([basket, []]));
      for(const voucher of (JSON.parse(rawVouchers) ?? []) as Voucher[]) {
        yield put(basketActions.addVoucher(voucher.code ?? ''))
      }
    }
  } catch (e) {
    /** */
  }
}

function* onFilterBasket(): Generator<any, any, any> {
  const basket: Basket = yield select((gs: GlobalState) => gs.basket.basket);
  const vouchers: Voucher[] = yield select((gs: GlobalState) => gs.basket.vouchers);
  const timeToOrder: number = yield select((gs: GlobalState) => gs.settings.TIME_TO_ORDER);
  try {
    const filteredEntries = Object.entries<BasketPerDay>(basket).filter(([date]) => addHours(startOfDay(new Date(date)), -timeToOrder).getTime() > Date.now());
    if (filteredEntries.length < Object.keys(basket).length) {
      yield call(toast.error, 'Część produktów z Twojego koszyka nie może być już zamówiona');
      yield put(basketActions.loadFinished([fromPairs(filteredEntries), vouchers ?? []]));
      yield call(onModifyBasket);
    }
  } catch (e) {
    console.error(e);
  }
}

function* onAddVoucher({ payload }: ReturnType<typeof basketActions.addVoucher>) {
  const api: VoucherApi = yield call(getApi, VoucherApi);
  const vouchers: Voucher[] = yield select((gs: GlobalState) => gs.basket.vouchers);
  const storedFCM = JSON.parse(localStorage.getItem(FCM_KEY) ?? '{}');
  try {
    if (vouchers.find(v => v.code === payload)) {
      yield call(toast.error, 'Nie możesz dodać tego samego kodu po raz kolejny.');
      return;
    }
    const voucher: Voucher = yield apply(api, api.apiVouchersCodeCodeGet, [payload, getRelations<Voucher>({ location: true, categories: true, products: true })]);

    if (voucher.percentage && vouchers.find(v => v.percentage)) {
      yield call(toast.error, 'Nie możesz dodać dwóch kuponów procentowych.');
      return;
    }

    if (!!voucher.mobileAppOnly && (!storedFCM || !storedFCM.fcm)) {
      yield call(toast.error, 'Kod zniżkowy działa tylko w aplikacji mobilnej.');
      return;
    }

    const today = new Date();

    if (voucher.dateFrom) {
      const dateFrom = voucher?.dateFrom;
      if (dateFrom > today) {
        yield call(toast.error, `Voucher jeszcze nie jest aktywny. Data startu: ${format(dateFrom, 'P, (EEEE)', { locale: pl })}.`);
        return;
      }
    }
    if (voucher.dateTo) {
      const dateTo = addDays(voucher?.dateTo, 1);
      if (today > dateTo) {
        yield call(toast.error, `Voucher wygasł. Data zakończenia: ${format(dateTo, 'P, (EEEE)', { locale: pl })}.`);
        return;
      }
    }

    if (voucher.used) {
      yield call(toast.error, 'Ten kod promocyjny został już wykorzystany.');
      return;
    }
    yield put(basketActions.addVoucherSuccess(voucher));
  } catch (e) {
    /** */
    console.error(e);
    yield call(toast.error, 'Podany kod jest niepoprawny.');
  }
}

function* onModifyBasket() {
  yield delay(1000);
  const basket: Basket = yield select((gs: GlobalState) => gs.basket.basket);
  const vouchers: Voucher[] = yield select((gs: GlobalState) => gs.basket.vouchers);
  yield apply(localStorage, localStorage.setItem, ['basket', JSON.stringify(basket)]);
  yield apply(localStorage, localStorage.setItem, ['vouchers', JSON.stringify(vouchers)]);
}

function* onCleanupBasketPeriodically() {
  yield delay(1 * 60 * 1000);
  const lastMod: Date | undefined = yield select((gs: GlobalState) => gs.basket.lastModifiedDate);
  const basket: Basket = yield select((gs: GlobalState) => gs.basket.basket);
  const now = new Date();
  const diff = now.getTime() - (lastMod?.getTime() ?? now.getTime());
  if (diff > 60 * 60 * 1000 && Object.entries(basket).length > 0) {
    yield call(toast.info, 'Minęła godzina odkąd dodałeś produkty więc wyczyściliśmy Twój koszyk.');
    yield put(basketActions.clear());
  }
  yield put(basketActions.cleanupBasketPeriodically());
}

function* onClearBasket() {
  yield apply(localStorage, localStorage.setItem, ['basket', JSON.stringify({})]);
  yield apply(localStorage, localStorage.setItem, ['vouchers', JSON.stringify([])]);
}

export function* basketSaga(): IterableIterator<any> {
  yield takeLatest(getType(basketActions.load), onLoad);
  yield takeLatest([
    getType(basketActions.modifyBasket),
    getType(basketActions.addVoucherSuccess),
    getType(basketActions.removeVoucher),
    getType(basketActions.changeCutlery)
  ], onModifyBasket);
  yield takeLatest(getType(basketActions.filterBasket), onFilterBasket);
  yield takeLatest(getType(basketActions.addVoucher), onAddVoucher);
  yield takeLatest(getType(basketActions.clear), onClearBasket);
  yield takeLatest(getType(basketActions.cleanupBasketPeriodically), onCleanupBasketPeriodically);
  yield combineLatest([getType(basketActions.loadFinished), getType(settingsActions.load.success)], onFilterBasket);
}
