/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuItem,
    MenuItemFromJSON,
    MenuItemFromJSONTyped,
    MenuItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface Menu
 */
export interface Menu {
    /**
     * 
     * @type {number}
     * @memberof Menu
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Menu
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Menu
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Menu
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<MenuItem>}
     * @memberof Menu
     */
    items?: Array<MenuItem>;
}

export function MenuFromJSON(json: any): Menu {
    return MenuFromJSONTyped(json, false);
}

export function MenuFromJSONTyped(json: any, ignoreDiscriminator: boolean): Menu {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(MenuItemFromJSON)),
    };
}

export function MenuToJSON(value?: Menu | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(MenuItemToJSON)),
    };
}


