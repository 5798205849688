import React, { useState, ReactElement, useEffect } from 'react';
import { Label } from '../label/label';
import styles from './genericEdit.module.scss';
import { Input, NumberInput, NumberInputProps, InputProps } from '../input';
import { Select, SelectProps, OptionType } from '../select/select';
import { DatePicker } from '../datePicker/datePicker';
import { Button } from '../button';
import { TextArea, TextAreaProps } from '../textArea';
import { Checkbox } from '../checkbox';
import { updateDateByTimezone } from 'src/logic/utils';
import cn from 'classnames';
import MonacoEditor from '@monaco-editor/react';
import { Modal } from 'src/components/admin/modal';
import { Row } from 'src/components/admin/grid';
import { IFrame } from 'src/components/admin/iframe';

export type FieldEntryProps<FT, TP> = {
  parent: TP;
  value: FT;
  onChange: (value: FT) => unknown;
  key: string;
}
export type FieldEntryComponentCreator<FT, TP> = (p: FieldEntryProps<FT, TP>) => ReactElement;
export type FieldsEntry<FT, TP> = [string | null, FieldEntryComponentCreator<FT, TP>];
export type FieldsDescription<T> = { [K in keyof T]: FieldsEntry<T[K], T> }

export type GenericEditEntryProps<T, TP> = {
  parent: TP;
  value: T;
  onChange: (value: T) => unknown;
  fields: FieldsDescription<T>;
}

export function GenericEditEntry<T, TP>({ fields, value, onChange }: GenericEditEntryProps<T, TP>) {
  if (value === undefined) {
    return null
  }
  return (
    <div>
      {
        (Object.entries(fields) as [keyof T, FieldsEntry<any | undefined, T>][])
          .map(([key, [title, cmpCreator]]) => {
            const cmp = cmpCreator({
              value: value[key],
              parent: value,
              onChange: (v) => onChange({ ...value, [key]: v }),
              key: `${key}`
            });
            return title ? <Label label={`${title}:`} key={`${key}`} className={styles.label}> {cmp} </Label> : cmp
          })
      }
    </div>
  )
}

export const getDisplayFieldsDescription = <T extends any>(name: string, fn?: (t: T) => any): FieldsEntry<T, {}> => {
  return [name, (o) => <div key={name} className={styles.textValue}>{fn ? fn(o.value) ?? '-' : `${o.value ?? '-'}`}</div>]
}

export const getDisplayPriceFieldsDescription = <T extends any>(name: string, fn?: (t: T) => number | undefined): FieldsEntry<number | undefined, T> => {
  const getter = (o: FieldEntryProps<number | undefined, T>) => fn ? fn(o.parent) : o.value;
  return [name, (o) => <div key={name} className={styles.textValue}>{`${((getter(o) ?? 0) / 100).toFixed(2)} zł`}</div>]
}

export const getDisplayPercentageFieldsDescription = <T extends any>(name: string, fn?: (t: T) => number | undefined): FieldsEntry<number | undefined, T> => {
  const getter = (o: FieldEntryProps<number | undefined, T>) => fn ? fn(o.parent) : o.value;
  return [name, (o) => <div key={name} className={styles.textValue}>{`${((getter(o) ?? 0) / 100).toFixed(0)} %`}</div>]
}

export const getDisplayImgFieldsDescription = <T extends any>(name: string, fn?: (t: T) => any): FieldsEntry<T, {}> => {
  return [name, (o) => {
    const src = fn ? fn(o.value) : o.value;
    return <div className={styles.textValue}> {src && <img key={name} alt={name} height={30} src={`${src}`} />}</div>
  }]
}

const ArrayFieldCmp = <T extends any>({ value, onChange, fields, onRemove, description, parent }: { value: T; parent: {}; onChange: (t: T) => unknown; fields: FieldsDescription<T>; onRemove: () => unknown; description: (t: T) => string }) => {
  const [open, changeOpen] = useState(false);
  const hasFields = !!Object.keys(fields).length;
  return (

    <li className={styles.listItem}>
      <div className={cn(styles.itemHeader, { [styles.clickable]: hasFields })} onClick={() => hasFields && changeOpen(o => !o)}>
        {hasFields && <span className={styles.chevron}>{open ? '˅' : '˃'}</span>}
        {description(value)}
      </div>
      {open && hasFields && <div className={styles.itemBody}>
        <GenericEditEntry fields={fields} value={value} onChange={onChange} parent={parent} />
        <button className={styles.removeButton} onClick={onRemove}>X</button>
      </div>
      }
    </li>

  )
}

export const getArrayFieldsDescription = <T extends {}>(name: string, fields: FieldsDescription<T>, description: (t: T) => string, defaultGetter?: () => T): FieldsEntry<Array<T> | undefined, {}> => {
  return [
    name,
    (o) => (
      <div className={styles.array} key={o.key}>
        <ul className={styles.list}>
          {
            o.value?.map((i, idx) => (
              <ArrayFieldCmp<T>
                key={`${o.key}_${idx}`}
                value={i}
                parent={o.parent}
                fields={fields}
                onChange={(nv) => o.onChange(o?.value?.map(((l, idx2) => idx2 === idx ? nv : l)))}
                onRemove={() => o.onChange(o?.value?.filter((_, idx2) => idx2 !== idx))}
                description={description}
              />
            ))
          }
        </ul>
        {defaultGetter && <Button size='small' color='primary' onClick={() => { o.onChange([...(o.value ?? []), defaultGetter()]) }}>Dodaj</Button>}
      </div>
    )
  ]
}

export const getGenericFieldsDescription = <T extends {}>(fields: FieldsDescription<T | undefined>): FieldsEntry<T | undefined, {}> => {
  return [
    null,
    (o) => (<GenericEditEntry key={o.key} fields={fields} parent={o.parent} value={o.value} onChange={o.onChange} />)
  ]
}

export const getInputFieldsDescription = (name: string, props?: Omit<InputProps, 'value' | 'onChange' | 'onBlur'>): FieldsEntry<string | undefined, {}> => {
  return [
    name,
    (o) => (<Input key={o.key} value={o.value ?? ''} fill onChange={(e) => o.onChange(e.target.value)} {...props} />)
  ]
}

export const getTextFieldsDescription = (name: string, props?: Omit<TextAreaProps, 'value' | 'onChange' | 'onBlur'>): FieldsEntry<string | undefined, {}> => {
  return [
    name,
    (o) => (<TextArea key={o.key} value={o.value ?? ''} fill onChange={(e) => o.onChange(e.target.value)} {...props} />)
  ]
}

export const getNumberInputFieldsDescription = (name: string, props?: NumberInputProps): FieldsEntry<number | undefined, {}> => {
  return [
    name,
    (o) => (<NumberInput key={o.key} value={o.value ?? ''} onValueChange={(e) => o.onChange(e.floatValue)} fill {...props} />)
  ]
}

const toPrice = (v: number | undefined) => v ? v / 100 : v;
const fromPrice = (v: number | undefined) => v ? Math.round(v * 100) : v;
export const getPriceInputFieldsDescription = (name: string, props?: NumberInputProps): FieldsEntry<number | undefined, {}> => {
  return [
    name,
    (o) => (<NumberInput key={o.key} value={toPrice(o.value) ?? ''} onValueChange={(e) => o.onChange(fromPrice(e.floatValue))} fill suffix=' zł' decimalScale={2} {...props} />)
  ]
}
export const getPercentageInputFieldsDescription = (name: string, props?: NumberInputProps): FieldsEntry<number | undefined, {}> => {

  return [
    name,
    (o) => (<NumberInput key={o.key} value={toPrice(o.value) ?? 0} onValueChange={(e) => o.onChange(fromPrice(e.floatValue))} fill suffix=' %' decimalScale={0} {...props} />)
  ]
}
export const getFlagsFieldsDescription = (name: string, valuesMap: { [k in number]: string }): FieldsEntry<number | undefined, {}> => {
  return [
    name,
    (o) => (
      <>
        {
          Object.entries(valuesMap).map(([k, label]) => (
            <Label gentle position='right' label={label} key={label}>
              <Checkbox checked={!!((o.value ?? 0) & parseInt(k, 10))} onChange={(v) => o.onChange(v ? (o.value ?? 0) | parseInt(k, 10) : (o.value ?? 0) & ~parseInt(k, 10))} />
            </Label>
          ))
        }
      </>
    )
  ]
}

export const getCheckFieldsDescription = <T extends unknown>(name: string, transform: {from: (u: T | undefined) => boolean; to: (b: boolean) => T} = {from: (u: unknown) => !!u, to: (b: boolean) => b as unknown as T}): FieldsEntry<T | undefined, {}> => {
  return [
    '',
    (o) => (
      <Label position='left' label={name} className={styles.check}>
        <Checkbox checked={transform.from(o.value)} onChange={(v) => o.onChange(transform.to(v))} />
      </Label>
    )
  ]
}

export const getChecksListFieldsDescription = <T extends any>(
  name: string,
  options: T[],
  getId: (t: T) => string,
  getLabel: (t: T) => string,
  props?: Omit<SelectProps<OptionType>, 'value' | 'onValueChange' | 'onBlur'>
): FieldsEntry<T[] | undefined, {}> => {
  return [
    name,
    (o) => {
      const optionLables = options.map(o => ({ id: getId(o), label: getLabel(o) }));
      return (
        <>
          {
            optionLables.map(({ id, label }) => (
              <Label gentle position='right' label={label} key={label}>
                <Checkbox
                  checked={o.value?.some(op => getId(op) === id)}
                  onChange={(v) => v ? o.onChange(o.value ? [options.find(op => getId(op) === id)!, ...o.value] : []) : o.onChange(o.value ? o.value.filter(op => getId(op) !== id) : [])}
                />
              </Label>
            ))
          }
        </>
      )
    }
  ]
}
export const getSelectFieldsDescription = <T extends any>(
  name: string,
  options: T[],
  getId: (t: T) => string,
  getLabel: (t: T) => string,
  props?: Omit<SelectProps<OptionType>, 'value' | 'onValueChange' | 'onBlur'>
): FieldsEntry<T | undefined, {}> => {
  return [
    name,
    (o) => {
      const opt = options.map(o => ({ value: getId(o), label: getLabel(o) }));
      return (
        <Select
          key={o.key}
          value={o.value ? { label: getLabel(o.value), value: getId(o.value) } : null}
          options={opt}
          onChange={(e) => e && o.onChange(options.find(o => getId(o) === (e as any).value))}
          fill
          {...props}
        />
      )
    }
  ]
}

export const getMultiSelectFieldsDescription = <T extends any>(
  name: string,
  options: T[],
  getId: (t: T) => string,
  getLabel: (t: T) => string,
  props?: Omit<SelectProps<OptionType>, 'value' | 'onValueChange' | 'onBlur'>
): FieldsEntry<T[] | undefined, {}> => {
  return [
    name,
    (o) => {
      const opt = options.map(o => ({ value: getId(o), label: getLabel(o) }));
      return (
        <Select
          key={o.key}
          value={o.value ? o.value.map(v => ({ label: getLabel(v), value: getId(v) })) : null}
          options={opt}
          isMulti
          onChange={
            (e) => {
              !e
                ? o.onChange([])
                : o.onChange(
                  options.filter(
                    o => 'value' in e
                      ? getId(o) === e.value
                      : e.some(x => x.value === getId(o))
                  )
                )
            }
          }
          fill
          {...props}
        />
      )
    }
  ]
}

export const getDateFieldsDescription = (name: string): FieldsEntry<Date | undefined, {}> => {
  return [
    name,
    (o) => {
      return (<DatePicker key={o.key} value={o.value?.toLocaleDateString() ?? ''} selected={o.value ?? null} onChange={(d) => o.onChange((d && updateDateByTimezone(d)) || undefined)} fill />)
    }
  ]
}

export const getSwitchFieldsDescription = <T extends {}>(name: string, e: (t: T) => FieldsEntry<any, T>): FieldsEntry<any | undefined, T> => {
  return [
    name,
    (o) => {
      const [label, cmpCreator] = e(o.parent);
      return <Label key={`${label}`} className={styles.label} label={label ?? ''}>{cmpCreator(o)}</Label>
    }
  ]
}

export const Editor = ({ value, changeValue }: { value: string; changeValue: (value: string) => void }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Row>
        <TextArea fill rows={9} disabled value={value}>
        </TextArea>
      </Row>
      <Button onClick={() => setOpen(true)}>Edytuj</Button>
      <Modal open={open} onClose={() => { setOpen(false) }} size={'large'} className={styles.codeModal}>
        <div>
          <Button onClick={() => { setOpen(false) }}>Uaktualnij</Button>
        </div>
        <MonacoEditor
          width="100%"
          height="600px"
          language="html"
          theme="vs-dark"
          value={value ?? ''}
          onChange={(v) => changeValue(v ?? '')}
        />
        <IFrame width='100%' height='500px' title='preview'>
          <div dangerouslySetInnerHTML={{ __html: value?.replace('{{address}}', `${window.location.protocol}//${window.location.host}`).replace('%recipient.name%', '[Imię użytkownika]') ?? '' }} />
        </IFrame>
      </Modal>
    </>
  )
}

export const getCodeFieldsDescription = (name: string): FieldsEntry<string | undefined, {}> => {
  return [
    name,
    (o) => (
      <Editor
        value={o.value ?? ''}
        changeValue={o.onChange}
      />)
  ]
}

export const JSONArray = ({value, changeValue}: {value: string; changeValue: (value:  string) => void}) => {
  const [values, setValues] = useState<string[]>([]);
  useEffect(()=>{
    try {
      setValues(JSON.parse(value))
    } catch {
      setValues([value])
    }
  }, [value])

  return (
    <ul className={styles.jsonArray}>
      {
        values.map((v, i)=>
          <div key={i} className={styles.jsonArrayEntry}>
            <Input
              fill
              value={v}
              onChange={
                (e) => {
                  const newValue = e.target.value;
                  setValues(v => [...v].map((vv, ii) => ii === i ? newValue: vv))
                }
              }
              onBlur={() => changeValue(JSON.stringify(values, null, 2))}
            />
            <Button
              color='danger'
              onClick={() => {
                  changeValue(JSON.stringify([...values.slice(0, i), ...values.slice(i+1)], null, 2))
                }
              }
            >X</Button>
          </div>
        )
      }
      <Button color='primary' onClick={() => {setValues(v => ([...v, '']))}}>+ Dodaj kolejny</Button>
    </ul>
  )
}

export const getJSONArray = (name: string): FieldsEntry<string | undefined, {}> => {
  return [
    name,
    (o) => (
      <JSONArray value={o.value ?? '[]'} changeValue={o.onChange} />
    )
  ]
}

export const ObjectArray = ({ value, changeValue}: {value: string; changeValue: (value:  string) => void}) => {
  const [values, setValues] = useState<{ name: string; value: number }[]>([]);
  useEffect(()=>{
    try {
      setValues(JSON.parse(value))
    } catch {
      setValues([])
    }
  }, [value])

  return (
    <ul className={styles.jsonArray}>
      {
        values.map((v, i)=>
          <div key={i} className={styles.jsonArrayEntry}>
            <Input
              fill
              value={v.name}
              onChange={
                (e) => {
                  const newName = e.target.value;
                  setValues(prevValue => prevValue.map((vv, ii) => ii === i ? { name: newName, value: vv.value  } : vv ))
                }
              }
              onBlur={() => changeValue(JSON.stringify(values, null, 2))}
            />
            <NumberInput 
              key={i} 
              value={toPrice(v.value)} 
              onChange={
                (e) => {
                  const newValue = Number(e.target.value.slice(0, -3));
                  setValues(prevValue => prevValue.map((vv, ii) => ii === i ? { value: fromPrice(newValue) || 0, name: vv.name } : vv ))
                }
              }
              onBlur={() => changeValue(JSON.stringify(values, null, 2))}
              fill 
              suffix=' zł' 
              decimalScale={2}
            />
            <Button
              color='danger'
              onClick={() => {
                  setValues(values.filter((p) => p.name !== v.name))
                }
              }
            >X</Button>
          </div>
        )
      }
      <Button color='primary' onClick={() => {setValues((p) => ([...p, { name: '', value: 0 }]))}}>+ Dodaj kolejny</Button>
    </ul>
  )
}
export const getObjectArray = (name: string): FieldsEntry<string | undefined, {}> => {
  return [
    name,
    (o) => (
      <ObjectArray value={o.value ?? '[]'} changeValue={o.onChange} />
    )
  ]
}

export const getNopFieldsDescription = (): FieldsEntry<unknown, unknown> => [null, () => <div />]

export const fields = {
  display: getDisplayFieldsDescription,
  displayPrice: getDisplayPriceFieldsDescription,
  img: getDisplayImgFieldsDescription,
  array: getArrayFieldsDescription,
  nested: getGenericFieldsDescription,
  input: getInputFieldsDescription,
  text: getTextFieldsDescription,
  numberInput: getNumberInputFieldsDescription,
  flags: getFlagsFieldsDescription,
  check: getCheckFieldsDescription,
  checksList: getChecksListFieldsDescription,
  price: getPriceInputFieldsDescription,
  percentage: getPercentageInputFieldsDescription,
  displayPercentage: getDisplayPercentageFieldsDescription,
  select: getSelectFieldsDescription,
  multiSelect: getMultiSelectFieldsDescription,
  date: getDateFieldsDescription,
  switch: getSwitchFieldsDescription,
  code: getCodeFieldsDescription,
  jsonArray: getJSONArray,
  jsonPackageArray: getObjectArray,
  nop: getNopFieldsDescription
}
