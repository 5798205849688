import React, { useEffect, useCallback, useMemo, useState, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { basketActions } from 'src/logic/basket';
import { push } from 'connected-react-router';
import { routes } from 'src/routes';
import { addressesActions } from 'src/logic/addresses';
import { PostalCode, orderActions } from '../../logic/order';
import { emptyHomeAddress, emptyOfficeAddress, emptyInvoiceAddress, validateAddress } from '../addressForm/addressForm';
import { Formik, FormikErrors } from 'formik';
import { pickBy, isEmpty, minBy } from 'lodash';
import { FormsValues, BuyForm } from './buyForm';
import { User } from 'src/api';
import { userActions } from 'src/logic/user';

const initialValues: FormsValues = {
  addressType: 'home',
  home: emptyHomeAddress,
  office: emptyOfficeAddress,
  invoice: emptyInvoiceAddress,
  location: null,
  vatInvoice: false,
  sameAddressForInvoice: true,
  createAccount: true,
  termsAgreement: false,
  marketingAgreement: false,
  name: '',
  lastName: '',
  company: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: ''
}

const validateFormValues = (userDetails: User | null, postalCodes: PostalCode[], dispatch: Dispatch<any> ) => (value: FormsValues): FormikErrors<FormsValues> => {
  const validationObject: FormikErrors<FormsValues> = {
    email: value.email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? undefined : 'Nieprawidłowy email',
    invoice: value.vatInvoice || (!value.vatInvoice && !value.sameAddressForInvoice) ? validateAddress()(value.invoice) : undefined,
    office: value.addressType === 'office' && !value.location ? validateAddress(dispatch, postalCodes)(value.office) : undefined,
    home: value.addressType === 'home' ? validateAddress(dispatch, postalCodes)(value.home) : undefined,
    name: (value.name?.length ?? 0) > 0 ? undefined : 'Imię jest wymagane',
    lastName: (value.lastName?.length ?? 0) > 0 ? undefined : 'Nazwisko jest wymagane',
    phone: value.phone?.match(/^(\+48)?\d{7,9}$/) ? undefined : 'Numer telefonu jest wymagany',
    password: userDetails || !value.createAccount || (value.password?.length ?? 0) > 5 ? undefined : 'Hasło musi mieć co najmniej 6 znaków',
    confirmPassword: userDetails || !value.createAccount || (value.password === value.confirmPassword) ? undefined : 'Hasła muszą być takie same',
    termsAgreement: value.termsAgreement ? undefined : 'Zapoznaj się z regulaminem, oraz zaakceptuj warunki usługi'
  };
  console.log(value, validationObject, pickBy(validationObject, (v) => !(v == null || isEmpty(v))))
  return pickBy(validationObject, (v) => !(v == null || isEmpty(v)))
}

const Buy = () => {
  useEffect(() => window.scroll(0, 0), []);

  const dispatch = useDispatch();
  const postalCodes = useSelector((gs: GlobalState) => gs.order.postalCodes);

  useEffect(() => {
    dispatch(addressesActions.load.request());
    dispatch(userActions.load.request());
  }, [dispatch]);

  const userDetails = useSelector((gs: GlobalState) => gs.user.details);
  const settings = useSelector((gs: GlobalState) => gs.settings)
  const vouchers = useSelector((gs: GlobalState) => gs.basket.vouchers)
  const basket = useSelector((gs: GlobalState) => gs.basket.basket);
  const disabled = useSelector((gs: GlobalState) => gs.order.pending);
  const [basketCache, setBasketCache] = useState(basket);

  useEffect(()=> {
    if(Object.keys(basket).length){
      setBasketCache(basket);
    }
  }, [basket])

  const locationFromStore = useSelector((gs: GlobalState) => gs.addresses.location);
  const locationFromVouchers = vouchers.find(v => v.location)?.location ?? null;
  const addresses = useSelector((gs: GlobalState) => gs.addresses.addresses);
  const homeAddress = addresses.find(a => a.type === 'home');
  const officeAddress = addresses.find(a => a.type === 'office');
  const invoiceAddress = addresses.find(a => a.type === 'invoice');
  const minDate = useMemo(() => minBy(Object.keys(basketCache).map(d => new Date(d)), (d) => d.getTime()), [basketCache]);
  const goToEdit = useCallback(() => dispatch(push(routes.menuBasket(minDate ?? ''))), [dispatch, minDate]);
  const validator = useCallback((values: FormsValues) => validateFormValues(userDetails, postalCodes, dispatch)(values), [userDetails, postalCodes]);
  const addCode = useCallback((code: string) => dispatch(basketActions.addVoucher(code)), [dispatch]);

  const handleCreateOrder = useCallback((values: FormsValues) => {
    const address = values.addressType === 'office' ? values.office : values.home;
    const location = values.addressType === 'office' ? values.location : undefined;
    const delivery = location ? { location } : { address: { ...address, type: values.addressType } };
    dispatch(orderActions.create.request({
      ...delivery,
      invoice: (values.vatInvoice || !values.sameAddressForInvoice) ? {
        ...values.invoice,
        vatin: values.vatInvoice ? values.invoice.vatin : undefined,
        name: values.vatInvoice ? values.invoice.name : undefined,
      } : address,
      userName: values.name ?? '',
      userLastName: values.lastName ?? '',
      email: values.email ?? '',
      phone: values.phone ?? '',
      companyName: values.company ?? '',
      password: values.password ?? '',
      marketingAgreement: !!values.marketingAgreement,
    }));
  }, [dispatch])
  return (
    <Formik<FormsValues> initialValues={initialValues} onSubmit={handleCreateOrder} validate={validator}>
      <BuyForm
        addCode={addCode}
        basket={basketCache}
        vouchers={vouchers}
        settings={settings}
        goToEdit={goToEdit}
        userDetails={userDetails}
        homeAddress={homeAddress}
        officeAddress={officeAddress}
        invoiceAddress={invoiceAddress}
        location={locationFromVouchers ?? locationFromStore}
        disabled={disabled}
      />
    </Formik>
  );
};

export default Buy;
