import { Configuration } from 'src/api'

const TOKEN_ID = 'utoken'
export const setToken = (token: string) => localStorage.setItem(TOKEN_ID, token);
export const getToken = () => localStorage.getItem(TOKEN_ID);

const getConfig = () => new Configuration({
  basePath: window.location.origin,
  accessToken: getToken() ?? undefined
});

export const getApi = <T>(c: { new(config: Configuration): T }): T => new c(getConfig());

type Primitive = null | void | undefined | string | number | boolean;

export type Relations<T> = {
  [K in keyof T]?:
    T[K] extends Primitive | Date
      ? never
      : T[K] extends (Array<infer R> | undefined)
        ? boolean | Relations<R>
        : boolean | Relations<T[K]>
};

export function getRelations<T>(r: Relations<T>): string {
  return JSON.stringify(r)
}

type Operator = 'and' | 'or' | 'any' | 'between' | 'col' | 'eq' | 'ne' | 'not' | 'gt' | 'lt' | 'gte' | 'lte' | 'like' | 'iLike';

export type Filter<T> = {
  [K in keyof T]?: {
    [o in Operator]?: string | number | string[] | number[] | Filter<T>[] | Date
  } | Filter<T[K]>
}

export function getFilter<T>(f?: Filter<T>): string | undefined {
  return f ? JSON.stringify(f) : undefined;
}
