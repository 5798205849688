import React, { useState, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Diff, Omit } from 'utility-types';

import { ColumnDefinition, ControlledGenericTable, ControlledTableProps, emptyTableState, MultiFilter, MultiFilterType, NoFilterType, SelectFilter, SelectFilterType, TableState, TextFilter, TextFilterType, NoFilter, DateRangeFilterType, DateRangeFilter } from '../controlledGenericTable';
import { SortingDirection } from '../sortingArrows';
import { getData, sliceData } from './dataLogic';
import { DateRange } from '../datePicker';
import { fromPairs } from 'lodash';

type WithPredicate<T, D> = { predicate: (row: T, filterData: D) => boolean };

export type TextFilterTypeWithPredicate<T> = TextFilterType & WithPredicate<T, string | null>;
export const TextFilterWithPredicate = <T extends any>(predicate: (row: T, filterData: string | null) => boolean): TextFilterTypeWithPredicate<T> => ({ ...TextFilter, predicate });

type SelectFilterValue = { value: string; label: string };

export type SelectFilterTypeWithPredicate<T> = SelectFilterType & WithPredicate<T, SelectFilterValue | null>;
export const SelectFilterWithPredicate = <T extends any>(values: string[], predicate: (row: T, filterData: SelectFilterValue | null) => boolean, defaultValue?: string): SelectFilterTypeWithPredicate<T> => ({ ...SelectFilter(values, defaultValue ? [defaultValue]: []), predicate });

export type MultiFilterTypeWithPredicate<T> = MultiFilterType & WithPredicate<T, SelectFilterValue[]>;
export const MultiFilterWithPredicate = <T extends any>(values: string[], predicate: (row: T, filterData: SelectFilterValue[]) => boolean): MultiFilterTypeWithPredicate<T> => ({ ...MultiFilter(values), predicate });

export type DateRangeFilterTypeWithPredicate<T> = DateRangeFilterType & WithPredicate<T, DateRange>;
export const DateRangeFilterWithPredicate = <T extends any>(predicate: (row: T, filterData: DateRange) => boolean, defaultValue?: DateRange): DateRangeFilterTypeWithPredicate<T> => ({ ...DateRangeFilter, predicate, defaultValue });

export type FilterTypeWithPredicate<T> = NoFilterType | TextFilterTypeWithPredicate<T> | SelectFilterTypeWithPredicate<T> | MultiFilterTypeWithPredicate<T> | DateRangeFilterTypeWithPredicate<T>;

export type FilterableColumnDefinition<T> = Omit<Omit<ColumnDefinition<T>, 'filterable'>, 'sortable'> & {
  filterable: FilterTypeWithPredicate<T>;
} & (
    {
      sortable: true;
      compare: (a: T, b: T, dir: SortingDirection) => -1 | 0 | 1;
    } | {
      sortable: false;
    }
  );

type SepcificKeys<T, T2> = { [k in keyof T]: T[k] extends (T2 | undefined) ? k : never }[keyof T];
type NumericKeys<T> = SepcificKeys<T, number>;
type ArrayKeys<T> = SepcificKeys<T, any[]>;

const getDirection = (dir: SortingDirection, v: -1 | 1): -1 | 1 => (dir === 'asc' ? v : (v === 1 ? -1 : 1));
export const genericComparator = <T, R>(getter: (x: T) => R): ((a: T, b: T, direction: SortingDirection) => -1 | 0 | 1) => {
  return (a: T, b: T, dir: SortingDirection) => getDirection(dir, getter(a) >= getter(b) ? 1 : -1);
};


export const getDefaultColumnDef = <T extends any>(label: string, column: keyof T | ((t: T) => any), cfg?: Partial<FilterableColumnDefinition<T>>): FilterableColumnDefinition<T> => {
  const getter = typeof column === 'function' ? column : ((t: T) => t[column]);
  return {
    label,
    labelComponent: cfg?.labelComponent,
    render: getter,
    text: (t) => `${getter(t)}`,
    exportValue: getter,
    sortable: true,
    compare: genericComparator(getter),
    key: `${label}`,
    minimal: true,
    centered: true,
    filterable: TextFilterWithPredicate((row, fd) => `${getter(row)?? ''}`.toLowerCase().includes(fd?.toLowerCase?.() || '')),
    ...cfg
  }
}

export const getPreColumnDef = <T extends any>(label: string, column: keyof T | ((t: T) => any), cfg?: Partial<FilterableColumnDefinition<T>>): FilterableColumnDefinition<T> => {
  const getter = typeof column === 'function' ? column : ((t: T) => t[column]);
  return {
    label,
    labelComponent: cfg?.labelComponent,
    render: (t: T) => <pre>{getter(t)}</pre>,
    text: (t) => `${getter(t)}`,
    exportValue: getter,
    sortable: true,
    compare: genericComparator(getter),
    key: `${label}`,
    minimal: true,
    centered: true,
    filterable: TextFilterWithPredicate((row, fd) => `${getter(row)?? ''}`.toLowerCase().includes(fd?.toLowerCase?.() || '')),
    ...cfg
  }
}

export const getListColumnDef = <T extends any>(label: string, column: ArrayKeys<T> | ((t: T) => any[] | undefined)): FilterableColumnDefinition<T> => {
  const getter = typeof column === 'function' ? column : ((t: T) => t[column] as any as any[] | undefined);
  return {
    label,
    labelComponent: null,
    render: (t: T) => <ul>{getter(t)?.map((i, idx) => <li key={idx}>{i}</li>)}</ul>,
    text: (t) => `${getter(t)?.join('\n') ?? ''}`,
    exportValue: (t) => `${getter(t)?.join('\n') ?? ''}`,
    sortable: true,
    compare: genericComparator(getter),
    key: `${label}`,
    minimal: true,
    centered: true,
    filterable: TextFilterWithPredicate((row, fd) => (`${getter(row)?.join('')?.toLowerCase?.() ?? ''}`).includes(fd?.toLowerCase?.() || ''))
  }
}

type PriceColumnOptions = { sufix: string; fractionDigits: number }
export const getPriceColumnDef = <T extends {}>(label: string, column: NumericKeys<T> | ((t: T) => number | undefined), options: (((t: T) => PriceColumnOptions) | undefined) = undefined): FilterableColumnDefinition<T> => {
  // column is constrained correctly, but TS is unable to figure it out here so we need to cast to any first.
  const getter = typeof column === 'function' ? column : ((t: T) => t[column] as any as number | undefined);
  const renderRow = (x: T) => {
    const {sufix, fractionDigits} = options ? options(x) : {sufix: 'zł', fractionDigits: 2 };
    return `${((getter(x) ?? 0) / 100).toFixed(fractionDigits)} ${sufix}`;
  };
  return {
    label,
    labelComponent: null,
    render: (x) => renderRow(x),
    text: (t) => renderRow(t),
    exportValue: (x) => (getter(x) ?? 0)/100,
    sortable: true,
    compare: genericComparator(getter),
    key: `${label}`,
    minimal: true,
    centered: true,
    filterable: TextFilterWithPredicate((row, fd) => (renderRow(row).toLowerCase()).includes(fd?.toLowerCase?.() || '')),
  }
}

export const getImgColumnDef = <T extends any>(label: string, column: keyof T | ((t: T) => any)): FilterableColumnDefinition<T> => {
  const getter = typeof column === 'function' ? column : ((t: T) => t[column]);
  return {
    label,
    labelComponent: null,
    render: (t: T) => getter(t) && <img src={getter(t)} alt={label} height={30} />,
    text: undefined,
    exportValue: undefined,
    sortable: false,
    key: `${label}`,
    minimal: true,
    centered: true,
    filterable: NoFilter
  }
}
export const columnsDefinitions = {
  default: getDefaultColumnDef,
  pre: getPreColumnDef,
  price: getPriceColumnDef,
  img: getImgColumnDef,
  list: getListColumnDef,
}

type InMemoryGenericTableProps<T> = Diff<ControlledTableProps<T>, {
  pages: number;
  tableState: TableState;
  onStateUpdate: (d: TableState) => void;
  columns: ColumnDefinition<T>;
}> & {
  itemsPerPage: number;
  columns: Array<FilterableColumnDefinition<T>>;
  storeFilterInLocation?: boolean;
  noDataPlaceholder: ReactNode;
  onFilter?: (dt: T[], slicedData: T[]) => unknown;
  onStateUpdate?: (d: TableState) => void;
};

export const getDefaultTableState = <T extends any>(columns: FilterableColumnDefinition<T>[]): TableState => {
  const filters = columns.map(c => [c.key, c.filterable.defaultValue]).filter(([, value]) => value && (!Array.isArray(value) || value.length));
  return {...emptyTableState, filters: fromPairs(filters)};
}

export function InMemoryGenericTable<T extends any>(props: InMemoryGenericTableProps<T>) {
  const { data, columns, itemsPerPage, noDataPlaceholder, rowKey, onFilter, onStateUpdate } = props;
  const [tableState, setTableState] = useState<TableState>(getDefaultTableState(columns));
  const [filteredData, setFilteredData] = useState<T[]>(getData(data, tableState, columns))

  const handleStateUpdate = useCallback((tableState: TableState) => {
    setTableState(tableState);
    onStateUpdate?.(tableState);
  }, [setTableState, onStateUpdate]);

  useEffect(() => { setFilteredData(getData(data, tableState, columns)) }, [data, tableState, columns]);
  const sliced = useMemo(() => sliceData(filteredData, tableState.page, props.itemsPerPage), [filteredData, tableState.page, props.itemsPerPage]);

  useEffect(() => { onFilter?.(filteredData, sliced) }, [onFilter, filteredData, sliced]);

  return <ControlledGenericTable
    {...props}
    data={sliced}
    rowKey={rowKey}
    pages={Math.ceil(filteredData.length / itemsPerPage)}
    onStateUpdate={handleStateUpdate}
    tableState={tableState}
    noDataPlaceholder={noDataPlaceholder}
  />;
}
