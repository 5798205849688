import React, { useEffect } from 'react';
import styles from './account.module.scss';
import PageWrapper from '../../containers/pageWrapper/pageWrapper';
import MyOrders from './myOrders';
import MyData from './myData';
import MyAddresses from './myAddresses';
import MyBalance from './myBalance';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'src/logic/user';
import { GlobalState } from 'src/logic/reducers';
import { displayPrice } from 'src/logic/utils';
import { routes } from 'src/routes';

import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import ContentHeader from '../content/contentHeader/contentHeader';
import ContentFooter from '../content/contentFooter/contentFooter';
import Modal from '../../containers/modal/modal';
import { Login } from '../loginForm';
import LandingPage from '../landing/landingPage';
import { push } from 'connected-react-router';

const Account = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.load.request())
  }, [dispatch]);
  const user = useSelector((gs: GlobalState) => gs.user.details);

  const userDetails = useSelector((gs: GlobalState) => gs.user.details);
  const userPending = useSelector((gs: GlobalState) => gs.user.pending);

  const menu = [
    { label: 'Moje dostawy', path: routes.accountOrders(), requireLogin: true },
    { label: 'Dane konta', path: routes.accountProfile(), requireLogin: true },
    { label: 'Adres dostaw', path: routes.accountAddresses(), requireLogin: true },
    { label: <>Saldo konta {userDetails?.wallet?<span className={styles.balanceValue}>{displayPrice(userDetails?.wallet ?? 0)}</span>:''}</>, path: routes.accountBalance(), requireLogin: true }
  ];

  const location = useLocation();

  const current = menu.find( m => m.path === location.pathname)?.label;

  if (!user && !userPending) {
    return (
      <>
        <LandingPage />
        <Modal close={()=>{dispatch(push(routes.index()))}}>
          <Login />
        </Modal>
      </>
    )
  }
  return (
    <>
    <ContentHeader menu={menu} logout />
    <PageWrapper>
      <h1><span className={styles.caption}>Moje konto</span> {current} </h1>


      <section className={styles.accountContent}>
        <Switch>
          <Route path={routes.accountOrders()} component={MyOrders} />
          <Route path={routes.accountProfile()} component={MyData} />
          <Route path={routes.accountAddresses()} component={MyAddresses} />
          <Route path={routes.accountBalance()} component={MyBalance} />
          <Redirect to={routes.accountOrders()} />
        </Switch>
      </section>
    </PageWrapper>
    <ContentFooter />
    </>
  );
};
export default Account;
