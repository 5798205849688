/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    IsAvailableResponse,
    IsAvailableResponseFromJSON,
    IsAvailableResponseToJSON,
} from '../models';

export interface ApiDeliveryAvailablePostalCodeGetRequest {
    postalCode: string;
}

/**
 * no description
 */
export class DeliveryApi extends runtime.BaseAPI {

    /**
     * Get delivery file
     */
    async apiDeliveryAvailablePostalCodeGetRaw(requestParameters: ApiDeliveryAvailablePostalCodeGetRequest): Promise<runtime.ApiResponse<IsAvailableResponse>> {
        if (requestParameters.postalCode === null || requestParameters.postalCode === undefined) {
            throw new runtime.RequiredError('postalCode','Required parameter requestParameters.postalCode was null or undefined when calling apiDeliveryAvailablePostalCodeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/deliveryAvailable/{postalCode}`.replace(`{${"postalCode"}}`, encodeURIComponent(String(requestParameters.postalCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IsAvailableResponseFromJSON(jsonValue));
    }

    /**
     * Get delivery file
     */
    async apiDeliveryAvailablePostalCodeGet(postalCode: string): Promise<IsAvailableResponse> {
        const response = await this.apiDeliveryAvailablePostalCodeGetRaw({ postalCode: postalCode });
        return await response.value();
    }

}
