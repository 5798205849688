import React, { useEffect } from 'react';
import styles from './contentFooter.module.scss';
import { routes } from 'src/routes';
import googleIcon from './google_play_ico.svg';
import appstoreIcon from './appstore_ico.svg';
import instaIcon from './insta_ico.svg';
import fbIcon from './fb_ico.svg';
import pfr from './pfr_poziom.png';
import pfrSr from './pfr_sr.png';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { userActions } from 'src/logic/user';

type ContentFooterProps = {
  menu?: {
    label: string;
    path: string;
  }[];
};

const footerMenu = [
  { label: 'O nas', path: routes.aboutUs() },
  { label: 'Dlaczego my?', path: routes.why() },
  { label: 'Zasięg', path: routes.deliveryRange() },
  { label: 'Dostawa do biur', path: routes.deliveryToOffices() },
  { label: 'Kontakt', path: routes.contact() },
  { label: 'Regulamin', path: routes.terms() },
  { label: 'Polityka prywatności', path: routes.privacy() },
];

const ContentFooter = ({ menu }: ContentFooterProps) => {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(userActions.loadDeviceType()) }, [dispatch]);
  const deviceType = useSelector((gs: GlobalState) => gs.user.deviceType);
  return (
    <footer className={styles.contentFooter}>
      <div className={styles.wrapper}>
        <nav className={styles.footerNav}>
          {menu ??
            footerMenu.map((menuItem, i) => (
              <a key={i} href={menuItem.path} className={styles.link}>
                <span>{menuItem.label}</span>
              </a>
            ))}
        </nav>
        <div className={styles.media}>
          {(!deviceType || (deviceType !== 'ios')) && <a href={'https://play.google.com/store/apps/details?id=pl.waw.pobudka.twa'} rel='noopener noreferrer' target='_blank'>
            <img src={googleIcon} alt={'Pobierz aplikacje z Google Play'} />
          </a>}
          {(!deviceType || (deviceType === 'ios')) && <a href={'https://apps.apple.com/pl/app/pobudka/id1539031354'} rel='noopener noreferrer' target='_blank'>
            <img src={appstoreIcon} alt={'Pobierz aplikacje z Appstore'} height={42} />
          </a>}
          <a href={'https://www.instagram.com/pobudka_catering/'} rel='noopener noreferrer' target='_blank'>
            <img src={instaIcon} alt={'Śledź nas na Instagramie'} />
          </a>
          <a href={'https://www.facebook.com/Pobudka.Catering/'} rel='noopener noreferrer' target='_blank'>
            <img src={fbIcon} alt={'Polub nas na Facebooku'} />
          </a>
        </div>
        <div className={styles.media} >
          <img src={pfrSr} alt="PFR" height={300} className={styles.mobile} />
          <img src={pfr} alt="PFR" height={100} className={styles.desktop} />
        </div>
        <p>Pobudka &copy; 2020</p>
      </div>
    </footer>
  )
};
export default ContentFooter;
