import cn from 'classnames';
import React from 'react';
import styles from './sortingArrows.module.scss';

export type SortingDirection = 'asc' | 'desc' | null;

type Props = {
  onChangeSort: (direction: SortingDirection) => void;
  direction: SortingDirection;
  allowDeselect?: boolean;
};

export function SortingArrows(props: Props) {
  const {direction, allowDeselect, onChangeSort} = props;
  const handleAsc = () => {
    if (onChangeSort) {
      if (direction === 'asc' && !allowDeselect) {
        return;
      }
      onChangeSort(direction === 'asc' && allowDeselect ? null : 'asc');
    }
  }

  const handleDesc = () => {
    if (onChangeSort) {
      if (direction === 'desc' && !allowDeselect) {
        return;
      }
      onChangeSort(direction === 'desc' && allowDeselect ? null : 'desc');
    }
  }
  return (
    <div className={styles.sortingArrows}>
      <button className={cn({ [styles.active]: direction === 'asc' })} onClick={handleAsc}>&#9652;</button>
      <button className={cn({ [styles.active]: direction === 'desc' })} onClick={handleDesc}>&#9662;</button>
    </div>
  );
}
