import cn from 'classnames';
import React, { ReactNode } from 'react';
import { Overlay } from '../overlay';
import styles from './modal.module.scss';

type Props = {
  children?: ReactNode;
  open: boolean;
  size: 'small' | 'medium' | 'big' | 'large';
  onClose?: () => void;
  className?: string;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
};

export function Modal({ children, open, onClose, size = 'medium', className, onScroll }: Props) {
  const modalClasses = cn(
    styles.modal,
    {
      [styles.small]: size === 'small',
      [styles.medium]: size === 'medium',
      [styles.big]: size === 'big',
      [styles.large]: size === 'large',
    },
    className,
  );
  return (
    <Overlay page active={open} onHide={onClose} zIndex={100}>
      <div className={modalClasses} onScroll={onScroll}>
        {children}
      </div>
    </Overlay>
  );
}
