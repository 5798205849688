/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Menu,
    MenuFromJSON,
    MenuFromJSONTyped,
    MenuToJSON,
    OrderMenuItem,
    OrderMenuItemFromJSON,
    OrderMenuItemFromJSONTyped,
    OrderMenuItemToJSON,
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface MenuItem
 */
export interface MenuItem {
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    menuId?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    quantityTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItem
     */
    quantityStock?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MenuItem
     */
    isPromoted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MenuItem
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MenuItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Menu}
     * @memberof MenuItem
     */
    menu?: Menu;
    /**
     * 
     * @type {Product}
     * @memberof MenuItem
     */
    product?: Product;
    /**
     * 
     * @type {Array<OrderMenuItem>}
     * @memberof MenuItem
     */
    orders?: Array<OrderMenuItem>;
}

export function MenuItemFromJSON(json: any): MenuItem {
    return MenuItemFromJSONTyped(json, false);
}

export function MenuItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'menuId': !exists(json, 'menuId') ? undefined : json['menuId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quantityTotal': !exists(json, 'quantityTotal') ? undefined : json['quantityTotal'],
        'quantityStock': !exists(json, 'quantityStock') ? undefined : json['quantityStock'],
        'isPromoted': !exists(json, 'isPromoted') ? undefined : json['isPromoted'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'menu': !exists(json, 'menu') ? undefined : MenuFromJSON(json['menu']),
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
        'orders': !exists(json, 'orders') ? undefined : ((json['orders'] as Array<any>).map(OrderMenuItemFromJSON)),
    };
}

export function MenuItemToJSON(value?: MenuItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'menuId': value.menuId,
        'productId': value.productId,
        'price': value.price,
        'quantityTotal': value.quantityTotal,
        'quantityStock': value.quantityStock,
        'isPromoted': value.isPromoted,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'menu': MenuToJSON(value.menu),
        'product': ProductToJSON(value.product),
        'orders': value.orders === undefined ? undefined : ((value.orders as Array<any>).map(OrderMenuItemToJSON)),
    };
}


