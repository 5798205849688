import React from 'react';
import styles from './banner.module.scss';
import { BtnLink } from 'src/components/forms/Forms';
import { routes } from 'src/routes';

const Banner = () => (
  <div className={styles.banner}>
    <div className={styles.bannerConent}>
      <h1>Wasze bezpieczeństwo jest naszym priorytetem</h1>

      <p>
        Poznaj zasady, jakie obowiązują u nas, aby w pełni bezpiecznie
        dostarczać Wam posiłki.
      </p>
    </div>
    <div className={styles.btnWrapper}>
        <BtnLink link={routes.security()} type="orange">
          Dowiedz się więcej
        </BtnLink>
      </div>

  </div>
);
export default Banner;
