import React, { useCallback, useState } from 'react';
import {
  FilterableColumnDefinition,
  columnsDefinitions as cd,
  genericComparator,
  DateRangeFilterWithPredicate,
  MultiFilterWithPredicate,
} from 'src/components/admin/InMemoryGenericTable';
import { Order, OrderMenuItem, OrderMenu, Payment } from 'src/api';
import { GlobalState } from 'src/logic/reducers';
import { adminActions, invoices } from 'src/logic/admin';
import { GenericList } from '../../components/admin/genericList/genericList';
import { useDispatch } from 'react-redux';
import { FieldsDescription, fields as fc, GenericDetailsComponent } from 'src/components/admin/genericEdit';
import { EntityInfo } from 'src/components/admin/adminContainer';
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { groupBy, sumBy, compact, min } from 'lodash';
import styles from './orders.module.scss';
import { displayPrice } from 'src/logic/utils';
import { Filter } from '../../logic/utils/apiConfig';
import { TableState } from 'src/components/admin/controlledGenericTable';
import { DateRange } from 'src/components/admin/datePicker';
import { Button } from 'src/components/admin/button';
import { toast } from 'react-toastify';

type OrderStatus = 'Anulowane' | 'Opłacone' | 'Nieopłacone';
const statuses = ['Anulowane', 'Opłacone', 'Nieopłacone'];

const OrderDate  = 'Data zamówienia';

const isCanceled = (order: Order): boolean => {
  return order?.status === 'canceled' || !!order?.deletedAt;
};
export const getOrderStatus = (order: Order): OrderStatus => {
  return isCanceled(order) ? 'Anulowane' : order?.payment?.paid ? 'Opłacone' : 'Nieopłacone';
};

function OrderList({ editUrl }: { editUrl: (id: number | 'new' | '') => string }) {
  const dispatch = useDispatch();
  const [invoiceGeneration, setInvoiceGeneration] = useState(false);
  const getFilter = useCallback((t: TableState): Filter<Order> => {
    const filter = t.filters[OrderDate] as DateRange | undefined;
    return {
      createdAt: {
        gte: filter?.minDate ?? subDays(new Date(), 2)
      }
    };
  }, []);

  const columns: FilterableColumnDefinition<Order>[] = [
    cd.default('Id zamówienia', 'id'),
    cd.default('Status', (r) => getOrderStatus(r), {
      filterable: MultiFilterWithPredicate(statuses, (row, fd) =>
        fd.length ? fd.map((f) => f.label).includes(getOrderStatus(row)) : true
      ),
    }),
    cd.list('Produkty', (r) => {
      const prods = r?.menus?.flatMap((i) =>
        i?.items?.map((i) => [i.menuItem?.product?.name ?? '', i.quantity ?? 0] as [string, number])
      ) ?? [];
      const cutlery = r?.menus?.map(i => ['Sztućce', i.cutlery] as [string, number]) ?? [];
      const packages = r?.menus?.flatMap((i) =>
        i?.items?.map((i) => (i._package ? [JSON.parse(i._package)?.name, i.quantity ?? 0]  : ['', 0] )as [string, number])
      ) ?? [];
      const dt = Object.entries(groupBy(prods.concat(cutlery).concat(packages), ([name]) => name))
        .map(([name, t]) => [name, compact(t)] as const)
        .map(([name, t]) => [name, sumBy(t, ([_, q]: [string, number]): number => q ?? 0)]);
      return dt?.filter(([_, qt]) => !!qt)?.map(([name, qt]) => `${qt} x ${name}`);
    }),
    cd.default(OrderDate, (r) => r?.createdAt?.toLocaleString(), {
      sortable: true,
      compare: genericComparator((x) => x.createdAt?.getTime()),
      filterable: DateRangeFilterWithPredicate(
        (row, p) =>
          (p.minDate ? (row.createdAt?.getTime() ?? 0) >= startOfDay(p.minDate).getTime() : true) &&
          (p.maxDate ? (row.createdAt?.getTime() ?? 0) <= endOfDay(p.maxDate).getTime() : true),
          {
            minDate: startOfDay(new Date()),
            maxDate: null
          }
      ),
    }),
    cd.default('Data pierwszej dostawy', (r) => min(r?.menus?.map(m => m.menu?.date))?.toLocaleString(), {
      sortable: true,
      compare: genericComparator((r) => min(r?.menus?.map(m => m.menu?.date))?.getTime()),
      filterable: DateRangeFilterWithPredicate(
        (row, p) =>
          (p.minDate ? (min(row?.menus?.map(m => m.menu?.date))?.getTime() ?? 0) >= startOfDay(p.minDate).getTime() : true) &&
          (p.maxDate ? (min(row?.menus?.map(m => m.menu?.date))?.getTime() ?? 0) <= endOfDay(p.maxDate).getTime() : true),
          {
            minDate: null,
            maxDate: null
          }
      )
    }),
    cd.default('Użytkownik', (r) => r.user?.email),
    cd.price('Wartość zamówienia', (r) => r.price),
    cd.price('Kwota płatności', (r) => r.payment?.paid ?  r.payment?.amount : 0),
    cd.price('Pobrane z portmonetki', (r) => r.payment?.paid ? (r.price ?? 0) - (r.payment?.amount ?? 0) : 0),
    cd.default('Vouchery', (r) => {
      const discounted = r.vouchers?.filter(v=>v.discount) ?? [];
      const percentage = r.vouchers?.filter(v=>v.percentage) ?? [];
      if(discounted.length === 0 && percentage.length === 0){
        return 'nie użyto';
      }
      const discountedEntry = discounted.length > 0 ? `${(sumBy(discounted.filter(v=>v.discount), (v) => v.discount ?? 0) / 100).toFixed(2)} zł (${discounted.map(v => v.code).join(', ')})` : '';
      const percentageEntry = percentage.length > 0 ? `${(sumBy(percentage.filter(v=>v.percentage), (v) => v.percentage ?? 0) / 100).toFixed(0)} % (${percentage.filter(v=>v.percentage).map(v => v.code).join(', ')})` : '';
      return `${discountedEntry}${(discountedEntry && percentageEntry) ? '\n' : ' '}${percentageEntry}`
    }),
    cd.default(
      'Dane do FV',
      (r) => r.invoice
        ? [
            r.invoice.name,
            ...(r.invoice.vatin ? [`NIP: ${r.invoice.vatin}`] : []),
            `${r.invoice.street} ${r.invoice.house}${r.invoice.local ? ` / ${r.invoice.local}` : ''}`,
            `${r.invoice.postalcode} ${r.invoice.city}`
        ].join('\n')
        : '')
  ];

  const customAction = (o: Order, tableState: TableState) => {
    if (o.invoiceNumber) {
      return (
        <>
					<Button
						color={'primary'}
						onClick={() => {
							if (o.id ) {
								invoices.get(o.id).then((response) => {
									const link = document.createElement('a');
									const blob = new Blob([response], { type: 'application/pdf' });
									link.href = window.URL.createObjectURL(blob);
									link.download = `${o.invoiceNumber}.pdf`;
									link.click();
								});
							}
						}}
					>
						Pobierz fakturę: {o.invoiceNumber}
					</Button>
					{o.invoiceCorrectionNumber && <Button
						color={'primary'}
						onClick={() => {
							if (o.id ) {
								invoices.getCorrection(o.id).then((response) => {
									const link = document.createElement('a');
									const blob = new Blob([response], { type: 'application/pdf' });
									link.href = window.URL.createObjectURL(blob);
									link.download = `${o.invoiceCorrectionNumber}.pdf`;
									link.click();
								});
							}
						}}
					>
						Pobierz korektę: {o.invoiceCorrectionNumber}
					</Button>}
				</>
      );
    }
		
    const address = o.invoice ?? o.menus?.find(m => m.address)?.address;
    if (address && !o.invoiceNumber && o?.payment?.paid) {
      return (
        <Button
          disabled={invoiceGeneration}
          color={'danger'}
          onClick={() => {
            if(o.id){
              setInvoiceGeneration(true);
              invoices
                .create(o.id)
                .catch((e) => {e.json().catch(() => e.text()).then((t: any) => toast.error(t?.error || `${t}`))})
                .then(() => dispatch(adminActions.orders.getAll.request({ filter: getFilter(tableState) })))
                .finally(() => setInvoiceGeneration(false));
            }
          }}
        >
          Wygeneruj fakturę
        </Button>
      );
    }

    return null;
  }

  return (
    <GenericList<Order>
      columns={columns}
      rowKey={(t) => `${t.id}`}
      loadAction={adminActions.orders.getAll.request}
      isPendingSelector={(gs: GlobalState) => gs.admin.orders.pending}
      dataSelector={(gs: GlobalState) => gs.admin.orders.list}
      editPath={editUrl}
      deleteAction={(e) => dispatch(adminActions.orders.delete.request(e))}
      rowClassName={(o) => (isCanceled(o) ? styles.canceled : !o.payment?.paid ? styles.notPaid : '')}
      getFilter={getFilter}
      customAction={customAction}
    />
  );
}

function OrderEdit({ editUrl }: { editUrl: (id: number | 'new' | '') => string }) {
  const dispatch = useDispatch();

  const orderItemFields: FieldsDescription<OrderMenuItem> = {
    quantity: fc.numberInput('Zamówiona ilość'),
    price: fc.price('Cena'),
    '_package': fc.display('Opakowanie', (t) => t ? `${JSON.parse(t).name} ${displayPrice(JSON.parse(t).value)}/szt.` : 'brak' )
  };

  const orderMenuFields: FieldsDescription<OrderMenu> = {
    menu: fc.display('Data', (m) => m?.date?.toLocaleDateString()),
    price: fc.price('Cena'),
    delivery: fc.price('Koszt dostawy'),
    items: fc.array(
      'Pozycje',
      orderItemFields,
      (t) => `${t.menuItem?.product?.name} x ${t.quantity}` ?? 'Nowy produkt'
    ),
  };

  const paymentFields: FieldsDescription<Payment> = {
    paid: fc.check('Zapłacone?'),
    transaction: fc.display('Identyfikator płatności'),
    amount: fc.displayPrice('Kwota płatności'),
    wallet: fc.displayPrice('Pobrane z portmonetki'),
  };

  const fields: FieldsDescription<Order> = {
    id: fc.display('Id'),
    price: fc.displayPrice('Wartość zamowienia', (o) => (o.price ?? 0) + sumBy(o.vouchers ?? [], ({discount}) => discount ?? 0)),
    payment: fc.nested(paymentFields),
    vouchers: fc.display('Kwota z voucherów', (v) => displayPrice(sumBy(v ?? [], ({discount}) => discount ?? 0))),
    menus: fc.array('Na dzień', orderMenuFields, (t) => t.menu?.date?.toLocaleDateString() ?? 'Nowa pozycja'),
  };

  return (
    <GenericDetailsComponent<Order, GlobalState>
      loadAction={adminActions.orders.getDetails.request}
      dataSelector={(id: number | 'new', gs: GlobalState) =>
        id !== 'new' && gs.admin.orders.details?.id === id ? gs.admin.orders.details : null
      }
      fields={fields}
      onSave={(entity) => dispatch(adminActions.orders.upsert.request({ entity, redirectTo: editUrl }))}
      getNewEntity={() => ({})}
    />
  );
}

export const ordersEntry: EntityInfo = ['Zamówienia', OrderList, OrderEdit, ['admin', 'orders']];
