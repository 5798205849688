/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendNotificationsRequest
 */
export interface SendNotificationsRequest {
    /**
     * 
     * @type {string}
     * @memberof SendNotificationsRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SendNotificationsRequest
     */
    body?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SendNotificationsRequest
     */
    sendToNotAssigned?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof SendNotificationsRequest
     */
    recipients?: Array<number>;
}

export function SendNotificationsRequestFromJSON(json: any): SendNotificationsRequest {
    return SendNotificationsRequestFromJSONTyped(json, false);
}

export function SendNotificationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendNotificationsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'sendToNotAssigned': !exists(json, 'sendToNotAssigned') ? undefined : json['sendToNotAssigned'],
        'recipients': !exists(json, 'recipients') ? undefined : json['recipients'],
    };
}

export function SendNotificationsRequestToJSON(value?: SendNotificationsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'body': value.body,
        'sendToNotAssigned': value.sendToNotAssigned,
        'recipients': value.recipients,
    };
}


