import React, { Fragment, useState } from 'react';
import styles from './myOrderDetails.module.scss';
import DeliveryIcon from '../deliveryIcon';
import { displayPrice } from 'src/logic/utils';
import { OrderMenu } from 'src/api';
import { round } from 'lodash';

export const OrderMenuItems = ({ orderMenu }: { orderMenu: OrderMenu }) => {
  const { calories, protein, carbs, fat } = orderMenu?.items?.reduce((agg, item) => {
    const { calories = 0, protein = 0, carbs = 0, fat = 0, weight = 0 } = item.menuItem?.product ?? { calories: 0, protein: 0, fat: 0, weight: 0};
    const multiplyConst = (weight / 100) * (item.quantity ?? 0);
    return {
      calories: agg.calories + (calories * multiplyConst),
      protein: agg.protein + (protein * multiplyConst),
      carbs: agg.carbs + (carbs * multiplyConst),
      fat: agg.fat + (fat * multiplyConst),
    }
  }, { calories: 0, protein: 0, carbs: 0, fat: 0 }) ?? { calories: 0, protein: 0, carbs: 0, fat: 0 };
  const [showMicro, updateShowMicro] = useState(false);
  return (
    <>
      {orderMenu.items?.map((or, i) => (
        <Fragment key={i}>
          <div className={styles.orderItem}>
            <div className={styles.quantityCol}>
              <span>{or.quantity}x</span>
            </div>
            <div className={styles.nameCol}>
              <div>
              <div>{or.menuItem?.product?.name} <span>{or.menuItem?.product?.category?.name ?? 'kategoria'}</span> </div>
              <div className={styles.macro}><b>K:</b>{or.menuItem?.product?.calories ?? 0}/<b>W:</b>{or.menuItem?.product?.carbs ?? 0}/<b>B:</b>{or.menuItem?.product?.protein ?? 0}/<b>F:</b>{or.menuItem?.product?.fat ?? 0}</div>
              </div>
            </div>
            <div className={styles.priceCol}>
              <span>
                {or.quantity} x {displayPrice(or.price)}
              </span>
              <div>{displayPrice((or.quantity ?? 0) * (or.price ?? 0))}</div>
            </div>
          </div>

          {
            or._package &&
            <div className={styles.orderItem}>
            <div className={styles.quantityCol}>
              <span>{or.quantity}x</span>
            </div>
            <div className={styles.nameCol}>
              <div>
              <div>{JSON.parse(or._package).name}</div>
              </div>
            </div>
            <div className={styles.priceCol}>
              <span>
                {or.quantity} x {displayPrice(JSON.parse(or._package).value)}
              </span>
              <div>{displayPrice((or.quantity ?? 0) * (JSON.parse(or._package).value ?? 0))}</div>
            </div>
          </div>
          }
        </Fragment>
      ))}
      {
        !!orderMenu.cutlery &&
        <div className={styles.orderItem}>
          <div className={styles.quantityCol}>
            <span>{orderMenu.cutlery}x</span>
          </div>
          <div className={styles.nameCol}>
            <div>Sztućce</div>
          </div>
          <div className={styles.priceCol}>
            <span>{orderMenu.cutlery} x {displayPrice(orderMenu.cutleryCost)}</span>
            <div className={styles.price}>{displayPrice((orderMenu.cutlery ?? 0) * (orderMenu.cutleryCost ?? 0))}</div>
          </div>
        </div>
      }
      <div className={styles.orderItem}>
        <div className={styles.quantityCol}>
          <span>
            <DeliveryIcon color={orderMenu.deletedAt ? '#000000' : '#ffffff'} />
          </span>
        </div>
        <div className={styles.nameCol}>
          <div>Dostawa</div>
        </div>
        <div className={styles.priceCol}>
          <span>1 x {displayPrice(orderMenu.delivery)}</span>
          <div className={styles.price}>{displayPrice(orderMenu.delivery)}</div>
        </div>
      </div>
      <div className={styles.orderItem}>
        <big>Suma zamówienia</big>
        <big className={styles.price}>
          {displayPrice((orderMenu.price ?? 0) + (orderMenu.delivery ?? 0))}
        </big>
      </div>
      <div className={styles.macroSummary}>
        <div className={styles.daySubSummary}>
            <span>Ilość kalorii</span>
            <span>{(calories).toFixed(1)} kcal</span>
          </div>
            {
              showMicro === false ? (
                <div className={styles.daySubSummary}>
                  <span>Makroskładniki</span>
                  <button onClick={() => updateShowMicro(!showMicro)}>Rozwiń</button>
                </div>
              ) :
                (
                  <>
                    <div className={styles.daySubSummary}>
                      <span>Białko</span>
                      <span>{round(protein, 1)} g</span>
                    </div>
                    <div className={styles.daySubSummary}>
                      <span>Węglowodany</span>
                      <span>{round(carbs, 1)} g</span>
                    </div>
                    <div className={styles.daySubSummary}>
                      <span>Tłuszcze</span>
                      <span>{round(fat, 1)} g</span>
                    </div>
                  </>
                )
            }
          </div>
    </>
  );
};
