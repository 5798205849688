import { userSaga } from './user';
import { addressesSaga } from './addresses';
import { saga as adminSaga } from './admin';
import { menuSaga } from './menu';
import { basketSaga } from './basket';
import { settingsSaga } from './settings';
import { orderSaga } from './order';
import { walletSaga } from './wallet';

export function* saga(): IterableIterator<any> {
  yield* userSaga();
  yield* addressesSaga();
  yield* adminSaga();
  yield* menuSaga();
  yield* basketSaga();
  yield* settingsSaga();
  yield* orderSaga();
  yield* walletSaga();
}
