/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
    OrderMenu,
    OrderMenuFromJSON,
    OrderMenuFromJSONTyped,
    OrderMenuToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface WalletHistory
 */
export interface WalletHistory {
    /**
     * 
     * @type {number}
     * @memberof WalletHistory
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WalletHistory
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof WalletHistory
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof WalletHistory
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof WalletHistory
     */
    orderId?: number;
    /**
     * 
     * @type {number}
     * @memberof WalletHistory
     */
    orderMenuId?: number;
    /**
     * 
     * @type {Date}
     * @memberof WalletHistory
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WalletHistory
     */
    updatedAt?: Date;
    /**
     * 
     * @type {User}
     * @memberof WalletHistory
     */
    user?: User;
    /**
     * 
     * @type {Order}
     * @memberof WalletHistory
     */
    order?: Order;
    /**
     * 
     * @type {OrderMenu}
     * @memberof WalletHistory
     */
    orderMenu?: OrderMenu;
}

export function WalletHistoryFromJSON(json: any): WalletHistory {
    return WalletHistoryFromJSONTyped(json, false);
}

export function WalletHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'orderMenuId': !exists(json, 'orderMenuId') ? undefined : json['orderMenuId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'order': !exists(json, 'order') ? undefined : OrderFromJSON(json['order']),
        'orderMenu': !exists(json, 'orderMenu') ? undefined : OrderMenuFromJSON(json['orderMenu']),
    };
}

export function WalletHistoryToJSON(value?: WalletHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'description': value.description,
        'userId': value.userId,
        'orderId': value.orderId,
        'orderMenuId': value.orderMenuId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'user': UserToJSON(value.user),
        'order': OrderToJSON(value.order),
        'orderMenu': OrderMenuToJSON(value.orderMenu),
    };
}


