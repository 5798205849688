import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'src/logic/user';
import styles from './loginForm.module.scss';
import { TextFormField } from '../forms/textField/textField';
import { BtnForm } from '../forms/Forms';
import { FacebookLogin } from '../facebookLogin';
import Fieldset from 'src/containers/fieldset/fieldset';
import { Regsitered } from './registered';
import { Reset } from './reset';
import { ResetDone } from './resetDone';
import { routes } from 'src/routes';
import { Link, useRouteMatch } from 'react-router-dom';
import { Formik, useFormikContext, FormikErrors, FormikHelpers } from 'formik';
import { useErrorsList } from '../hooks/useErrorsList';
import ErrorList from '../forms/errorList/errorList';
import { pickBy } from 'lodash';
import { SetPassword } from './setPassword';
import { replace } from 'connected-react-router';
import { GlobalState } from 'src/logic/reducers';
import { CheckBoxFormField } from '../forms/checkBox/checkBox';
import { FCM_KEY } from '../device/device';

enum FormStep {
  login = 0,
  register = 1,
  registered = 2,
  reset = 3,
  resetDone = 4,
  setPassword = 5,
}

export type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  step: FormStep;
  verifyCode: string;
  marketingAgreement: boolean;
}

const initialValues: FormValues = {
  email: '',
  password: '',
  confirmPassword: '',
  step: FormStep.login,
  verifyCode: '',
  marketingAgreement: false,
}

const keys: (keyof FormValues)[] = ['email', 'password', 'confirmPassword'];

const LoginForm = () => {
  const { values, errors, touched, setFieldValue, handleSubmit, isSubmitting, status, setStatus } = useFormikContext<FormValues>();
  const errorsList = useErrorsList<FormValues>(keys, touched, errors, status ? `${status}` : undefined);
  const m = useRouteMatch<{ code: string }>();
  const setStep = useCallback((step: FormStep) => setFieldValue('step', step), [setFieldValue]);
  const dispatch = useDispatch();
  const { SHOW_FACEBOOK_LOGIN } = useSelector((gs: GlobalState) => gs.settings);

  useEffect(() => {
    if (m.params.code) {
      setStep(FormStep.setPassword);
      setFieldValue('verifyCode', m.params.code);
      dispatch(replace(routes.index()));
    }
  }, [m.params.code, dispatch, setFieldValue, setStep]);

  useEffect(() => {
    if (status) {
      setStatus(undefined);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const titles = [
    'Zaloguj się',
    'Utwórz konto',
    'Udało się!',
    'Resetuj hasło',
    'Dziękujemy',
    'Stwórz nowe hasło',
  ];
  const texts = [
    'Masz konto? Zaloguj się i zamów Pobudkę już teraz',
    'Zarejestruj się i zamawiaj Pobudkę kiedy tylko chcesz!',
    'Twoje konto zostało utworzone :)',
    'Wpisz swój email aby zresetować hasło',
    '',
  ];

  return (
    <div className={styles.loginForm}>
      <Fieldset title={titles[values.step]}>
        <p>{texts[values.step]}</p>
        {values.step < 2 && (
          <>
            { SHOW_FACEBOOK_LOGIN &&
              <>
                <FacebookLogin />
                <p className="uppercase black50">
                  <b>lub</b>
                </p>
              </>
            }
            <TextFormField<keyof FormValues>
              name='email'
              label="adres email"
              type="email"
            />
            <TextFormField<keyof FormValues>
              name='password'
              label="hasło"
              type="password"
            />
            {values.step === FormStep.register && (
              <TextFormField<keyof FormValues>
                name='confirmPassword'
                label="powtórz hasło"
                type="password"
              />
            )}
            {
              values.step === FormStep.register && (
                <CheckBoxFormField<keyof FormValues> name='marketingAgreement'>
                  WYRAŻAM ZGODĘ NA PRZESYŁANIE MI INFORMACJI O ZNIŻKACH I WAŻNYCH WYDARZENIACH Z ŻYCIA FIRMY (O TYM JAK PRZETWARZAMY DANE OSOBOWE PRZECZYTASZ W  <Link to={routes.privacy()}>POLITYCE PRYWATNOŚCI</Link> SERWISU)
                </CheckBoxFormField>
              )
            }
            <ErrorList errors={errorsList} />
            <div className={styles.buttonWrapper}>
              <BtnForm type="orange" action={handleSubmit} disabled={isSubmitting}>
                {values.step === FormStep.login
                  ? 'Zaloguj się na swoje konto'
                  : 'Dołącz do Pobudki'}
              </BtnForm>
            </div>
          </>
        )}

        {values.step === FormStep.registered && <Regsitered />}

        {values.step === FormStep.reset && <Reset errorsList={errorsList} />}

        {values.step === FormStep.resetDone && <ResetDone login={() => setStep(FormStep.login)} />}

        {values.step === FormStep.setPassword && <SetPassword login={() => setStep(FormStep.login)} errorsList={errorsList} />}

        {values.step === FormStep.login && (
          <>
            <p className="uppercase black50 small">
              Zapomniałeś hasła?
              <a onClick={() => setStep(FormStep.reset)}> Zresetuj je</a>
            </p>

            <p className="uppercase">
              Nie posiadasz konta?
              <a onClick={() => setStep(FormStep.register)}> Utwórz konto</a>
            </p>
          </>
        )}
        {values.step === FormStep.register && (
          <>
            <p className="uppercase black50">
              posiadasz konto?
              <a onClick={() => setStep(FormStep.login)}> Zaloguj się</a>
            </p>
            <p className="uppercase">
              Rejestrując się zgadzasz się z warunkami <Link to={routes.terms()}>regulaminu</Link>{' '}
              i <Link to={routes.privacy()}>Polityką prywatności</Link> oraz akceptujesz{' '}
              <Link to={routes.terms()}>warunki usługi</Link>
            </p>
          </>
        )}
        {values.step === FormStep.reset && (
          <>
            <p className="uppercase black50">
              Wróć do
              <a onClick={() => setStep(FormStep.login)}> logowania</a>
            </p>
          </>
        )}
      </Fieldset>
    </div>
  );
}

const validate = (values: FormValues): FormikErrors<FormValues> => {
  const result = {
    email: values.step === FormStep.setPassword || values.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? undefined : 'Niepoprawny adres email',
    password: values.step === FormStep.login && !values.password.length
      ? 'Podaj hasło'
      : (values.step === FormStep.register || values.step === FormStep.setPassword) && values.password.length < 6
        ? 'Hasło musi mieć co najmniej 6 znaków'
        : undefined,
    confirmPassword: (values.step === FormStep.register || values.step === FormStep.setPassword) && values.password !== values.confirmPassword
      ? 'Hasła muszą być takie same'
      : undefined
  }
  return pickBy(result, (v) => v)
}

export const Login = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    const { fcm, deviceType } = JSON.parse(localStorage.getItem(FCM_KEY) ?? '{}');
    return new Promise((res, rej) => {
      const andThen = (nextStep: FormStep) => (msg: string | undefined) => {
        if (msg) {
          rej(msg);
          helpers.setStatus(msg);
        } else {
          helpers.setFieldValue('step', nextStep);
          res();
        }
      }
      switch (values.step) {
        case FormStep.login:
          dispatch(userActions.login({ email: values.email, password: values.password, fcm, deviceType, andThen: andThen(FormStep.login) }))
          return;
        case FormStep.register:
          dispatch(userActions.register.request({ email: values.email, password: values.password, marketingAgreement: values.marketingAgreement, fcm, deviceType, andThen: andThen(FormStep.registered) }))
          return;
        case FormStep.reset:
          dispatch(userActions.resetPassword.request({ email: values.email, andThen: andThen(FormStep.resetDone) }))
          return;
        case FormStep.setPassword:
          dispatch(userActions.setPassword.request({ code: values.verifyCode, password: values.password, andThen: andThen(FormStep.login) }))
          return;
        default:
          rej('Coś poszło nie tak');
      }
    })
  };
  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
      <LoginForm />
    </Formik>
  )
}
