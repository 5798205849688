import React from 'react';
import styles from './menuItem.module.scss';

type MenuItemTagProps = {
  label: string;
  color: 'red' | 'green' | 'blue' | 'orange' | 'yellow';
};

export const MenuItemTag = ({ label, color }: MenuItemTagProps) => {
  const cssClass = {
    red: styles.redTag,
    green: styles.greenTag,
    blue: styles.blueTag,
    orange: styles.orangeTag,
    yellow: styles.yellowTag
  };
  return (
    <div className={[cssClass[color], styles.itemTag].join(' ')}>
      <div className={styles.back}></div>
      <div className={styles.front}>{label}</div>
    </div>
  );
};
