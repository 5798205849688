import React from 'react';
import { Location } from 'src/api';
import styles from './location.module.scss';
import { BtnForm } from '../forms/Forms';
import { getDeliveryHours } from 'src/logic/addresses';

export const LocationForm = ({ location, change }: { location: Location; change: () => void }) => {
  return (
    <div className={styles.locationForm}>
      <section>
        <div className={styles.fieldBig}>Darmowa dostawa:</div>
      </section>
      <section>
        <div className={styles.fieldMedium}>{location.name}</div>
        <div className={styles.field}>
          {location.street ?? ''} {location.local ? `${location.house}/${location.local}` : location.house}
        </div>
        <div className={styles.field}>{location.postalcode ?? '00-000'} {location.city}</div>
      </section>
      {
        location.additional &&
        <section>
          <div className={styles.notes}>{location.additional}</div>
        </section>
      }
      <section className={styles.actions}>
        <BtnForm type='black' action={change}>Zmień adres biura</BtnForm>
      </section>
    </div>
  )
}
