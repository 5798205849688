/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuItem,
    MenuItemFromJSON,
    MenuItemFromJSONTyped,
    MenuItemToJSON,
    OrderMenu,
    OrderMenuFromJSON,
    OrderMenuFromJSONTyped,
    OrderMenuToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrderMenuItem
 */
export interface OrderMenuItem {
    /**
     * 
     * @type {number}
     * @memberof OrderMenuItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenuItem
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenuItem
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenuItem
     */
    orderMenuId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderMenuItem
     */
    menuItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderMenuItem
     */
    _package?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderMenuItem
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderMenuItem
     */
    updatedAt?: Date;
    /**
     * 
     * @type {OrderMenu}
     * @memberof OrderMenuItem
     */
    orderMenu?: OrderMenu;
    /**
     * 
     * @type {MenuItem}
     * @memberof OrderMenuItem
     */
    menuItem?: MenuItem;
}

export function OrderMenuItemFromJSON(json: any): OrderMenuItem {
    return OrderMenuItemFromJSONTyped(json, false);
}

export function OrderMenuItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMenuItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'orderMenuId': !exists(json, 'orderMenuId') ? undefined : json['orderMenuId'],
        'menuItemId': !exists(json, 'menuItemId') ? undefined : json['menuItemId'],
        '_package': !exists(json, 'package') ? undefined : json['package'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'orderMenu': !exists(json, 'orderMenu') ? undefined : OrderMenuFromJSON(json['orderMenu']),
        'menuItem': !exists(json, 'menuItem') ? undefined : MenuItemFromJSON(json['menuItem']),
    };
}

export function OrderMenuItemToJSON(value?: OrderMenuItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'price': value.price,
        'quantity': value.quantity,
        'orderMenuId': value.orderMenuId,
        'menuItemId': value.menuItemId,
        'package': value._package,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'orderMenu': OrderMenuToJSON(value.orderMenu),
        'menuItem': MenuItemToJSON(value.menuItem),
    };
}


