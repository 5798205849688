import { getDateString } from 'src/logic/utils/dates';

export const routes = {
  index: () => '/',
  reset: () => '/reset/:code',
  verify: () => '/verify/:code',
  menu: (date: Date | '' | ':date?' = ':date?') => `/menu/${date instanceof Date ? getDateString(date) : date}`,
  menuBasket: (date: Date | '' | ':date?' = ':date?') => `${routes.menu(date)}/koszyk`,
  menuDetails: (date: Date | '' | ':date?' = ':date?', id = ':id?') => `${routes.menu(date)}/${id}`,
  admin: () => '/admin',
  buy: () => '/kup',
  order: (orderId: string | number | ':orderId' = ':orderId', action: 'paid' | 'paymentFailed' | 'display' | ':action' = ':action') => `/zamowienie/${action}/${orderId}`,
  delivery: (orderId: string | number | ':orderId' = ':orderId', deliveryId: number | ':deliveryId' = ':deliveryId') => `${routes.order(orderId, 'display')}/${deliveryId}`,
  account: () => '/konto',
  accountOrders: () => `${routes.account()}/zamowienia`,
  accountProfile: () => `${routes.account()}/dane`,
  accountAddresses: () => `${routes.account()}/adresy`,
  accountBalance: () => `${routes.account()}/saldo`,
  aboutUs: () => '/onas',
  why: () => '/dlaczego',
  security: () => '/bezpieczenstwo',
  deliveryRange: () => '/zasieg',
  deliveryToOffices: () => '/dostawa-do-biur',
  contact: () => '/kontakt',
  terms: () => '/regulamin',
  privacy: () => '/prywatnosc',
  offline: () => '/offline',
  unsubscribe: () => '/unsubscribe'
}
