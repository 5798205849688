import React from 'react';
import styles from './sideCTABtn.module.scss';

type SideCTABtnProps = {
  action?(): void;
  children?: React.ReactNode;
  disabled: boolean;
};

const SideCTABtn = ({ action, children, disabled }: SideCTABtnProps) => (
  <button className={styles.sideCTABtn} onClick={action} disabled={disabled} >
    {children}
  </button>
);
export default SideCTABtn;
