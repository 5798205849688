/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserDataFrame
 */
export interface UserDataFrame {
    /**
     * 
     * @type {number}
     * @memberof UserDataFrame
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof UserDataFrame
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof UserDataFrame
     */
    limit: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserDataFrame
     */
    data: Array<User>;
}

export function UserDataFrameFromJSON(json: any): UserDataFrame {
    return UserDataFrameFromJSONTyped(json, false);
}

export function UserDataFrameFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDataFrame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'offset': json['offset'],
        'limit': json['limit'],
        'data': ((json['data'] as Array<any>).map(UserFromJSON)),
    };
}

export function UserDataFrameToJSON(value?: UserDataFrame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'offset': value.offset,
        'limit': value.limit,
        'data': ((value.data as Array<any>).map(UserToJSON)),
    };
}


