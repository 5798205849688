import { Order } from '../api/models/Order';
import { sum } from 'lodash';
import { Basket } from 'src/logic/basket';

const GA_KEY = 'UA-169402169-1';

const tractFbq = (id: number, amount: number, quantity: number) => {
  const fbq = (window as any).fbq;
  fbq?.('track', 'Purchase', {
    value: amount / 100,
    currency: 'PLN',
    contents: [
      {
        id: `${id}`,
        quantity: quantity,
      },
    ],
  });
};

const trackGTag = (order: Order, basket: Basket) => {
  const ga = (window as any).ga;
  ga?.('create', GA_KEY);
  ga?.('require', 'ec');
  ga?.('set', 'currencyCode', 'PLN');

  const delivery = sum(order.menus?.map((om) => om.delivery ?? 0));
  const value = (order.price ?? 0) - delivery;

  Object.entries(basket)
    .flatMap(([date, bpd]) => bpd.items.map((bi) => [bi, date] as const))
    .forEach(([bi, date]) => {
      ga?.('ec:addProduct', {
        id: `${bi.productId}`,
        name: bi.productName,
        category: bi.productCategory,
        brand: 'Pobudka',
        variant: date,
        price: (bi.price / 100).toFixed(2),
        quantity: bi.quantity,
      });
    });

  ga?.('ec:setAction', 'purchase', {
    id: `${order.id}`,
    affiliation: '',
    revenue: (value / 100).toFixed(2),
    tax: '0',
    shipping: (delivery / 100).toFixed(2),
  });

  return new Promise((res) => {
    // this force GA to flush the data
    ga?.('send', 'pageview');
    ga?.('send', 'event', 'Purchase', 'purchase', {
      hitCallback: res,
    });
    setTimeout(res, 1500);
  });
};

export const trackPurchase = (order: Order, basket: Basket) => {
  const quantity =
    order.menus
      ?.flatMap((m) => m.items?.map((i) => i.quantity ?? 0) ?? [])
      ?.reduce((a, b) => a + b, 0) ?? 0;
  tractFbq(order.id ?? 0, order.price ?? 0, quantity);
  return trackGTag(order, basket);
};
