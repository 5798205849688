/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiProductsEntity,
    ApiProductsEntityFromJSON,
    ApiProductsEntityToJSON,
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Product,
    ProductFromJSON,
    ProductToJSON,
    ProductDataFrame,
    ProductDataFrameFromJSON,
    ProductDataFrameToJSON,
} from '../models';

export interface ApiProductsGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiProductsIdDeleteRequest {
    id: number;
}

export interface ApiProductsIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiProductsPostRequest {
    relations?: string;
    deleted?: boolean;
    entity?: ApiProductsEntity;
    pictureMenu?: Blob;
    pictureName?: Blob;
}

/**
 * no description
 */
export class ProductApi extends runtime.BaseAPI {

    /**
     * Get list of Product
     */
    async apiProductsGetRaw(requestParameters: ApiProductsGetRequest): Promise<runtime.ApiResponse<ProductDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Product
     */
    async apiProductsGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<ProductDataFrame> {
        const response = await this.apiProductsGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Product
     */
    async apiProductsIdDeleteRaw(requestParameters: ApiProductsIdDeleteRequest): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProductsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Remove entity of Product
     */
    async apiProductsIdDelete(id: number): Promise<Product> {
        const response = await this.apiProductsIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Product
     */
    async apiProductsIdGetRaw(requestParameters: ApiProductsIdGetRequest): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProductsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Get entity of Product
     */
    async apiProductsIdGet(id: number, relations?: string, deleted?: boolean): Promise<Product> {
        const response = await this.apiProductsIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Product (if id defined)
     */
    async apiProductsPostRaw(requestParameters: ApiProductsPostRequest): Promise<runtime.ApiResponse<Product>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.entity !== undefined) {
            formParams.append('entity', requestParameters.entity as any);
        }

        if (requestParameters.pictureMenu !== undefined) {
            formParams.append('pictureMenu', requestParameters.pictureMenu as any);
        }

        if (requestParameters.pictureName !== undefined) {
            formParams.append('pictureName', requestParameters.pictureName as any);
        }

        const response = await this.request({
            path: `/api/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Product (if id defined)
     */
    async apiProductsPost(relations?: string, deleted?: boolean, entity?: ApiProductsEntity, pictureMenu?: Blob, pictureName?: Blob): Promise<Product> {
        const response = await this.apiProductsPostRaw({ relations: relations, deleted: deleted, entity: entity, pictureMenu: pictureMenu, pictureName: pictureName });
        return await response.value();
    }

}
