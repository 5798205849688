import React, { useCallback, useEffect } from 'react';
import styles from './basket.module.scss';
import SidepanelFooter from '../../containers/sidepanel/sidepanelfooter';
import SideCTABtn from '../buttons/sideCTABtn/sideCTABtn';
import SidepanelContent from 'src/containers/sidepanel/sidepanelContent';
import OrderDay from '../order/orderDay/orderDay';
import OrderSummary from '../order/orderSummary/orderSummary';
import { Basket as BasketType, getBasketStats, basketActions } from 'src/logic/basket';
import { displayPrice } from 'src/logic/utils/price';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { push } from 'connected-react-router';
import { routes } from 'src/routes';
import { gtmBeginCheckout, gtmViewCart } from 'src/logic/gtm';


export type OrderListing = {
  title: string;
  price: number;
  quantinty: number;
};

type BasketProps = {
  order: BasketType;
};

export const orderTxt: { [n in number]: string } = {
  0: 'produktów',
  1: 'produkt',
  2: 'produkty',
  3: 'produkty',
  4: 'produkty',
}

const Basket = ({ order }: BasketProps) => {
  const { DELIVERY_HOME, FREE_DELIVERY, DELIVERY_OFFICE, CUTLERY_COST } = useSelector((gs: GlobalState) => gs.settings);
  const vouchers = useSelector((gs: GlobalState) => gs.basket.vouchers)
  const location = useSelector((gs: GlobalState) => gs.addresses.location);
  const locationVouchers = vouchers.filter((v) => v.location);
  const { orderByDay, itemsCount, foodTotal, deliveryNum, total, cutleryTotal, cutleryNum, packagesTotal } = getBasketStats(order, vouchers, FREE_DELIVERY, DELIVERY_HOME, !!location, CUTLERY_COST);
  const dispatch = useDispatch();
  const remove = useCallback((code: string) => dispatch(basketActions.removeVoucher(code)), [dispatch]);
  const addCode = useCallback((code) => dispatch(basketActions.addVoucher(code)), [dispatch]);
  useEffect(() => { gtmViewCart() }, []);

  return (
    <>
      <SidepanelContent>
        <h4 className={styles.title}>Koszyk</h4>
        <h2 className={styles.header}>
          {' '}
          Masz <em>{itemsCount} {orderTxt[itemsCount] ?? 'produktów'}</em> w koszyku
        </h2>
        <div className={styles.ordersList}>
          {
            orderByDay.map(([date, dayBasket]) => (
              <OrderDay
                dayBasket={dayBasket}
                date={new Date(date)}
                key={date}
                freeDelivery={deliveryNum === 0 ? 0 : FREE_DELIVERY}
                deliveryFee={DELIVERY_HOME}
                cutleryCost={CUTLERY_COST}
                hasLocation={!!location || !!locationVouchers.length}
              />
            ))
          }
        </div>

        <OrderSummary
          total={total}
          foodTotal={foodTotal}
          deliveryNum={deliveryNum}
          deliveryFee={DELIVERY_HOME}
          deliveryOfficeFee={DELIVERY_OFFICE}
          cutleryTotal={cutleryTotal}
          cutleryCost={CUTLERY_COST}
          cutleryNum={cutleryNum}
          vouchers={vouchers}
          hasLocation={!!location}
          removeCode={remove}
          addCode={addCode}
          packagesTotal={packagesTotal}
        />
      </SidepanelContent>
      <SidepanelFooter>
        Razem: {displayPrice(total)}
        <SideCTABtn disabled={itemsCount === 0} action={() => { dispatch(push(routes.buy())); gtmBeginCheckout(order, total, vouchers)}}>
          <span>Przejdź dalej</span> <img src="/icons/btnarrow.svg" alt='' />
        </SideCTABtn>
      </SidepanelFooter>
    </>
  );
};

export default Basket;
