import { push } from 'connected-react-router';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GlobalState } from 'src/logic/reducers';
import styles from './adminBanner.module.scss';

const parseArray = (value: string) => {
  try{
    return JSON.parse(value);
  } catch {
    return [value];
  }
}


const AdminBanner = () => {
  const showBanner = useSelector((gs: GlobalState) => gs.settings.SHOW_BANNER);

  const bannerDesktopRaw = useSelector((gs: GlobalState) => gs.settings.BANNER_DESKTOP);
  const bannerDesktop: string[] = useMemo(() => parseArray(bannerDesktopRaw), [bannerDesktopRaw]);

  const bannerMobileRaw = useSelector((gs: GlobalState) => gs.settings.BANNER_MOBILE);
  const bannerMobile: string[] = useMemo(() => parseArray(bannerMobileRaw), [bannerMobileRaw]);

  const bannerAddressesRaw = useSelector((gs: GlobalState) => gs.settings.BANNER_ADDRESS);
  const bannerAddresses = useMemo(() => parseArray(bannerAddressesRaw), [bannerAddressesRaw]);

  const [isMobile, setIsMobile] = useState(window.matchMedia?.('(max-width: 768px)').matches || false);

  useEffect(() => {
    window.onresize = () => {
      setIsMobile(window.matchMedia?.('(max-width: 768px)').matches || false);
    }
    return () => {window.onresize = null};
  }, [setIsMobile])

  if(!showBanner) {
    return null
  }
  return (
    <div className={styles.banner} >
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        centerMode={isMobile}
        centerSlidePercentage={85}
        showArrows={!isMobile}
        autoPlay
        interval={3000}
      >
        {
          (isMobile ? bannerMobile : bannerDesktop).map((src, idx) => {
            return (
              <a href={bannerAddresses[idx]} key={idx} className={'slajder01'}>
                <img src={src} alt='banner' />
              </a>
            )
          })
        }
      </Carousel>
    </div>
  );
};
export default AdminBanner;
