import cn from 'classnames';
import React, { Component, ReactNode } from 'react';

import styles from './overlay.module.scss';

type Props = {
  active: boolean;
  color?: 'light' | 'dark' | 'transparent';
  page?: boolean;
  children?: ReactNode;
  onHide?: () => void;
  zIndex?: number;
};

type State = {
  active: boolean;
  prevActive: boolean;
};

export class Overlay extends Component<Props, State> {
  public static defaultProps = {
    color: 'light',
    page: false,
  };

  public static getDerivedStateFromProps = (props: Props, state: State) => {
    if (props.active != null && props.active !== state.active) {
      return {
        active: props.active,
        prevActive: state.prevActive || state.active,
      };
    }
    return null;
  }

  public state: State = {
    active: false,
    prevActive: false,
  };

  public ref: HTMLDivElement | null = null;

  public handleHide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.ref !== e.target) {
      return;
    }
    const { onHide } = this.props;
    if (this.props.active == null) {
      this.setState({ active: false });
    }
    if (onHide) {
      onHide();
    }
  }

  public setRef = (ref: HTMLDivElement | null) => { this.ref = ref; };

  public render() {
    const { color, page, children, zIndex } = this.props;
    const classes = cn(
      styles.overlay,
      {
        [styles.active]: this.state.active,
        [styles.noactive]: !this.state.active && this.state.prevActive,
        [styles.light]: color === 'light',
        [styles.dark]: color === 'dark',
        [styles.transparent]: color === 'transparent',
        [styles.page]: page,
      },
    );
    return <div className={classes} onClick={this.handleHide} ref={this.setRef} style={{ zIndex }}>{children}</div>;
  }
}
