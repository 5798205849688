import React, { useEffect, useMemo } from 'react';
import styles from './account.module.scss';
import MyOrderItem from './myOrderItem/myOrderItem';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from '../../logic/order';
import { GlobalState } from 'src/logic/reducers';
import { routes } from 'src/routes';
import { BtnLink } from '../forms/Forms';
import { ColumnsWrapper, SideColumnn, MainColumnn } from 'src/containers/columns/columns';
import { getOrderStatus } from 'src/containers/Admin/orders';

const MyOrders = () => {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(orderActions.loadAll.request()) }, [dispatch]);
  const orders = useSelector((gs: GlobalState) => gs.order.list);
  const orderMenus = useMemo(() => { return orders.flatMap(o => o.menus?.map(m => ({ ...m, order: o })) ?? []) }, [orders]);
  const planned = orders.flatMap(o => o.menus?.filter(_ => getOrderStatus(o) === 'Opłacone').filter(m => (m.menu?.date?.getTime() ?? 0) > Date.now())?.length ?? 0).reduce((a, b) => a + b, 0);
  const getDeliveryText = (planned: number): string => {
    switch(planned){
      case 0: return '0 opłaconych dostaw';
      case 1: return `${planned} opłaconą dostawę`;
      case 2: return `${planned} opłacone dostawy`;
      case 3: return `${planned} opłacone dostawy`;
      case 4: return `${planned} opłacone dostawy`;
      default: return `${planned} opłaconych dostaw`;
    }
  }
  return (
      <ColumnsWrapper>
      <SideColumnn>
      <section className={styles.sideHeader}>
        <h2>
          {
            planned
              ? (<>Obecnie masz zaplanowane <b className="nobr">{getDeliveryText(planned)}</b></>)
              : (<>Nie masz jeszcze zaplanowanych i opłaconych zamówień</>)
          }
        </h2>
      </section>
      </SideColumnn>
      <MainColumnn>
      <section>
        {
          !orderMenus.length &&
          <h2>Jeszcze nic u nas nie zamówiłeś… <br />Pobudka, zaczynamy?</h2>
        }
        {
          orderMenus.sort((a,b) => ( (a.menu?.date?? new Date(0,0,0)).getTime() < (b.menu?.date?? new Date(0,0,0)).getTime()) ? 1 : -1 ).map((orderMenu) => (
            <MyOrderItem item={orderMenu} key={orderMenu.id ?? 0} />
          ))
        }
        <BtnLink type="orange" link={routes.menu('')}>Dodaj nowe zamówienie</BtnLink>
      </section>
      </MainColumnn>
      </ColumnsWrapper>
  )
};
export default MyOrders;
