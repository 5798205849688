/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Order,
    OrderFromJSON,
    OrderToJSON,
    OrderDataFrame,
    OrderDataFrameFromJSON,
    OrderDataFrameToJSON,
    RedirectResponse,
    RedirectResponseFromJSON,
    RedirectResponseToJSON,
} from '../models';

export interface ApiOrdersGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiOrdersIdCancelOrderMenuIdPutRequest {
    id: string;
    orderMenuId: string;
}

export interface ApiOrdersIdCancelPutRequest {
    id: string;
}

export interface ApiOrdersIdDeleteRequest {
    id: number;
}

export interface ApiOrdersIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiOrdersIdInvoiceCorrectionGetRequest {
    id: string;
}

export interface ApiOrdersIdInvoiceCorrectionPutRequest {
    id: string;
}

export interface ApiOrdersIdInvoiceGetRequest {
    id: string;
}

export interface ApiOrdersIdInvoicePutRequest {
    id: string;
}

export interface ApiOrdersPostRequest {
    relations?: string;
    deleted?: boolean;
    order?: Order;
}

export interface ApiOrdersPutRequest {
    order?: Order;
}

export interface ApiOrdersUuidGetRequest {
    uuid: string;
    relations?: string;
    deleted?: boolean;
}

export interface ApiOrdersUuidPaymentGetRequest {
    uuid: string;
    redirect?: string;
}

/**
 * no description
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     * Get list of Order
     */
    async apiOrdersGetRaw(requestParameters: ApiOrdersGetRequest): Promise<runtime.ApiResponse<OrderDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Order
     */
    async apiOrdersGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<OrderDataFrame> {
        const response = await this.apiOrdersGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Cancel single menu form order
     */
    async apiOrdersIdCancelOrderMenuIdPutRaw(requestParameters: ApiOrdersIdCancelOrderMenuIdPutRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdCancelOrderMenuIdPut.');
        }

        if (requestParameters.orderMenuId === null || requestParameters.orderMenuId === undefined) {
            throw new runtime.RequiredError('orderMenuId','Required parameter requestParameters.orderMenuId was null or undefined when calling apiOrdersIdCancelOrderMenuIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}/cancel/{orderMenuId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"orderMenuId"}}`, encodeURIComponent(String(requestParameters.orderMenuId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Cancel single menu form order
     */
    async apiOrdersIdCancelOrderMenuIdPut(id: string, orderMenuId: string): Promise<Order> {
        const response = await this.apiOrdersIdCancelOrderMenuIdPutRaw({ id: id, orderMenuId: orderMenuId });
        return await response.value();
    }

    /**
     * Cancel whole order
     */
    async apiOrdersIdCancelPutRaw(requestParameters: ApiOrdersIdCancelPutRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdCancelPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Cancel whole order
     */
    async apiOrdersIdCancelPut(id: string): Promise<Order> {
        const response = await this.apiOrdersIdCancelPutRaw({ id: id });
        return await response.value();
    }

    /**
     * Remove entity of Order
     */
    async apiOrdersIdDeleteRaw(requestParameters: ApiOrdersIdDeleteRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Remove entity of Order
     */
    async apiOrdersIdDelete(id: number): Promise<Order> {
        const response = await this.apiOrdersIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Order
     */
    async apiOrdersIdGetRaw(requestParameters: ApiOrdersIdGetRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Get entity of Order
     */
    async apiOrdersIdGet(id: number, relations?: string, deleted?: boolean): Promise<Order> {
        const response = await this.apiOrdersIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Get invoice correction for given order
     */
    async apiOrdersIdInvoiceCorrectionGetRaw(requestParameters: ApiOrdersIdInvoiceCorrectionGetRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdInvoiceCorrectionGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}/invoiceCorrection`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get invoice correction for given order
     */
    async apiOrdersIdInvoiceCorrectionGet(id: string): Promise<Blob> {
        const response = await this.apiOrdersIdInvoiceCorrectionGetRaw({ id: id });
        return await response.value();
    }

    /**
     * Create invoice correction for paid order
     */
    async apiOrdersIdInvoiceCorrectionPutRaw(requestParameters: ApiOrdersIdInvoiceCorrectionPutRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdInvoiceCorrectionPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}/invoiceCorrection`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create invoice correction for paid order
     */
    async apiOrdersIdInvoiceCorrectionPut(id: string): Promise<string> {
        const response = await this.apiOrdersIdInvoiceCorrectionPutRaw({ id: id });
        return await response.value();
    }

    /**
     * Get invoice for given order
     */
    async apiOrdersIdInvoiceGetRaw(requestParameters: ApiOrdersIdInvoiceGetRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdInvoiceGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}/invoice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get invoice for given order
     */
    async apiOrdersIdInvoiceGet(id: string): Promise<Blob> {
        const response = await this.apiOrdersIdInvoiceGetRaw({ id: id });
        return await response.value();
    }

    /**
     * Create invoice for paid order
     */
    async apiOrdersIdInvoicePutRaw(requestParameters: ApiOrdersIdInvoicePutRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersIdInvoicePut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders/{id}/invoice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create invoice for paid order
     */
    async apiOrdersIdInvoicePut(id: string): Promise<string> {
        const response = await this.apiOrdersIdInvoicePutRaw({ id: id });
        return await response.value();
    }

    /**
     * Create or update entity of Order (if id defined)
     */
    async apiOrdersPostRaw(requestParameters: ApiOrdersPostRequest): Promise<runtime.ApiResponse<Order>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderToJSON(requestParameters.order),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Order (if id defined)
     */
    async apiOrdersPost(relations?: string, deleted?: boolean, order?: Order): Promise<Order> {
        const response = await this.apiOrdersPostRaw({ relations: relations, deleted: deleted, order: order });
        return await response.value();
    }

    /**
     * Create entity of Order
     */
    async apiOrdersPutRaw(requestParameters: ApiOrdersPutRequest): Promise<runtime.ApiResponse<Order>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderToJSON(requestParameters.order),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Create entity of Order
     */
    async apiOrdersPut(order?: Order): Promise<Order> {
        const response = await this.apiOrdersPutRaw({ order: order });
        return await response.value();
    }

    /**
     * Get entity of Order
     */
    async apiOrdersUuidGetRaw(requestParameters: ApiOrdersUuidGetRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiOrdersUuidGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Get entity of Order
     */
    async apiOrdersUuidGet(uuid: string, relations?: string, deleted?: boolean): Promise<Order> {
        const response = await this.apiOrdersUuidGetRaw({ uuid: uuid, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Get order payment link
     */
    async apiOrdersUuidPaymentGetRaw(requestParameters: ApiOrdersUuidPaymentGetRequest): Promise<runtime.ApiResponse<RedirectResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiOrdersUuidPaymentGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.redirect !== undefined) {
            queryParameters['redirect'] = requestParameters.redirect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/orders/{uuid}/payment`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectResponseFromJSON(jsonValue));
    }

    /**
     * Get order payment link
     */
    async apiOrdersUuidPaymentGet(uuid: string, redirect?: string): Promise<RedirectResponse> {
        const response = await this.apiOrdersUuidPaymentGetRaw({ uuid: uuid, redirect: redirect });
        return await response.value();
    }

}
