import React, { useEffect } from 'react';
import styles from './menuList.module.scss';
import MenuItem from '../menuItem/menuItem';
import { MenuItemWithProduct } from 'src/logic/menu';
import { DateString } from 'src/logic/utils';
import { gtmViewItemList, setItemToGtmItem } from 'src/logic/gtm';

type MenuListProps = {
  setList?: boolean;
  special?: 'xmas' | 'help' | 'easter';
  menuItems: MenuItemWithProduct[];
  title: string;
  loading: boolean;
  itemLimit: number;
  activeDate: DateString | null;
};

const MenuList = ({ setList, special, menuItems, title, loading, itemLimit, activeDate }: MenuListProps) => {
  const gtmItems = menuItems.map(menuItem => { return setItemToGtmItem(menuItem) }); 
  useEffect(() => gtmViewItemList(gtmItems), [activeDate]);

  return (
  <section className={special ?? ''}>
    <h1 className={styles.sectionTitle}>{title}</h1>
    {special === 'help'  && <h3>Pomoc 1+1! Ty kupujesz jeden posiłek dla Uchodźcy, my sponsorujemy drugi. Możesz dorzucić danie dla Uchodźcy do swojego zamówienia lub zrobić osobne zamówienie. W przypadku, gdy ograniczysz się jedynie do pomocy to w koszyku wpisz kod: <b>POMOC</b>. Posiłki dostarczamy Ukraińcom, którzy przebywają na terenie hali Ptak Warsaw Expo w Nadarzynie. </h3>}
    <div className={setList ? styles.menuSetsList : styles.menuItemsList}>
      {menuItems.map((menuItem, i) => (
        <MenuItem
          key={i}
          item={menuItem}
          loading={loading}
          itemLimit={itemLimit}
          setItem={!!setList}
          activeDate={activeDate}
        />
      ))}
    </div>
  </section>
)};
export default MenuList;
