import cn from 'classnames';
import React, { Component, ReactNode } from 'react';
import styles from './button.module.scss';

export type ButtonProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => any;
  outline?: boolean;
  color?: 'neutral' | 'primary' | 'lightgray' | 'transparent' | 'danger' | 'warn' | 'success';
  size?: 'small' | 'medium' | 'big';
};

export class Button extends Component<ButtonProps> {
  public render() {
    const { color = 'neutral', size = 'medium', className, children, outline = false, ...rest } = this.props;
    const classNames = cn(styles.button, {
      [styles.primary]: color === 'primary',
      [styles.neutral]: color === 'neutral',
      [styles.lightgray]: color === 'lightgray',
      [styles.transparent]: color === 'transparent',
      [styles.danger]: color === 'danger',
      [styles.success]: color === 'success',
      [styles.warn]: color === 'warn',
      [styles.small]: size === 'small',
      [styles.medium]: size === 'medium',
      [styles.big]: size === 'big',
      [styles.outline]: outline,
    }, className);
    return (
      <button className={classNames} {...rest}>
        {children}
      </button>
    );
  }
}
