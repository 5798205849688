import { History } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { userReducer as user } from './user'
import { addressesReducer as addresses } from './addresses'
import { reducer as admin } from './admin'
import { menuReducer as menu } from './menu'
import { basketReducer as basket } from './basket'
import { settingsReducer as settings } from './settings'
import { orderReducer as order } from './order'
import { walletReducer as wallet } from './wallet'

export const createReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  user,
  addresses,
  admin,
  menu,
  basket,
  order,
  settings,
  wallet,
});

export type GlobalState = ReturnType<ReturnType<typeof createReducer>>;
