import React from 'react';
import styles from './deliveryInfo.module.scss';
import { Address, Location } from 'src/api';
import { getDeliveryHours } from 'src/logic/addresses';

type DeliveryInfoProps = {
  address: Address | Location;
  person: string;
  phone: string;
  notes: string;
  deliveryTo6: boolean;
  deliveryTo7: boolean;
};
const DeliveryInfo = ({
  address,
  person,
  phone,
  notes,
  deliveryTo6,
  deliveryTo7
}: DeliveryInfoProps) => {

  return (
    <div className={styles.deliveryInfo}>
      <section>
        <div className={styles.fieldBig}>{person}</div>
        <div className={styles.field}>tel. {phone}</div>
      </section>
      <section>
        <div className={styles.field}>
          {address.street || ''} {address.local ? `${address.house}/${address.local}` : address.house}
        </div>
        <div className={styles.field}>{address.postalcode ?? '00-000'} {address.city}</div>
        <div className={styles.notes}>{notes}</div>
      </section>
      <section>
        <div className={styles.fieldMedium}>{getDeliveryHours()} </div>
        {deliveryTo6 && (
          <div className={styles.notes}>
            Proszę o dostawę do godz 6:00
          </div>
        )}
        {deliveryTo7 && (
          <div className={styles.notes}>
            Proszę o dostawę do godz 7:00
          </div>
        )}
      </section>
    </div>
  );
};
export default DeliveryInfo;
