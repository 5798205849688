import { useState, useEffect } from 'react';

function getwindowSize() {
  const { innerWidth: width, innerHeight: height, devicePixelRatio } = window;
  
  const pixelRatio = (width < 768) ? 1: devicePixelRatio;
  return {
    width,
    height,
    pixelRatio
  };
}

export default function useWindowSize() {
  const [windowSize, setwindowSize] = useState(getwindowSize());

  useEffect(() => {
    function handleResize() {
        setwindowSize(getwindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}