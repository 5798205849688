import { call, apply, put, takeLatest, select } from 'redux-saga/effects';
import { ActionType, createAction, getType, createAsyncAction } from 'typesafe-actions';
import qs from 'qs';
import { User, UserFromJSON, AuthApi, SignResponse, SignInRequest, InlineResponse403, UserApi, ApiResponse, CommonApi } from 'src/api';
import { replace } from 'connected-react-router';
import { setToken, getToken, getApi } from './utils';
import { GlobalState } from 'src/logic/reducers';
import { getRelations } from './utils/apiConfig';
import { SignUpRequest } from '../api/models/SignUpRequest';
import { ResetGenerateRequest } from '../api/models/ResetGenerateRequest';
import { ResetExecuteRequest } from '../api/models/ResetExecuteRequest';
import { toast } from 'react-toastify';
import { addressesActions } from './addresses';
import { FCM_KEY } from 'src/components/device/device';
import { isString } from 'lodash';
import { gtmDeviceType, gtmLogin, gtmRegister } from './gtm';

export type UserWithPassword = User & { password?: string; confirmPassword?: string };
type UnsubscribeParams = { code: string; email: string; andThen: (success: boolean) => void };

export const userActions = {
  check: createAction('check user auth status')<string | void>(),
  load: createAsyncAction('load user', 'load user success', 'load user failure')<void, User, Error>(),
  update: createAsyncAction('update user', 'update user success', 'update user failure')<UserWithPassword, User, Error>(),
  login: createAction('login user')<SignInRequest & { andThen?: (error?: string) => void }>(),
  loginFailure: createAction('login user failure')<string[]>(),
  loginSuccess: createAction('login user success')<User>(),
  register: createAsyncAction('register', 'register success', 'register failure')<SignUpRequest & { andThen?: (error?: string) => void }, void, Error>(),
  resetPassword: createAsyncAction('reset password', 'reset password success', 'reset password failure')<ResetGenerateRequest & { andThen?: (error?: string) => void }, void, Error>(),
  setPassword: createAsyncAction('set password', 'set password success', 'set password failure')<ResetExecuteRequest & { code: string; andThen?: (error?: string) => void }, void, Error>(),
  verifyAccount: createAsyncAction('verify account', 'verify account success', 'verify account failure')<{ code: string; andThen?: (error?: string) => void }, void, Error>(),
  logout: createAction('logout')<void>(),
  logoutComplete: createAction('logout complete')<void>(),
  contact: createAction('contact')<{ email: string; subject: string; message: string; andThen?: (error?: string) => void }>(),
  loadUserSettings: createAction('load user settings')<void>(),
  loadUserSettingsFinished: createAction('load user settings finished')<UserSettings>(),
  confirmCookies: createAction('confirm cookies policy')<void>(),
  unsubscribe: createAction('unsubscribe')<UnsubscribeParams>(),
  registerDevice: createAction('register device')<{ token: string; deviceType: string }>(),
  loadDeviceType: createAction('load device type')<void>(),
  setDeviceType: createAction('set device type')<string>(),
  delete: createAction('delete yser account')<string>(),
};

export type UserActions = ActionType<typeof userActions>;

// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
const b64DecodeUnicode = (str: string): string => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export const parseJwt = <T extends {}>(token: string): T => {
  const [, base64Url] = token.split('.');
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(b64DecodeUnicode(base64));
};

type UserSettings = {
  cookiesConfirmed: boolean;
};

type State = {
  details: User | null;
  error: string[] | null;
  pending: boolean;
  deviceType: null | string;
  userSettings: UserSettings;
}

const initialState: State = {
  details: null,
  error: null,
  pending: true,
  deviceType: null,
  userSettings: { cookiesConfirmed: false },
};

const mapError = (error: string | undefined, defaultError: string): string => {
  if (!error) {
    return defaultError;
  }

  switch (error) {
    case 'Invalid credentials': return 'Niepoprawne dane logowania';
    case 'Account inactive': return 'Twoje konto nie jest aktywne, link aktywacyjny został wysłany na maila';
    case 'Validation error: Validation isEmail on email failed': return 'Niepoprawny adres email';
    case 'Address already used': return 'Adres już istnieje';
    case 'Invalid email': return 'Niepoprawny adres email';
    case 'Invalid code': return 'Kod jest niepoprawny, wygasł lub został już wykorzystany wcześniej';
    default: return defaultError;
  }
}


const tryGetErrorMsg = async (e: any, defaultError: string): Promise<string> => {
  try {
    const json = await (e as Response).json() as InlineResponse403;
    return mapError(json.error, defaultError);
  } catch (e) {
    return defaultError;
  }
}



export const userReducer = (state: State = initialState, action: UserActions) => {
  switch (action.type) {
    case getType(userActions.check): return { ...state, pending: true, details: null };

    case getType(userActions.load.request): return { ...state, pending: true };
    case getType(userActions.load.success): return { ...state, pending: false, details: action.payload };
    case getType(userActions.load.failure): return { ...state, pending: false };

    case getType(userActions.update.request): return { ...state, pending: true };
    case getType(userActions.update.success): return { ...state, pending: false, details: action.payload };
    case getType(userActions.update.failure): return { ...state, pending: false };

    case getType(userActions.loginSuccess): return { ...state, pending: false, details: action.payload };
    case getType(userActions.loginFailure): return { ...state, pending: false, details: null };
    case getType(userActions.logoutComplete): return { ...state, pending: false, details: null };

    case getType(userActions.confirmCookies): return { ...state, userSettings: { ...state.userSettings, cookiesConfirmed: true } };
    case getType(userActions.loadUserSettings): return { ...state };
    case getType(userActions.loadUserSettingsFinished): return { ...state, userSettings: action.payload };
    case getType(userActions.registerDevice): return { ...state, deviceType: action.payload.deviceType };
    case getType(userActions.setDeviceType): return { ...state, deviceType: action.payload };

    default: return state;
  }
};

function* onLoad() {
  const api: UserApi = yield call(getApi, UserApi);
  const userId: number = yield select((gs: GlobalState) => gs.user.details?.id);
  if (!userId) {
    yield put(userActions.load.failure(new Error('User is not logged in')));
    return;
  }
  try {
    const user: User = yield apply(api, api.apiUsersIdGet, [userId, getRelations<User>({ roles: true, locations: true, addresses: true }), false]);
    yield put(userActions.load.success(user));
  } catch (e) {
    yield put(userActions.load.failure(e));
  }
}

function* onUpdate({ payload }: ReturnType<typeof userActions.update.request>) {
  const api: UserApi = yield call(getApi, UserApi);

  try {
    const toUpdate = { ...payload };
    if (!toUpdate.password) {
      delete toUpdate['password'];
    }
    const response: ApiResponse<User> = yield apply(api, api.apiUsersPostRaw, [{ relations: getRelations<User>({ roles: true, addresses: true, locations: true }), deleted: false, entity: JSON.stringify(toUpdate) as any }]);
    const user: User = yield apply(response, response.value, []);
    yield put(userActions.update.success(user));
  } catch (e) {
    yield put(userActions.update.failure(e));
  }
}

function* onLogin({ payload: { andThen, ...signInParams } }: ReturnType<typeof userActions.login>) {
  const api: AuthApi = yield call(getApi, AuthApi);
  try {
    const response: SignResponse = yield apply(api, api.authSigninPost, [signInParams]);
    yield put(userActions.loginSuccess(UserFromJSON(parseJwt(response.token))));
    yield call(setToken, response.token);
    yield put(addressesActions.load.request());
    if (andThen) { yield call(andThen); }
    gtmLogin();
  } catch (e) {
    const error: string = yield tryGetErrorMsg(e, 'Problem z logowaniem');
    if (andThen) {
      yield call(andThen, error);
    }
    yield put(userActions.loginFailure(e));
  }
}

function* onRegister({ payload: { andThen, ...signUpParams } }: ReturnType<typeof userActions.register.request>) {
  const api: AuthApi = yield call(getApi, AuthApi);
  try {
    const response: SignResponse = yield apply(api, api.authSignupPost, [signUpParams]);
    yield put(userActions.register.success());
    yield put(userActions.loginSuccess(UserFromJSON(parseJwt(response.token))));
    yield call(setToken, response.token);
    yield put(addressesActions.load.request());
    if (andThen) { yield call(andThen); }
    gtmRegister();
  } catch (e) {
    const error: string = yield tryGetErrorMsg(e, 'Problem z rejestracją');
    if (andThen) {
      yield call(andThen, error);
    }
    yield put(userActions.register.failure(e));
  }
}

function* onResetPassword({ payload: { andThen, ...resetParams } }: ReturnType<typeof userActions.resetPassword.request>) {
  const api: UserApi = yield call(getApi, UserApi);
  try {
    yield apply(api, api.apiUsersResetPost, [resetParams]);
    yield put(userActions.resetPassword.success());
    if (andThen) { yield call(andThen); }
  } catch (e) {
    const error: string = yield tryGetErrorMsg(e, 'Nie można zresetować hasła');
    if (andThen) {
      yield call(andThen, error);
    }
    yield put(userActions.resetPassword.failure(e));
  }
}

function* onSetPassword({ payload: { andThen, code, ...setParams } }: ReturnType<typeof userActions.setPassword.request>) {
  const api: UserApi = yield call(getApi, UserApi);
  try {
    const signResponse: SignResponse = yield apply(api, api.apiUsersResetCodePost, [code, setParams]);
    yield put(userActions.check(signResponse.token));
    yield put(userActions.resetPassword.success());
    if (andThen) { yield call(andThen); }
  } catch (e) {
    const error: string = yield tryGetErrorMsg(e, 'Nie można ustawić hasła');
    if (andThen) {
      yield call(andThen, error);
    }
    yield put(userActions.resetPassword.failure(e));
  }
}

function* onVerifyAccount({ payload: { andThen, code } }: ReturnType<typeof userActions.verifyAccount.request>) {
  const api: UserApi = yield call(getApi, UserApi);
  try {
    const signResponse: SignResponse = yield apply(api, api.apiUsersVerifyCodeGet, [code]);
    yield put(userActions.check(signResponse.token));
    yield put(userActions.verifyAccount.success());
    if (andThen) { yield call(andThen); }
  } catch (e) {
    const error: string = yield tryGetErrorMsg(e, 'Nie można zweryfikować konta');
    if (andThen) {
      yield call(andThen, error);
    }
    yield put(userActions.verifyAccount.failure(e));
  }
}

function* onLogout() {
  yield call(setToken, '');
  yield put(userActions.logoutComplete());
}

function* onCheck({ payload }: ReturnType<typeof userActions.check>) {
  const api: AuthApi = yield call(getApi, AuthApi);
  if (typeof payload === 'string') {
    yield put(userActions.loginSuccess(UserFromJSON(parseJwt(payload))));
    yield call(setToken, payload);
    return;
  }
  const { token, ...searchRest }: { token?: string } = qs.parse(window.location.search.slice(1));
  if (token) {
    yield put(userActions.loginSuccess(UserFromJSON(parseJwt(token))));
    const params = qs.stringify(searchRest);
    yield put(replace(`${window.location.pathname}${params ? `?${params}` : ''}`));
    yield call(setToken, token);
    return;
  }
  if (getToken()) {
    try {
      const response: SignResponse = yield apply(api, api.authRefreshGet, []);
      yield put(userActions.check(response.token));
    } catch (e) {
      yield put(userActions.logout());
    }
  }
}


function* onContact({ payload: { andThen, ...payload } }: ReturnType<typeof userActions.contact>) {
  const api: CommonApi = yield call(getApi, CommonApi);
  try {
    yield apply(api, api.apiContactPost, [payload]);
    if (andThen) {
      yield call(andThen);
    }
  } catch (e) {
    if (andThen) {
      yield call(andThen, 'Nie udało się wysłać wiadomości');
    }
    yield call(toast.error, 'Nie udało się wysłać wiadomości');
  }
}

function* onRegisterDevice({ payload: { token, deviceType } }: ReturnType<typeof userActions.registerDevice>) {
  const api: CommonApi = yield call(getApi, CommonApi);
  try {
    yield apply(api, api.apiRegisterDevicePost, [{ token, deviceType }]);
  } catch (e) {
    console.error(e);
  }
}

function* onConfirmCookies() {
  const userSettings = yield select((gs: GlobalState) => gs.user.userSettings);
  yield apply(localStorage, localStorage.setItem, ['userSettings', JSON.stringify(userSettings)]);
}

function* onLoadUserSettings() {
  const rawUserSettings = yield apply(localStorage, localStorage.getItem, ['userSettings']);
  try {
    const userSettings: UserSettings = rawUserSettings ? JSON.parse(rawUserSettings) : initialState.userSettings;
    yield put(userActions.loadUserSettingsFinished(userSettings));
  } catch (e) {
    /** */
  }
}

function* onLoadDeviceType() {
  const rawFCM = yield apply(localStorage, localStorage.getItem, [FCM_KEY]);
  if (!rawFCM) {
    return;
  }
  try {
    const deviceType: string = JSON.parse(rawFCM).deviceType;
    if (isString(deviceType)) {
      yield call(gtmDeviceType, deviceType)
      yield put(userActions.setDeviceType(deviceType));
    } else {
      yield call(gtmDeviceType, 'web')
    }
  } catch (e) {
    /** */
  }
}

function* onUnsubscribe({ payload: { code, email, andThen } }: ReturnType<typeof userActions.unsubscribe>) {
  const api: UserApi = yield call(getApi, UserApi);
  try {
    yield apply(api, api.apiUsersUnsubscribePost, [{ code, email }])
    yield call(andThen, true);
  } catch (e) {
    yield call(andThen, false);
  }
}


function* onDelete({ payload }: ReturnType<typeof userActions.delete>) {
  const api: AuthApi = yield call(getApi, AuthApi);
  try {
    const { status }: { status: string } = yield apply(api, api.authDeletePost, [{ password: payload }])
    if (status === 'ok') {
      yield put(userActions.logout())
    }
  } catch (e) {
    console.error('sth goes wrong', e)
  }
}
export function* userSaga(): IterableIterator<any> {
  yield takeLatest(getType(userActions.check), onCheck);
  yield takeLatest(getType(userActions.load.request), onLoad);
  yield takeLatest(getType(userActions.update.request), onUpdate);
  yield takeLatest(getType(userActions.login), onLogin);
  yield takeLatest(getType(userActions.register.request), onRegister);
  yield takeLatest(getType(userActions.resetPassword.request), onResetPassword);
  yield takeLatest(getType(userActions.setPassword.request), onSetPassword);
  yield takeLatest(getType(userActions.verifyAccount.request), onVerifyAccount);
  yield takeLatest(getType(userActions.logout), onLogout);
  yield takeLatest(getType(userActions.contact), onContact);
  yield takeLatest(getType(userActions.confirmCookies), onConfirmCookies);
  yield takeLatest(getType(userActions.loadUserSettings), onLoadUserSettings);
  yield takeLatest(getType(userActions.unsubscribe), onUnsubscribe);
  yield takeLatest(getType(userActions.registerDevice), onRegisterDevice);
  yield takeLatest(getType(userActions.loadDeviceType), onLoadDeviceType);
  yield takeLatest(getType(userActions.delete), onDelete);
}
