import React from 'react';
import ContentPage from './contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import IconParagraph from './iconParagraph/iconParagraph';
import styles from './contentPage/contentPage.module.scss';

const Safety = () => (
  <ContentPage>
    <PageWrapper>
      <div className="max960">
        <h1 className="h1">Bezpieczeństwo</h1>
        <h2 className="h2 black50">
          Naszym głównym priorytetem jest bezpieczeństwo Wasze i naszych
          pracowników.
        </h2>
        <h3>
          W związku z zaistniałą sytuacją chcemy Was zapewnić o przestrzeganiu
          najwyższych norm i standardów, jeśli chodzi o bezpieczeństwo
          produkcji, jak i cały łańcuch dostaw. Dlatego…
        </h3>
      </div>
      <div className={styles.columns}>
        <div>
          <IconParagraph image="/images/termometr.svg">
            Codziennie monitorujemy stan zdrowia pracowników, każdy pracownik
            przed wejściem na teren zakładu ma mierzoną temperaturę ciała.
          </IconParagraph>
          <IconParagraph image="/images/fartuch.svg">
            Każdy pracownik kuchni jest wyposażony w kompletny strój ochronny,
            to jest odzież roboczą, czepek jednorazowy, obuwie ochronne,
            rękawiczki jednorazowe, maseczki lub przyłbice.
          </IconParagraph>
          <IconParagraph image="/images/oko.svg">
            Zakład produkcyjny jest pod nadzorem stacji
            sanitarno-epidemiologicznej, dodatkowo korzystamy z zewnętrznej
            firmy audytorskiej, która na bieżąco monitoruje przestrzeganie
            wszystkich obostrzeń i zaleceń.
          </IconParagraph>
          <IconParagraph image="/images/dlon.svg">
            Przed wejściem do zakładu każdy pracownik ma obowiązek zdezynfekować
            ręce. Przed zakładem, jak i w kilku strategicznych miejscach na
            terenie zakładu rozmieściliśmy dyspensery do dezynfekcji rąk.
          </IconParagraph>
          </div>
          <div>          
          <IconParagraph image="/images/spray.svg">
            Na teren części produkcyjnej zakładu nie mają wstępu żadne osoby z
            zewnątrz. Przeprowadzamy codzienną dezynfekcję pomieszczeń.
          </IconParagraph>
          <IconParagraph image="/images/maska.svg">
            Nasi dostawcy wyposażeni są zawsze w kompletny strój, to jest odzież
            roboczą, maseczkę ochronną, rękawiczki jednorazowe.
          </IconParagraph>
          <IconParagraph image="/images/poddrzwi.svg">
            Dostawy on-line odbywają się w sposób bezkontaktowy, a nasi dostawcy
            po każdym punkcie dezynfekują ręce. Staramy się, aby wśród dostawców
            była możliwie mała rotacja, tak aby, produkty dostarczała Państwu
            zawsze ta sama osoba.
          </IconParagraph>
        </div>
      </div>
    </PageWrapper>
  </ContentPage>
);

export default Safety;
