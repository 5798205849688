import { call, apply, put, takeLatest, select } from 'redux-saga/effects';
import { ActionType, getType, createAsyncAction, createAction } from 'typesafe-actions';
import { WalletHistoryApi, WalletHistory, UserApi } from 'src/api'
import { getApi, getRelations } from './utils';
import { GlobalState } from 'src/logic/reducers';
import { UserPayoutRequest } from '../api/models/UserPayoutRequest';
import { toast } from 'react-toastify';
import { WalletHistoryDataFrame } from '../api/models/WalletHistoryDataFrame';
import { getFilter } from './utils/apiConfig';

export const walletActions = {
  load: createAsyncAction('get all wallet history items', 'get all wallet history items success', 'get all wallet history items failure')<void, WalletHistory[], Error>(),
  requestPayout: createAction('request payout')<UserPayoutRequest>(),
};
export type WalletActions = ActionType<typeof walletActions>;

type State = {
  list: WalletHistory[];
  pending: boolean;
}

const initialState: State = {
  list: [],
  pending: true,
};

export const walletReducer = (state: State = initialState, action: WalletActions): State => {
  switch (action.type) {
    case getType(walletActions.load.request): return { ...state, pending: true };
    case getType(walletActions.load.success): return { ...state, pending: false, list: action.payload };
    case getType(walletActions.load.failure): return { ...state, pending: false, list: initialState.list };
    default: return state;
  }
};

function* onWalletGetAll() {
  const api: WalletHistoryApi = yield call(getApi, WalletHistoryApi);
  const userId: number | undefined = yield select((gs: GlobalState) => gs.user.details?.id);
  try {
    const response: WalletHistoryDataFrame = yield apply(api, api.apiWalletHistoriesGet, [getRelations<WalletHistory>({ order: true, orderMenu: true }), true, getFilter<WalletHistory>({userId: {eq: userId}})]);
    yield put(walletActions.load.success(response.data.filter(w => w.userId === userId)));
  } catch (e) {
    yield put(walletActions.load.failure(e));
  }
}

function* onRequestPayout({ payload }: ReturnType<typeof walletActions.requestPayout>) {
  const api: UserApi = yield call(getApi, UserApi);
  try {
    yield apply(api, api.apiUsersPayoutPost, [payload]);
    yield put(walletActions.load.request());
  } catch (e) {
    yield call(toast.error, 'Wypłata się nie powiodła, spróbuj ponownie później.');
  }
}

export function* walletSaga(): IterableIterator<any> {
  yield takeLatest(getType(walletActions.load.request), onWalletGetAll);
  yield takeLatest(getType(walletActions.requestPayout), onRequestPayout);
}
