import { call, apply, put, takeLatest, select } from 'redux-saga/effects';
import { ActionType, getType, createAsyncAction, createAction } from 'typesafe-actions';
import { OrderApi, Order, OrderMenu, OrderMenuItem, AddressApi, Address, Location, Voucher, RedirectResponse, DeliveryApi } from 'src/api';
import { getApi, getPackagePrice } from './utils';
import { GlobalState } from 'src/logic/reducers';
import { Basket } from './basket';
import { BasketItem, basketActions } from 'src/logic/basket';
import { fromPairs, groupBy } from 'lodash';
import { ProductPackage, State as SettingsState } from './settings'
import { toast } from 'react-toastify';
import { routes } from 'src/routes';
import { push } from 'connected-react-router';
import { getRelations, getFilter } from './utils/apiConfig';
import { trackPurchase } from './analytics';
import { OrderDataFrame } from '../api/models/OrderDataFrame';
import { isSunday } from 'date-fns';

type CreateOrderPayload = {
  invoice?: Address;
  userName: string;
  userLastName: string;
  phone: string;
  email: string;
  companyName: string;
  password: string;
  marketingAgreement: boolean;
} & (
    { address: Address } | { location: Location }
  )
  
export type PostalCode = {
  postalCode: string;
  isAvailable: boolean;
}

export const orderActions = {
  create: createAsyncAction('create order', 'create order success', 'create order failure')<CreateOrderPayload, void, Error>(),
  pay: createAction('pay for order')<[string]>(),
  cancel: createAction('cancel order')<string>(),
  cancelMenu: createAction('cancel order menu')<[string, string]>(),
  editMenu: createAction('edit order menu')<[string, string]>(),
  loadAll: createAsyncAction('load all orders', 'load all orders success', 'load all orders failure')<void, Order[], Error>(),
  load: createAsyncAction('load order', 'load order success', 'load order failure')<string, Order, Error>(),
  downloadInvoice: createAction('download invoice')<{ id: number; isCorrection?: boolean }>(),
  checkPostalCode: createAsyncAction('check postal code', 'check postal code success', 'check postal code failure')<string, PostalCode, Error>(),
};

export type OrderActions = ActionType<typeof orderActions>;

type State = {
  details: Order | null;
  list: Order[];
  pending: boolean;
  postalCodes: PostalCode[];
}

const initialState: State = {
  pending: false,
  list: [],
  details: null,
  postalCodes: [],
};

export const orderReducer = (state: State = initialState, action: OrderActions): State => {
  switch (action.type) {
    case getType(orderActions.create.request): return { ...state, pending: true };
    case getType(orderActions.create.success): return { ...state, pending: false };
    case getType(orderActions.create.failure): return { ...state, pending: false };

    case getType(orderActions.loadAll.request): return { ...state, pending: true };
    case getType(orderActions.loadAll.success): return { ...state, pending: false, list: action.payload };
    case getType(orderActions.loadAll.failure): return { ...state, pending: false, list: initialState.list };

    case getType(orderActions.load.request): return { ...state, pending: true };
    case getType(orderActions.load.success): return { ...state, pending: false, details: action.payload };
    case getType(orderActions.load.failure): return { ...state, pending: false, details: null };

    case getType(orderActions.checkPostalCode.success): {
      const payload = (action as ReturnType<typeof orderActions.checkPostalCode.success>).payload
      return { ...state, postalCodes: [...state.postalCodes, payload] };
    }
    default: return state;
  }
};

function* onCreate({ payload }: ReturnType<typeof orderActions.create.request>) {
  const addressApi: AddressApi = yield call(getApi, AddressApi);
  const api: OrderApi = yield call(getApi, OrderApi);

  const userId: number | undefined = yield select((gs: GlobalState) => gs.user.details?.id);
  const basket: Basket = yield select((gs: GlobalState) => gs.basket.basket);
  const vouchers: Voucher[] = yield select((gs: GlobalState) => gs.basket.vouchers);
  const { DELIVERY_HOME, FREE_DELIVERY, CUTLERY_COST }: SettingsState = yield select((gs: GlobalState) => gs.settings);

  const allItems: BasketItem[] = Object.values(basket).flatMap(basket => basket.items);
  const cutleryMap = fromPairs(Object.values(basket).flatMap((basket) => basket.items.map(i => ([i.menuId, basket.cutlery]))));
  if ('address' in payload && userId) {
    yield apply(addressApi, addressApi.apiAddressesPost, [getRelations<Address>({}), false, { ...payload.address, userId }]);
  }

  if (payload.invoice && userId) {
    yield apply(addressApi, addressApi.apiAddressesPost, [getRelations<Address>({}), false, { ...payload.invoice, userId }])
  }

  const groupedItems = groupBy(allItems, (i) => i.menuId);
  const menus: OrderMenu[] = Object.entries(groupedItems).map(([menuId, items]: [string, BasketItem[]]) => {
    const price = items.map(i => i.price * i.quantity).reduce((a, b) => a + b, 0);
    const menu: OrderMenu = {
      price,
      delivery: price >= FREE_DELIVERY ? 0 : DELIVERY_HOME,
      menuId: parseInt(menuId, 10),
      locationId: 'location' in payload ? payload.location.id : undefined,
      address: 'address' in payload ? payload.address : undefined,
      cutlery: cutleryMap[menuId] ?? 0,
      cutleryCost: CUTLERY_COST,
      items: items.map((itm): OrderMenuItem => {
        return {
          price: itm.price,
          quantity: itm.quantity,
          menuItemId: itm.menuItemId
        }
      })
    }
    return menu;
  });

  const price = menus.map(i => (i.price ?? 0) + (i.delivery ?? 0)).reduce((a, b) => a + b, 0);

  const order: Order = {
    price,
    menus,
    invoice: payload.invoice,
    user: {
      email: payload.email,
      name: payload.userName,
      phone: payload.phone,
      lastName: payload.userLastName,
      password: payload.password,
      termsAgreement: true,
      marketingAgreement: payload.marketingAgreement,
    },
    vouchers: vouchers.map(({ id, code }) => ({ id, code }))
  }

  let createdOrder: Order | null = null;

  try {
    createdOrder = yield apply(api, api.apiOrdersPut, [order]);
  } catch (e) {
    if(e instanceof Response){
      const result: { error?: string } = yield e.json();
      if(result?.error?.match('too late to order menu')) {
        const message = isSunday(new Date()) ? 'Zamówienie na poniedziałek przyjmujemy do godziny 12:00': 'Zamówienia na kolejny dzień przyjmujemy do godziny 12:00'
        yield call(toast.error,  message);
        return;
      }
    }
    yield call(toast.error, 'Nie udało się utworzyć zamówienia');
    yield put(orderActions.create.failure(new Error(`${e}`)));
    return;
  }
  if(order){
    yield call(trackPurchase, order, basket);
  }
  yield put(orderActions.pay([createdOrder?.uuid ?? '']));
  yield put(basketActions.clear());
}

function* onPay({ payload }: ReturnType<typeof orderActions.pay>) {
  const api: OrderApi = yield call(getApi, OrderApi);
  const [uuid] = payload;
  try {
    const pay: RedirectResponse = yield apply(api, api.apiOrdersUuidPaymentGet, [`${uuid}`, routes.order(uuid, 'paid')]);
    window.location.href = pay.redirect ?? '';
  } catch (e) {
    yield put(push(routes.order(uuid, 'paymentFailed')));
  }
}

function* onDownloadInvoice({ payload }: ReturnType<typeof orderActions.downloadInvoice>) {
  const api: OrderApi = yield call(getApi, OrderApi);
  const { id, isCorrection } = payload;
  try {

    const data: Blob = yield apply(api, isCorrection ? api.apiOrdersIdInvoiceCorrectionGet : api.apiOrdersIdInvoiceGet, [`${id}`]);
    const aElement = document.createElement('a');
    aElement.setAttribute('download', isCorrection? 'invoice correction.pdf' : 'invoice.pdf');
    const href = URL.createObjectURL(data);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
  } catch (e) {
    console.error('downnload invoice failed',e )
  }
}

function* onCancel({ payload }: ReturnType<typeof orderActions.cancel>) {
  const api: OrderApi = yield call(getApi, OrderApi);

  try {
    yield apply(api, api.apiOrdersIdCancelPut, [payload]);
    yield put(orderActions.load.request(payload));
  } catch (e) {
    yield call(toast.error, `Wystąpił problem podczas anulowania zamówienia`);
  }
}

function* onCancelMenu({ payload }: ReturnType<typeof orderActions.cancelMenu>) {
  const api: OrderApi = yield call(getApi, OrderApi);

  try {
    yield apply(api, api.apiOrdersIdCancelOrderMenuIdPut, payload);
    yield put(orderActions.load.request(`${payload[0]}`));
  } catch (e) {
    yield call(toast.error, `Wystąpił problem podczas anulowania dostawy`);
  }
}

function* onEditMenu({ payload }: ReturnType<typeof orderActions.editMenu>) {
  const api: OrderApi = yield call(getApi, OrderApi);

  const order: Order = yield select((gs: GlobalState) => gs.order.details);
  const packages: ProductPackage[] = yield select((gs: GlobalState) => gs.settings.PRODUCT_PACKAGE);
  const orderMenu = order.menus?.find(m => `${m.id}` === payload[1]);
  yield put(basketActions.clear());
  for (const itm of orderMenu?.items ?? []) {
    yield put(basketActions.modifyBasket({
      productId: itm.menuItem?.product?.id ?? 0,
      productName: itm.menuItem?.product?.name ?? '',
      productCategory: itm?.menuItem?.product?.category?.name ?? '',
      productCategoryId: itm?.menuItem?.product?.category?.id ?? 0,
      productDescription: itm.menuItem?.product?.description ?? '',
      date: orderMenu?.menu?.date ?? new Date(),
      quantity: itm.quantity ?? 0,
      price: itm.price ?? 0,
      discountedPrice: itm.price ?? 0,
      menuId: orderMenu?.menuId ?? 0,
      menuItemId: itm?.menuItemId ?? 0,
      itemLimit: 999999,
      productStock: itm.menuItem?.quantityStock ?? 0,
      productInfo: {
        calories: itm.menuItem?.product?.calories ?? 0,
        protein: itm.menuItem?.product?.protein ?? 0,
        carbs: itm.menuItem?.product?.carbs ?? 0,
        fat: itm.menuItem?.product?.fat ?? 0,
        weight: itm.menuItem?.product?.weight ?? 0
      },
      package: { name: itm?.menuItem?.product?._package || '', value: itm?.menuItem?.product?._package ? getPackagePrice(packages, itm?.menuItem?.product?._package) : 0 },
    }))
  }
  try {
    yield apply(api, api.apiOrdersIdCancelOrderMenuIdPut, payload);
    yield put(orderActions.load.request(`${payload[0]}`));
    yield put(push(routes.menuBasket(orderMenu?.menu?.date ?? '')))
  } catch (e) {
    yield call(toast.error, `Wystąpił problem podczas anulowania dostawy`);
  }
}


function* onLoadAll() {
  const orderApi: OrderApi = yield call(getApi, OrderApi);
  const userId: number | undefined = yield select((gs: GlobalState) => gs.user.details?.id);

  try {
    const orders: OrderDataFrame = yield apply(orderApi, orderApi.apiOrdersGet, [getRelations<Order>({ menus: { items: true, menu: true, address: true }, payment: true }), true, getFilter<Order>({userId: {eq: userId}})]);
    yield put(orderActions.loadAll.success(orders.data.filter(o => o.userId === userId)))
  } catch (e) {
    yield put(orderActions.loadAll.failure(e));
  }
}

function* onLoad({ payload }: ReturnType<typeof orderActions.load.request>) {
  const orderApi: OrderApi = yield call(getApi, OrderApi);
  const relations = getRelations<Order>({ menus: { items: { menuItem: { product:  { category: true } } }, menu: true, address: true, location: true }, payment: true, user: true, vouchers: true, invoice: true });
  try {
    const order: Order = payload.match(/^\d+$/)
      ? yield apply(orderApi, orderApi.apiOrdersIdGet, [parseInt(payload, 10), relations, true])
      : yield apply(orderApi, orderApi.apiOrdersUuidGet, [payload, relations, true]);
    yield put(orderActions.load.success(order))
  } catch (e) {
    yield put(orderActions.load.failure(e));
  }
}

function* onCheckPostalCode({ payload }: ReturnType<typeof orderActions.checkPostalCode.request>) {
  const deliveryApi: DeliveryApi = yield call(getApi, DeliveryApi)
  try {
    const response = yield apply(deliveryApi, deliveryApi.apiDeliveryAvailablePostalCodeGet, [payload]);
    yield put(orderActions.checkPostalCode.success({ postalCode: payload, isAvailable: response.isAvailable }))
  } catch (e) {
    yield put(orderActions.checkPostalCode.failure(e))
  }
}

export function* orderSaga(): IterableIterator<any> {
  yield takeLatest(getType(orderActions.create.request), onCreate);
  yield takeLatest(getType(orderActions.pay), onPay);
  yield takeLatest(getType(orderActions.downloadInvoice), onDownloadInvoice);
  yield takeLatest(getType(orderActions.cancel), onCancel);
  yield takeLatest(getType(orderActions.cancelMenu), onCancelMenu);
  yield takeLatest(getType(orderActions.editMenu), onEditMenu);
  yield takeLatest(getType(orderActions.loadAll.request), onLoadAll);
  yield takeLatest(getType(orderActions.load.request), onLoad);
  yield takeLatest(getType(orderActions.checkPostalCode.request), onCheckPostalCode);
}
