import React, { useState, useEffect, useContext, useMemo, useCallback, ReactNode } from 'react';
import { Label } from '../label/label';
import styles from './genericEdit.module.scss';
import { Dropzone } from '../dropzone/dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Button } from '../button';
import { Row } from '../grid/row';
import { Spacer } from '../grid/spacer';
import { useRouteMatch } from 'react-router-dom';
import { AdminContext, listUrl } from '../adminContainer';
import { DropzoneOptions } from 'react-dropzone';
import { FieldsDescription, GenericEditEntry } from 'src/components/admin/genericEdit';
import { push } from 'connected-react-router';

type DropzoneDescription<T> = [keyof T & string, string] | [keyof T & string, string, DropzoneOptions];

type UseGenericEditorProps<T extends { id?: number }, GS> = {
  loadAction: (id: number) => unknown;
  dataSelector: (id: number | 'new', gs: GS) => T | null;
  onSave: (dt: T, file: { [k in keyof T]?: File }, originalDt: T | null) => unknown;
  cloneEntity?: (t: T) => T;
  getNewEntity: () => T;
};


const simpleClone = <T extends { id?: number }>(t: T): T => ({ ...t, id: undefined });

export const useGenericEditor = <T extends { id?: number }, GS>({ loadAction, dataSelector, onSave, cloneEntity, getNewEntity }: UseGenericEditorProps<T, GS>) => {
  const m = useRouteMatch<{ id: string }>()
  const id = !isNaN(parseInt(m.params.id, 10)) ? parseInt(m.params.id, 10) : m.params.id;
  const [value, changeValue] = useState<T>(getNewEntity());
  const [files, changeFiles] = useState<{ [k in keyof T]?: File }>({});
  const { entity, baseUrl } = useContext(AdminContext);

  const dispatch = useDispatch();
  const idToFetch = id === 'new'
    ? 'new'
    : typeof id === 'number'
      ? id
      : parseInt(id.match('clone_([0-9]*)')?.[1] ?? '', 10);

  useEffect(() => {
    if (idToFetch !== 'new') {
      dispatch(loadAction(idToFetch))
    }
  }, [idToFetch, dispatch, loadAction])

  const selectedData = useSelector((gs: GS) => dataSelector(idToFetch, gs));
  const clone = cloneEntity ?? simpleClone;
  const data: T | null = useMemo(() => {
    return id === 'new'
      ? getNewEntity()
      : typeof id === 'string' && selectedData
        ? clone(selectedData)
        : selectedData;
  }, [id, clone, selectedData, getNewEntity])

  useEffect(() => {
    changeValue(data ?? getNewEntity())
    changeFiles({});
  }, [data, getNewEntity]);

  const hasChanged = !isEqual(data, value) || Object.values(files).some(_ => _) || data?.id == null;
  const handleReset = useCallback(() => { changeValue(data ?? getNewEntity()); changeFiles({}); }, [data, changeValue, changeFiles, getNewEntity])
  const handleClose = useCallback(() => dispatch(push(listUrl(baseUrl, entity))), [baseUrl, entity, dispatch]);
  const handleSave = useCallback(() => { value && onSave(value, files, data) }, [onSave, value, files, data]);
  return { hasChanged, handleReset, value, changeValue, files, changeFiles, handleClose, handleSave, id, idToFetch };
}

type GenericEditorProps<T extends {}, GS> = UseGenericEditorProps<T, GS> & {
  dropzones?: DropzoneDescription<T>[];
  fields: FieldsDescription<T>;
  extraComponent?: ReactNode;
}
const emptyFile: File[] = [];
export function GenericDetailsComponent<T extends { id?: number }, GS>(props: GenericEditorProps<T, GS>) {
  const { handleClose, files, changeFiles, value, changeValue, hasChanged, handleReset, handleSave } = useGenericEditor<T, GS>(props);
  const { fields, dropzones, extraComponent } = props;
  return (
    <div className={styles.editor}>
      <Row nomargin className={styles.hidePanel}>
        <Button color='transparent' onClick={handleClose}> Ukryj &gt; </Button>
        <Spacer />
      </Row>
      {
        dropzones?.map(
          ([key, description, options]) => {
            const file: File | undefined = files[key];
            return (
              <Label key={key} label={description}>
                <Dropzone onDrop={(f) => changeFiles(files => ({ ...files, [key]: f[0] }))} files={file ? [file] : emptyFile} options={options} />
              </Label>
            )
          }
        )
      }
      <GenericEditEntry<T, T> value={value} parent={value} onChange={changeValue} fields={fields} />
      {extraComponent}
      <Row>
        <Spacer />
        <Button color='warn' disabled={!hasChanged} onClick={handleReset}>Przywróć</Button>
        <Button color='success' disabled={!hasChanged} onClick={handleSave}>Zapisz</Button>
      </Row>
    </div>
  )
}
