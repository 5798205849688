import React, { FC, useEffect } from 'react';
import styles from './contentPage.module.scss';
import ContentHeader from '../contentHeader/contentHeader';
import ContentFooter from '../contentFooter/contentFooter';
import { routes } from 'src/routes';


const ContentPage: FC = (props) => {
  const menu = [
    { label: 'O nas', path: routes.aboutUs(), requireLogin: false },
    { label: 'Dlaczego my?', path: routes.why(), requireLogin: false },
    { label: 'Bezpieczeństwo', path: routes.security(), requireLogin: false },
    { label: 'Zasięg', path: routes.deliveryRange(), requireLogin: false },
    { label: 'Dostawa do biur', path: routes.deliveryToOffices(), requireLogin: false },
    { label: 'Kontakt', path: routes.contact(), requireLogin: false },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <>
      <ContentHeader menu={menu} />
      <div className={styles.contentWrapper}>{props.children}</div>
      <ContentFooter />
    </>
  );
};
export default ContentPage;
