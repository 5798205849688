import { FormattedInput as LibFormattedInput, InputFormat } from '@buttercup/react-formatted-input';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { Omit } from 'utility-types';
import { Props as RightActionsProps, RightActions } from '../rightActions';
import styles from './input.module.scss';

export type InputProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  className?: string;
  type?: 'text' | 'number' | 'password';
  fill?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
} & RightActionsProps;

export function Input({ className, fill, disabled = false, disableActions, rightActions, readOnly, ...rest }: InputProps) {
  const containerClasses = cn(
    styles.component,
    {
      [styles.fill]: fill,
    },
  );
  const inputClasses = cn(
    styles.input,
    {
      [styles.fill]: fill,
    },
    className,
  );
  return (
    <div className={containerClasses}>
      <input className={inputClasses} disabled={disabled} readOnly={readOnly} {...rest} />
      <RightActions rightActions={rightActions} disableActions={disableActions} disabled={disabled} />
    </div>
  );
}

export type FormattedInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (formatted: string, raw: string) => void;
  format: InputFormat;
};

export const NIPFormat: InputFormat = [
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 2 },
  { exactly: '-' },
  { char: /\d/, repeat: 2 },
];

export const REGONFormat: InputFormat = [
  { char: /\d/, repeat: 9 },
];

export const PostalFormat: InputFormat = [
  { char: /\d/, repeat: 2 },
  { exactly: '-' },
  { char: /\d/, repeat: 3 },
];

export function FormattedInput(props: FormattedInputProps) {
  // this is workaround to call onBlur; need to fork format library...
  const handleChange = useCallback((f: string, raw: string ) => {
    if (props.onChange) { props.onChange(f, raw); }
    if (props.onBlur) { props.onBlur({} as any); }
  }, [props]);
  return <LibFormattedInput element={Input} {...props} onChange={handleChange} />;
}
