/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    AddressDataFrame,
    AddressDataFrameFromJSON,
    AddressDataFrameToJSON,
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
} from '../models';

export interface ApiAddressesGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiAddressesIdDeleteRequest {
    id: number;
}

export interface ApiAddressesIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiAddressesPostRequest {
    relations?: string;
    deleted?: boolean;
    address?: Address;
}

/**
 * no description
 */
export class AddressApi extends runtime.BaseAPI {

    /**
     * Get list of Address
     */
    async apiAddressesGetRaw(requestParameters: ApiAddressesGetRequest): Promise<runtime.ApiResponse<AddressDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Address
     */
    async apiAddressesGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<AddressDataFrame> {
        const response = await this.apiAddressesGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Address
     */
    async apiAddressesIdDeleteRaw(requestParameters: ApiAddressesIdDeleteRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAddressesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Remove entity of Address
     */
    async apiAddressesIdDelete(id: number): Promise<Address> {
        const response = await this.apiAddressesIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Address
     */
    async apiAddressesIdGetRaw(requestParameters: ApiAddressesIdGetRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAddressesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Get entity of Address
     */
    async apiAddressesIdGet(id: number, relations?: string, deleted?: boolean): Promise<Address> {
        const response = await this.apiAddressesIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Address (if id defined)
     */
    async apiAddressesPostRaw(requestParameters: ApiAddressesPostRequest): Promise<runtime.ApiResponse<Address>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/addresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Address (if id defined)
     */
    async apiAddressesPost(relations?: string, deleted?: boolean, address?: Address): Promise<Address> {
        const response = await this.apiAddressesPostRaw({ relations: relations, deleted: deleted, address: address });
        return await response.value();
    }

}
