import React, { useEffect } from 'react';
import styles from './sidepanel.module.scss';

type SidepanelProps = {
    open: boolean;
    close(): void;
    children?: React.ReactNode;
}

const Sidepanel = ({ open, close, children }: SidepanelProps) => {

    useEffect(()=> {
      open ? document.body.classList.add('open') : document.body.classList.remove('open');
      return () => document.body.classList.remove('open');
    }, [open]);

    return (
        <div className={[styles.sidepanel, open ? styles.open : ''].join(' ')}  >
            <div className={styles.clicker} onClick={close}></div>
            <div className={styles.sidepanelWrapper}>
            {children}
            <a className={styles.closeBtn} onClick={() => close()}>&times;</a>
            </div>
        </div>
    )
}
export default Sidepanel;
