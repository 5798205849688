import React, { useEffect, useCallback, useState } from 'react';
import PageWrapper from '../../containers/pageWrapper/pageWrapper';
import styles from './buy.module.scss';
import { TextFormField } from '../forms/textField/textField';
import CheckBox, { CheckBoxFormField } from '../forms/checkBox/checkBox';
import { FormSection, FormRow, BtnForm } from '../forms/Forms';
import ErrorList from '../forms/errorList/errorList';
import { FacebookLogin } from '../facebookLogin';
import { displayPrice } from '../../logic/utils/price';
import { routes } from 'src/routes';
import {
  AddressForm,
  emptyHomeAddress,
  emptyOfficeAddress,
  emptyInvoiceAddress,
  AddressType,
} from '../addressForm/addressForm';
import { useFormikContext } from 'formik';
import { User, Address, Location, Voucher } from 'src/api';
import { Basket, getBasketStats } from 'src/logic/basket';
import { LocationForm } from '../addressForm/location';
import { State as SettingsState } from 'src/logic/settings';
import { useErrorsList, hasErrors } from '../hooks/useErrorsList';
import ContentHeader from '../content/contentHeader/contentHeader';
import ContentFooter from '../content/contentFooter/contentFooter';
import Fieldset from 'src/containers/fieldset/fieldset';
import Modal from 'src/containers/modal/modal';
import { Login } from '../loginForm';
import { useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { FocusOnFirstError } from '../focusOnFirstError/focusOnFirstError';
import { gtmPaymentInfo, gtmShippingInfo } from 'src/logic/gtm';

export type FormsValues = {
  addressType: AddressType;
  home: Address;
  office: Address;
  invoice: Address;
  location: Location | null;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  company: string;
  createAccount: boolean;
  password: string;
  confirmPassword: string;
  vatInvoice: boolean;
  sameAddressForInvoice: boolean;
  termsAgreement: boolean;
  marketingAgreement: boolean;
};

const accountKeys: (keyof FormsValues)[] = ['name', 'phone', 'email', 'lastName', 'password', 'confirmPassword'];
const agreementKeys: (keyof FormsValues)[] = ['termsAgreement', 'marketingAgreement'];

type BuyFormProps = {
  basket: Basket;
  vouchers: Voucher[];
  settings: SettingsState;
  goToEdit: () => void;
  userDetails: User | null;
  homeAddress: Address | undefined;
  officeAddress: Address | undefined;
  invoiceAddress: Address | undefined;
  location: Location | null;
  addCode: (code: string) => void;
  disabled: boolean;
};

export const BuyForm = (props: BuyFormProps) => {
  const {
    basket,
    vouchers,
    userDetails,
    homeAddress,
    officeAddress,
    invoiceAddress,
    location,
    settings,
    addCode,
    disabled,
  } = props;
  const { values, submitForm, errors, touched, setFieldValue } = useFormikContext<FormsValues>();
  const { orderByDay, itemsCount, total } = getBasketStats(
    basket,
    vouchers,
    settings.FREE_DELIVERY,
    settings.DELIVERY_HOME,
    !!values.location && values.addressType === 'office',
    settings.CUTLERY_COST,
  );

  useEffect(() => {
    setFieldValue('email', userDetails?.email ?? '');
    setFieldValue('name', userDetails?.name ?? '');
    setFieldValue('lastName', userDetails?.lastName ?? '');
    setFieldValue('phone', userDetails?.phone ?? '');
    setFieldValue('termsAgreement', userDetails?.termsAgreement ?? false);
    setFieldValue('marketingAgreement', userDetails?.marketingAgreement);
  }, [userDetails, setFieldValue]);

  useEffect(() => {
    setFieldValue('home', homeAddress ?? emptyHomeAddress);
    setFieldValue('office', officeAddress ?? emptyOfficeAddress);
    setFieldValue('invoice', invoiceAddress ?? emptyInvoiceAddress);
    setFieldValue('location', location);
    setFieldValue('addressType', location || officeAddress ? 'office' : 'home');
    setFieldValue('vatInvoice', !!invoiceAddress);
    setFieldValue('sameAddressForInvoice', !invoiceAddress);
  }, [homeAddress, officeAddress, invoiceAddress, setFieldValue, location]);

  const isOffice = values.addressType === 'office';
  const accountErrors = useErrorsList(accountKeys, touched, errors);
  const agreementErrors = useErrorsList(agreementKeys, touched, errors);
  const removeLocation = useCallback(() => {
    setFieldValue('location', null);
  }, [setFieldValue]);
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const { SHOW_FACEBOOK_LOGIN } = useSelector((gs: GlobalState) => gs.settings);

  const handleOrder = () => {
    submitForm();
    gtmShippingInfo(basket, total, values);
    gtmPaymentInfo(basket, total, values);
  };

  return (
    <>
      <ContentHeader menu={[]} />
      <PageWrapper>
        <h1 className={styles.title}>Twoje zamówienie zaraz trafi na kuchnię,</h1>
        <h2>ale potrzebujemy jeszcze kilku informacji...</h2>
        <div className={styles.columns}>
          <div className={styles.sideColumn}>
            <section className={styles.header}>
              <div className={styles.content}>
                <h2>Do zapłaty: {displayPrice(total)}</h2>
                <p>
                  Zamówienie na:{' '}
                  <strong>
                    {orderByDay.length} {orderByDay.length === 1 ? 'dzień' : 'dni'}
                  </strong>
                  <br />
                  Produkty łącznie: <strong>{itemsCount}</strong>
                </p>
              </div>
            </section>
            {!userDetails && (
              <Fieldset title='Masz juz konto?'>
                <h3 className='black50'>Zaloguj się aby łatwo wczytać swoje dane i kontrolować zamówienia!</h3>
                <div className={styles.actions}>
                  <BtnForm type='black' action={() => setShowLoginForm(!showLoginForm)}>
                    Zaloguj
                  </BtnForm>
                  {showLoginForm && <Modal close={() => setShowLoginForm(!showLoginForm)}>{<Login />}</Modal>}
                </div>
              </Fieldset>
            )}
          </div>
          <div className={styles.mainColumn}>
            <FormSection>
              <h3>Wybierz miejsce dostawy</h3>
              {values.location && isOffice && <LocationForm location={values.location} change={removeLocation} />}
              {!(values.location && isOffice) && (
                <AddressForm
                  key={isOffice ? 'office' : 'home'}
                  address={isOffice ? officeAddress ?? emptyOfficeAddress : homeAddress ?? emptyHomeAddress}
                  addressType={isOffice ? 'office' : 'home'}
                  parent={isOffice ? 'office' : 'home'}
                  addCode={addCode}
                />
              )}
            </FormSection>
            <FormSection>
              <h3>Wpisz swoje dane</h3>
              <FormRow>
                <TextFormField<keyof FormsValues> name='name' label='Imię' />
                <TextFormField<keyof FormsValues> name='lastName' label='Nazwisko' />
              </FormRow>
              <FormRow>
                <TextFormField<keyof FormsValues> name='email' label='Adres email' disabled={!!userDetails} />
                <TextFormField<keyof FormsValues> name='phone' label='Numer telefonu' />
              </FormRow>
              {!userDetails && (
                <>
                  <h3>Chcesz utworzyć konto?</h3>
                  <FormRow>
                    <CheckBoxFormField name='createAccount' radioValue>
                      tak, chcę utworzyć
                    </CheckBoxFormField>
                    <CheckBoxFormField name='createAccount' radioValue={false}>
                      zamawiam bez tworzenia konta
                    </CheckBoxFormField>
                  </FormRow>

                  <div>
                    {!!values.createAccount && (
                      <FormRow>
                        <TextFormField<keyof FormsValues> name='password' label='Wprowadź hasło' type='password' />
                        <TextFormField<keyof FormsValues>
                          name='confirmPassword'
                          label='Potwierdź hasło'
                          type='password'
                        />
                      </FormRow>
                    )}
                    {SHOW_FACEBOOK_LOGIN && (
                      <FormRow>
                        <span>LUB</span> <FacebookLogin />
                      </FormRow>
                    )}
                  </div>
                </>
              )}
              <ErrorList errors={accountErrors} />
            </FormSection>
            <FormSection>
              <h3>wybierz metodę płatności</h3>
              <CheckBox checked={true} radioValue>
                Płacę szybkim przelewem, kartą lub Blikiem przez{' '}
                <img src='/images/przelewy24.png' alt='przelewy24' className={styles.przelewy24} />
              </CheckBox>

              <h3>Faktura na firmę?</h3>
              <FormRow>
                <CheckBoxFormField<keyof FormsValues> name='vatInvoice' radioValue={false}>
                  Faktura imienna
                </CheckBoxFormField>
                <CheckBoxFormField<keyof FormsValues> name='vatInvoice' radioValue>
                  poproszę o fakturę Vat na firmę
                </CheckBoxFormField>
              </FormRow>

              {!values.vatInvoice && (
                <CheckBoxFormField<keyof FormsValues> name='sameAddressForInvoice' >
                  Adres do faktury taki sam jak adres wysyłki
                </CheckBoxFormField>
              )}
              {(!!values.vatInvoice || !values.sameAddressForInvoice) && (
                <AddressForm addressType={values.vatInvoice ? 'invoice' : 'not-company-invoice'} parent='invoice' address={invoiceAddress ?? emptyInvoiceAddress} />
              )}
              <h3>Zaakceptuj warunki i regulamin</h3>
              {!userDetails?.termsAgreement && (
                <CheckBoxFormField<keyof FormsValues> name='termsAgreement'>
                  <span>
                    Akceptuję{' '}
                    <a className='link' href={routes.terms()}>
                      Regulamin świadczenia usług
                    </a>
                  </span>
                </CheckBoxFormField>
              )}
              <CheckBoxFormField<keyof FormsValues> name='marketingAgreement'>
                <span>
                  Wyrażam zgodę na przesyłanie mi informacji marketingowych dotyczących Serwisu. (O tym jak przetwarzamy
                  dane osobowe przeczytasz w{' '}
                  <a className='link' href={routes.privacy()}>
                    Polityce prywatności
                  </a>{' '}
                  Serwisu)
                </span>
              </CheckBoxFormField>
              <ErrorList errors={agreementErrors} name='termsAgreement' />
            </FormSection>

            <footer className={styles.footer}>
              {hasErrors(errors, touched) && <ErrorList errors={['Formularz zawiera błędy']} />}
              <button className={styles.bigBtn} onClick={handleOrder} disabled={disabled}>
                Zamawiam i płacę {displayPrice(total)}
              </button>
            </footer>
          </div>
        </div>
      </PageWrapper>
      <ContentFooter />
      <FocusOnFirstError />
    </>
  );
};
