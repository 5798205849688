/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Mailing,
    MailingFromJSON,
    MailingToJSON,
    MailingDataFrame,
    MailingDataFrameFromJSON,
    MailingDataFrameToJSON,
} from '../models';

export interface ApiMailingsGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiMailingsIdDeleteRequest {
    id: number;
}

export interface ApiMailingsIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiMailingsPostRequest {
    relations?: string;
    deleted?: boolean;
    mailing?: Mailing;
}

/**
 * no description
 */
export class MailingApi extends runtime.BaseAPI {

    /**
     * Get list of Mailing
     */
    async apiMailingsGetRaw(requestParameters: ApiMailingsGetRequest): Promise<runtime.ApiResponse<MailingDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mailings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Mailing
     */
    async apiMailingsGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<MailingDataFrame> {
        const response = await this.apiMailingsGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Mailing
     */
    async apiMailingsIdDeleteRaw(requestParameters: ApiMailingsIdDeleteRequest): Promise<runtime.ApiResponse<Mailing>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMailingsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mailings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingFromJSON(jsonValue));
    }

    /**
     * Remove entity of Mailing
     */
    async apiMailingsIdDelete(id: number): Promise<Mailing> {
        const response = await this.apiMailingsIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Mailing
     */
    async apiMailingsIdGetRaw(requestParameters: ApiMailingsIdGetRequest): Promise<runtime.ApiResponse<Mailing>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMailingsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mailings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingFromJSON(jsonValue));
    }

    /**
     * Get entity of Mailing
     */
    async apiMailingsIdGet(id: number, relations?: string, deleted?: boolean): Promise<Mailing> {
        const response = await this.apiMailingsIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Mailing (if id defined)
     */
    async apiMailingsPostRaw(requestParameters: ApiMailingsPostRequest): Promise<runtime.ApiResponse<Mailing>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mailings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailingToJSON(requestParameters.mailing),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MailingFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Mailing (if id defined)
     */
    async apiMailingsPost(relations?: string, deleted?: boolean, mailing?: Mailing): Promise<Mailing> {
        const response = await this.apiMailingsPostRaw({ relations: relations, deleted: deleted, mailing: mailing });
        return await response.value();
    }

}
