import React, { useEffect, useMemo, useCallback, useState, useContext, ReactNode } from 'react';
import {
  InMemoryGenericTable,
  FilterableColumnDefinition,
  getDefaultTableState,
} from 'src/components/admin/InMemoryGenericTable';
import { GlobalState } from 'src/logic/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { NoFilter, TableState } from 'src/components/admin/controlledGenericTable';
import { ConfirmationButton } from '../confirmationButton/confirmationButton';
import { Button } from '../button';
import { Row } from '../grid/row';
import styles from './genericList.module.scss';
import { Parser } from 'json2csv';
import { fromPairs, isString } from 'lodash';
import { AdminContext, detailsUrl } from '../adminContainer';
import { Workbook } from 'exceljs';
import { Filter } from 'src/logic/utils';

export const downloadString = (filename: string, text: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadBuffer = (filename: string, buffer: ArrayBuffer) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([buffer]));
  link.download = filename;
  link.click();
};

export type CustomExportColumns<T> = { [k in string]: (t: T) => string };

const getCSV = <T extends any>(
  data: T[],
  columns: FilterableColumnDefinition<T>[],
  custom?: CustomExportColumns<T>
) => {
  const fields = custom ? Object.keys(custom) : columns.filter((c) => c.text).map((c) => c.label);
  const opts = { fields };
  const parser = new Parser(opts);
  const mappedData = data
    .map((row) =>
      custom
        ? Object.entries(custom).map(([field, fn]) => [field, fn(row)])
        : columns.filter((c) => c.text).map((c) => [c.label, c.text?.(row)])
    )
    .map((p) => fromPairs(p));
  const csv = parser.parse(mappedData);
  downloadString('export.csv', 'sep=,\n' + csv);
};

export const getXLSX = <T extends any>(
  data: T[],
  columns: FilterableColumnDefinition<T>[],
  custom?: CustomExportColumns<T>
) => {
  const fields = custom ? Object.keys(custom) : columns.filter((c) => c.text).map((c) => c.label);
  const mappedData = data.map((row) =>
    custom
      ? fields.map((field) => custom[field]?.(row))
      : fields.map((field) =>{
          const column = columns
          .find((c) => c.label === field);

          const value = (column?.exportValue ?? column?.text)?.(row)
          return isString(value) ? value?.replace(/\n/g, '\n') : value;
        })
  );
  const wb = new Workbook();
  const sheet = wb.addWorksheet('Dane');
  sheet.columns = fields
    .map((name, idx) =>
      Math.max(
        name.length,
        ...mappedData.map((d) => Math.max(...(`${d[idx]}`?.split('\n')?.map((e) => e?.length ?? 0) ?? [0])) ?? 0)
      )
    )
    .map((l) => ({ width: Math.min(l + 5, 100) }));
  sheet.addRow(fields, '');
  if (sheet.lastRow) {
    sheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFBBBBBB' },
      bgColor: { argb: 'FF000000' },
    };
  }

  sheet.addRows(mappedData, '');
  sheet.eachRow((row, rowIdx) => {
    row.height = Math.max(18, ...(mappedData[rowIdx - 2]?.map((c) => (`${c??''}`.split('\n').length ?? 0) * 14) ?? []));
    row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  });
  wb.xlsx.writeBuffer({ filename: 'test.xlsx' }).then((buffer) => downloadBuffer('export.xlsx', buffer));
};

type GenericListType<T, TActionEntity> = {
  columns: FilterableColumnDefinition<T>[];
  exporters?: ({ name: string; type: 'csv' | 'xlsx' } & (
    | { columns: FilterableColumnDefinition<T>[] }
    | { exportMap: CustomExportColumns<T> }
  ))[];
  customExportColumns?: CustomExportColumns<T>;
  rowKey: (t: T) => string;
  rowClassName?: (t: T) => string;
  loadAction: (params?: { filter?: Filter<TActionEntity>; offset?: number; limit?: number }) => unknown;
  dataSelector: (gs: GlobalState) => T[];
  isPendingSelector: (gs: GlobalState) => boolean;
  editPath?: (id: number | 'new' | '') => string;
  clone?: boolean;
  deleteAction?: (id: number) => unknown;
  allowToCreate?: boolean;
  actions?: ReactNode;
  customAction?: (t: T, tableState: TableState) => ReactNode;
  getFilter?: (t: TableState) => Filter<TActionEntity>;
  onFilteredDataChanged?: (t: T[], page: T[]) => void;
};

const actionsColumn = <T extends { id?: number }>(
  tableState: TableState,
  editPath?: (id: number | string) => string,
  deleteAction?: (id: number) => unknown,
  clone?: boolean,
  customAction?: (o: T, tableState: TableState) => ReactNode,
): FilterableColumnDefinition<T> => ({
  label: 'Akcje',
  labelComponent: null,
  render: (o: T) => (
    <>
      {clone && editPath && (
        <Link to={editPath(`clone_${o.id}`)}>
          <Button>Klonuj</Button>
        </Link>
      )}
      {editPath && o.id && (
        <Link to={editPath(o.id)}>
          <Button color={'primary'}>Edytuj</Button>
        </Link>
      )}
      {deleteAction && (
        <ConfirmationButton color='danger' message={'Jesteś pewien?'} onClick={() => o.id && deleteAction(o.id)}>
          Usuń
        </ConfirmationButton>
      )}
      {customAction && customAction(o, tableState)}
    </>
  ),
  text: () => '',
  exportValue: undefined,
  sortable: false,
  key: `act`,
  minimal: true,
  centered: true,
  filterable: NoFilter,
});

export function GenericList<T extends {id?: number}, TActionEntity = T>({
  columns,
  dataSelector,
  isPendingSelector,
  loadAction,
  rowKey,
  deleteAction,
  allowToCreate,
  editPath,
  clone,
  actions,
  customExportColumns,
  rowClassName,
  customAction,
  exporters,
  getFilter,
  onFilteredDataChanged,
}: GenericListType<T, TActionEntity>) {
  const data = useSelector(dataSelector);
  const isPending = useSelector(isPendingSelector);
  const [filteredData, setFilteredData] = useState<T[]>(data);
  const [pageData, setPageData] = useState<T[]>([]);
  const handleSetFilteredData = useCallback((filtered: T[], page: T[]) => { setFilteredData(filtered); setPageData(page) }, [setFilteredData, setPageData]);
  const [tableState, handleTableState] = useState(getDefaultTableState(columns));
  const { entity, baseUrl } = useContext(AdminContext);
  const [filter, setFilter] = useState(getFilter?.(tableState));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data || !data.length) {
      dispatch(loadAction({ filter }));
    }
  }, [dispatch, loadAction, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefresh = useCallback(() => dispatch(loadAction({ filter })), [dispatch, loadAction, filter]);

  useEffect(() => {
    setFilter(getFilter?.(tableState));
  }, [getFilter, tableState]);

  useEffect(() => {
    handleRefresh();
  }, [filter, handleRefresh]);

  useEffect(() => {
    onFilteredDataChanged?.(filteredData, pageData)
  }, [onFilteredDataChanged, filteredData, pageData]);

  const memoColumns = useMemo(() => {
    if (editPath || deleteAction || customAction) {
      return [
        ...columns,
        actionsColumn(
          tableState,
          editPath ? (id) => detailsUrl(baseUrl, entity, id) : undefined,
          deleteAction,
          clone,
          customAction,
        ),
      ];
    }
    return columns;
  }, [columns, baseUrl, entity, deleteAction, clone, editPath, customAction]);

  return (
    <>
      <Row spacing className={styles.actionsRow}>
        {allowToCreate && entity && (
          <Link to={detailsUrl(baseUrl, entity, 'new') ?? ''}>
            <Button color='primary'>+ Dodaj nowy</Button>
          </Link>
        )}
        <Button onClick={handleRefresh}>Odśwież</Button>
        {!exporters ? (
          <>
            <Button onClick={() => getCSV(filteredData, columns, customExportColumns)}>CSV</Button>
            <Button onClick={() => getXLSX(filteredData, columns, customExportColumns)}>XLSX</Button>
          </>
        ) : (
          exporters.map((dt) => (
            <Button
              key={dt.name}
              onClick={() =>
                (dt.type === 'csv' ? getCSV : getXLSX)(
                  filteredData,
                  'columns' in dt ? dt.columns : columns,
                  'exportMap' in dt ? dt.exportMap : undefined
                )
              }
            >
              {dt.name}
            </Button>
          ))
        )}
        {actions}
      </Row>
      {isPending && <div className={styles.center}>Ładowanie danych</div>}
      <InMemoryGenericTable<T>
        itemsPerPage={25}
        data={data}
        rowKey={rowKey}
        rowClassName={rowClassName}
        columns={memoColumns}
        actions={null}
        noDataPlaceholder='Brak danych'
        onFilter={handleSetFilteredData}
        onStateUpdate={handleTableState}
      />
    </>
  );
}
