import React from 'react';
import { FormSection, FormRow, BtnForm, BtnLink } from 'src/components/forms/Forms';
import LabelValue from '../labelValue/labelValue';
import { useDispatch } from 'react-redux';
import { orderActions } from '../../../logic/order';
import { Order } from 'src/api';

export const PaymentSection = ({ order, orderMenuId }: { order: Order | null; orderMenuId?: number }) => {
  const dispatch = useDispatch();
  const orderMenu = order?.menus?.find(m => m.id === orderMenuId);
  const deleted = order?.deletedAt || order?.status === 'canceled' || orderMenu?.deletedAt;
  if (!order?.payment) {
    return (
      <FormSection className="side">
        <h3>Szczegóły płatności</h3>
        <LabelValue label="Metoda płatności" value="Przelewy24" />
        <LabelValue label="Status płatności" value={deleted ? 'Zamówienie anulowane' : 'Niezrealizowana'} />
        {
          !(deleted) &&
          <FormRow>
            <BtnForm type="black" action={() => { dispatch(orderActions.pay([order?.uuid ?? ''])) }}>Ponów płatność</BtnForm>
          </FormRow>
        }
      </FormSection>
    )
  }
  const transactionId = order?.payment?.identifier ?? order?.payment?.transaction;
  return (
    <FormSection className="side">
      <h3>Szczegóły płatności</h3>
      <LabelValue label="Metoda płatności" value={(order.payment.amount ?? 0) > 0 ? 'Przelewy24' : 'Saldo użytkownika'} />
      <LabelValue
        label="Numer i data transakcji"
        value={`${transactionId ? `${transactionId} – ` : ''} ${order?.payment.updatedAt?.toLocaleString()}`}
      />
      <LabelValue label="Status płatności" value={order?.payment.paid ? 'Zrealizowana' : 'Oczekuje'} />
      {
        !(deleted) &&
        !order.payment.paid &&
        <FormRow>
          <BtnForm type="black" action={() => { dispatch(orderActions.pay([order?.uuid ?? ''])) }}>Ponów płatność</BtnForm>
        </FormRow>
      }
      {
        order.invoiceNumber && (
            <LabelValue
              label="Faktura VAT"
              value={
                <BtnForm action={() => dispatch(orderActions.downloadInvoice({ id: order.id! }))} type="orange">Pobierz fakturę {order.invoiceNumber}</BtnForm>
              } />
        )
      }
       {
        order?.invoiceCorrectionNumber && (
            <LabelValue
              label="Faktura VAT - korekta"
              value={
                <BtnForm action={() => dispatch(orderActions.downloadInvoice({ id: order.id!, isCorrection: true }))} type="orange">Pobierz korektę faktury {orderMenu?.invoiceCorrectionNumber || order?.invoiceCorrectionNumber}</BtnForm>
              } />
        )
      }
    </FormSection>
  )
}
