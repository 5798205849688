/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    WalletHistory,
    WalletHistoryFromJSON,
    WalletHistoryToJSON,
    WalletHistoryDataFrame,
    WalletHistoryDataFrameFromJSON,
    WalletHistoryDataFrameToJSON,
} from '../models';

export interface ApiWalletHistoriesGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiWalletHistoriesIdDeleteRequest {
    id: number;
}

export interface ApiWalletHistoriesIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiWalletHistoriesPostRequest {
    relations?: string;
    deleted?: boolean;
    walletHistory?: WalletHistory;
}

/**
 * no description
 */
export class WalletHistoryApi extends runtime.BaseAPI {

    /**
     * Get list of WalletHistory
     */
    async apiWalletHistoriesGetRaw(requestParameters: ApiWalletHistoriesGetRequest): Promise<runtime.ApiResponse<WalletHistoryDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/walletHistories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WalletHistoryDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of WalletHistory
     */
    async apiWalletHistoriesGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<WalletHistoryDataFrame> {
        const response = await this.apiWalletHistoriesGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of WalletHistory
     */
    async apiWalletHistoriesIdDeleteRaw(requestParameters: ApiWalletHistoriesIdDeleteRequest): Promise<runtime.ApiResponse<WalletHistory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWalletHistoriesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/walletHistories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WalletHistoryFromJSON(jsonValue));
    }

    /**
     * Remove entity of WalletHistory
     */
    async apiWalletHistoriesIdDelete(id: number): Promise<WalletHistory> {
        const response = await this.apiWalletHistoriesIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of WalletHistory
     */
    async apiWalletHistoriesIdGetRaw(requestParameters: ApiWalletHistoriesIdGetRequest): Promise<runtime.ApiResponse<WalletHistory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiWalletHistoriesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/walletHistories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WalletHistoryFromJSON(jsonValue));
    }

    /**
     * Get entity of WalletHistory
     */
    async apiWalletHistoriesIdGet(id: number, relations?: string, deleted?: boolean): Promise<WalletHistory> {
        const response = await this.apiWalletHistoriesIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of WalletHistory (if id defined)
     */
    async apiWalletHistoriesPostRaw(requestParameters: ApiWalletHistoriesPostRequest): Promise<runtime.ApiResponse<WalletHistory>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/walletHistories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WalletHistoryToJSON(requestParameters.walletHistory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WalletHistoryFromJSON(jsonValue));
    }

    /**
     * Create or update entity of WalletHistory (if id defined)
     */
    async apiWalletHistoriesPost(relations?: string, deleted?: boolean, walletHistory?: WalletHistory): Promise<WalletHistory> {
        const response = await this.apiWalletHistoriesPostRaw({ relations: relations, deleted: deleted, walletHistory: walletHistory });
        return await response.value();
    }

}
