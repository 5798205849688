/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
    OrderMenu,
    OrderMenuFromJSON,
    OrderMenuFromJSONTyped,
    OrderMenuToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    house?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    local?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    additional?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    delivery?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    contactless?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    deliveryTo6?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    deliveryTo7?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    vatin?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    orderId?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    orderMenuId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Address
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Address
     */
    updatedAt?: Date;
    /**
     * 
     * @type {User}
     * @memberof Address
     */
    user?: User;
    /**
     * 
     * @type {Order}
     * @memberof Address
     */
    order?: Order;
    /**
     * 
     * @type {OrderMenu}
     * @memberof Address
     */
    orderMenu?: OrderMenu;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'house': !exists(json, 'house') ? undefined : json['house'],
        'local': !exists(json, 'local') ? undefined : json['local'],
        'additional': !exists(json, 'additional') ? undefined : json['additional'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postalcode': !exists(json, 'postalcode') ? undefined : json['postalcode'],
        'delivery': !exists(json, 'delivery') ? undefined : json['delivery'],
        'contactless': !exists(json, 'contactless') ? undefined : json['contactless'],
        'deliveryTo6': !exists(json, 'deliveryTo6') ? undefined : json['deliveryTo6'],
        'deliveryTo7': !exists(json, 'deliveryTo7') ? undefined : json['deliveryTo7'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'vatin': !exists(json, 'vatin') ? undefined : json['vatin'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'orderMenuId': !exists(json, 'orderMenuId') ? undefined : json['orderMenuId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'order': !exists(json, 'order') ? undefined : OrderFromJSON(json['order']),
        'orderMenu': !exists(json, 'orderMenu') ? undefined : OrderMenuFromJSON(json['orderMenu']),
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'street': value.street,
        'house': value.house,
        'local': value.local,
        'additional': value.additional,
        'city': value.city,
        'postalcode': value.postalcode,
        'delivery': value.delivery,
        'contactless': value.contactless,
        'deliveryTo6': value.deliveryTo6,
        'deliveryTo7': value.deliveryTo7,
        'type': value.type,
        'vatin': value.vatin,
        'userId': value.userId,
        'orderId': value.orderId,
        'orderMenuId': value.orderMenuId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'user': UserToJSON(value.user),
        'order': OrderToJSON(value.order),
        'orderMenu': OrderMenuToJSON(value.orderMenu),
    };
}


