import cn from 'classnames';
import React, { useMemo, ReactNode } from 'react';
import styles from './grid.module.scss';

type ColumnProps = {
  children: ReactNode;
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;
  className?: string;
  offset?: number;
};

export function Column(props: ColumnProps) {
  const { width, children, className, offset } = props;

  const classes = cn(
    styles.column,
    styles[`width${width}`],
    className,
  );

  const style = useMemo(() => ({
    '--offset': `${offset||0}px`
  }), [offset])

  return <div className={classes} style={style as any}>{children}</div>;
}

type FixedWidthColumnProps = {
  children: ReactNode;
  width: number;
  className?: string;
};

export function FixedWidthColumn(props: FixedWidthColumnProps) {
  const { width, children, className } = props;

  const classes = cn(
    styles.column,
    className,
  );

  const style = useMemo(() => ({
    minWidth: `calc(${width}px - 20px)`
  }), [width])

  return <div className={classes} style={style}>{children}</div>;
}
