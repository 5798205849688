
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createReducer } from './reducers';
import { saga } from './sagas';
import { userActions } from 'src/logic/user';
import { settingsActions } from 'src/logic/settings';
import { menuActions } from 'src/logic/menu';

export function configureStore(initialState: object, history: History) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
  ];

  if (process.env.NODE_ENV !== 'production') {
    const logger = require('redux-logger').default;
    middlewares.push(logger);
  }

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    createReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(saga);
  // These two actions have to be run before any other.
  store.dispatch(userActions.check());
  store.dispatch(settingsActions.load.request());
  store.dispatch(menuActions.menu.request());
  return store;
}
