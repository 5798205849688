import React, { createRef, useState } from 'react';
import styles from './calendar.module.scss';
import CalendarDayCard from './calendarDayCard';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from 'src/routes';
import { Menu } from 'src/api';
import { getDateString } from 'src/logic/utils/dates';
import ScrollBtn from '../buttons/scrollBtn/scrollBtn';
import { useParamDate } from 'src/components/hooks/useActiveDate';
import { useMinMenu } from '../hooks/useActiveDate';
import { differenceInCalendarDays, startOfDay, isWeekend } from 'date-fns';

type DayOrders = {
  day: Date;
  count: number;
};

type CalendarProps = {
  orders: DayOrders[];
  menus: Menu[];
  timeToOrder: number;
};

const today = new Date();

const getOrdersForDay = (day: Date, orders: DayOrders[]) => {
  return orders.find((d) => d.day.toDateString() === day.toDateString())?.count;
};

const getCalendarDays = (
  count = 30,
  orders: DayOrders[],
  active: Date,
  menus: Menu[],
  minMenuDate: Date
): {
  day: Date;
  count: number | undefined;
  active: boolean;
  disabled: boolean;
  style: 'normal' | 'xmas';
}[] => {
  const days = [];
  for (let i = 1; i < count; i++) {
    days.push(
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + i, 12)
    );
  }
  const xMas = [new Date(2020,11,14).getTime(), new Date(2020,11,24).getTime()];
  return days.map((d) => ({
    day: d,
    style: d.getTime() >= xMas[0] && d.getTime() <= xMas[1] && !isWeekend(d.getTime()) ? 'xmas' : 'normal',
    count: getOrdersForDay(d, orders),
    active: getDateString(d) === getDateString(active),
    disabled: startOfDay(d) < startOfDay(minMenuDate) || !menus.some(
      (m) => m.date && getDateString(m.date) === getDateString(d)
    ),
  }));
};

const Calendar = ({ orders, menus, timeToOrder }: CalendarProps) => {
  const [active] = useParamDate();
  const minMenu = useMinMenu();
  const daysToEndOfTheYear = differenceInCalendarDays(new Date(2021, 0, 1), new Date());
  const days = getCalendarDays(Math.max(21, daysToEndOfTheYear), orders, active ?? new Date(), menus, minMenu?.date ?? new Date());
  const dispatch = useDispatch();
  const setActiveDate = (day: Date) => {
    dispatch(push(routes.menu(day)));
  };

  const sliderRef = createRef<HTMLDivElement>();

  const [edge, setEdge] = useState(-1);

  const checkEdges = (offset: number, scroll: number, wrapper: number) => {
    if (offset <= 0) setEdge(-1);
    else if (wrapper + offset >= scroll) setEdge(1);
    else setEdge(0);
  };

  const Scroll = (back: boolean) => {
    if (sliderRef.current) {
      const newOffset = sliderRef.current.scrollLeft + (back ? -240 : 240);
      sliderRef.current.scrollLeft = newOffset;
      checkEdges(newOffset, sliderRef.current.scrollWidth, sliderRef.current.clientWidth)
    }
  };

  const onScroll = () => {
    if (sliderRef.current) {
      checkEdges(sliderRef.current.scrollLeft, sliderRef.current.scrollWidth, sliderRef.current.clientWidth)
    }
  }

  return (
    <div className={styles.calendarWrapper}>
      <div className={styles.calendarSlider} ref={sliderRef}>
        {days.map((d, i) => (
          <CalendarDayCard
            key={i}
            day={d.day}
            ordersCount={d.count}
            active={d.active}
            disabled={d.disabled}
            action={setActiveDate}
            timeToOrder={timeToOrder}
            style={d.style}
            onScroll={onScroll}
          />
        ))}
      </div>
      <div className={[styles.scrollLeft, edge === -1 && styles.hide].join(' ')}>
        <ScrollBtn action={() => Scroll(true)} invert />{' '}
      </div>
      <div className={[styles.scrollRight, edge === 1 && styles.hide].join(' ')}>
        <ScrollBtn action={() => Scroll(false)} />{' '}
      </div>
    </div>
  );
};
export default Calendar;
