import { isString, snakeCase } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { ValueType } from 'react-select';

import { Input } from '../input';
import { Label } from '../label';
import { Pager } from '../pager';
import { Select } from '../select';
import { SortingArrows, SortingDirection } from '../sortingArrows';
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '../table';
import { Row } from '../grid/row';
import { Spacer } from '../grid/spacer';
import { DateRange, isDateRangeFilterValue } from '../datePicker/datePicker';

declare global {
  interface ArrayConstructor {
    isArray(arg: ReadonlyArray<any> | any): arg is ReadonlyArray<any>;
  }
}

export type NoFilterType = { type: 'none'; values: void; defaultValue?: void };
export const NoFilter: NoFilterType = { type: 'none', values: undefined };

export type TextFilterType = { type: 'text'; values: void;  defaultValue?: string };
export const TextFilter: TextFilterType = { type: 'text', values: undefined };

type SelectFilterValue = { value: string; label: string };

export type SelectFilterType = { type: 'select'; values: SelectFilterValue[]; defaultValue: SelectFilterValue[] };
export const SelectFilter = (values: string[], defaultValue?: string[]): SelectFilterType => ({ type: 'select', values: values.map((v) => ({ value: v, label: v })), defaultValue: defaultValue?.map(v => ({value: v, label: v})) ?? [] });

export type MultiFilterType = { type: 'multi'; values: SelectFilterValue[]; defaultValue: SelectFilterValue[] };
export const MultiFilter = (values: string[]): MultiFilterType => ({ type: 'multi', values: values.map((v) => ({ value: v, label: v })), defaultValue: [] });

export type DateRangeFilterType = { type: 'date'; values: void; defaultValue?: DateRange };
export const DateRangeFilter = { type: 'date', values: undefined } as const;

export type FilterType = NoFilterType | TextFilterType | SelectFilterType | MultiFilterType | DateRangeFilterType;

type RequiredColumnPart<T> = {
  label: string;
  render: (t: T) => ReactNode;
};

type OptionalColumnPart<T> = {
  key: string;
  sortable: boolean;
  filterable: FilterType;
  minimal: boolean;
  centered: boolean;
  text: ((t: T) => string) | undefined;
  labelComponent: ReactNode;
  exportValue: ((t: T) => string | number) | undefined;
};

export type ColumnDefinition<T> = RequiredColumnPart<T> & OptionalColumnPart<T>;
export type SimplifiedColumnDefinition<T> = RequiredColumnPart<T> & Partial<OptionalColumnPart<T>>;

export function getColumnDefinitions<T>(columns: Array<SimplifiedColumnDefinition<T>>): Array<ColumnDefinition<T>> {
  return columns.map(({ label, render, key, text = undefined, sortable = false, filterable = NoFilter, minimal = false, centered = false, labelComponent = null, exportValue = undefined }) => ({
    centered,
    filterable,
    key: key || snakeCase(label),
    label,
    labelComponent,
    text,
    minimal,
    render,
    sortable,
    exportValue,
  }));
}


type FilterValue = string | SelectFilterValue | SelectFilterValue[] | DateRange | null;

export const stringFilterValue = (fv: FilterValue): string => {
  if (fv == null || (Array.isArray(fv) && fv.length === 0)) {
    return '';
  }
  if (isDateRangeFilterValue(fv)) {
    return '';
  }
  if (Array.isArray(fv)) {
    return fv[0]?.value ?? '';
  }
  if (isString(fv)) {
    return fv;
  }
  return fv.value;
};

export const selectFilterValue = (fv: FilterValue): SelectFilterValue | null => {
  if (fv == null || (Array.isArray(fv) && fv.length === 0)) {
    return null;
  }
  if (isDateRangeFilterValue(fv)) {
    return null;
  }
  if (Array.isArray(fv)) {

    return fv[0] ?? null;
  }
  if (isString(fv)) {
    return {
      label: fv,
      value: fv,
    };
  }
  return fv;
};

export const multiSelectFilterValue = (fv: FilterValue): SelectFilterValue[] => {
  if (fv == null) {
    return [];
  }
  if (isDateRangeFilterValue(fv)) {
    return [];
  }
  if (Array.isArray(fv)) {
    return fv;
  }
  if (isString(fv)) {
    return [{
      label: fv,
      value: fv,
    }];
  }
  return [fv];
};

export const dateRangeFilterValue = (fv: FilterValue): DateRange => {
  if (isDateRangeFilterValue(fv)) {
    return fv;
  }
  return { minDate: null, maxDate: null }
};

export type TableState = {
  sort: string | null;
  sortDirection: SortingDirection;
  filters: {
    [key: string]: FilterValue;
  };
  page: number;
};

export const emptyTableState = {
  filters: {},
  page: 1,
  sort: null,
  sortDirection: null,
};

export type ControlledTableProps<T> = {
  data: T[];
  pages: number;
  columns: Array<ColumnDefinition<T>>;
  onStateUpdate: (d: TableState) => void;
  tableState: TableState;
  actions: ReactNode;
  rowKey: (t: T) => number | string;
  rowClassName?: (t: T) => string;
  noDataPlaceholder: string;
};

class ControlledTableHeaderCell<T> extends Component<{ column: ColumnDefinition<T>; tableState: TableState; stateUpdate: (s: Partial<TableState>) => void }> {
  public handleSorting = (dir: SortingDirection) => {
    const { column, stateUpdate } = this.props;
    stateUpdate({ sort: column.key, sortDirection: dir });
  }

  public handleFilter = (value: string | SelectFilterValue | ReadonlyArray<SelectFilterValue> | DateRange | null) => {
    const { column, stateUpdate, tableState } = this.props;
    stateUpdate({
      filters: {
        ...tableState.filters,
        [column.key]: Array.isArray(value) ? [...value] : value,
      },
    });
  }

  public handleTextFilter = (e: React.ChangeEvent<HTMLInputElement>) => this.handleFilter(e.currentTarget.value);
  public handleSelectFilter = (value: ValueType<SelectFilterValue>) => this.handleFilter(value || null);
  public handleDateRangeFilter = (value: DateRange) => this.handleFilter(value || null);

  public getComponent(filterType: FilterType, sorting: ReactNode): ReactNode {
    const { column, tableState: { filters } } = this.props;
    const columnId = column.key;
    if (column.labelComponent) {
      return column.labelComponent;
    }
    const filterValue = filters[columnId];
    switch (filterType.type) {
      case 'none': return sorting ? <Label position={'left'} label={column.label}>{sorting}</Label> : column.label;
      case 'text': return <Input fill onChange={this.handleTextFilter} value={stringFilterValue(filterValue)} placeholder={column.label} rightActions={sorting} />;
      case 'select': return <Select fill placeholder={column.label} onChange={this.handleSelectFilter} value={selectFilterValue(filterValue)} isClearable options={filterType.values} rightActions={sorting} />;
      case 'multi': return <Select fill placeholder={column.label} onChange={this.handleSelectFilter} value={multiSelectFilterValue(filterValue)} isMulti options={filterType.values} rightActions={sorting} />;
      case 'date': return <DateRange onChange={this.handleDateRangeFilter} fill rightActions={sorting} value={filterValue as DateRange} />;
    }
  }

  public render() {
    const { column, tableState } = this.props;
    const sortingDirection: SortingDirection = tableState.sort === column.key ? tableState.sortDirection : null;
    const sorting = column.sortable ? <SortingArrows direction={sortingDirection} onChangeSort={this.handleSorting} /> : null;
    const filterType = column.filterable;
    const filter = this.getComponent(filterType, sorting);
    return <TableHeaderCell minimal={column.minimal} centered={column.centered}>{filter}</TableHeaderCell>;
  }
}

export class ControlledGenericTable<T> extends Component<ControlledTableProps<T>> {
  public handleStateUpdate = (stateChange: Partial<TableState>) => {
    const { onStateUpdate, tableState } = this.props;
    onStateUpdate({ ...tableState, ...stateChange });
  }

  public componentDidUpdate(prevProps: ControlledTableProps<T>) {
    if (this.props.pages < this.props.tableState.page && prevProps.tableState.page !== Math.max(1, this.props.pages)) {
      this.handleStateUpdate({ page: Math.max(1, this.props.pages) });
    }
  }

  public handleUpdatePage = (page: number) => this.handleStateUpdate({ page });

  public render() {
    const { columns, tableState, data, rowKey, pages, actions, rowClassName, noDataPlaceholder } = this.props;
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {
                columns.map((c) => <ControlledTableHeaderCell key={c.key} column={c} tableState={tableState} stateUpdate={this.handleStateUpdate} />)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((row) => (
                <TableRow key={rowKey(row)} className={rowClassName?.(row)}>
                  {
                    columns.map((c) => <TableCell key={c.key} minimal={c.minimal} centered={c.centered}>{c.render(row)}</TableCell>)
                  }
                </TableRow>
              ))
            }
            {
              !data.length && <TableRow>{noDataPlaceholder}</TableRow>
            }
          </TableBody>
        </Table>
        <Row>
          <Pager pages={pages} page={tableState.page} onChange={this.handleUpdatePage} />
          <Spacer />
          {actions}
        </Row>
      </>
    );
  }
}
