/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    action?: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    done?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    userId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    updatedAt?: Date;
    /**
     * 
     * @type {User}
     * @memberof Task
     */
    user?: User;
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'done': !exists(json, 'done') ? undefined : json['done'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'action': value.action,
        'amount': value.amount,
        'description': value.description,
        'done': value.done,
        'userId': value.userId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'user': UserToJSON(value.user),
    };
}


