/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    Voucher,
    VoucherFromJSON,
    VoucherFromJSONTyped,
    VoucherToJSON,
    WalletHistory,
    WalletHistoryFromJSON,
    WalletHistoryFromJSONTyped,
    WalletHistoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    wallet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    anonymous?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    verifyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    resetCode?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    avatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    termsAgreement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    marketingAgreement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    unsubscribeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    encodedEmail?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    roles?: Array<Role>;
    /**
     * 
     * @type {Array<Order>}
     * @memberof User
     */
    orders?: Array<Order>;
    /**
     * 
     * @type {Array<Address>}
     * @memberof User
     */
    addresses?: Array<Address>;
    /**
     * 
     * @type {Array<WalletHistory>}
     * @memberof User
     */
    walletHistories?: Array<WalletHistory>;
    /**
     * 
     * @type {Array<Location>}
     * @memberof User
     */
    locations?: Array<Location>;
    /**
     * 
     * @type {Array<Voucher>}
     * @memberof User
     */
    vouchers?: Array<Voucher>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof User
     */
    devices?: Array<Device>;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'wallet': !exists(json, 'wallet') ? undefined : json['wallet'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'anonymous': !exists(json, 'anonymous') ? undefined : json['anonymous'],
        'verifyCode': !exists(json, 'verifyCode') ? undefined : json['verifyCode'],
        'resetCode': !exists(json, 'resetCode') ? undefined : json['resetCode'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'termsAgreement': !exists(json, 'termsAgreement') ? undefined : json['termsAgreement'],
        'marketingAgreement': !exists(json, 'marketingAgreement') ? undefined : json['marketingAgreement'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'unsubscribeCode': !exists(json, 'unsubscribeCode') ? undefined : json['unsubscribeCode'],
        'encodedEmail': !exists(json, 'encodedEmail') ? undefined : json['encodedEmail'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(RoleFromJSON)),
        'orders': !exists(json, 'orders') ? undefined : ((json['orders'] as Array<any>).map(OrderFromJSON)),
        'addresses': !exists(json, 'addresses') ? undefined : ((json['addresses'] as Array<any>).map(AddressFromJSON)),
        'walletHistories': !exists(json, 'walletHistories') ? undefined : ((json['walletHistories'] as Array<any>).map(WalletHistoryFromJSON)),
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(LocationFromJSON)),
        'vouchers': !exists(json, 'vouchers') ? undefined : ((json['vouchers'] as Array<any>).map(VoucherFromJSON)),
        'devices': !exists(json, 'devices') ? undefined : ((json['devices'] as Array<any>).map(DeviceFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'lastName': value.lastName,
        'company': value.company,
        'password': value.password,
        'phone': value.phone,
        'wallet': value.wallet,
        'active': value.active,
        'anonymous': value.anonymous,
        'verifyCode': value.verifyCode,
        'resetCode': value.resetCode,
        'avatar': value.avatar,
        'termsAgreement': value.termsAgreement,
        'marketingAgreement': value.marketingAgreement,
        'fullName': value.fullName,
        'displayName': value.displayName,
        'unsubscribeCode': value.unsubscribeCode,
        'encodedEmail': value.encodedEmail,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(RoleToJSON)),
        'orders': value.orders === undefined ? undefined : ((value.orders as Array<any>).map(OrderToJSON)),
        'addresses': value.addresses === undefined ? undefined : ((value.addresses as Array<any>).map(AddressToJSON)),
        'walletHistories': value.walletHistories === undefined ? undefined : ((value.walletHistories as Array<any>).map(WalletHistoryToJSON)),
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(LocationToJSON)),
        'vouchers': value.vouchers === undefined ? undefined : ((value.vouchers as Array<any>).map(VoucherToJSON)),
        'devices': value.devices === undefined ? undefined : ((value.devices as Array<any>).map(DeviceToJSON)),
    };
}


