/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    Setting,
    SettingFromJSON,
    SettingToJSON,
    SettingDataFrame,
    SettingDataFrameFromJSON,
    SettingDataFrameToJSON,
} from '../models';

export interface ApiSettingsGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiSettingsIdDeleteRequest {
    id: number;
}

export interface ApiSettingsIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiSettingsPostRequest {
    relations?: string;
    deleted?: boolean;
    setting?: Setting;
}

/**
 * no description
 */
export class SettingApi extends runtime.BaseAPI {

    /**
     * Get list of Setting
     */
    async apiSettingsGetRaw(requestParameters: ApiSettingsGetRequest): Promise<runtime.ApiResponse<SettingDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of Setting
     */
    async apiSettingsGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<SettingDataFrame> {
        const response = await this.apiSettingsGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of Setting
     */
    async apiSettingsIdDeleteRaw(requestParameters: ApiSettingsIdDeleteRequest): Promise<runtime.ApiResponse<Setting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSettingsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingFromJSON(jsonValue));
    }

    /**
     * Remove entity of Setting
     */
    async apiSettingsIdDelete(id: number): Promise<Setting> {
        const response = await this.apiSettingsIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of Setting
     */
    async apiSettingsIdGetRaw(requestParameters: ApiSettingsIdGetRequest): Promise<runtime.ApiResponse<Setting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSettingsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingFromJSON(jsonValue));
    }

    /**
     * Get entity of Setting
     */
    async apiSettingsIdGet(id: number, relations?: string, deleted?: boolean): Promise<Setting> {
        const response = await this.apiSettingsIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of Setting (if id defined)
     */
    async apiSettingsPostRaw(requestParameters: ApiSettingsPostRequest): Promise<runtime.ApiResponse<Setting>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SettingToJSON(requestParameters.setting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingFromJSON(jsonValue));
    }

    /**
     * Create or update entity of Setting (if id defined)
     */
    async apiSettingsPost(relations?: string, deleted?: boolean, setting?: Setting): Promise<Setting> {
        const response = await this.apiSettingsPostRaw({ relations: relations, deleted: deleted, setting: setting });
        return await response.value();
    }

}
