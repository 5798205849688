import React from 'react';
import { FormSection } from 'src/components/forms/Forms';
import DeliveryInfo from 'src/components/deliveryInfo/deliveryInfo';
import { User, Address, Location } from 'src/api';


export const DeliverySection = ({ user, address }: { user: User | undefined; address: Address | Location | undefined }) => {
  if (!user || !address) {
    return null;
  }
  return (
    <FormSection>
      <h3>Dane dostawy</h3>
      <DeliveryInfo
        person={user.fullName ?? ''}
        phone={user.phone ?? ''}
        address={address}
        notes={address.additional ?? ''}
        deliveryTo6={'deliveryTo6' in address ? !!address.deliveryTo6 : false}
        deliveryTo7={'deliveryTo7' in address ? !!address.deliveryTo7 : false}
      />
    </FormSection>
  )
}
