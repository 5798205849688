import React from 'react';
import { FormattedInput as LibFormattedInput, InputFormat } from '@buttercup/react-formatted-input';
import { useField } from 'formik';
import TextField, { TextFieldProps } from './textField';

export type FormattedTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange?: (formatted: string, raw: string) => void;
  format: InputFormat;
};

export const NIPFormat: InputFormat = [
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 3 },
  { exactly: '-' },
  { char: /\d/, repeat: 2 },
  { exactly: '-' },
  { char: /\d/, repeat: 2 },
];

export const PostalFormat: InputFormat = [
  { char: /\d/, repeat: 2 },
  { exactly: '-' },
  { char: /\d/, repeat: 3 },
];


export const IBANFormat: InputFormat = [
  { char: /\d/, repeat: 2 },
  { exactly: ' ' },
  { char: /\d/, repeat: 4 },
  { exactly: ' ' },
  { char: /\d/, repeat: 4 },
  { exactly: ' ' },
  { char: /\d/, repeat: 4 },
  { exactly: ' ' },
  { char: /\d/, repeat: 4 },
  { exactly: ' ' },
  { char: /\d/, repeat: 4 },
  { exactly: ' ' },
  { char: /\d/, repeat: 4 },
];

export const FormattedTextField = (props: FormattedTextFieldProps) => {
  return <LibFormattedInput element={TextField} {...props} />;
}

type FormattedTextFormFieldProps<K extends string> = Omit<FormattedTextFieldProps, 'onChange' | 'onBlur' | 'value' | 'error'> & { name: K; parent?: string };
export const FormattedTextFormField = <K extends string>({ name, parent, ...props }: FormattedTextFormFieldProps<K>) => {
  const [field, meta, helpers] = useField(parent ? `${parent}.${name}` : name);
  return <FormattedTextField {...props} {...field} value={meta.value} onChange={helpers.setValue} onBlur={() => helpers.setTouched(true)} error={meta.touched ? meta.error : undefined} />;
}

export default FormattedTextField;
