import React from 'react';
import ContentPage from './contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import HalfImage from './halfImage/halfImage';

const AboutPage = () => (
  <ContentPage>
    <PageWrapper>
      <h1>O nas</h1>
      <HalfImage image="/images/about1.png" shift>
        <p>Od początku przyświeca nam jeden cel, aby każdy mógł{' '}
        <em>zjeść smacznie i zdrowo</em>, a nasze produkty pobudzały do życia i 
        <em> uwalniały pozytywną energię</em>. Już od 7 lat codziennie z pasją
        przygotowujemy dla Ciebie <em>pyszne dania</em>, które następnie nasi sprzedawcy
        dostarczają wprost do Twoich rąk.</p>
      </HalfImage>
      <h1 className="max960">
      Pyszną Pobudką możesz cieszyć się nie tylko w biurze, ale również w domu. Dostarczamy jedzenie prosto pod Twoje drzwi.
      </h1>
      <HalfImage image="/images/about2.png" invert>
        <p>Wychodząc naprzeciw dzisiejszym oczekiwaniom uruchomiliśmy właśnie specjalną 
          i innowacyjną usługę <em>zamówień on-line</em>, dzięki, której niezależnie, czy jesteś <em>w biurze, czy w domu</em> możesz na nas liczyć. 
          Specjalnie przygotowana funkcjonalność 
          kalendarza pozwoli zrobić Ci zamówienie z kilkudniowym wyprzedzeniem. Co więcej, 
          przyjazne menu i bardzo intuicyjny system zamówień są doskonale przystosowane 
          do ekranu Twojego laptopa i komórki. Już tylko kilka kliknięć dzieli Cię od smacznej Pobudki. 
           <em> Wybierz dni</em> w kalendarzu, następnie <em>posiłki</em>, jakie chcesz byśmy dla Ciebie dostarczyli i rano spodziewaj się <em>pysznej przesyłki pod Twoimi drzwiami</em>.</p>
      </HalfImage>
    </PageWrapper>
  </ContentPage>
);

export default AboutPage;
