/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrdersPerDaySummary
 */
export interface OrdersPerDaySummary {
    /**
     * 
     * @type {number}
     * @memberof OrdersPerDaySummary
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrdersPerDaySummary
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrdersPerDaySummary
     */
    ordersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersPerDaySummary
     */
    orderProductsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersPerDaySummary
     */
    orderItemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersPerDaySummary
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersPerDaySummary
     */
    avgAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrdersPerDaySummary
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrdersPerDaySummary
     */
    updatedAt?: Date;
}

export function OrdersPerDaySummaryFromJSON(json: any): OrdersPerDaySummary {
    return OrdersPerDaySummaryFromJSONTyped(json, false);
}

export function OrdersPerDaySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrdersPerDaySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'ordersCount': !exists(json, 'ordersCount') ? undefined : json['ordersCount'],
        'orderProductsCount': !exists(json, 'orderProductsCount') ? undefined : json['orderProductsCount'],
        'orderItemsCount': !exists(json, 'orderItemsCount') ? undefined : json['orderItemsCount'],
        'totalAmount': !exists(json, 'totalAmount') ? undefined : json['totalAmount'],
        'avgAmount': !exists(json, 'avgAmount') ? undefined : json['avgAmount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function OrdersPerDaySummaryToJSON(value?: OrdersPerDaySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'ordersCount': value.ordersCount,
        'orderProductsCount': value.orderProductsCount,
        'orderItemsCount': value.orderItemsCount,
        'totalAmount': value.totalAmount,
        'avgAmount': value.avgAmount,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}


