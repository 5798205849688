import React from 'react';
import styles from './cutleryDelivery.module.scss';
import CountEditor from '../../countEditor/countEditor';
import SmallBtn from '../../buttons/smallBtn/smallBtn';
import { displayPrice } from 'src/logic/utils/price';


type CutleryProps = {
  cutleryCount: number;
  updateCount: (quantity: number) => void;
  cutleryPrice: number;
}

const CutleryDelivery = ({ cutleryCount, updateCount, cutleryPrice }: CutleryProps) => (
  <div className={styles.cutleryItem}>
  <h3 className={styles.cutleryHeader}>Potrzebujesz sztućców?</h3>
  <footer>
    <div className={styles.cutleryItemActions}>
      <CountEditor value={cutleryCount} updateCount={(qt: number) => updateCount(qt)} small limit={100} />
      {cutleryCount > 0 && <SmallBtn action={()=>updateCount(-cutleryCount)}>usuń</SmallBtn>}
    </div>
    <b className={styles.cutleryValue}>{displayPrice(cutleryPrice * cutleryCount)}</b>
  </footer>
</div>
);

export default CutleryDelivery;
