import React from 'react';
import CountEditor from '../../countEditor/countEditor';
import styles from './orderItem.module.scss';
import SmallBtn from '../../buttons/smallBtn/smallBtn';
import { BasketItem } from 'src/logic/basket';
import { displayPrice } from 'src/logic/utils/price';
import { useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';

type OrderItemProps = {
  item: BasketItem;
  updateCount: (quantity: number, item: BasketItem) => void;
}

const OrderItem = ({ item, updateCount }: OrderItemProps) => {
  const { calories, protein, carbs, fat, weight } = item.productInfo;
  const packageVat = useSelector((gs: GlobalState) => gs.settings.PRODUCT_PACKAGE_VAT);
  const multiplyConst = (weight / 100) * item.quantity;
  return (
    <div className={styles.orderItem}>
      <h3 className={styles.orderHeader}>{item.productName}</h3>
      <p>{item.productDescription}</p>
      <p><b>Kalorie</b>: {(calories * multiplyConst).toFixed(1)} kcal / <b>Białko</b>: {(protein * multiplyConst).toFixed(1)} g / <b>Węglowodany</b>: {(carbs * multiplyConst).toFixed(1)} g / <b>Tłuszcze</b>: {(fat * multiplyConst).toFixed(1)} g</p>
      {item?.package?.name &&
      <div className={styles.orderItemPackage}>
        <p>{item?.package?.name}</p>
        <b>{displayPrice((item?.package?.value ?? 0) * item.quantity)}</b>
      </div>}
      <footer>
        <div className={styles.orderItemActions}>
          <CountEditor value={item.quantity} updateCount={(qt: number) => updateCount(qt, item)} small limit={item.itemLimit} />
          <SmallBtn action={() => updateCount(-item.quantity, item)}>usuń</SmallBtn>
        </div>
        <b className={styles.orderValue}>{displayPrice(item.discountedPrice * item.quantity)}</b>
      </footer>
    </div>
  );
}

export default OrderItem;
