import cn from 'classnames';
import React, { useEffect, useState, useCallback } from 'react';
import BaseNumberInput, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

import { Props as RightActionsProps, RightActions } from '../rightActions';
import styles from './input.module.scss';

export type NumberInputProps = NumberFormatProps & RightActionsProps & {
  fill?: boolean;
  step?: number;
} & RightActionsProps;

export const NumberInput = React.memo(function NumberInput({ step = 1, ...props }: NumberInputProps) {
  const { onValueChange } = props;
  const [value, changeValue] = useState(!!props.value || props.value === 0 ? parseFloat(`${props.value}`) : undefined);

  useEffect(() => {
    changeValue(!!props.value || props.value === 0 ? parseFloat(`${props.value}`) : undefined);
  }, [props.value]);

  useEffect(() => {
    if(value) {
      onValueChange?.({ floatValue: value, value: value.toString(), formattedValue: value.toString()});
    }
  }, [value]);

  const handleUp = useCallback(() => {
    changeValue(value => (value || 0) + step);
  }, [step]);

  const handleDown = useCallback(() => {
    changeValue(value => (value || 0) - step);
  }, [step]);

  const handleValueChange = useCallback((a: NumberFormatValues) => {
    if (parseFloat(`${a.value}`) === value) {
      return;
    }
    changeValue(parseFloat(`${a.value}`));
    onValueChange?.(a);
  }, [onValueChange, value]);

  const { className, fill, disabled, readOnly, disableActions, rightActions, placeholder, ...rest } = props;
  const containerClasses = cn(
    styles.component,
    {
      [styles.fill]: fill,
    },
  );
  const inputClasses = cn(
    styles.input,
    {
      [styles.fill]: fill,
    },
    className,
  );
  return (
    <div className={containerClasses}>
      <BaseNumberInput
        {...rest}
        className={inputClasses}
        disabled={disabled}
        value={value ?? ''}
        onValueChange={handleValueChange}
        readOnly={readOnly}
        placeholder={placeholder}
      />
      <div className={cn(styles.upDownButtons, { [styles.disabled]: disabled, [styles.readOnly]: readOnly })}>
        <button onClick={handleUp} disabled={disabled} tabIndex={-1}>&#9652;</button>
        <button onClick={handleDown} disabled={disabled} tabIndex={-1}>&#9662;</button>
      </div>
      <RightActions rightActions={rightActions} disableActions={disableActions} disabled={disabled} className={styles.rightActions} />
    </div>
  );
})
