import React from 'react';

type IconProps = {
  color: string;
};

const DeliveryIcon = ({color}: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M18.75 20.25C19.5784 20.25 20.25 19.5784 20.25 18.75C20.25 17.9216 19.5784 17.25 18.75 17.25C17.9216 17.25 17.25 17.9216 17.25 18.75C17.25 19.5784 17.9216 20.25 18.75 20.25Z"
          fill={color}
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 20.25C6.07843 20.25 6.75 19.5784 6.75 18.75C6.75 17.9216 6.07843 17.25 5.25 17.25C4.42157 17.25 3.75 17.9216 3.75 18.75C3.75 19.5784 4.42157 20.25 5.25 20.25Z"
          fill={color}
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.25 18.75H21.75C22.1478 18.75 22.5294 18.592 22.8107 18.3107C23.092 18.0294 23.25 17.6478 23.25 17.25V11.25C23.25 10.4544 22.9339 9.69129 22.3713 9.12868C21.8087 8.56607 21.0456 8.25 20.25 8.25H16.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 18.75V5.25C16.5 4.85218 16.342 4.47064 16.0607 4.18934C15.7794 3.90804 15.3978 3.75 15 3.75H2.25C1.85218 3.75 1.47064 3.90804 1.18934 4.18934C0.908035 4.47064 0.75 4.85218 0.75 5.25V17.25C0.75 17.6478 0.908035 18.0294 1.18934 18.3107C1.47064 18.592 1.85218 18.75 2.25 18.75H3.75"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.25 18.75H6.75"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.25 12.75H21C20.6022 12.75 20.2206 12.592 19.9393 12.3107C19.658 12.0294 19.5 11.6478 19.5 11.25V8.25"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 14.25H16.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DeliveryIcon;
