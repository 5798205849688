/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Voucher,
    VoucherFromJSON,
    VoucherFromJSONTyped,
    VoucherToJSON,
} from './';

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    house?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    local?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    additional?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    postalcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    delivery?: string;
    /**
     * 
     * @type {Date}
     * @memberof Location
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Location
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<Voucher>}
     * @memberof Location
     */
    vouchers?: Array<Voucher>;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'house': !exists(json, 'house') ? undefined : json['house'],
        'local': !exists(json, 'local') ? undefined : json['local'],
        'additional': !exists(json, 'additional') ? undefined : json['additional'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postalcode': !exists(json, 'postalcode') ? undefined : json['postalcode'],
        'delivery': !exists(json, 'delivery') ? undefined : json['delivery'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'vouchers': !exists(json, 'vouchers') ? undefined : ((json['vouchers'] as Array<any>).map(VoucherFromJSON)),
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'street': value.street,
        'house': value.house,
        'local': value.local,
        'additional': value.additional,
        'city': value.city,
        'postalcode': value.postalcode,
        'delivery': value.delivery,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'vouchers': value.vouchers === undefined ? undefined : ((value.vouchers as Array<any>).map(VoucherToJSON)),
    };
}


