import cn from 'classnames';
import React, { Component, ReactNode } from 'react';
import styles from './label.module.scss';

type Props = {
  children?: ReactNode;
  label: string;
  position: 'top' | 'bottom' | 'left' | 'right';
  inverted: boolean;
  gentle: boolean;
  fill: boolean;
  centerEntry?: boolean;
  className?: string;
};

export class Label extends Component<Props> {
  public static defaultProps = {
    gentle: false,
    inverted: false,
    position: 'top',
    fill: false,
  };
  public render() {
    const { label, position, children, inverted, gentle, fill, centerEntry, className } = this.props;
    const classes = cn(
      styles.label,
      {
        [styles.top]: position === 'top',
        [styles.bottom]: position === 'bottom',
        [styles.left]: position === 'left',
        [styles.right]: position === 'right',
        [styles.inverted]: inverted,
        [styles.gentle]: gentle,
        [styles.fill]: fill,
      },
      className
    );
    return (
      <div className={classes}>
        <div className={styles.labelText}>{label}</div>
        <div className={cn(styles.entry, {[styles.center]: centerEntry === true})}>{children}</div>
      </div>
    );
  }
}
