/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    nameForAd?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    vat?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    availability?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    pictureMenu?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    pictureName?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    descriptionForAd?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ingredients?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    dip?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    protein?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    fat?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    carbs?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    calories?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isPromoted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    defaultDays?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    _package?: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    deletedAt?: Date;
    /**
     * 
     * @type {Category}
     * @memberof Product
     */
    category?: Category;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Product
     */
    tags?: Array<Tag>;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameForAd': !exists(json, 'nameForAd') ? undefined : json['nameForAd'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'availability': !exists(json, 'availability') ? undefined : json['availability'],
        'pictureMenu': !exists(json, 'pictureMenu') ? undefined : json['pictureMenu'],
        'pictureName': !exists(json, 'pictureName') ? undefined : json['pictureName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'descriptionForAd': !exists(json, 'descriptionForAd') ? undefined : json['descriptionForAd'],
        'ingredients': !exists(json, 'ingredients') ? undefined : json['ingredients'],
        'dip': !exists(json, 'dip') ? undefined : json['dip'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'protein': !exists(json, 'protein') ? undefined : json['protein'],
        'fat': !exists(json, 'fat') ? undefined : json['fat'],
        'carbs': !exists(json, 'carbs') ? undefined : json['carbs'],
        'calories': !exists(json, 'calories') ? undefined : json['calories'],
        'isPromoted': !exists(json, 'isPromoted') ? undefined : json['isPromoted'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'defaultDays': !exists(json, 'defaultDays') ? undefined : json['defaultDays'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        '_package': !exists(json, 'package') ? undefined : json['package'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'nameForAd': value.nameForAd,
        'order': value.order,
        'price': value.price,
        'vat': value.vat,
        'availability': value.availability,
        'pictureMenu': value.pictureMenu,
        'pictureName': value.pictureName,
        'description': value.description,
        'descriptionForAd': value.descriptionForAd,
        'ingredients': value.ingredients,
        'dip': value.dip,
        'weight': value.weight,
        'protein': value.protein,
        'fat': value.fat,
        'carbs': value.carbs,
        'calories': value.calories,
        'isPromoted': value.isPromoted,
        'note': value.note,
        'defaultDays': value.defaultDays,
        'categoryId': value.categoryId,
        'package': value._package,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'category': CategoryToJSON(value.category),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
    };
}


