import React, { useEffect } from 'react';
import styles from './account.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'src/logic/user';
import { GlobalState } from 'src/logic/reducers';
import { displayPrice } from 'src/logic/utils';
import { routes } from 'src/routes';

import ContentHeader from '../content/contentHeader/contentHeader';

const AccountHeader = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.load.request())
  }, [dispatch]);

  const userDetails = useSelector((gs: GlobalState) => gs.user.details);

  const menu = [
    { label: 'Moje dostawy', path: routes.accountOrders(), requireLogin: true},
    { label: 'Dane konta', path: routes.accountProfile(), requireLogin: true },
    { label: 'Adres dostaw', path: routes.accountAddresses(), requireLogin: true },
    { label: <>Saldo konta {userDetails?.wallet?<span className={styles.balanceValue}>{displayPrice(userDetails?.wallet ?? 0)}</span>:''}</>, path: routes.accountBalance(), requireLogin: true }
  ];

  return (
    <ContentHeader menu={menu} logout />
  );
};
export default AccountHeader;
