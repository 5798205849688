import React from 'react';
import styles from './modal.module.scss';

type ModalProps = {
  close(): void;
  children: React.ReactNode;
};

const Modal = ({ close, children }: ModalProps) => (
  <div className={styles.modalBackdrop}>
    <div className={styles.modal}>
      <a onClick={close} className={styles.closeModal}>&times;</a>
      {children}
    </div>
  </div>
);
export default Modal;
