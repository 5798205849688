import React from 'react';
import styles from './halfImage.module.scss';

type HalfImageProps = {
    invert?: boolean;
    children?: React.ReactNode;
    image?: string;
    shift?: boolean;
  };


const HalfImage = ({invert, children, image, shift}: HalfImageProps) => (
  <section className={[styles.halfImage, invert && styles.invert, shift && styles.shift].join(' ')}>
    <article className={styles.text}>
      {children}
    </article>
        <div className={styles.pic}>
        <picture>
          <img src={image} alt='' />
        </picture>
        </div>
  </section>
);
export default HalfImage;
