import React from 'react';
import styles from './feature.module.scss';

type FeatureProps = {
  type: 'cal' | 'bag' | 'delivery';
  text: string;
}

const Feature = ({type, text}: FeatureProps) => (
  <div className={styles.feature}>
    <div className={[styles.featureImg, styles[type]].join(' ')}></div>    
    <div className={styles.featureContent}>
      {text.split('\n').map((t, i) => <p key={i}>{t}</p>)}
    </div>
  </div>
);
export default Feature;
