/* eslint-disable @typescript-eslint/camelcase */
import {  MenuItem, Order, Voucher } from 'src/api';
import { Basket } from './basket';
import { FormsValues } from 'src/components/buy/buyForm';
import { sum } from 'lodash';
import { DateString } from './utils';

export const setItemToGtmItem = (item: MenuItem) => {
  return { id: item?.id, name: item?.product?.name, category: item?.product?.category?.name, price: item?.price };
}
const windowAsAny = window as any;
windowAsAny.dataLayer = windowAsAny.dataLayer || [];

const fixedPrice = (price?: number): number => {
    return Number(((price || 0) / 100).toFixed(2));
}

const getItemObject = (item: Item) => {
  return {
    item_id: item.id,
    item_name: item?.name,
    price: fixedPrice(item.price),
    item_brand: 'Pobudka',
    item_category: item.category,
  }
}
type Item = {
  name?: string;
  id?: number;
  price?: number;
  category?: string;
}
const getItems = (item: Item, date: DateString | null) => {
  return {
    items: [getItemObject(item)],
    item_list_name: item.category,
    item_list_id: `menu/${date}`,
    currency: 'PLN',
}
}

export function gtmViewItem(item: Item, date: DateString | null) {
  windowAsAny.dataLayer.push({
    event: 'view_item',
    ecommerce: getItems(item, date),
  });
}

export function gtmSelectItem(item: Item, date: DateString | null) {
  windowAsAny.dataLayer.push({
    event: 'select_item',
    ecommerce: getItems(item, date),
  });
}

export function gtmViewItemList(items: Item[]) {
  windowAsAny.dataLayer.push({
    event: 'view_item_list',
      ecommerce: {
        items: items.map((item) => { return {
          ...getItemObject(item),
          item_list_name: item.category,
        }})
      }
  });
}

export function gtmAddToCart(item: Item) {
  windowAsAny.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'PLN',
      value: fixedPrice(item.price),
      items: [{
        ...getItemObject(item),
        quantity: 1,
      }]
    }
  });
}

export function gtmRemoveFromCart(item: Item) {
  windowAsAny.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'PLN',
      value: fixedPrice(item.price),
      items: [{
        ...getItemObject(item),
        quantity: 1,
      }]
    }
  });
}


const dataBasket = (items: Basket) => Object.keys(items).map(function(key) {
  return {
    [key]: {
      cutluery: items[key].cutlery,
      items: items[key].items.map(item => {
        return {
          ...getItemObject({id: item.productId, name: item.productName, category: item.productCategory, price: item.price }),
          item_list_name: item.productCategory,
          quantity: item.quantity,
        }
      })
    }
  }
});

export function gtmViewCart() {
  windowAsAny.dataLayer.push({
    event: 'view_cart',
  });
}

export function gtmBeginCheckout(items: Basket, value: number, voucher: Voucher[]) {
  windowAsAny.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      value: fixedPrice(value),
      currency: 'PLN',
      promotion: voucher.map(voucher => voucher.code),
      items: dataBasket(items)
    }
  })
}

export function gtmShippingInfo(items: Basket, value: number, buyFormValues: FormsValues) {
  windowAsAny.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      value: fixedPrice(value),
      currency: 'PLN',
      delivery: buyFormValues.home,
      items: dataBasket(items)
    }
  });
}

export function gtmPaymentInfo(items: Basket, value: number, buyFormValues: FormsValues) {
  windowAsAny.dataLayer.push({
    event:  'add_payment_info',
    ecommerce: {
      value: fixedPrice(value),
      currency: 'PLN',
      customer: {
        name: buyFormValues.name,
        last_name: buyFormValues.lastName,
        email: buyFormValues.email,
        phone: buyFormValues.phone,
      },
      items: dataBasket(items),
    }
  });
}

export function gtmPurchase(order: Order) {
  windowAsAny.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: order.id,
      currency: 'PLN',
      shipping: fixedPrice(sum(order?.menus?.map(menu => menu.delivery ?? 0) ?? [])),
      value: fixedPrice(order.price),
      items: order?.menus?.flatMap(
        menu => menu?.items?.map(item => ({
          ...getItemObject({id: item.id, name: item.menuItem?.product?.name, category: item.menuItem?.product?.category?.name, price: item.price }),
          quantity: item.quantity,
        }))
      )
    }
  });
}

export function gtmLogin() {
  windowAsAny.dataLayer.push({
    event: 'log_in',
  });
}

export function gtmRegister() {
  windowAsAny.dataLayer.push({
    event: 'create_account',
  });
}

export function gtmDeviceType(deviceType: string) {
  windowAsAny.dataLayer.push({
    event: 'device_type',
    deviceType: deviceType
  });
}
