/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileDataFrame
 */
export interface FileDataFrame {
    /**
     * 
     * @type {number}
     * @memberof FileDataFrame
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof FileDataFrame
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof FileDataFrame
     */
    limit: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof FileDataFrame
     */
    data: Array<any>;
}

export function FileDataFrameFromJSON(json: any): FileDataFrame {
    return FileDataFrameFromJSONTyped(json, false);
}

export function FileDataFrameFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDataFrame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'offset': json['offset'],
        'limit': json['limit'],
        'data': json['data'],
    };
}

export function FileDataFrameToJSON(value?: FileDataFrame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'offset': value.offset,
        'limit': value.limit,
        'data': value.data,
    };
}


