import React, { useEffect } from 'react';
import Modal from 'src/containers/modal/modal';
import Fieldset from 'src/containers/fieldset/fieldset';
import styles from './congrats.module.scss';
import { BtnForm } from 'src/components/forms/Forms';
import { gtmPurchase } from 'src/logic/gtm';
import { Order } from 'src/api';

export type OrderCreatedPaymentStatus = 'paid' | 'paymentFailed';

export const OrderCreatedModal = ({ open, status, close, order }: { open: boolean; status: OrderCreatedPaymentStatus | undefined; close: () => void; order: Order | null }) => {
  useEffect(() => { order && gtmPurchase(order) }, [order?.id]);

  if (!open || !status) {
    return null;
  }

  return (
    <Modal close={close}>
      <Fieldset title="Stworzono zamówienie">
        <div className={styles.modalInfo}>
          { (status === 'paid') &&
<>
          <div className={styles.pic}>
          <div className={styles.ok}></div>
          </div>
          <h2>Gratulujemy! </h2>
          <p>
          Zabieramy się za szykowanie Twojego zamówienia. Do zobaczenia! :)
          </p>
          </>
          }
          { (status === 'paymentFailed') &&
          <>
          <div className={styles.pic}>
          </div>
          <h2>
          Zamówienie trafiło do systemu!</h2>
          <p>
          </p>
          <p>
          Natomiast jeszcze nie wpłyneło potwierdzenie przelewu.
          </p>
          <p>
           Jeśli jest ok - poczekaj chwilę, jeśli nastąpił bład - opłać ponownie! :)
          </p>
          </>

          }
        </div>
        <div className={styles.centerBtn}>
        <BtnForm action={close} type="orange">
        OK
        </BtnForm>
        </div>
      </Fieldset>
    </Modal>
  );
}
