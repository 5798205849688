import { useRouteMatch } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { routes } from 'src/routes';
import { DateString, getDateString } from 'src/logic/utils/dates';
import { GlobalState } from 'src/logic/reducers';
import { getMinMenu } from 'src/logic/menu';
import { isValid } from 'date-fns';

export const useMinMenu = () => {
  const menus = useSelector((gs: GlobalState) => gs.menu.menu);
  const timeToOrder = useSelector((gs: GlobalState) => gs.settings.TIME_TO_ORDER);

  const minMenu = useMemo(() => getMinMenu(menus, timeToOrder), [menus, timeToOrder]);
  return minMenu;
};

export const useParamDate = (): [Date | null, DateString | null, string | undefined] => {
  const m = useRouteMatch<{ date: string }>();
  if (m.params.date && isValid(new Date(m.params.date))) {
    return [new Date(m.params.date), getDateString(new Date(m.params.date)), m.params.date];
  }
  return [null, null, m.params.date];
};

export const useActiveDate = () => {
  const [, activeDate, dateParam] = useParamDate();
  const additionalParameter = dateParam && !isValid(new Date(dateParam)) ? `/${dateParam}` : '';
  const dispatch = useDispatch();

  const minMenu = useMinMenu();

  useEffect(() => {
    if ((!activeDate || new Date(activeDate).getTime() < (minMenu?.date?.getTime() ?? 0)) && minMenu?.date) {
      dispatch(replace(`${routes.menu(minMenu?.date ?? '')}${additionalParameter}${window.location.hash}`));
    }
  }, [activeDate, dispatch, minMenu, additionalParameter]);
  return activeDate;
};
