import React from 'react';
import ContentPage from './contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';

const Rules = () => (
  <ContentPage>
    <PageWrapper>
      <div className="max960 rules">
        <h2 className="h2">Regulamin Serwisu &quot;Pobudka”</h2>
        <h3 className="h3">
          określający zasady składania zamówień oraz świadczenia usług.
        </h3>
        Postanowienia dotyczące Przedsiębiorcy uprzywilejowanego mają zastosowanie do
umów zawartych od dnia 1 stycznia 2021 r.
        <h3>§ 1 Definicje</h3>
        <ol start={1} type="1">
          <li>
            <b>Dni robocze</b>- dni od poniedziałku do piątku za wyjątkiem dni
            ustawowo wolnych od pracy.
          </li>
          <li>
            <b>Konsument</b>- osoba fizyczna dokonująca ze Sprzedawcą, za
            pośrednictwem Serwisu, czynności prawnej niezwiązanej bezpośrednio z
            jej działalnością gospodarczą lub zawodową.
          </li>
          <li>
            <b>Użytkownik uprzywilejowany</b>– Konsument lub Przedsiębiorca uprzywilejowany.
          </li>
          <li>
            <b>Przedsiębiorca uprzywilejowany</b>– osoba fizyczna zawierająca Umowę ze Sprzedawcą
bezpośrednio związaną z jej działalnością gospodarczą, ale nie posiadającą dla niej charakteru
zawodowego (definicja obowiązuje dla umów zawartych od dnia 1 stycznia 2021 r.).
          </li>
          <li>
            <b>Konto Prepaid</b>- funkcjonalność Konta Użytkownika pozwalająca
            na przedpłatę pieniędzy na poczet przyszłych Zamówień.
          </li>
          <li>
            <b>Voucher</b>- voucher, w postaci ciągu znaków, pozwalający na
            obniżenie ceny za Zamówienie w Serwisie.
          </li>
          <li>
            <b>Konto Użytkownika</b>- usługa świadczona przez Sprzedawcę drogą
            elektroniczną, która umożliwia Użytkownikowi skorzystanie z
            wszystkich funkcji Serwisu m.in. w zakresie zarządzania Zamówieniami
            (w tym na modyfikację oraz anulowanie zamówień na poszczególne dni -
            w przypadku Zamówień, dla których jest taka możliwość), dostępu do
            wystawionych faktur oraz korzystania z Konta Prepaid.
          </li>
          <li>
            <b>Produkty</b>- produkty spożywcze (zwłaszcza dania) przygotowywane
            przez Sprzedawcę na zamówienie Użytkownika mające, ze względu na
            swój charakter, krótki termin przydatności do spożycia.
          </li>
          <li>
            <b>Serwis</b>- serwis internetowy &quot;Pobudka&quot; prowadzony
            przez Sprzedawcę pod adresem{' '}
            <a href="http://pobudka.waw.pl/">http://pobudka.waw.pl/</a> za
            pośrednictwem, którego Użytkownik może zamówić Produkty na wskazany
            przez siebie adres, w określonym przez siebie terminie lub okresie,
            na zasadach określonych w Regulaminie.
          </li>
          <li>
            <b>Sprzedawca</b>- Łukasz Besaraba, prowadzący działalność
            gospodarczą pod firmą POBUDKA Łukasz Besaraba, ul. Projektowana 2a, 
            05-805 Otrębusy, wpisany do Centralnej
            Ewidencji i Informacji o Działalności Gospodarczej prowadzonej przez
            ministra właściwego do spraw gospodarki i prowadzenia Centralnej
            Ewidencji i Informacji o Działalności Gospodarczej, NIP: 5732704967,
            REGON: 146849386.
          </li>
          <li>
            <b>Użytkownik</b>- każdy użytkownik Serwisu.
          </li>
          <li>
            <b>Zamówienie</b>- zamówienie na Produkty złożone za pośrednictwem
            Serwisu, mogące obejmować kilka dni, w ramach którego Sprzedawca
            dostarcza Użytkownikowi zamówione Produkty.
          </li>
          <li>
            <b>Regulamin Serwisu</b>- niniejszy regulamin określający zasady
            korzystania z Serwisu oraz realizacji Zamówień.
          </li>
        </ol>
        <h3>§ 2 Dane Sprzedawcy</h3>
        <ol start={1} type="1">
          <li>Adres pocztowy: ul. Projektowana 2a, 05-805 Otrębusy</li>
          <li>
            Adres e-mail:{' '}
            <a href="mailto:kontakt@pobudka.waw.pl">kontakt@pobudka.waw.pl</a>
          </li>
          <li>
            Numer telefonu: <a href="tel:784635440">784 635 440</a>
          </li>
        </ol>
        <h3>§ 3 Składanie Zamówień</h3>
        <ol start={1} type="1">
          <li>
            Zamówienia mogą być składane przez Użytkowników zarówno z
            wykorzystaniem Konta Użytkownika jak i z pominięciem Konta
            Użytkownika.
          </li>
          <li>
            Użytkownik składając Zamówienie wybiera termin lub okres dostawy
            Zamówienia oraz Produkty - z dostępnych w Serwisie.
          </li>
          <li>
            Sprzedawca nie realizuje dostaw w dni ustawowo wolne od pracy, a
            także w okresie przerw świątecznych czy Dni roboczych przypadających
            w tzw. &quot;Długie weekendy&quot;. Szczegółowe informacje w tym
            zakresie dostępne są w Serwisie.
          </li>
          <li>
            Dostawa Produktów możliwa jest wyłącznie na terenie miejscowości w
            całej Polsce. Szczegółowe informacje w tym zakresie
            znajdują się w Serwisie - Użytkownik przez podanie kodu pocztowego
            miejsca dostawy uzyska informację czy dostawa dla danej lokalizacji
            jest dostępna.
          </li>
          <li>
            Zamówienie należy złożyć i opłacić najpóźniej do godziny 12:00 w
            Dzień roboczy poprzedzający dzień dostawy.
          </li>
          <li>
            W przypadku złożenia Zamówienia obejmującego kilka dni termin, o
            którym mowa w ustępie 4, określa się w odniesieniu do dnia, w którym
            ma zostać zrealizowana pierwsza dostawa w ramach danego Zamówienia.
          </li>
          <li>
            Użytkownik składający Zamówienie za pośrednictwem Konta Użytkownika,
            z zastrzeżeniem ust. 8, może za jego pośrednictwem edytować lub
            anulować zamówienia na poszczególne dni, w terminach wskazanych w
            ust.5.
          </li>
          <li>
            Zamówienia złożone z wykorzystaniem Vouchera obniżającego kwotę
            zamówienia o określoną Voucherem kwotę nie mogą zostać anulowane
            oraz modyfikowane.
          </li>
          <li>
            Zwrot środków za zamówienia anulowane lub modyfikowane - jeśli
            modyfikacja będzie się wiązać ze zmniejszeniem wartości zamówienia,
            nastąpi na Konto Prepaid Użytkownika.
          </li>
        </ol>
        <h3>§ 4 Koszt Zamówień</h3>

        <ol start={1} type="1">
          <li>
            Ceny podane w Serwisie są cenami brutto tj. zawierają podatek VAT.
          </li>
          <li>
            Na całkowitą cenę Zamówienia składa się łączny koszt zamówionych
            Produktów oraz dostawy.
          </li>
          <li>
            W przypadku gdy Użytkownik posiada Voucher należy go wprowadzić
            przed złożeniem Zamówienia, w odpowiednim miejscu w Serwisie.
          </li>
        </ol>
        <h3>§ 5 Płatności</h3>
        <ol start={1} type="1">
          <li>
            Za Zamówienie złożone w Serwisie należy zapłacić z góry,
            bezpośrednio po jego złożeniu, przy wykorzystaniu metod płatności
            wskazanych w ustępie 3.
          </li>
          <li>
            Zamówienia nieopłacone w terminie określonym w § 3 ust. 4 i 5 nie
            będą przez Sprzedawcę realizowane, a środki wpłacone po tym terminie
            zostaną Użytkownikowi zwrócone chyba, że Strony zgodnie ustalą
            inaczej (np. o przekazaniu tych środków na Konto Prepaid).
          </li>
          <li>
            W Serwisie dostępne są następujące metody płatności:
            <ol type="a">
              <li>
                za pośrednictwem bramki płatniczej z wykorzystaniem rachunku
                bankowego Użytkownika (w tym blik);
              </li>
              <li>
                za pośrednictwem bramki płatniczej z wykorzystaniem karty
                płatniczej Użytkownika;
              </li>
              <li>płatność z Konta Prepaid Użytkownika;</li>
            </ol>
          </li>
          <li>
            Szczegółowa lista akceptowanych form płatności (w tym rodzaje
            akceptowanych kart płatniczych), o których mowa w ustępie 3 dostępna
            jest w Serwisie.
          </li>
          <li>
            Zasilenia Konta Prepaid można dokonać w sposób wskazany w § 5 ust. 3
            lit. a) oraz b).
          </li>
          <li>
            Minimalna kwota zasilenia Konta Prepaid wynosi 50 złotych, a
            maksymalna 1000 złotych.
          </li>
        </ol>
        <h3>§ 6 Realizacja zamówień</h3>
        <ol start={1} type="1">
          <li>
            Sprzedawca realizuje wyłącznie Zamówienia opłacone zgodnie z
            postanowieniami § 3 Regulaminu.
          </li>
          <li>
            Realizacja Zamówień odbywa się w dniach określonych w Serwisie z tym
            zastrzeżeniem, że Sprzedawca nie realizuje Zamówień w weekendy oraz
            w dni ustawowo wolne od pracy.
          </li>
          <li>
            Dostarczanie Zamówień odbywa się od godziny 02:00 do godziny 08:00.
          </li>
          <li>
            W przypadku Zamówień, które mają zostać dostarczone na adresy
            firmowe będą one dostarczane do recepcji firmy lub całego budynku.
          </li>
          <li>
            Podając adres firmy jako adres dostawy dla Zamówienia Użytkownik
            zapewnia, że osoba czynna w recepcji wskazanej przez Użytkownika
            firmy będzie uprawniona do odbioru Zamówienia w imieniu Użytkownika,
            w godzinach określonych w ustępie 3 oraz dokona takiego odbioru.
          </li>
          <li>Sprzedawca jest zobowiązany do dostarczenia towarów bez wad.</li>
        </ol>
        <h3>§ 7 Brak możliwości odstąpienia od umowy</h3>
        <ol start={1} type="1">
          <li>
            W związku z tym, że przedmiotem świadczenia w ramach umów
            zawieranych za pośrednictwem Serwisu są rzeczy ulegające szybkiemu
            zepsuciu lub mające krótki termin przydatności do użycia i
            jednocześnie świadczone są przez Sprzedawcę usługi w zakresie
            gastronomii, których dzień lub okres świadczenia jest oznaczony w
            umowie i indywidualnie uzgodniony z Użytkownikiem, zgodnie z art. 38
            ustawy o prawach konsumenta Użytkownikowi nie przysługuje prawo
            odstąpienia od umowy zawartej za pośrednictwem Serwisu.
          </li>
        </ol>
        <h3>§ 8 Reklamacje</h3>
        <ol start={1} type="1">
          <li>
            W przypadku wystąpienia wady Produktu Użytkownik ma możliwość
            reklamowania wadliwego Produktu na podstawie uregulowanej w Kodeksie
            cywilnym rękojmi.
          </li>
          <li>
            Korzystając z rękojmi Użytkownik może, na zasadach oraz w terminach
            określonych w Kodeksie cywilnym:
          </li>
          <ol start={1} type="a">
            <li>złożyć oświadczenie o obniżeniu ceny,</li>
            <li>
              przy wadzie istotnej - złożyć oświadczenie o odstąpieniu od umowy,
            </li>
            <li>żądać wymiany rzeczy na wolną od wad,</li>
            <li>żądać usunięcia wady.</li>
          </ol>
          <li>
            Sprzedawca prosi o składanie reklamacji na podstawie rękojmi, a
            także reklamacji dotyczących działania Serwisu na adres
            elektroniczny wskazany w § 2 Regulaminu.
          </li>
          <li>
            Rozpatrzenie reklamacji przez Sprzedawcę nastąpi w terminie do 14
            dni.
            <br />
            <br />
            <b>
              POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ
            </b>
          </li>
          <li>
            W przypadku, gdy postępowanie reklamacyjne nie przyniesie
            oczekiwanego przez Konsumenta rezultatu, Konsument może skorzystać
            m.in. z:
          </li>
          <ol start={1} type="a">
            <li>
              mediacji prowadzonej przez właściwy terenowo Wojewódzki
              Inspektorat Inspekcji Handlowej, do którego należy się zwrócić z
              wnioskiem o mediację. Co do zasady postępowanie jest bezpłatne.
              Wykaz Inspektoratów znajduje się tutaj:
              <a
                href="https://www.uokik.gov.pl/wazne_adresy.php#faq595"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.uokik.gov.pl
              </a>
              ;
            </li>
            <li>
              pomocy właściwego terenowo stałego polubownego sądu konsumenckiego
              działającego przy Wojewódzkim Inspektoracie Inspekcji Handlowej,
              do którego należy złożyć wniosek o rozpatrzenie sprawy przed sądem
              polubownym. Co do zasady postępowanie jest bezpłatne. Wykaz sądów
              dostępny jest pod adresem:
              <a
                href="https://www.uokik.gov.pl/wazne_adresy.php#faq596"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.uokik.gov.pl
              </a>
              ;
            </li>
            <li>
              bezpłatnej pomocy miejskiego lub powiatowego rzecznika
              Konsumentów;
            </li>
            <li>
              internetowej platformy ODR dostępnej pod adresem:
              <a
                href="https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks"
                rel="noopener noreferrer"
                target="_blank"
              >
                ec.europa.eu/consumers
              </a>
              .
            </li>
          </ol>
        </ol>
        <h3>§ 9 Konto Użytkownika</h3>
        <ol start={1} type="1">
          <li>
            Konto Użytkownika można założyć poprzez formularz rejestracyjny
            dostępny w Serwisie.
          </li>
          <li>
            Usługa, a także Zamówienia składane za pośrednictwem Konta
            Użytkownika, realizowane są zgodnie z niniejszym regulaminem.
          </li>
          <li>
            W momencie utworzenia Konta Użytkownika zostaje zawarta między
            Użytkownikiem a Sprzedawcą umowa na czas nieokreślony o świadczenie
            usługi Konto Użytkownika.
          </li>
          <li>Konto Użytkownika prowadzone jest dla Użytkownika bezpłatnie.</li>
          <li>
            Użytkownik w każdej chwili może zrezygnować z Konta Użytkownika, bez
            podawania przyczyny i ponoszenia z tego powodu jakichkolwiek kosztów
            wysyłając na adres e-mail Sprzedawcy określony w § 2 wiadomość w tym
            zakresie.
          </li>
          <li>
            Konto Użytkownika zostanie usunięte niezwłocznie po rezygnacji
            Użytkownika, a umowa rozwiązana.
          </li>
          <li>
            W przypadku gdy w momencie rozwiązania umowy Użytkownik ma
            niezrealizowane Zamówienia - ich realizacja odbywa się na
            dotychczasowych warunkach.
          </li>
          <li>
            W przypadku rozwiązania umowy środki wpłacone przez Użytkownika na
            Konto Prepaid zostaną zwrócone Użytkownikowi na rachunek bankowy, w
            terminie 7 dni od przekazania Sprzedawcy przez Użytkownika numeru
            bankowego.
          </li>
        </ol>
        <h3>§ 10 Dane osobowe</h3>
        <ol type="1">
          <li>
            Administratorem danych osobowych Użytkownika, przekazanych
            podczas korzystania z Serwisu, jest Sprzedawca.
          </li>
          <li>
            Celem przetwarzania danych przez Sprzedawcę, podanych przez
            Użytkownika w związku z korzystaniem z Serwisu, jest w szczególności
            realizacja umów zawartych za pośrednictwem Serwisu. Podstawą
            przetwarzania danych osobowych w tym przypadku jest:
            <br />
            - Umowa lub działania podejmowane na żądanie Użytkownika,
            zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO) oraz
            <br /> - prawnie uzasadniony interes Sprzedawcy, polegający na
            przetwarzaniu danych w celu zapewnienia możliwości ustalenia,
            dochodzenia lub obrony ewentualnych roszczeń (art. 6 ust. 1 lit. f
            RODO).
          </li>
          <li>
            Szczegółowe informacje dotyczące przetwarzania danych osobowych
            Użytkownika przez Sprzedawcę - w tym o pozostałych celach oraz
            podstawach przetwarzania danych, a także o odbiorcach danych -
            znajdują się w dostępnej w <a>Serwisie polityce prywatności </a>- ze
            względu na zasadę przejrzystości, zawartą w ogólnym rozporządzeniu
            Parlamentu Europejskiego i Rady (UE) o ochronie danych (<q>RODO</q>
            ).
          </li>
          <li>
            Podanie danych przez Użytkownika jest dobrowolne, ale
            jednocześnie konieczne do zawarcia umów ze Sprzedawcą.
          </li>
          <li>
            Dane Użytkownika podane w związku z korzystaniem z Serwisu będą
            przetwarzane do momentu, w którym:
            <ol type="a">
            <li>ustanie możliwość dochodzenia roszczeń związanych z
            umowami zawieranymi za pośrednictwem Serwisu;
            </li><li>zostanie przyjęty sprzeciw Użytkownika wobec przetwarzania
            jego danych osobowych - w przypadku gdy podstawą przetwarzania
            danych był uzasadniony interes Użytkownika - w zależności od tego,
            co ma zastosowanie w danym przypadku i co nastąpi najpóźniej.
            </li></ol>
          </li>
          <li>
            Użytkownikowi przysługuje prawo żądania:
            <ol type="a">
              <li>dostępu do swoich danych osobowych,</li>
              <li>ich sprostowania,</li>
              <li>usunięcia,</li>
              <li>ograniczenia przetwarzania,</li>
              <li>
                żądania przeniesienia danych do innego administratora
                <br />a także prawo:
              </li>
              <li>
                wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania
                danych z przyczyn związanych ze szczególną sytuacją Użytkownika
                - wobec przetwarzania dotyczących go danych osobowych, opartego
                na art. 6 ust. 1 lit. f RODO (tj. na prawnie uzasadnionych
                interesach realizowanych przez administratora).
              </li>
            </ol>
          </li>
          <li>
            W celu realizacji swoich praw, Użytkownik powinien skontaktować
            się ze Sprzedawcą za pośrednictwem adresu e-mail:{' '}
            <a href="mailto:kontakt@pobudka.waw.pl">kontakt@pobudka.waw.pl</a>
          </li>
          <li>
            {' '}
            W przypadku gdy Użytkownik uzna, że jego dane są przetwarzane
            niezgodnie z prawem, może złożyć skargę do Prezesa Urzędu Ochrony
            Danych Osobowych.
          </li>
        </ol>
        <h3>§ 11 Postanowienia końcowe</h3>
        <ol start={1} type="1">
          <li>
            Zakazane jest dostarczanie przez Użytkowników treści o charakterze
            bezprawnym.
          </li>
          <li>Dla prawidłowego funkcjonowania Serwisu potrzebne jest:</li>
          <ol start={1} type="1">
            <li>urządzenie z dostępem do Internetu</li>
            <li>
              przeglądarka internetowa obsługująca JavaScript oraz pliki
              cookies.
            </li>
          </ol>
          <li>
            Dla złożenia Zamówienia w Sklepie, a także dla założenia Konta
            Użytkownika poza wymogami określonymi w ust. 2, niezbędne jest
            aktywne konto e-mail oraz numer telefonu.
          </li>
          <li>
            Każde Zamówienie składane za pośrednictwem Serwisu stanowi odrębną
            umowę, która jest zawierana na czas realizacji Zamówienia.
          </li>
          <li>
            W przypadku Użytkowników posiadających Konto Użytkownika<b> </b>
            Sprzedawca zastrzega sobie prawo do zmiany Regulaminu. Użytkownicy
            składający Zamówienia bez wykorzystania Konta Użytkownika
            każdorazowo akceptują regulamin.
          </li>
          <li>
            Zmiana może nastąpić tylko z ważnych przyczyn. Jako ważną przyczynę
            rozumie się konieczność zmiany Regulaminu spowodowaną:
            <ol type="a">
              <li>
                dostosowaniem Serwisu do przepisów prawa, mających wpływ na
                usługi świadczone za pośrednictwem Serwisu;
              </li>
              <li>poprawą bezpieczeństwa świadczonych usług; </li>
              <li>
                rozwojem Serwisu polegającym na dodaniu nowych usług czy
                usprawnieniu już funkcjonujących (np. dodanie kolejnych metod
                płatności, rozszerzenie obszaru dostaw Zamówień).
              </li>
            </ol>
          </li>
          <li>
            Zmiana regulaminu nie będzie miała zastosowania do już złożonych
            Zamówień, będą one realizowane w oparciu o zasady obowiązujące w
            momencie ich składania.
          </li>
          <li>
            Informacja o planowanej zmianie Regulaminu zostanie wysłana na adres
            e-mail Użytkownika, podany w Koncie Użytkownika, co najmniej na 14
            dni przed wprowadzeniem zmian w życie.
          </li>
          <li>
            W przypadku gdy Użytkownik nie sprzeciwi się planowanym zmianom do
            chwili wejścia ich w życie - przyjmuje się, że akceptuje je.
          </li>
          <li>
            W przypadku braku akceptacji dla planowanych zmian Użytkownik
            powinien przekazać informację o tym na adres e-mail podany w § 2
            Regulaminu, co będzie skutkować usunięciem Konta Użytkownika oraz
            rozwiązaniem umowy o świadczenie usługi z chwilą wejścia w życie
            planowanych zmian, a także zwrotem środków zgromadzonych na Koncie
            Prepaid na zasadach określonych w § 9 ust. 8 Regulaminu.
          </li>
        </ol>
      </div>
    </PageWrapper>
  </ContentPage>
);

export default Rules;
