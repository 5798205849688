/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    OrdersPerDaySummary,
    OrdersPerDaySummaryFromJSON,
    OrdersPerDaySummaryToJSON,
    OrdersPerDaySummaryDataFrame,
    OrdersPerDaySummaryDataFrameFromJSON,
    OrdersPerDaySummaryDataFrameToJSON,
} from '../models';

export interface ApiOrdersPerDaySummariesGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiOrdersPerDaySummariesIdDeleteRequest {
    id: number;
}

export interface ApiOrdersPerDaySummariesIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiOrdersPerDaySummariesPostRequest {
    relations?: string;
    deleted?: boolean;
    ordersPerDaySummary?: OrdersPerDaySummary;
}

/**
 * no description
 */
export class OrdersPerDaySummaryApi extends runtime.BaseAPI {

    /**
     * Get list of OrdersPerDaySummary
     */
    async apiOrdersPerDaySummariesGetRaw(requestParameters: ApiOrdersPerDaySummariesGetRequest): Promise<runtime.ApiResponse<OrdersPerDaySummaryDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ordersPerDaySummaries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersPerDaySummaryDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of OrdersPerDaySummary
     */
    async apiOrdersPerDaySummariesGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<OrdersPerDaySummaryDataFrame> {
        const response = await this.apiOrdersPerDaySummariesGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of OrdersPerDaySummary
     */
    async apiOrdersPerDaySummariesIdDeleteRaw(requestParameters: ApiOrdersPerDaySummariesIdDeleteRequest): Promise<runtime.ApiResponse<OrdersPerDaySummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersPerDaySummariesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ordersPerDaySummaries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersPerDaySummaryFromJSON(jsonValue));
    }

    /**
     * Remove entity of OrdersPerDaySummary
     */
    async apiOrdersPerDaySummariesIdDelete(id: number): Promise<OrdersPerDaySummary> {
        const response = await this.apiOrdersPerDaySummariesIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of OrdersPerDaySummary
     */
    async apiOrdersPerDaySummariesIdGetRaw(requestParameters: ApiOrdersPerDaySummariesIdGetRequest): Promise<runtime.ApiResponse<OrdersPerDaySummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrdersPerDaySummariesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ordersPerDaySummaries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersPerDaySummaryFromJSON(jsonValue));
    }

    /**
     * Get entity of OrdersPerDaySummary
     */
    async apiOrdersPerDaySummariesIdGet(id: number, relations?: string, deleted?: boolean): Promise<OrdersPerDaySummary> {
        const response = await this.apiOrdersPerDaySummariesIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Create or update entity of OrdersPerDaySummary (if id defined)
     */
    async apiOrdersPerDaySummariesPostRaw(requestParameters: ApiOrdersPerDaySummariesPostRequest): Promise<runtime.ApiResponse<OrdersPerDaySummary>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ordersPerDaySummaries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrdersPerDaySummaryToJSON(requestParameters.ordersPerDaySummary),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersPerDaySummaryFromJSON(jsonValue));
    }

    /**
     * Create or update entity of OrdersPerDaySummary (if id defined)
     */
    async apiOrdersPerDaySummariesPost(relations?: string, deleted?: boolean, ordersPerDaySummary?: OrdersPerDaySummary): Promise<OrdersPerDaySummary> {
        const response = await this.apiOrdersPerDaySummariesPostRaw({ relations: relations, deleted: deleted, ordersPerDaySummary: ordersPerDaySummary });
        return await response.value();
    }

}
