import React from 'react';

import ContentPage from 'src/components/content/contentPage/contentPage';
import PageWrapper from './pageWrapper/pageWrapper';

const Offline = () => {
  return (
    <ContentPage>
      <PageWrapper>
        Wygląda na to, że strona jest obecnie offline, sprawdź swoje połączenie z internetem i spróbuj ponownie
      </ PageWrapper>
    </ContentPage>
  )
}

export default Offline;
