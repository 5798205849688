import React, { useState } from 'react';
import styles from './orderDay.module.scss';
import OrderItem from '../orderItem/orderItem';
import DayDelivery from '../dayDelivery/dayDelivery';
import { BasketPerDay, basketActions, BasketItem } from 'src/logic/basket';
import { useDispatch } from 'react-redux';
import { displayPrice } from 'src/logic/utils/price';
import { format } from 'date-fns'
import { pl } from 'date-fns/locale'
import { capitalize, round } from 'lodash';
import CutleryDelivery from '../cutleryDelivery/cutleryDelivery';
import { gtmAddToCart, gtmRemoveFromCart } from 'src/logic/gtm';

type OrderDayProps = {
  date: Date;
  dayBasket: BasketPerDay;
  freeDelivery: number;
  deliveryFee: number;
  cutleryCost: number;
  hasLocation: boolean;
};

const OrderDay = ({ date, dayBasket, freeDelivery, deliveryFee, cutleryCost, hasLocation }: OrderDayProps) => {
  const items = dayBasket.items;
  const total = items.reduce((a, b) => a + (b.discountedPrice + b.package.value) * b.quantity, 0) + cutleryCost * dayBasket.cutlery;
  const [showMicro, updateShowMicro] = useState(false);
  const { calories, protein, carbs, fat } = items.reduce((agg, item) => {
    const { calories, protein, carbs, fat, weight } = item.productInfo;
    const multiplyConst = (weight / 100) * item.quantity;
    return {
      calories: agg.calories + (calories * multiplyConst),
      protein: agg.protein + (protein * multiplyConst),
      carbs: agg.carbs + (carbs * multiplyConst),
      fat: agg.fat + (fat * multiplyConst),
    }
  }, { calories: 0, protein: 0, carbs: 0, fat: 0 });

  const dispatch = useDispatch();
  const updateItemCount = (quantity: number, item: BasketItem) => {
    dispatch(basketActions.modifyBasket({ ...item, quantity, date }))
    const gtmItem = { id: item.productId, name: item.productName, category: item.productCategory, price: item.price}
    quantity === 1 ? gtmAddToCart(gtmItem) : gtmRemoveFromCart(gtmItem);
  }
  const updateCutleryCount = (quantity: number) => { dispatch(basketActions.changeCutlery({date, cutlery: Math.max(quantity + dayBasket.cutlery , 0)  })) }

  return (
    <section className={styles.orderDay}>
      <header className={styles.dayHeader}>{capitalize(format(date, 'EEEE, P', { locale: pl }))}</header>
      <div className={styles.dayItems}>
        {
          items.map((item, i) => (
            <OrderItem item={item} key={i} updateCount={updateItemCount} />
          ))
        }

        <CutleryDelivery cutleryCount={dayBasket.cutlery} cutleryPrice={cutleryCost} updateCount={updateCutleryCount} />
        <DayDelivery diff={freeDelivery - total} deliveryPrice={deliveryFee} freeDelivery={total >= freeDelivery} hasLocation={hasLocation} />


        <div className={styles.daySummary}>
          <span>Łącznie</span>
          <em>{displayPrice(total + (((total >= freeDelivery) || hasLocation) ? 0 : deliveryFee))}</em>
        </div>
        <div className={styles.daySubSummary}>
          <span>Ilość kalorii</span>
          <span>{(calories).toFixed(1)} kcal</span>
        </div>
        {showMicro === false ? (
          <div className={styles.daySubSummary}>
            <span>Makroskładniki</span>
            <button onClick={() => updateShowMicro(!showMicro)}>Rozwiń</button>
          </div>
        ) :
          (
            <div>
              <div className={styles.daySubSummary}>
                <span>Białko</span>
                <span>{round(protein, 1)} g</span>
              </div>
              <div className={styles.daySubSummary}>
                <span>Węglowodany</span>
                <span>{round(carbs, 1)} g</span>
              </div>
              <div className={styles.daySubSummary}>
                <span>Tłuszcze</span>
                <span>{round(fat, 1)} g</span>
              </div>
            </div>
          )
        }
      </div>
    </section>
  );
};

export default OrderDay;
