import React, { ReactNode } from 'react';
import styles from './smallBtn.module.scss';


type ButtonProps = {
    action(): void;
    children?: ReactNode;
}

const SmallBtn = ({ action, children }: ButtonProps) => (
    <span className={styles.smallBtnWrapper}>
        <a className={styles.smallBtn} onClick={() => action()}>
            {children}
        </a>
    </span>
);

export default SmallBtn;