import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { createBrowserHistory } from 'history';
import { configureStore } from 'src/logic/store';
import * as serviceWorker from 'src/serviceWorker';
import 'element.scrollintoviewifneeded-polyfill';
import { initializeAI } from './logic/applicationInsights';

const history = createBrowserHistory();
initializeAI(history);
const store = configureStore({}, history);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
