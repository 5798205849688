import React, { useCallback, useState } from 'react';
import { Button, ButtonProps } from '../button/button';
import { Column, Spacer } from '../grid';
import { Row } from '../grid/row';
import { Overlay } from '../overlay';
import { Tooltip } from '../tooltip';
import styles from './confirmationButton.module.scss';

export function ConfirmationButton({ message, onClick, ...buttonProps }: ButtonProps & { message: string }) {
  const [open, changeOpen] = useState(false);
  const handleClose = useCallback(() => changeOpen(false), [changeOpen]);
  const handleOpen = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { evt.stopPropagation(); changeOpen(true) }, [changeOpen]);
  const handleClick = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { handleClose(); onClick?.(evt)}, [onClick, handleClose])
  return (
    <>
      <Tooltip
        isOpen={open}
        tipContentHover={true}
        hoverDelay={1000}
        content={
          <div>
            <Overlay color='transparent' onHide={handleClose} active page><div /></Overlay>
            <Row><div className={styles.message}>{message}</div></Row>
            <Row>
              <Spacer />
              <Column width={4}>
                <Button onClick={handleClick} color={'primary'}>Tak</Button>
              </Column>
              <Spacer />
            </Row>
          </div>
        }>
        <Button {...buttonProps} onClick={handleOpen} />
      </Tooltip>
    </>
  );
}
