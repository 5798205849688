
import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { isObject } from 'lodash';

const getAllErrorKeys = (obj: Record<string, unknown>, keys: string[] = []): string[] => {
  if(!isObject(obj)){
    return [keys.join('.')];
  }
  return Object.keys(obj).flatMap(key => getAllErrorKeys(obj[key] as Record<string, unknown>, [...keys, key]));
};

export const FocusOnFirstError = () => {
  const formik = useFormikContext();
  const [submitCount, setSubmitCount] = useState(formik.submitCount);

  useEffect(() => {
    if (!formik.isValid && formik.submitCount > submitCount) {
      const sortedErrorNodes = getAllErrorKeys(formik.errors)
        .map(key => window.document.getElementsByName(key))
        .flatMap(nodes => Array.from(nodes))
        .sort((e1, e2) => e1.getBoundingClientRect().left - e2.getBoundingClientRect().left)
        .sort((e1, e2) => e1.getBoundingClientRect().top - e2.getBoundingClientRect().top);

      if(sortedErrorNodes.length > 0){
        sortedErrorNodes[0].focus();
        sortedErrorNodes[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      setSubmitCount(formik.submitCount);
    }
  }, [formik.submitCount, submitCount, formik.isValid, formik.errors]);
  return null;
};
