import React from 'react';
import styles from './categories.module.scss';
import { useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';
import { routes } from 'src/routes';
import { DateString } from 'src/logic/utils';
import { slugifyName } from '../../../logic/utils/slug';

const catImg = new Map<string,string>([
  ['kanapki','/images/categories/kanapka.png'],
  ['sałatki', '/images/categories/salatka.png'],
  ['obiady', '/images/categories/obiad.png'],
  ['zupy', '/images/categories/zupa.png'],
  ['desery', '/images/categories/deser.png'],
  ['przekąski', '/images/categories/przekaska.png'],
  ['napoje', '/images/categories/sok.png'],
  ['śniadania', '/images/categories/sniadanie.png'],
  ['sushi', '/images/categories/sushi.png'],
  ['święta', '/images/categories/dladzieci.png'], // TODO
  ['dla dzieci', '/images/categories/dladzieci.png'],
  ['pomagamy', '/images/categories/pomagamy.png'],
  ['wielkanoc', '/images/categories/wielkanoc.png'],
  ['batony', '/images/categories/batony.png']]
);


const Category = ({ name, url, activeDate }: { name: string; url: string; activeDate: DateString }) => {
  return (
    <div className={styles.category}>
      <div>
        <img src={url} alt={name} />
      </div>
      <a href={`${routes.menu(new Date(activeDate))}#${slugifyName(name)}`}>{name}</a>
    </div>
  )
}

export const Categories = ({activeDate}: {activeDate: DateString}) => {
  const categories = useSelector((gs: GlobalState) => gs.menu.categories);
  const exclude = ['zestawy', 'polecane'];
  return (
    <>
      <h1 className={styles.sectionTitle}>Wybierz coś dla siebie!</h1>
      <div className={styles.categoriesList}>
        {categories.map(c => exclude.includes(c.toLowerCase())? '': <Category key={c} name={c} activeDate={activeDate} url={catImg.get(c.toLowerCase())?? '/dinner.png'} />)}
      </div>
    </>
  )
}
