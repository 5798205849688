import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { subHours, startOfDay, format, isMonday } from 'date-fns';
import pl from 'date-fns/locale/pl';

import styles from './myOrderDetails.module.scss';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import { BtnForm } from 'src/components/forms/Forms';
import { orderActions } from '../../../logic/order';
import { GlobalState } from 'src/logic/reducers';
import { displayPrice } from 'src/logic/utils';
import { routes } from '../../../routes';
import { OrderMenuItems } from './orderMenuItems';
import { DeliverySection } from './deliverySection';
import { PaymentSection } from './paymentSection';
import { EditDeliveryModal } from './editDelivery';
import { CancelDeliveryModal } from './cancelDelivery';
import {
  ColumnsWrapper,
  MainColumnn,
  SideColumnn,
} from 'src/containers/columns/columns';
import AccountHeader from '../accountHeader';

const MyDeliveryDetails = () => {
  const dispatch = useDispatch();
  const m = useRouteMatch<{ orderId: string; deliveryId: string }>();
  useEffect(() => {
    dispatch(orderActions.load.request(m.params.orderId));
  }, [dispatch, m.params.orderId]);
  const orderMenuId = parseInt(m.params.deliveryId, 10);
  const [editOpen, changeEditOpen] = useState(false);
  const [cancelOpen, changeCancelOpen] = useState(false);

  const order = useSelector((gs: GlobalState) => gs.order.details);
  const orderMenu = order?.menus?.find((om) => om.id === orderMenuId);
  const deliveryDate = orderMenu?.menu?.date ?? new Date();
  const title = `Dostawa na ${
    format(deliveryDate, 'EEEE, P', { locale: pl })
  }`;
  const canceled =
    order?.status === 'canceled' || order?.deletedAt || orderMenu?.deletedAt;

  const timeToCancel = useSelector(
    (gs: GlobalState) => gs.settings.TIME_TO_CANCEL
  );
  const total = (orderMenu?.price ?? 0) + (orderMenu?.delivery ?? 0);

  const ttc = timeToCancel;
  const disableCancel =
    !!order?.vouchers?.filter(v => !v.locationId)?.length ||
    subHours(
      startOfDay(deliveryDate),
      ttc
    ).getTime() < Date.now();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AccountHeader />
      <PageWrapper>
        <h1>{title}</h1>
        <h2 className={styles.subHeader}>
          <Link to={routes.account()}>
            <img
              src="/icons/back-arrow.svg"
              alt="wróć"
              title="Wróć do zamówień"
            />
          </Link>{' '}
          Wartość dostawy <b>{displayPrice(total)}</b>
        </h2>
        <ColumnsWrapper>
          <SideColumnn>
            <PaymentSection order={order} orderMenuId={orderMenuId} />
          </SideColumnn>
          <MainColumnn>
            {canceled && (
              <header className={styles.canelInfo}>
                <h4>Dostawa anulowana</h4>
                Środki ({displayPrice(total)}) trafiły na Twoje saldo
              </header>
            )}

            <section
              className={[
                styles.orderSummary,
                canceled && styles.orderCanceled,
              ].join(' ')}
            >
              <div className={styles.orderGroup}>
                {orderMenu && <OrderMenuItems orderMenu={orderMenu} />}
              </div>
            </section>
            <DeliverySection
              user={order?.user}
              address={
                order?.menus?.find((a) => !!a.address)?.address ??
                order?.menus?.find((a) => !!a.location)?.location
              }
            />

            <div className={styles.actionsSection}>
              <BtnForm type="black" action={() => window.print()}>
                Pobierz potwierdzenie
              </BtnForm>
              <BtnForm
                type="black"
                action={() => dispatch(push(routes.order(m.params.orderId, 'display')))}
              >
                Zobacz całe zamówienie
              </BtnForm>
              {!canceled && (
                <BtnForm
                  type="black"
                  action={() => changeEditOpen(true)}
                  disabled={disableCancel}
                >
                  Edytuj zamówienie
                </BtnForm>
              )}
              {!canceled && (
                <BtnForm
                  type="red"
                  action={() => changeCancelOpen(true)}
                  disabled={disableCancel}
                >
                  Anuluj zamówienie
                </BtnForm>
              )}
            </div>
          </MainColumnn>
        </ColumnsWrapper>
        <EditDeliveryModal
          open={editOpen}
          orderId={order?.id}
          orderMenuId={orderMenu?.id}
          close={() => changeEditOpen(false)}
        />
        <CancelDeliveryModal
          open={cancelOpen}
          orderId={order?.id}
          orderMenuId={orderMenu?.id}
          close={() => changeCancelOpen(false)}
        />
      </PageWrapper>
    </>
  );
};

export default MyDeliveryDetails;
