import React from 'react';
import ContentPage from './contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';

const Privacy = () => (
  <ContentPage>
    <PageWrapper>
      <div className="max960 rules">
        <h2 className="h2">Polityka prywatności Serwisu &quot;Pobudka”</h2>

        <p>
          <b>Drogi Użytkowniku!</b><br />
          <strong>
          Dbamy o Twoją prywatność i chcemy, abyś w czasie korzystania z naszych usług czuł się
komfortowo. Dlatego też poniżej prezentujemy Ci najważniejsze informacje o zasadach
przetwarzania przez nas Twoich danych osobowych oraz plikach cookies, które są wykorzystywane
przez nasz Serwis. Informacje te zostały przygotowane z uwzględnieniem RODO, czyli ogólnego
rozporządzenia o ochronie danych.
          </strong>
        </p>
        <h3>ADMINISTRATOR DANYCH OSOBOWYCH </h3>
        <p>
          <strong>Łukasz Besaraba</strong> przedsiębiorca wpisany do Centralnej
          Ewidencji i Informacji o Działalności Gospodarczej prowadzonej przez
          ministra właściwego do spraw gospodarki i prowadzenia Centralnej
          Ewidencji i Informacji o Działalności Gospodarczej, działający pod
          firmą POBUDKA&nbsp;Łukasz Besaraba, ul. Projektowana 2a, 05-805 Otrębusy, NIP: 5732704967, REGON: 146849386.
        </p>
        <p>
          Jeśli chcesz skontaktować się z nami w związku z przetwarzaniem przez
          nas Twoich danych osobowych, napisz do nas na adres e-mail:{' '}
          <a href="mailto:kontakt@pobudka.waw.pl">kontakt@pobudka.waw.pl</a>
        </p>
        
        <h3>TWOJE UPRAWNIENIA</h3>
        <p>
          Twoje dane są przez nas przetwarzane w celach związanych z
          funkcjonowaniem Serwisu i świadczeniem usług w nim oferowanych. W
          odniesieniu do przetwarzania przysługuje Ci prawo żądania:&nbsp;
        </p>
        <ul>
          <li>ddostępu do Twoich danych osobowych, w tym uzyskania kopii Twoich danych (art. 15 RODO lub
- jeśli ma to zastosowanie - art. 13 ust. 1 lit. f RODO),</li>
          <li>ich sprostowania (art. 16 RODO),</li>
          <li>usunięcia (art. 17 RODO),</li>
          <li>ograniczenia przetwarzania (art. 18 RODO),</li>
          <li>
            żądania przeniesienia danych do innego administratora (art. 20
            RODO).
          </li>
        </ul>
        <p>
          A także prawo wniesienia w dowolnym momencie sprzeciwu wobec
          przetwarzania Twoich danych:
        </p>
        <ul>
          <li>
            z przyczyn związanych z Twoją szczególną sytuacją &ndash;
            wobec przetwarzania dotyczących Ciebie danych osobowych, opartego na
            art. 6 ust. 1 lit. f RODO (tj. na realizowanych przez nas prawnie
            uzasadnionych interesach).
          </li>
        </ul>
        <p>
          Skontaktuj się z nami, jeśli chcesz skorzystać ze swoich praw.
          Sprzeciw w odniesieniu do wykorzystywania przez nas plików
          cookies (o których przeczytasz poniżej) możesz wyrazić
          zwłaszcza za pomocą odpowiednich ustawień przeglądarki.
        </p>
        <p>
          Jeśli uznasz, że Twoje dane są przetwarzane niezgodnie z prawem,
          możesz złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.
        </p>
        <h3>DANE OSOBOWE I PRYWATNOŚĆ</h3>
        <p>
          Poniżej znajdziesz szczegółowe informacje na temat
          przetwarzania Twoich danych w zależności od podejmowanych przez Ciebie
          działań.
        </p>
        <h3>1. Złożenie zamówienia w Serwisie.</h3>
        <table>
          <tbody>
            <tr>
            <th>W jakim celu?</th>
            </tr>
            <tr>
              <td>
                <p>realizacja złożonego przez Ciebie zamówienia</p>
              </td>
            </tr>
            <tr>
              <th>Na jakiej podstawie?</th>
            </tr>
            <tr>
              <td>
                <p>umowa o świadczenie usług (art. 6 ust. 1 lit. b RODO)</p>
              </td>
            </tr>
            <tr>
              <th>Jak długo?</th>
            </tr>
            <tr>
              <td>
                <p>przez czas niezbędny do realizacji zamówienia</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <i>
                    ponadto, Twoje dane będą przetwarzane do momentu, w
                    którym upłynie okres, w którym możliwe jest
                    dochodzenie roszczeń &ndash; przez Ciebie lub nas.
                  </i>
                </p>
                <p>
                  <i>
                    (więcej informacji na ten temat znajdziesz w ostatniej
                    tabeli tej sekcji)
                  </i>
                </p>
              </td>
            </tr>
            <tr>
              <th>Co się stanie, jeśli nie podam danych?</th>
            </tr>
            <tr>
              <td>
                <p>nie będziesz mieć możliwości złożenia zamówienia</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>2. Założenie konta w Serwisie.</h3>
        <table>
          <tbody>
            <tr>
              <th>W jakim celu?</th>
            </tr>
            <tr>
              <td>
                <p>
                  realizacja umowy o świadczenie usługi prowadzenia konta w
                  Serwisie
                </p>
              </td>
            </tr>
            <tr>
              <th>Na jakiej podstawie?</th>
            </tr>
            <tr>
              <td>
                <p>umowa o świadczenie usług (art. 6 ust. 1 lit. b RODO)</p>
              </td>
            </tr>
            <tr>
              <th>Jak długo?</th>
            </tr>
            <tr>
              <td>
                <p>
                  do momentu, w którym konto zostanie usunięte przez
                  Ciebie lub przez nas na Twoje żądanie
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <i>
                    ponadto, Twoje dane będą przetwarzane do momentu, w
                    którym upłynie okres, w którym możliwe jest
                    dochodzenie roszczeń &ndash; przez Ciebie lub nas.
                  </i>
                </p>
                <p>
                  <i>
                    (więcej informacji na ten temat znajdziesz w ostatniej
                    tabeli tej sekcji)
                  </i>
                </p>
              </td>
            </tr>
            <tr>
              <th>Co się stanie, jeśli nie podam danych?</th>
            </tr>
            <tr>
              <td>
                <p>
                  nie będziesz mieć możliwości założenia konta i korzystania z
                  jego funkcji
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>3.Nawiązanie z nami kontaktu (np. w celu zadania pytania).</h3>
        <table>
          <tbody>
            <tr>
              <th colSpan={2}>
                W jakim celu?</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>obsługa Twoich zapytań lub zgłoszeń</p>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>
                Na jakiej podstawie?</th>
            </tr>
            <tr>
              <td>
                <p>
                  umowa lub działania podejmowane na Twoje żądanie, zmierzające
                  do jej zawarcia (art. 6 ust. 1 lit. b RODO) &ndash; w
                  przypadku, gdy Twoje zapytanie lub zgłoszenie dotyczy umowy,
                  której jesteśmy lub możemy być stroną
                </p>
              </td>
              <td>
                <p>
                  nasz prawnie uzasadniony interes, polegający na przetwarzaniu
                  Twoich danych w celu prowadzenia z Tobą komunikacji (art. 6
                  ust. 1 lit. f RODO) &ndash; jeżeli Twoje zapytanie lub
                  zgłoszenie nie ma związku z umową
                </p>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>
                Jak długo?</th>
            </tr>
            <tr>
              <td>
                <p>
                  przez czas trwania wiążącej nas umowy lub &ndash; jeśli umowa
                  nie zostanie zawarta &ndash; 12 miesięcy
                </p>
              </td>
              <td>
                <p>
                  &nbsp;12 miesięcy &ndash; lub do momentu, w którym
                  uwzględnimy Twój sprzeciw wobec przetwarzania *
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>
                  <i>
                    ponadto, Twoje dane będą przetwarzane do momentu, w
                    którym upłynie okres, w którym możliwe jest
                    dochodzenie roszczeń &ndash; przez Ciebie lub nas.
                  </i>
                </p>
                <p>
                  <i>
                    (więcej informacji na ten temat znajdziesz w ostatniej
                    tabeli tej sekcji)
                  </i>
                </p>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>
                Co się stanie, jeśli nie podam danych?</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>
                  nie będziemy mieli możliwości udzielenia odpowiedzi na Twoje
                  zapytanie lub zgłoszenie
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          *w zależności od tego, które ma zastosowanie w danym przypadku
          i które nastąpi najwcześniej
        </p>
        <p>
          <strong>&nbsp;W jakim celu?</strong>
        </p>
        <h3>4. Wyrażenie przez Ciebie zgody na otrzymywanie od nas treści
              marketingowych (w tym informacji o ofertach specjalnych).
            </h3>
        <table>
          <tbody>
            <tr>
              <th>W jakim celu?</th>
            </tr>
            <tr>
              <td>
                <p>
                  wysyłka informacji marketingowych, zwłaszcza ofert specjalnych
                </p>
              </td>
            </tr>
            <tr>
              <th>Na jakiej podstawie?</th>
            </tr>
            <tr>
              <td>
                <p>
                  Twoja zgoda na nasze działania marketingowe (art. 6 ust. 1
                  lit. a RODO)
                </p>
              </td>
            </tr>
            <tr>
              <th>Jak długo?</th>
            </tr>
            <tr>
              <td>
                <p>
                  do momentu wycofania przez Ciebie zgody &ndash; pamiętaj, w
                  każdej chwili możesz wycofać zgodę; przetwarzanie danych do
                  momentu cofnięcia przez Ciebie zgody pozostaje zgodne z
                  prawem.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <i>
                    ponadto, Twoje dane będą przetwarzane do momentu, w
                    którym upłynie okres, w którym możliwe jest
                    dochodzenie roszczeń &ndash; przez Ciebie lub nas.
                  </i>
                </p>
                <p>
                  <i>
                    (więcej informacji na ten temat znajdziesz w ostatniej
                    tabeli tej sekcji)
                  </i>
                </p>
              </td>
            </tr>
            <tr>
              <th>Co się stanie, jeśli nie podam danych?</th>
            </tr>
            <tr>
              <td>
                <p>
                  nie będziesz otrzymywać naszych materiałów
                  marketingowych, w tym informacji o naszych ofertach
                  specjalnych
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>5. Ustawienia przeglądarki lub inne zbliżone działanie
                    zezwalające na prowadzenie działań analitycznych.</h3>
        <table>
          <tbody>
            <tr>
              <th>W jakim celu?</th>
            </tr>
            <tr>
              <td>
                <p>
                  analiza sposobu korzystania i poruszania się przez Ciebie po
                  stronie internetowej Serwisu, celem dostosowania strony do
                  potrzeb i zachowania Użytkowników <br /> (więcej na ten
                  temat przeczytasz w sekcji &bdquo;Działania analityczne&rdquo;
                  i &bdquo;Pliki cookies&rdquo; Polityki prywatności)
                </p>
              </td>
            </tr>
            <tr>
              <th>Na jakiej podstawie?</th>
            </tr>
            <tr>
              <td>
                <p>
                  nasz prawnie uzasadniony interes, polegający na przetwarzaniu
                  danych w podanym wyżej celu <br /> (art. 6 ust. 1 lit. f RODO)
                </p>
              </td>
            </tr>
            <tr>
              <th>Jak długo?</th>
            </tr>
            <tr>
              <td>
                <p>
                  do momentu wygaśnięcia ważności lub usunięcia przez Ciebie
                  pliku cookie *
                </p>
              </td>
            </tr>
            <tr>
              <th>Co się stanie, jeśli nie podam danych?</th>
            </tr>
            <tr>
              <td>
                <p>
                  nie uwzględnimy Twoich preferencji dotyczących korzystania z
                  Serwisu w pracach nad jej rozwojem
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>6. Podjęcie działania lub zaniechanie mogące powodować powstanie
              roszczeń związanych z Serwisem lub naszymi usługami.{' '}
            </h3>
        <table>
          <tbody>
            <tr>
              <th>W jakim celu?</th>
            </tr>
            <tr>
              <td>
                <p>
                  ustalenie, dochodzenie lub obrona ewentualnych roszczeń,
                  związanych z zawartą umową lub świadczonymi usługami
                </p>
              </td>
            </tr>
            <tr>
              <th>Na jakiej podstawie?</th>
            </tr>
            <tr>
              <td>
                <p>
                  nasz prawnie uzasadniony interes, polegający na przetwarzaniu
                  danych osobowych we wskazanym powyżej celu (art. 6 ust. 1 lit.
                  f RODO)
                </p>
              </td>
            </tr>
            <tr>
              <th>Jak długo?</th>
            </tr>
            <tr>
              <td>
                <p>
                  do upływu okresu przedawnienia roszczeń lub do momentu, w
                  którym uwzględnimy Twój sprzeciw wobec
                  przetwarzania*
                </p>
              </td>
            </tr>
            <tr>
              <th>Co się stanie, jeśli nie podam danych?</th>
            </tr>
            <tr>
              <td>
                <p>
                  brak możliwości ustalenia, dochodzenia lub obrony roszczeń
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          *w zależności od tego, które ma zastosowanie w danym przypadku
          i które nastąpi najwcześniej
        </p>
        <h3>DZIAŁANIA ANALITYCZNE</h3>
        <p>
          W ramach Serwisu prowadzimy działania analityczne, mające na celu
          zwiększenie jego intuicyjności i przystępności &ndash; w odniesieniu
          do Ciebie będzie to miało miejsce, jeśli zezwolisz (np. przez
          odpowiednie ustawienia Twojej przeglądarki) na takie działania. W
          ramach analizy będziemy brać pod uwagę sposób, w jaki poruszasz
          się po Serwisie &ndash; a więc np. to, ile czasu spędzasz na danej
          podstronie, czy w które miejsca na stronie klikasz. Dzięki temu
          możemy dostosować układ i&nbsp;wygląd Serwisu oraz zamieszczane w nim
          treści do potrzeb Użytkowników.
        </p>
        <h3>BEZPIECZEŃSTWO DANYCH</h3>
        <p>
          Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i
          techniczne zgodne z właściwymi przepisami prawa, w tym stosujemy
          szyfrowanie połączenia
        </p>
        <h3>PLIKI COOKIES </h3>
        <p>
          Nasz Serwis, jak większość witryn internetowych, korzysta z tzw.
          plików cookies (ciasteczek). Pliki te:
        </p>
        <ul>
          <li>
            są zapisywane w pamięci Twojego urządzenia (komputera, telefonu,
            itd.);
          </li>
          <li>nie powodują zmian w ustawieniach Twojego urządzenia.</li>
        </ul>
        <p>W tym Serwisie ciasteczka wykorzystywane są w celach:</p>
        <ul>
          <li>zapamiętywania Twojej sesji,</li>
          <li>statystycznych,</li>
          <li>udostępniania funkcji Serwisu.</li>
        </ul>
        <p>
          Aby dowiedzieć się, jak zarządzać plikami cookies, w tym jak wyłączyć
          ich obsługę w Twojej przeglądarce, możesz skorzystać z pliku pomocy
          Twojej przeglądarki. Z informacjami na ten temat możesz zapoznać się
          wciskając klawisz F1 w przeglądarce. Ponadto odpowiednie
          wskazówki znajdziesz na następujących podstronach, w zależności
          od przeglądarki, której używasz:
        </p>
        <ul>
          <li>
            <a href="https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">
              Firefox
            </a>
          </li>
          <li>
            <a href="https://support.google.com/chrome/answer/95647?hl=plX">
              Chrome
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/kb/PH5042?locale=en_US">
              Safari
            </a>
          </li>
          <li>
            <a href="http://windows.microsoft.com/pl-pl/windows-10/edge-privacy-faq">
              Internet Explorer / Microsoft Edge
            </a>
          </li>
        </ul>
        <p>
          Poniżej znajdziesz informacje na temat funkcji przetwarzanych przez
          nas plików cookie oraz ich okresie ważności.
        </p>
        <table style={{textAlign:'center'}}>
          <tbody>
            <tr>
              <th>nazwa pliku cookie</th>
              <th>okres ważności pliku cookie</th>
              <th>funkcja pliku cookie</th>
            </tr>
            <tr>
              <td><p>fr</p></td>
              <td><p>2 miesiące</p></td>
              <td><p>Cel marketingowy.</p></td>
            </tr>  
            <tr>
              <td><p>wd</p></td>
              <td><p>1 tydzień </p></td>
              <td><p>Udostępnianie innych funkcji Serwisu.</p></td>
            </tr> 
            <tr>
              <td><p>sb</p></td>
              <td><p>2 lata</p></td>
              <td><p>Cel marketingowy.</p></td>
            </tr>  
            <tr>
              <td><p>datr</p></td>
              <td><p>2 lata </p></td>
              <td><p>Cel analistyczny.</p></td>
            </tr> 
            <tr>
              <td><p>1P_JAR</p></td>
              <td><p>3 tygodnie</p></td>
              <td><p>Cel statystyczny.</p></td>
            </tr>  
            <tr>
              <td><p>CONSENT</p></td>
              <td><p>17 lat</p></td>
              <td><p>Plik cookie używany przez platformę Google w celu zbierania zgody.</p></td>
            </tr>  
            <tr>
              <td><p>_gid</p></td>
              <td><p>1 dzień</p></td>
              <td><p>Cel analityczny.</p></td>
            </tr>  
            <tr>
              <td><p>_fbp</p></td>
              <td><p>2 miesiące</p></td>
              <td><p>Cel marketingowy.</p></td>
            </tr>  
            <tr>
              <td><p>_ga</p></td>
              <td><p>2 lata</p></td>
              <td><p>Cel analityczny.</p></td>
            </tr>  
            <tr>
              <td><p>__cfdiud</p></td>
              <td><p>1 miesiąc </p></td>
              <td><p>Plik cookie konieczny do korzystania ze strony.</p></td>
            </tr>
          </tbody>
        </table>
        <p>
          Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej chwili
          możesz:
        </p>
        <ul>
          <li>usunąć pliki cookies,</li>
          <li>blokować wykorzystanie plików cookies w przyszłości.</li>
        </ul>
        <p>W takich przypadkach nie będziemy ich już dłużej przetwarzać.</p>
        <p>
          Więcej informacji o plikach cookies znajdziesz w{' '}
          <a href="https://pl.wikipedia.org/wiki/HTTP_cookie">Wikipedii</a>.
        </p>
        <p>
          <strong>USŁUGI ZEWNĘTRZNE / ODBIORCY DANYCH</strong>
        </p>
        <p>
          Korzystamy z usług podmiotów zewnętrznych, które
          wspierają nas w prowadzeniu działalności. Powierzamy im do
          przetwarzania Twoje dane &ndash; podmioty te przetwarzają dane
          wyłącznie na nasze udokumentowane polecenie.
        </p>
        <p>Poniżej znajdziesz listę odbiorców Twoich danych:</p>
        <table>
          <tbody>
            <tr>
              <th>
                DZIAŁANIE</th>
              <th>ODBIORCY DANYCH</th>
              <th>PRZEKAZANIE DANYCH POZA UNIĘ EUROPEJSKĄ </th>
            </tr>
            <tr>
              <th rowSpan={2}>
                każde działanie w związku z Serwisem</th>
              <td>
                <p>hostingodawca</p>
              </td>
              <td>
                <p>nie ma miejsca</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>podmiot zapewniający nam wsparcie techniczne/IT</p>
              </td>
              <td>
                <p>nie ma miejsca</p>
              </td>
            </tr>
            <tr>
              <th>przebywanie na stronie Serwisu z ustawieniami<br />
                    zezwalającymi na prowadzenie działań analitycznych
              </th>
              <td>
                <p>podmiot umożliwiający działania analityczne na stronie</p>
              </td>
              <td>
                <p>tak - Stany Zjednoczone Ameryki**</p>
              </td>
            </tr>
            <tr>
              <th rowSpan={3}>
               złożenie zamówienia w Serwisie</th>
              <td>
                <p>dostawca płatności</p>
              </td>
              <td>
                <p>nie ma miejsca</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>biuro rachunkowe</p>
              </td>
              <td>
                <p>nie ma miejsca</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  osoby współpracujące z nami na podstawie umów
                  cywilnoprawnych, wspierające naszą bieżącą działalność
                </p>
              </td>
              <td>
                <p>nie ma miejsca</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>A ponadto:</strong>
        </p>
        <p>
          - odpowiednie organy publiczne w zakresie, w jakim jesteśmy
          zobowiązani do udostępnienia im danych.
        </p>
        <h3>
            PRZEKAZYWANIE DANYCH OSOBOWYCH DO PAŃSTW SPOZA UNII EUROPEJSKIEJ
          </h3>
        <p>
          ** W związku z powyższym, Twoje dane osobowe mogą być przetwarzane również przez podmioty
spoza Unii Europejskiej. Właściwy poziom ochrony Twoich danych, w tym poprzez stosowanie

odpowiednich zabezpieczeń, zapewnia zastosowanie standardowych klauzul ochrony danych
przyjętych przez Komisję Europejską, o których mowa w art. 46 ust. 2 lit. c RODO.
        </p>
      </div>
    </PageWrapper>
  </ContentPage>
);

export default Privacy;
