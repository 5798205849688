import React, { useState } from 'react';
import styles from './filter.module.scss';
import Tag from '../tag/tag';
import CheckBox from '../forms/checkBox/checkBox';
import {ReactComponent as FilterIcon} from './filter.svg';
import { FilterOption } from 'src/logic/menu';

type FilterProps = {
  options: FilterOption[];
  saveAction?: (filterOption: FilterOption[]) => void;
  clearAction?: (filterOption: FilterOption[]) => void;
};

const Filter = ({ options, saveAction, clearAction }: FilterProps) => {
  const [checkedOptions, changeShowOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);
  const saveHandle = () => {
    setShowOptions(!showOptions)
    if (saveAction) saveAction(checkedOptions);
  };
  const clearHandle = () => {
    setShowOptions(!showOptions)
    const defaultOptions = checkedOptions.map(o => { return {...o, active: false} });
    changeShowOptions(defaultOptions);
    if (clearAction) clearAction(defaultOptions);
  };

  const active = checkedOptions.filter(o => o.active).length;
  const filterOptionChanged = (option: FilterOption, checked: boolean) => {
    return changeShowOptions(checkedOptions.map(fo => fo.id === option.id ? {...fo, active: checked} : fo));
  }
  return (
    <div className={styles.filterWrapper} >
      <div>
        <button className={[styles.filterBtn, active && styles.active ].join(' ')} onClick={()=>setShowOptions(!showOptions)}>
         <FilterIcon /> <span>{active > 0 ? active : 'Filtruj'}</span>
        </button>
      </div>
      {showOptions && <>
        <div className={styles.clicker} onClick={saveHandle}></div>
        <div className={styles.filterOptions}>
          {checkedOptions.map(o => (
            <CheckBox key={o.id} checked={o.active ?? false} onChange={(checked) => filterOptionChanged(o, checked)} >
            <Tag tag={o} showLabel invert />
            </CheckBox>
          ))}
        <div className={styles.filterButtons}>
          <a className={styles.clearFilter} onClick={clearHandle}>Wyczyść</a>
          <a className={styles.saveFilter} onClick={saveHandle}>Zapisz</a>
        </div>

        </div>
      </>}
    </div>
  );
};

export default Filter;
