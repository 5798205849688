/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersWithOrdersInfo
 */
export interface UsersWithOrdersInfo {
    /**
     * 
     * @type {number}
     * @memberof UsersWithOrdersInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersWithOrdersInfo
     */
    wallet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithOrdersInfo
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithOrdersInfo
     */
    anonymous?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    verifyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    resetCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    avatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithOrdersInfo
     */
    termsAgreement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithOrdersInfo
     */
    marketingAgreement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    unsubscribeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithOrdersInfo
     */
    encodedEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersWithOrdersInfo
     */
    ordersValue?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersWithOrdersInfo
     */
    numberOfOrders?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersWithOrdersInfo
     */
    ordersPaidValue?: number;
    /**
     * 
     * @type {Date}
     * @memberof UsersWithOrdersInfo
     */
    lastOrderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof UsersWithOrdersInfo
     */
    devicesCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof UsersWithOrdersInfo
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UsersWithOrdersInfo
     */
    updatedAt?: Date;
}

export function UsersWithOrdersInfoFromJSON(json: any): UsersWithOrdersInfo {
    return UsersWithOrdersInfoFromJSONTyped(json, false);
}

export function UsersWithOrdersInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersWithOrdersInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'wallet': !exists(json, 'wallet') ? undefined : json['wallet'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'anonymous': !exists(json, 'anonymous') ? undefined : json['anonymous'],
        'verifyCode': !exists(json, 'verifyCode') ? undefined : json['verifyCode'],
        'resetCode': !exists(json, 'resetCode') ? undefined : json['resetCode'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'termsAgreement': !exists(json, 'termsAgreement') ? undefined : json['termsAgreement'],
        'marketingAgreement': !exists(json, 'marketingAgreement') ? undefined : json['marketingAgreement'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'unsubscribeCode': !exists(json, 'unsubscribeCode') ? undefined : json['unsubscribeCode'],
        'encodedEmail': !exists(json, 'encodedEmail') ? undefined : json['encodedEmail'],
        'ordersValue': !exists(json, 'OrdersValue') ? undefined : json['OrdersValue'],
        'numberOfOrders': !exists(json, 'NumberOfOrders') ? undefined : json['NumberOfOrders'],
        'ordersPaidValue': !exists(json, 'OrdersPaidValue') ? undefined : json['OrdersPaidValue'],
        'lastOrderDate': !exists(json, 'LastOrderDate') ? undefined : (new Date(json['LastOrderDate'])),
        'devicesCount': !exists(json, 'DevicesCount') ? undefined : json['DevicesCount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function UsersWithOrdersInfoToJSON(value?: UsersWithOrdersInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'lastName': value.lastName,
        'company': value.company,
        'password': value.password,
        'phone': value.phone,
        'wallet': value.wallet,
        'active': value.active,
        'anonymous': value.anonymous,
        'verifyCode': value.verifyCode,
        'resetCode': value.resetCode,
        'avatar': value.avatar,
        'termsAgreement': value.termsAgreement,
        'marketingAgreement': value.marketingAgreement,
        'fullName': value.fullName,
        'displayName': value.displayName,
        'unsubscribeCode': value.unsubscribeCode,
        'encodedEmail': value.encodedEmail,
        'OrdersValue': value.ordersValue,
        'NumberOfOrders': value.numberOfOrders,
        'OrdersPaidValue': value.ordersPaidValue,
        'LastOrderDate': value.lastOrderDate === undefined ? undefined : (value.lastOrderDate.toISOString()),
        'DevicesCount': value.devicesCount,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}


