import React, { ReactNode } from 'react';
import styles from './labelValue.module.scss';

type LabelValueProps = {
  label: string;
  value: ReactNode;
};

const LabelValue = ({ label, value }: LabelValueProps) => {
  return (
    <div className={styles.labelValue}>
      <label className={styles.label}>{label}</label>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default LabelValue;
