
type Gravity = 'no' | 'so' | 'ea' | 'we' | 'noea' | 'soea' | 'sowe' | 'ce' | 'sm';
type ResizeType = 'fit' | 'fill' | 'auto';
type Enlarge = 't' | 'f';
type Format = 'png' | 'jpg' | 'webp';

type Options = {
  gravity?: Gravity;
  resizeType?: ResizeType;
  enlarge?: Enlarge;
  format?: Format;
}

export const imgUrl = (url: string, w: number, h: number, options?: Options) =>
  `/img/${options?.resizeType ?? 'fit'}/${Math.round(w)}/${Math.round(h)}/${options?.gravity ?? 'sm'}/${options?.enlarge ?? 't'}/${url.replace(/^\//, '')}${options?.format ? `@${options.format}` : ''}`;
