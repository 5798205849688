import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import styles from './dropzone.module.scss';

type DropdownProps = {
  onDrop: (f: File[]) => void;
  files: File[];
  options: DropzoneOptions | undefined;
};

export function Dropzone({ onDrop, options, files }: DropdownProps) {
  const [previews, setPreviews] = useState<Array<string>>([]);
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    onDrop(acceptedFiles);
  }, [onDrop])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ ...options, onDrop: handleDrop })

  const thumbs = previews.map((preview, idx) => (
    <div className={styles.thumb} key={idx}>
      <div>
        <img
          alt={files[idx]?.name}
          src={preview}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    setPreviews(files.map(file => URL.createObjectURL(file)));
  }, [files]);

  useEffect(() => () => previews.forEach(previw => URL.revokeObjectURL(previw)), [previews])

  return (
    <div {...getRootProps()} className={styles.dropzone}>
      <input {...getInputProps()} />
      {
        isDragActive ? 'Upuść plik tutaj' : 'Kliknij lub przeciągnij i upuść'
      }
      <aside className={styles.thumbsContainer}>
        {thumbs}
      </aside>
    </div>
  )
}
