import React from 'react';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import ContentPage from './contentPage/contentPage';
import { TextFormField } from '../forms/textField/textField';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { CheckBoxFormField } from '../forms/checkBox/checkBox';
import { ReactComponent as Offices } from './delivery-to-offices.svg';
import { BtnForm } from '../forms/Forms';
import ErrorList from '../forms/errorList/errorList';
import { useErrorsList } from '../hooks/useErrorsList';
import { useDispatch } from 'react-redux';
import { userActions } from 'src/logic/user';
import { replace } from 'connected-react-router';
import { routes } from 'src/routes';
import { pickBy } from 'lodash';
import { toast } from 'react-toastify';

type FormValues = {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	companyName: string;
	companyAddress: string;
	emailAgreement: boolean;
	phoneAgreement: boolean;
	privacyPolicy: boolean;
};

type TextFields = {
	id: number;
	name: keyof FormValues;
	label: string;
};

type CheckboxFields = {
	id: number;
	name: keyof FormValues;
	label: JSX.Element;
};

const textFields: TextFields[] = [
	{ id: 1, name: 'firstName', label: 'Imię' },
	{ id: 2, name: 'lastName', label: 'Nazwisko' },
	{ id: 3, name: 'phone', label: 'Numer telefonu' },
	{ id: 4, name: 'email', label: 'Adres e-mail', },
	{ id: 5, name: 'companyName', label: 'Nazwa firmy' },
	{ id: 6, name: 'companyAddress', label: 'Adres firmy' },
];

const initialValues: FormValues = {
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	companyName: '',
	companyAddress: '',
	emailAgreement: false,
	phoneAgreement: false,
	privacyPolicy: false,
};

const formKeys: (keyof FormValues)[] = ['firstName', 'lastName', 'phone', 'email', 'companyName', 'companyAddress'];
const agreementKeys: (keyof FormValues)[] = ['emailAgreement', 'phoneAgreement', 'privacyPolicy'];

const checkboxFields: CheckboxFields[] = [
	{
		id: 1,
		name: 'emailAgreement',
		label: <div className='checkbox'>
			<b>E-mail:</b>
			<p>Wyrażam zgodę na otrzymywanie drogą elektroniczną,
				na podany przeze mnie adres e-mail, informacji handlowych w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r.
				(t.j. Dz .U. z 2020 r. poz. 344 z późn. zm.) dotyczących oferty i usług firmy POBUDKA Łukasz Besaraba</p>
		</div>
	},
	{
		id: 2,
		name: 'phoneAgreement',
		label: <div className='checkbox'>
			<b>SMS/Kontakt telefoniczny:</b>
			<p>Wyrażam zgodę na przekazywanie treści dotyczących oferty i usług firmy POBUDKA Łukasz Besaraba na podany przeze mnie numer telefonu,
				w tym przy użyciu automatycznych systemów wywołujących w rozumieniu ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (t.j. Dz. U. z 2024 r. poz. 34 z późn. zm.).</p>
		</div>
	},
	{
		id: 3,
		name: 'privacyPolicy',
		label: <div className='checkbox'><p>Oświadczam, że zapoznałem się z<a href={routes.privacy()}>Polityką Prywatności</a>oraz zasadami przetwarzania danych osobowych przez POBUDKA Łukasz Besaraba</p></div>
	}
]

const emailTemplate = (values: FormValues, copyToClient = false) => {
	const data = `
Imię: ${values.firstName}
Nazwisko: ${values.lastName}
Numer telefonu: ${values.phone || 'Brak'}
Email: ${values.email || 'Brak'}
Nazwa firmy: ${values.companyName}
Adres firmy: ${values.companyAddress}
Zgoda na kontakt email: ${values.emailAgreement ? 'Tak' : 'Nie'}
Zgoda na kontakt telefoniczny: ${values.phoneAgreement ? 'Tak' : 'Nie'}
Zgoda - Polityka prywatności: ${values.privacyPolicy ? 'Tak' : 'Nie'}
`

	const copyToClientHeader = `
Pobudka - Twój formularz został wysłany.
Wkrótce skontaktujemy się z Tobą w celu omówienia szczegółów.
Poniżej przesyłamy kopię formularza.
`
	return copyToClient ? copyToClientHeader + data : data
};

const DeliveryToOffices = () => {
	const dispatch = useDispatch();

	const handleSend = (values: FormValues) => {
		dispatch(
			userActions.contact({
				message: emailTemplate(values),
				subject: 'Dostawa do biur',
				email: values.email,
				andThen: () => {
					if (values.email) {
						dispatch(
							userActions.contact({
								message: emailTemplate(values, true),
								subject: 'Potwierdzenie wysłania formularza',
								email: values.email,
							}))
					}
					toast.success('Wiadomość została wysłana');
					dispatch(replace(routes.index()));
				}
			})
		)
	}

	const validate = (values: FormValues): FormikErrors<FormValues> => {
		const phoneRegex = /^(\+48)?\d{7,9}$/;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const phoneValidation = phoneRegex.test(values.phone);
		const emailValidation = values.email.match(emailRegex);

		const result = {
			email: emailValidation || phoneValidation
				? undefined
				: 'Numer telefonu i/lub email są wymagane',
			firstName: !values.firstName.length
				? 'Imię jest wymagane'
				: undefined,
			lastName: !values.lastName.length
				? 'Nazwisko jest wymagane'
				: undefined,
			phone: phoneValidation || emailValidation
				? undefined
				: 'Numer telefonu i/lub email są wymagane',
			companyName: !values.companyName.length
				? 'Nazwa firmy jest wymagana'
				: undefined,
			companyAddress: !values.companyAddress.length
				? 'Adres firmy jest wymagany'
				: undefined,
			privacyPolicy: !values.privacyPolicy
				? 'Akceptacja Polityki Prywatności jest wymagana'
				: undefined,
			emailAgreement: emailValidation && !values.emailAgreement
				? 'Zgoda na kontakt email jest wymagana'
				: undefined,
			phoneAgreement: phoneValidation && !values.phoneAgreement
				? 'Zgoda na kontakt telefoniczny/SMS jest wymagana'
				: undefined,
		}
		return pickBy(result, (v) => v)
	}
	return <ContentPage>
		<PageWrapper>
			<div className="max960">
				<h1 className="h1">Dostawa do biur</h1>
				<p className="deliveryToOfficeHeader">
					Cieszymy się, że zainteresowałeś się naszym cateringiem :)
					<br />
					Chętnie odwiedzimy Twoje miejsce pracy i pobudzimy Cię do życia pysznymi posiłkami.
					<br />
					Zostaw nam do siebie kontakt, a my skontaktujemy się z Tobą w celu omówienia szczegółów.
				</p>
			</div>
			<Formik<FormValues> initialValues={initialValues} onSubmit={handleSend} validate={validate}>
				<DeliveryToOfficesForm />
			</Formik>
		</PageWrapper>
	</ContentPage >
}

const DeliveryToOfficesForm = () => {
	const { errors, submitForm, touched } = useFormikContext<FormValues>();
	const agreementErrors = useErrorsList(agreementKeys, touched, errors);
	const formErrors = useErrorsList(formKeys, touched, errors);
	const handleSend = () => {
		submitForm();
	};

	return <div className='deliveryToOfficeForm'>
		<div className='split'>
			<div className='textFieldsForm'>
				{textFields.map((field) =>
					<TextFormField<keyof FormValues>
						key={field.id}
						name={field.name}
						label={field.label}
					/>
				)}
				<ErrorList errors={[...new Set(formErrors)]} />
			</div>
			<Offices />
		</div>
		{checkboxFields.map(field =>
			<div key={field.id}>
				<CheckBoxFormField<keyof FormValues>
					name={field.name}>
					{field.label}
				</CheckBoxFormField>

			</div>
		)}
		{agreementErrors.length ? <ErrorList errors={agreementErrors} name={'agreements'} /> : null}
		<BtnForm type='orange' action={handleSend}>Wyślij</BtnForm>
	</div >
}

export default DeliveryToOffices;