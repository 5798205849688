/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteRequest,
    DeleteRequestFromJSON,
    DeleteRequestToJSON,
    DeleteResponse,
    DeleteResponseFromJSON,
    DeleteResponseToJSON,
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    SignInRequest,
    SignInRequestFromJSON,
    SignInRequestToJSON,
    SignResponse,
    SignResponseFromJSON,
    SignResponseToJSON,
    SignUpRequest,
    SignUpRequestFromJSON,
    SignUpRequestToJSON,
    ValidateResponse,
    ValidateResponseFromJSON,
    ValidateResponseToJSON,
} from '../models';

export interface AuthDeletePostRequest {
    deleteRequest?: DeleteRequest;
}

export interface AuthFacebookGetRequest {
    redirect?: string;
    code?: string;
}

export interface AuthSigninPostRequest {
    signInRequest?: SignInRequest;
}

export interface AuthSignupPostRequest {
    signUpRequest?: SignUpRequest;
}

/**
 * no description
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Delete user account endpoint
     */
    async authDeletePostRaw(requestParameters: AuthDeletePostRequest): Promise<runtime.ApiResponse<DeleteResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteRequestToJSON(requestParameters.deleteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete user account endpoint
     */
    async authDeletePost(deleteRequest?: DeleteRequest): Promise<DeleteResponse> {
        const response = await this.authDeletePostRaw({ deleteRequest: deleteRequest });
        return await response.value();
    }

    /**
     * Facebook OAuth2 request
     */
    async authFacebookGetRaw(requestParameters: AuthFacebookGetRequest): Promise<runtime.ApiResponse<SignResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.redirect !== undefined) {
            queryParameters['redirect'] = requestParameters.redirect;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/facebook`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignResponseFromJSON(jsonValue));
    }

    /**
     * Facebook OAuth2 request
     */
    async authFacebookGet(redirect?: string, code?: string): Promise<SignResponse> {
        const response = await this.authFacebookGetRaw({ redirect: redirect, code: code });
        return await response.value();
    }

    /**
     * Refresh token
     */
    async authRefreshGetRaw(): Promise<runtime.ApiResponse<SignResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/refresh`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignResponseFromJSON(jsonValue));
    }

    /**
     * Refresh token
     */
    async authRefreshGet(): Promise<SignResponse> {
        const response = await this.authRefreshGetRaw();
        return await response.value();
    }

    /**
     * Sign-in endpoint
     */
    async authSigninPostRaw(requestParameters: AuthSigninPostRequest): Promise<runtime.ApiResponse<SignResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInRequestToJSON(requestParameters.signInRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignResponseFromJSON(jsonValue));
    }

    /**
     * Sign-in endpoint
     */
    async authSigninPost(signInRequest?: SignInRequest): Promise<SignResponse> {
        const response = await this.authSigninPostRaw({ signInRequest: signInRequest });
        return await response.value();
    }

    /**
     * Sign-up endpoint
     */
    async authSignupPostRaw(requestParameters: AuthSignupPostRequest): Promise<runtime.ApiResponse<SignResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpRequestToJSON(requestParameters.signUpRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignResponseFromJSON(jsonValue));
    }

    /**
     * Sign-up endpoint
     */
    async authSignupPost(signUpRequest?: SignUpRequest): Promise<SignResponse> {
        const response = await this.authSignupPostRaw({ signUpRequest: signUpRequest });
        return await response.value();
    }

    /**
     * Validate token
     */
    async authValidateGetRaw(): Promise<runtime.ApiResponse<ValidateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateResponseFromJSON(jsonValue));
    }

    /**
     * Validate token
     */
    async authValidateGet(): Promise<ValidateResponse> {
        const response = await this.authValidateGetRaw();
        return await response.value();
    }

}
