import React, { useEffect, Fragment, useState } from 'react';
import styles from './myOrderDetails.module.scss';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from '../../../logic/order';
import { useRouteMatch, Link } from 'react-router-dom';
import { GlobalState } from 'src/logic/reducers';
import { displayPrice } from 'src/logic/utils';
import { routes } from '../../../routes';
import { OrderMenuItems } from './orderMenuItems';
import { PriceSummarySection } from './priceSummarySection';
import { DeliverySection } from './deliverySection';
import { PaymentSection } from './paymentSection';
import { SideColumnn, MainColumnn, ColumnsWrapper } from 'src/containers/columns/columns';
import AccountHeader from '../accountHeader';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { OrderCreatedPaymentStatus, OrderCreatedModal } from 'src/components/order/congrats/orderCreated';

const MyOrderDetails = () => {
  const dispatch = useDispatch();
  const m = useRouteMatch<{ orderId: string; action: OrderCreatedPaymentStatus | 'display' }>();
  const action = m.params.action;
  useEffect(() => {
    dispatch(orderActions.load.request(m.params.orderId))
  }, [dispatch, m.params.orderId])

  useEffect(()=> {
    window.scrollTo(0,0);
  }, []);

  //this is to be replaced with some order logic.
  const order = useSelector((gs: GlobalState) => gs.order.details);
  const title = 'Szczegóły zamówienia';
  const canceled = order?.status === 'canceled' || order?.deletedAt;
  const [showmodal, changeShowmodal] = useState(action !== 'display');

  const showPriceSummary = (order?.menus || []).filter(o => !o.deletedAt).length > 1 || (order?.vouchers || []).length > 0;

  return (
    <>
    <AccountHeader />
    <PageWrapper>
      <h1>{title}</h1>
      <Link to={routes.account()} className={styles.backLink}><img src="/icons/back-arrow.svg" alt="wróć" />wróć </Link>
      <ColumnsWrapper invert>
      <SideColumnn>
        <PaymentSection order={order} />
      </SideColumnn>
      <MainColumnn>
      {
        canceled && (
          <header className={styles.canelInfo}>
            <h4>Zamównienie anulowano</h4>
            Środki ({displayPrice(order?.price)}) trafiły na Twoje saldo
          </header>
        )
      }

      <section className={[styles.orderSummary, canceled && styles.orderCanceled].join(' ')}>
        <div className={styles.orderGroup}>
          {
            order?.menus?.filter(o => !o.deletedAt)?.map((om, idx) => (
              <Fragment key={om.id}>
                {idx > 0 && <hr />}

                <div className={styles.orderItem}>
                  <Link to={routes.delivery(m.params.orderId, om.id)}>
                    <big>Dostawa na {om.menu?.date && format(om.menu?.date,  'EEEEEE, P', { locale: pl })}</big>
                  </Link></div>
                <OrderMenuItems orderMenu={om} />
              </Fragment>
            ))
          }
        </div>
      </section>

      {showPriceSummary && <PriceSummarySection order={order} />}
      <DeliverySection user={order?.user} address={order?.menus?.find(a => !!a.address)?.address ?? order?.menus?.find(a => !!a.location)?.location} />
      </MainColumnn>
      </ColumnsWrapper>
      <OrderCreatedModal open={showmodal} status={m.params.action !== 'display' ? m.params.action : undefined} close={() => changeShowmodal(false)} order={order}/>
    </PageWrapper>
    </>
  );
};

export default MyOrderDetails;
