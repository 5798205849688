import React, { useCallback } from 'react';
import styles from './checkBox.module.scss';
import { useField } from 'formik';

type CheckBoxProps = {
  checked: boolean;
  radioValue?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onChange?(v: boolean): void;
  error?: string;
};

const CheckBox = ({
  checked,
  disabled,
  children,
  onChange,
  radioValue,
  error,
}: CheckBoxProps) => {
  const toggleCheck = useCallback(() => {
    if (disabled) {
      return;
    }
    onChange?.(radioValue != null ? radioValue : !checked);
  }, [onChange, radioValue, disabled, checked]);

  const isChecked = (radioValue == null && checked) || (radioValue != null && radioValue === checked);
  return (
    <div
      onClick={toggleCheck}
      className={[
        styles.checkboxWrapper,
        isChecked && styles.checked,
        disabled && styles.disabled,
      ].join(' ')}
    >
      <div className={[styles.checkIcon, radioValue != null && styles.radio, error && styles.error].join(' ')}>
        {
          isChecked &&
          (
            (radioValue != null)
              ? (<div></div>)
              : (
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 2.5L3.5 5L7.5 1" stroke="white" strokeWidth="2" />
                </svg>
              )
          )
        }
      </div>
      <label className={styles.label}>{children}</label>
    </div>
  );
};

export default CheckBox;

type CheckBoxFormFieldProps<K extends string> = Omit<CheckBoxProps, 'checked'> & { name: K; parent?: string }

export const CheckBoxFormField = <K extends string>({ name, parent, onChange, ...props }: CheckBoxFormFieldProps<K>) => {
  const [, meta, {setValue, setTouched}] = useField({ name: parent ? `${parent}.${name}` : name, multiple: true });
  const handleChange = useCallback((v) => {
      setValue(v);
      setTimeout(() => setTouched(true), 0);
      onChange?.(v);
    },
    [setValue, setTouched, onChange]
  );
  return <CheckBox {...props} checked={meta.value} onChange={handleChange} error={meta.touched ? meta.error : undefined} />
}
