import React, { useState } from 'react';
import ContentPage from '../contentPage/contentPage';
import PageWrapper from 'src/containers/pageWrapper/pageWrapper';

import styles from './contact.module.scss';

import googleIcon from './google_play_ico.svg';
import appstoreIcon from './appstore_ico.svg';
import instaIcon from './insta_ico.svg';
import fbIcon from './fb_ico.svg';
import { useSelector } from 'react-redux';
import { GlobalState } from 'src/logic/reducers';


const Contact = () => {
  const menuFile = useSelector((gs: GlobalState) => gs.settings.MENU_FILE);
  return (
    <ContentPage>
      <PageWrapper>
        <h1 className='h1'>Kontakt</h1>
        {menuFile && <h3>W tym miejscu znajdziesz nasze menu obiadowe na ten tydzień: <a href={menuFile} download="menu.pdf">pobierz</a></h3>}
        <div className='split split50'>
          <section className={styles.socialSection}>
            <h3>Znajdziesz nas na</h3>
            <div className={styles.socialLinks}>
              <a href={'https://www.instagram.com/pobudka_catering/'} rel='noopener noreferrer' target='_blank' className={styles.socialLink}>
                <img src={instaIcon} alt={'Śledź nas na Instagramie'} /> Instagram
              </a>
              <a href={'https://www.facebook.com/Pobudka.Catering/'} rel='noopener noreferrer' target='_blank' className={styles.socialLink}>
                <img src={fbIcon} alt={'Polub nas na Facebooku'} /> Facebook
              </a>
            </div>
          </section>
          <section className={styles.socialSection}>
            <h3>Pobierz naszą aplikację mobilną:</h3>
            <div className={styles.appLinks}>
              <a
                href={'https://play.google.com/store/apps/details?id=pl.waw.pobudka.twa'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <img src={googleIcon} alt={'Pobierz aplikacje z Google Play'} />
              </a>
              <a href={'https://apps.apple.com/pl/app/pobudka/id1539031354'} rel='noopener noreferrer' target='_blank'>
                <img src={appstoreIcon} alt={'Pobierz aplikacje z Appstore'} height={42} />
              </a>
            </div>
          </section>
        </div>
        <h2 className='h2'>Głodny? Skontaktuj się z nami!</h2>
        <div >
          <section>
            <p className='tel'>
              <a href='tel:+48222438300'>+48 222 438 300</a>
            </p>
            <p className='tel'>
            <a href='mailto:on-line@pobudka.waw.pl'>on-line@pobudka.waw.pl</a><span>dla zamówień internetowych</span>
            </p>
            <p className='tel'>
            <a href='mailto:kontakt@pobudka.waw.pl'>kontakt@pobudka.waw.pl</a><span>dla sprzedaży w biurach</span>
            </p>
            <p className='tel'>
              <span>Godziny pracy biura obsługi klienta:</span>{' '}
            </p>
            <p className='tel'>
              <a >poniedziałek - piątek:</a>{' '}
              <a >08:00 - 15:00</a>{' '}
            </p>

          </section>
        </div>
      </PageWrapper>
    </ContentPage>
  );
};

export default Contact;
