import { call, apply, put, takeLatest, select } from 'redux-saga/effects';
import { ActionType, getType, createAsyncAction } from 'typesafe-actions';
import { AddressApi, Address, Location, UserApi, User, VoucherApi, Voucher } from 'src/api'
import { getApi } from './utils';
import { GlobalState } from 'src/logic/reducers';
import { getRelations } from 'src/logic/utils';
import { toast } from 'react-toastify';

export const getDeliveryHours = (): string => {
  return 'Dostawa realizowana jest w godz. 00:00 - 8:00';
};

export type State = {
  addresses: Address[];
  location: Location | null;
  pending: boolean;
}
export const addressesActions = {
  load: createAsyncAction('load addresses', 'load addresses success', 'load addresses failure')<void, { addresses: Address[]; location: Location | null }, Error>(),
  upsert: createAsyncAction('upsert address', 'upsert address success', 'upsert address failure')<Address, Address, Error>(),
  removeLocation: createAsyncAction('remove location', 'remove location success', 'remove location failure')<void, void, Error>(),
  addLocation: createAsyncAction('add location', 'add location success', 'add location failure')<string, Location, Error>(),
};

export type AddressActions = ActionType<typeof addressesActions>;

const initialState: State = {
  addresses: [],
  location: null,
  pending: false
};

export const addressesReducer = (state: State = initialState, action: AddressActions): State => {
  switch (action.type) {
    case getType(addressesActions.load.request): return { ...state, addresses: initialState.addresses, pending: true };
    case getType(addressesActions.load.success): return { ...state, addresses: action.payload.addresses, location: action.payload.location, pending: false };
    case getType(addressesActions.load.failure): return { ...state, addresses: initialState.addresses, location: null, pending: false };

    case getType(addressesActions.upsert.request): return { ...state, pending: true };
    case getType(addressesActions.upsert.success): return { ...state, addresses: state.addresses.find(a => a.id === action.payload.id) ? state.addresses.map(a => a.id === action.payload.id ? action.payload : a) : [...state.addresses, action.payload], pending: false };
    case getType(addressesActions.upsert.failure): return { ...state, pending: false };

    case getType(addressesActions.removeLocation.request): return { ...state, pending: true };
    case getType(addressesActions.removeLocation.success): return { ...state, location: null, pending: false };
    case getType(addressesActions.removeLocation.failure): return { ...state, pending: false };

    case getType(addressesActions.addLocation.request): return { ...state, pending: true };
    case getType(addressesActions.addLocation.success): return { ...state, location: action.payload, pending: false };
    case getType(addressesActions.addLocation.failure): return { ...state, location: null, pending: false };
    default: return state;
  }
};

function* onLoad() {
  const userApi: UserApi = yield call(getApi, UserApi);
  const userId = yield select((gs: GlobalState) => gs.user.details?.id);
  if (!userId) {
    return;
  }
  try {
    // TODO: change this fetch to userApi when ACL will be in place
    const response: User = yield apply(userApi, userApi.apiUsersIdGet, [userId, getRelations<User>({ addresses: true, locations: true })]);
    const addresses = response.addresses ?? initialState.addresses;
    const location = response.locations?.filter((v): v is Location => !!v)?.reverse()?.[0] ?? null;
    yield put(addressesActions.load.success({ addresses, location }));
  } catch (e) {
    yield put(addressesActions.load.failure(e));
  }
}

function* onUpsert({ payload }: ReturnType<typeof addressesActions.upsert.request>) {
  const addressApi: AddressApi = yield call(getApi, AddressApi);
  const userId = yield select((gs: GlobalState) => gs.user.details?.id);
  if (!userId) {
    return;
  }
  try {
    const response: Address = yield apply(addressApi, addressApi.apiAddressesPost, [getRelations<Address>({ user: true }), true, { ...payload, userId }]);
    yield put(addressesActions.upsert.success(response));
  } catch (e) {
    yield put(addressesActions.upsert.failure(e));
  }
}

function* onRemoveLocation() {
  const api: UserApi = yield call(getApi, UserApi);

  try {
    const userId = yield select((gs: GlobalState) => gs.user.details?.id);
    const user: User = yield apply(api, api.apiUsersIdGet, [userId ?? 0, getRelations<User>({ roles: true, addresses: true, locations: true }), false])
    yield apply(api, api.apiUsersPostRaw, [{ relations: getRelations<User>({ roles: true, addresses: true, locations: true }), deleted: false, entity: JSON.stringify({ ...user, location: [] }) as any }]);
    yield put(addressesActions.removeLocation.success());
    // todo refresh token & details?
  } catch (e) {
    yield put(addressesActions.removeLocation.failure(e));
  }
}

function* onAddLocation({ payload }: ReturnType<typeof addressesActions.addLocation.request>) {
  const voucherApi: VoucherApi = yield call(getApi, VoucherApi);

  try {
    const voucher: Voucher = yield apply(voucherApi, voucherApi.apiVouchersCodeCodeGet, [payload, getRelations<Voucher>({ location: true, categories: true })]);
    if (voucher.used || !voucher.location) {
      yield call(toast.error, 'Niepoprawny voucher');
      return;
    }
    yield apply(voucherApi, voucherApi.apiVouchersUserCodePut, [payload]);
    yield put(addressesActions.addLocation.success(voucher.location));
  } catch (e) {
    yield call(toast.error, 'Niepoprawny voucher');
    yield put(addressesActions.addLocation.failure(e));
  }
}

export function* addressesSaga(): IterableIterator<any> {
  yield takeLatest(getType(addressesActions.load.request), onLoad);
  yield takeLatest(getType(addressesActions.upsert.request), onUpsert);
  yield takeLatest(getType(addressesActions.removeLocation.request), onRemoveLocation);
  yield takeLatest(getType(addressesActions.addLocation.request), onAddLocation);
}
