/* tslint:disable */
/* eslint-disable */
/**
 * PanKanapka
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiUsersEntity,
    ApiUsersEntityFromJSON,
    ApiUsersEntityToJSON,
    InlineResponse403,
    InlineResponse403FromJSON,
    InlineResponse403ToJSON,
    ResetExecuteRequest,
    ResetExecuteRequestFromJSON,
    ResetExecuteRequestToJSON,
    ResetGenerateRequest,
    ResetGenerateRequestFromJSON,
    ResetGenerateRequestToJSON,
    SignResponse,
    SignResponseFromJSON,
    SignResponseToJSON,
    UnsubscribeRequest,
    UnsubscribeRequestFromJSON,
    UnsubscribeRequestToJSON,
    UnsubscribeResponse,
    UnsubscribeResponseFromJSON,
    UnsubscribeResponseToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserDataFrame,
    UserDataFrameFromJSON,
    UserDataFrameToJSON,
    UserPayoutRequest,
    UserPayoutRequestFromJSON,
    UserPayoutRequestToJSON,
} from '../models';

export interface ApiUsersGetRequest {
    relations?: string;
    deleted?: boolean;
    filtering?: string;
    offset?: number;
    limit?: number;
    order?: string;
}

export interface ApiUsersIdDeleteRequest {
    id: number;
}

export interface ApiUsersIdGetRequest {
    id: number;
    relations?: string;
    deleted?: boolean;
}

export interface ApiUsersPayoutPostRequest {
    userPayoutRequest?: UserPayoutRequest;
}

export interface ApiUsersPostRequest {
    relations?: string;
    deleted?: boolean;
    entity?: ApiUsersEntity;
    avatar?: Blob;
}

export interface ApiUsersResetCodePostRequest {
    code: string;
    resetExecuteRequest?: ResetExecuteRequest;
}

export interface ApiUsersResetPostRequest {
    resetGenerateRequest?: ResetGenerateRequest;
}

export interface ApiUsersUnsubscribePostRequest {
    unsubscribeRequest?: UnsubscribeRequest;
}

export interface ApiUsersVerifyCodeGetRequest {
    code: string;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Get list of User
     */
    async apiUsersGetRaw(requestParameters: ApiUsersGetRequest): Promise<runtime.ApiResponse<UserDataFrame>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.filtering !== undefined) {
            queryParameters['filtering'] = requestParameters.filtering;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDataFrameFromJSON(jsonValue));
    }

    /**
     * Get list of User
     */
    async apiUsersGet(relations?: string, deleted?: boolean, filtering?: string, offset?: number, limit?: number, order?: string): Promise<UserDataFrame> {
        const response = await this.apiUsersGetRaw({ relations: relations, deleted: deleted, filtering: filtering, offset: offset, limit: limit, order: order });
        return await response.value();
    }

    /**
     * Remove entity of User
     */
    async apiUsersIdDeleteRaw(requestParameters: ApiUsersIdDeleteRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Remove entity of User
     */
    async apiUsersIdDelete(id: number): Promise<User> {
        const response = await this.apiUsersIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * Get entity of User
     */
    async apiUsersIdGetRaw(requestParameters: ApiUsersIdGetRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get entity of User
     */
    async apiUsersIdGet(id: number, relations?: string, deleted?: boolean): Promise<User> {
        const response = await this.apiUsersIdGetRaw({ id: id, relations: relations, deleted: deleted });
        return await response.value();
    }

    /**
     * Payout amount from wallet
     */
    async apiUsersPayoutPostRaw(requestParameters: ApiUsersPayoutPostRequest): Promise<runtime.ApiResponse<User>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/payout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPayoutRequestToJSON(requestParameters.userPayoutRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Payout amount from wallet
     */
    async apiUsersPayoutPost(userPayoutRequest?: UserPayoutRequest): Promise<User> {
        const response = await this.apiUsersPayoutPostRaw({ userPayoutRequest: userPayoutRequest });
        return await response.value();
    }

    /**
     * Create or update entity of User (if id defined)
     */
    async apiUsersPostRaw(requestParameters: ApiUsersPostRequest): Promise<runtime.ApiResponse<User>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.relations !== undefined) {
            queryParameters['relations'] = requestParameters.relations;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.entity !== undefined) {
            formParams.append('entity', requestParameters.entity as any);
        }

        if (requestParameters.avatar !== undefined) {
            formParams.append('avatar', requestParameters.avatar as any);
        }

        const response = await this.request({
            path: `/api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Create or update entity of User (if id defined)
     */
    async apiUsersPost(relations?: string, deleted?: boolean, entity?: ApiUsersEntity, avatar?: Blob): Promise<User> {
        const response = await this.apiUsersPostRaw({ relations: relations, deleted: deleted, entity: entity, avatar: avatar });
        return await response.value();
    }

    /**
     * Reset password
     */
    async apiUsersResetCodePostRaw(requestParameters: ApiUsersResetCodePostRequest): Promise<runtime.ApiResponse<SignResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling apiUsersResetCodePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/reset/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetExecuteRequestToJSON(requestParameters.resetExecuteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignResponseFromJSON(jsonValue));
    }

    /**
     * Reset password
     */
    async apiUsersResetCodePost(code: string, resetExecuteRequest?: ResetExecuteRequest): Promise<SignResponse> {
        const response = await this.apiUsersResetCodePostRaw({ code: code, resetExecuteRequest: resetExecuteRequest });
        return await response.value();
    }

    /**
     * Generate reset code
     */
    async apiUsersResetPostRaw(requestParameters: ApiUsersResetPostRequest): Promise<runtime.ApiResponse<object>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetGenerateRequestToJSON(requestParameters.resetGenerateRequest),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Generate reset code
     */
    async apiUsersResetPost(resetGenerateRequest?: ResetGenerateRequest): Promise<object> {
        const response = await this.apiUsersResetPostRaw({ resetGenerateRequest: resetGenerateRequest });
        return await response.value();
    }

    /**
     * Unsubscribe
     */
    async apiUsersUnsubscribePostRaw(requestParameters: ApiUsersUnsubscribePostRequest): Promise<runtime.ApiResponse<UnsubscribeResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users/unsubscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsubscribeRequestToJSON(requestParameters.unsubscribeRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnsubscribeResponseFromJSON(jsonValue));
    }

    /**
     * Unsubscribe
     */
    async apiUsersUnsubscribePost(unsubscribeRequest?: UnsubscribeRequest): Promise<UnsubscribeResponse> {
        const response = await this.apiUsersUnsubscribePostRaw({ unsubscribeRequest: unsubscribeRequest });
        return await response.value();
    }

    /**
     * Verify user
     */
    async apiUsersVerifyCodeGetRaw(requestParameters: ApiUsersVerifyCodeGetRequest): Promise<runtime.ApiResponse<SignResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling apiUsersVerifyCodeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users/verify/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignResponseFromJSON(jsonValue));
    }

    /**
     * Verify user
     */
    async apiUsersVerifyCodeGet(code: string): Promise<SignResponse> {
        const response = await this.apiUsersVerifyCodeGetRaw({ code: code });
        return await response.value();
    }

}
