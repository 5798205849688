import React from 'react';
import styles from './iconParagraph.module.scss';

type IconParagraphProps = {
  children?: React.ReactNode;
  image?: string;
};

const IconParagraph = ({ children, image }: IconParagraphProps) => (
  <section className={styles.iconParagraph}>
    <picture className={styles.pic}>
      <img src={image} alt='' />
    </picture>
    <article className={styles.text}><p>{children}</p></article>
  </section>
);
export default IconParagraph;
