import React, { useEffect, useState } from 'react';
import { default as DatePickerBase, ReactDatePickerProps, registerLocale } from 'react-datepicker';
import styles from './datePicker.module.scss';
import { RightActions, Props as RightActionsProps, } from '../rightActions';
import pl from 'date-fns/locale/pl';

import cn from 'classnames';

export function DatePicker({ disabled = false, disableActions, rightActions, fill, ...rest }: ReactDatePickerProps & RightActionsProps & { fill: boolean }) {
  useEffect(() => { registerLocale('pl', pl) }, [])
  return (
    <div className={cn(styles.component, { [styles.fill]: fill })}>
      <DatePickerBase className={styles.input} {...rest} disabled={disabled} locale='pl' />
      <RightActions rightActions={rightActions} disableActions={disableActions} disabled={disabled} />
    </div>
  )
}

export type DateRange = { minDate: Date | null; maxDate: Date | null };
export const isDateRangeFilterValue = (x: any): x is DateRange => !!x && typeof x === 'object' &&
  'minDate' in x && (x.minDate instanceof Date || x.minDate == null) &&
  'maxDate' in x && (x.maxDate instanceof Date || x.maxDate == null);

export function DateRange({ onChange, fill, rightActions, value }: { onChange?: (dr: DateRange) => void; fill: boolean; value: DateRange | undefined } & RightActionsProps) {
  const [startDate, setStartDate] = useState<Date | null>(value?.minDate ?? null);
  const [endDate, setEndDate] = useState<Date | null>(value?.maxDate ?? null);
  useEffect(() => {
    onChange?.({ minDate: startDate, maxDate: endDate });
  }, [startDate, endDate, onChange])

  return (
    <div className={cn(styles.component, { [styles.fill]: fill })}>
      <DatePicker
        fill={fill}
        selected={startDate}
        onChange={date => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        isClearable
      />
      <DatePicker
        fill={fill}
        selected={endDate}
        onChange={date => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        isClearable
        rightActions={rightActions}
      />
    </div>
  )
}
